import * as React from 'react';
import {useState} from 'react';
import {LoadableFontSpecs} from "./font";
import {useRender} from "./useRender";
import {RoughPath} from './svg-rough';
import {Popover, LetterDetails} from '../farsi-popover/popover';
import {useAnnotations} from "./annotations";
import {LetterStyle, RenderFunc} from "./svg.types";

/**
 * Consider this an opinionated helper class. If you need more customization,
 * you can always render the letters individually.
 */
export function SvgLettering(props: {
  font: LoadableFontSpecs,
  text: string,
  annotations?: any[],
  annotationMode?: "mix"|"below",
  children?: RenderFunc,
  fontSize?: number,

  // This is the base style for the letters.
  letterStyle?: LetterStyle,
  hoverLetterStyle?: LetterStyle,
  annotationStyle?: any,

  // Use rough.js
  useRough?: boolean,
  roughConfig?: any

  // Per letter color source
  getColor?: (idx: number) => string,

  onPathsChanged?: (paths: any) => void,
  style?: any,
  className?: string
}) {
  const {boundingBox, paths, chars} = useRender(props);
  const {annotations, shift, size} = useAnnotations({
    paths,
    annotations: props.annotations,
    mode: props.annotationMode,
    boundingBox,
    distance: 30
  });

  React.useEffect(() => {
    if (props.onPathsChanged) {
      props.onPathsChanged(paths);
    }
  }, [paths, props.onPathsChanged]);

  if (!boundingBox) {
    return null;
  }

  if (!paths) {
    return null;
  };

  const width = size?.width;
  const height = size?.height;

  return <svg viewBox={`0 0 ${width} ${height}`} className={props.className} style={props.style}>
    <g transform={`translate(${shift.x}, ${shift.y})`}>
    {paths.map((path, idx) => {
      return <Letter
        index={idx}
        key={idx}
        path={path}
        char={chars![idx]!}
        letterStyle={props.letterStyle}
        hoverLetterStyle={props.hoverLetterStyle}
        getColor={props.getColor}
        useRough={props.useRough}
        roughConfig={props.roughConfig}
        className={"letter"}
      />
    })}

    {annotations ? <g className={"annotations"}>
      {annotations?.map((annotation, idx) => {
        const color = props.getColor?.(idx);
        return <g className={"annotation"}>
          {/*<rect*/}
          {/*  x={annotation.left}*/}
          {/*  y={annotation.top}*/}
          {/*  width={props.annotations![idx].width}*/}
          {/*  height={props.annotations![idx].height}*/}
          {/*  stroke={"black"}*/}
          {/*  fill={color}*/}
          {/*/>*/}
          <text x={annotation.left + props.annotations![idx].width/2}
                y={annotation.top + props.annotations![idx].height/2}
                textAnchor="middle" alignmentBaseline="middle"
                {...props.annotationStyle}
          >{props.annotations?.[idx].label}</text>
          {annotation.arrow ?
            <line
              x1={annotation.arrow?.start.x} y1={annotation.arrow.start.y}
              x2={annotation.arrow?.end.x} y2={annotation.arrow.end.y}
              stroke={color}
            />
            : null}
        </g>
      })}
    </g> : null}

    {props.children?.({paths: paths})}
    </g>
  </svg>
}


// It might be better to have the logic "up one level" of handling mouse over etc. Smarter hit testing for example..
function Letter(props: {
  index: number,
  path: opentype.Path,
  char: string,

  letterStyle?: LetterStyle,
  hoverLetterStyle?: LetterStyle,
  useRough?: boolean,
  roughConfig?: any,
  getColor?: (idx: number) => string,

  className?: string,
  hasPopup?: boolean,
}) {
  const [isHovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };

  const box = props.path.getBoundingBox();
  const path = React.useMemo(() => props.path.toPathData(4), [props.path]);
  const color = React.useMemo(() => props.getColor?.(props.index), [props.index, props.getColor]);

  const style = React.useMemo(() => {
    return isHovered ? {...props.letterStyle, ...props.hoverLetterStyle} : props.letterStyle;
  }, [isHovered, props.hoverLetterStyle, props.letterStyle])

  const shapeProps: RenderPathProps = {
    path: path,
    fill: (style?.fill == "auto" ? color : style?.fill) ?? "black",
    stroke: (style?.stroke == "auto" ? color : style?.stroke) ?? "none",
    strokeWidth: style?.strokeWidth ?? 2,
  }

  let Class = props.useRough ? RoughPath : NormalPath;
  let shape = <Class {...shapeProps} />

  let outlineRect = <rect
    x={box.x1}
    y={box.y1}
    width={box.x2 - box.x1}
    height={box.y2 - box.y1}
    onMouseOver={handleMouseOver}
    onMouseOut={handleMouseOut}
    fill={"transparent"}
    stroke={style?.bboxVisible ? "red" : undefined}
  />;

  if (props.hasPopup) {
    outlineRect = <Popover
      content={<LetterDetails letter={props.char.normalize('NFKD')} />}
      interactive={true}
      appendTo={document.body}
    >
      {outlineRect}
    </Popover>;
  }

  return <g className={props.className}>
    {shape}
    {outlineRect}
  </g>
}
Letter.defaultProps = {
  fillColor: 'black',
  fillColorHover: 'red'
}

export type RenderPathProps = {
  path: string,
  fill?: string,
  stroke?: string,
  strokeWidth?: number
};


export function NormalPath(props: RenderPathProps) {
  return <>
    <path
      d={props.path}
      fill={props.fill}
      stroke={props.stroke}
      strokeWidth={props.strokeWidth}
    />
  </>
}


// Manually draw:
//let roughSvg = rough.svg(document.getElementById('svg'));
// const el = React.useMemo(() => {
//   const e: any = document.createElementNS("http://www.w3.org/2000/svg", 'rect')
//   e.setAttribute("width", 299);
//   e.setAttribute("height", 299);
//   e.setAttribute("fill", "red");
//   return e;
// }, []);
//
// const r = React.useRef<any>();
// React.useEffect(() => {
//   r.current!.appendChild(el);
// }, [el])
//
// return <g className={props.className} ref={r}>
// </g>

