import React from "react";
import {PickOrCreateWorldField} from "./PickOrCreateWordField";
import { FieldArray, useFormikContext } from "formik";
import {useNestedValues} from "../../../../../../components/NestedFormik";


export function CompoundVerbPrimaryField(props: {}) {
  const values = useNestedValues();

  const newItemButton = (arrayHelpers: any) => <button
    type="button"
    onClick={() => arrayHelpers.push({id: true})}
  >+</button>;

  return <FieldArray
    name="primary"
    render={arrayHelpers => (
      <div>
        {values.primary.map((primary: any, index: number) => (
          <div key={index}>
            <CompoundVerbSinglePrimaryField
              name={`primary.${index}`}
              actionLinks={[
                <button type="button" onClick={() => arrayHelpers.remove(index)}>-</button>,
                index == values.primary.length-1 ? newItemButton(arrayHelpers) : null
              ]}
            />
          </div>
        ))}

        {values.primary.length === 0 ? newItemButton(arrayHelpers) : null}
      </div>
    )}
  />
}

export function CompoundVerbSinglePrimaryField(props: {
  name: string,
  actionLinks?: any[]
}) {
  return <PickOrCreateWorldField
    name={props.name}
    label="Primary Component"
    helperText={"For راست گفتن, this is راست."}
    config={{
      disallowedWordTypes: ['verb']
    }}
    actionLinks={props.actionLinks}
  />
}


