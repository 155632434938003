/**
 * https://github.com/apollographql/apollo-feature-requests/issues/85#issuecomment-533108029
 */


import React, { useContext } from 'react';
import {ApolloClient, DocumentNode} from '@apollo/client';
import {OperationVariables, QueryHookOptions, QueryResult, MutationHookOptions, MutationTuple} from '@apollo/client';
import * as hooks from '@apollo/client';
import {LazyQueryHookOptions, QueryTuple} from '@apollo/client';


export interface ApolloContextValue {
  clients?: {[key: string]: ApolloClient<object>};
  defaultClient?: ApolloClient<object>,
  renderPromises?: Record<any, any>;
}

let apolloContext: React.Context<ApolloContextValue>;


export function getApolloContext() {
  if (!apolloContext) {
    apolloContext = React.createContext<ApolloContextValue>({});
  }
  return apolloContext;
}

export interface ApolloProviderProps<TCache> {
  clients?: {[key: string]: ApolloClient<TCache>};
  defaultClient?: ApolloClient<TCache>,
  children: React.ReactNode | React.ReactNode[] | null;
}

export const ApolloProvider: React.FC<ApolloProviderProps<any>> = (
  {
    defaultClient,
    clients,
    children
  }) =>
{
  const ApolloContext = getApolloContext();

  // invariant(
  //   context.client,
  //   'ApolloProvider was not passed a client instance. Make ' +
  //   'sure you pass in your client via the "client" prop.'
  // );

  const context = {
    clients,
    defaultClient
  }

  return (
    <ApolloContext.Provider value={context}>
      {children}
    </ApolloContext.Provider>
  );
};


export function useClient(name: string|ApolloClient<any>|undefined): ApolloClient<object> {
  if (name instanceof ApolloClient) {
    return  name;
  }

  const context = useContext(getApolloContext());

  if (name === undefined) {
    if (!context.defaultClient) {
      throw new Error(`No default client found in <MultiApollo.ApolloProvider>.`)
    }
    return context.defaultClient;
  }

  if (context.clients && context.clients[name]) {
    return context.clients[name]
  }
  throw new Error(`No client with the name ${name} found in <MultiApollo.ApolloProvider>.`)
}


interface MultiApolloQueryHookOptions<TData, TVariables> extends Omit<QueryHookOptions<TData, TVariables>, 'client'> {
  client?: string
};


export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: MultiApolloQueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables>
{
  const {client, ...rest} = options || {};
  const clientObj = useClient(client);

  const realOptions = {
    ...rest,
    client: clientObj
  };
  return hooks.useQuery(query, realOptions);
}


interface MultiApolloLazyQueryHookOptions<TData, TVariables> extends Omit<LazyQueryHookOptions<TData, TVariables>, 'client'> {
  client?: string
};


export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: MultiApolloLazyQueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables>
{
  const {client, ...rest} = options || {};
  const clientObj = useClient(client);

  const realOptions = {
    ...rest,
    client: clientObj
  };
  return hooks.useLazyQuery(query, realOptions);
}


interface MultiApolloMutationHookOptions<TData, TVariables> extends Omit<MutationHookOptions<TData, TVariables>, 'client'> {
  client?: string
};


export function useMutation<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: MultiApolloMutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables>
{
  const {client, ...rest} = options || {};
  const clientObj = useClient(client);

  const realOptions = {
    ...rest,
    client: clientObj
  };
  return hooks.useMutation(query, realOptions);
}
