import {NestableField} from "../../../../components/NestedFormik";
import {validateRequired} from "../index";
import React from "react";
import {useUIControls} from "../../../../components/FormUISwitch";


export function WordNumeralQuantityField(props: any) {
  const UI = useUIControls();

  return <NestableField
    name={"quantity"}
    component={UI.Text}

    label="Quantity"
    description={"The quantity this numeral represents."}
    validate={validateRequired}

    {...props}
  />;
}