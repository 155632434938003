import * as React from 'react';
import {Popover, IconButton, makeStyles, Typography, Button, Link} from '@material-ui/core';
import { Forum } from '@material-ui/icons';
import { Formik, Field, FieldProps } from 'formik';
import { TextField } from 'formik-material-ui';
import {
  IndexedContentIssue,
  IndexedContentIssues,
  useCreateContentIssue,
  useSaveContentIssue
} from "../../Market/ContentIssues";
import classnames from 'classnames';
import { useState } from 'react';
import {useDialogs} from "../../../ui/dialogs/DialogManager";
import {IssueDetailsDialog} from "../../Market/ContentIssuesScreen/IssueDetailsDialog";


const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(1),
  },
  hasIssues: {
    display: 'block !important'
  }
}));


export const CreateIssueButton = React.memo((props: {
  vocableId: string,
  lineIndex: number,
  trackVersionId: string,
  buttonClassName?: string,
  contentIssues?: IndexedContentIssues
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const firstIssue = props.contentIssues?.byVocable?.[props.vocableId]?.[0];
  const hasIssues = !!firstIssue;

  return <>
    <IconButton
      size={hasIssues ? "medium" : 'small'}
      className={classnames(props.buttonClassName, {
        [classes.hasIssues]: !!firstIssue
      })}
      onClick={handleClick}
    >
      <Forum
        fontSize="inherit"
        color={!hasIssues ? "secondary" : undefined}
        htmlColor={hasIssues ? 'orange' : undefined}
      />
    </IconButton>

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <AddEditIssuePopupContent
        existingIssue={firstIssue}
        vocableId={props.vocableId}
        lineIndex={props.lineIndex}
        trackVersionId={props.trackVersionId}
        onRequestClose={() => setAnchorEl(null)}
      />
    </Popover>
  </>
});


export function AddEditIssuePopupContent(props: {
  existingIssue?: IndexedContentIssue,
  vocableId: string,
  lineIndex: number,
  trackVersionId: string,
  onRequestClose: () => void,
}) {
  const {existingIssue} = props;
  const dialogs = useDialogs();
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(!props.existingIssue);

  let content;
  if (!isEditing) {
    content = <div>
      <UserTag editor={props.existingIssue?.issue.assignedTo} style={{marginRight: '4px'}} />

      {props.existingIssue?.issue.description}

      <div>
        <Link onClick={() => setIsEditing(true)}>Edit</Link>
        {"  "}
        {props.existingIssue ? <Link onClick={() =>
          dialogs.show(IssueDetailsDialog, {
            contentIssueId: existingIssue!.issue.id
          })
        }>Comments</Link> : null}
      </div>
    </div>
  }
  else {
    content = <WriteIssueForm
      initialText={existingIssue?.issue.description}
      editIssueId={existingIssue?.issue.id}
      vocableId={props.vocableId}
      lineIndex={props.lineIndex}
      trackVersionId={props.trackVersionId}
      onRequestClose={() => {
        setIsEditing(false);
      }}
    />;
  }

  return <Typography className={classes.typography} component={"div"}>
    {content}
  </Typography>
}


export function UserTag(props: {editor?: {name: string}|null, style?: any}) {
  if (!props.editor) {
    return null;
  }
  return <div style={{
    display: 'inline-block',
    borderRadius: '2px',
    background: '#2196f3',
    color: 'white',
    padding: '2px 4px',
    ...props.style
  }}>
    {props.editor.name}
  </div>
}


function WriteIssueForm(props: {
  initialText?: string,
  editIssueId?: string,
  vocableId: string,
  lineIndex: number,
  trackVersionId: string,
  onRequestClose: () => void
}) {
  const createContentIssue = useCreateContentIssue();
  const saveContentIssue = useSaveContentIssue();

  return (
    <Formik<{text: string}>
      initialValues={{
        text: props.initialText || ""
      }}
      onSubmit={async (values, { setSubmitting }) => {
       try {
         if (props.editIssueId) {
           await saveContentIssue({
             description: values.text,
             issueId: props.editIssueId
           });
         }
         else {
           await createContentIssue({
             description: values.text,
             captionTrackVersionId: props.trackVersionId,
             location: {
               vocable: {
                 vocableId: parseInt(props.vocableId),
                 lineIndex: props.lineIndex
               }
             }
           });
         }
         props.onRequestClose();
       }
       finally {
         setSubmitting(false);
       }
     }}
    >
      {formik => {
        return <>
          <Field name={"text"}>
            {
              (props: FieldProps) => {
                return <TextField multiline placeholder={"Describe the Issue"} {...props} />
              }
            }
          </Field>
          <div style={{marginTop: '10px'}}>
            <Button
              variant="contained"
              color={"primary"}
              disabled={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              Save
            </Button>
          </div>
        </>
      }}
    </Formik>)
}