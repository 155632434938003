import React from 'react';
import {useQuery} from "multi-apollo";
import { gql as gqlw } from '@apollo/client';
import {handleApolloState} from "../../utils/handleApolloState";
import {DateTime} from "../../utils/DateTime";
import {formatDistanceToNow, parseISO} from "date-fns";
import {
  ActivityLogEntry,
  activityLogEntryFragment, ActivityLogGroupedEntry,
  groupLogEntriesByUserAndTime
} from "../../components/AnnotationLogEntry";
import {WordActivityLogQuery} from "../../types/WordActivityLogQuery";


function useLog(wordId: string) {
  const {loading, error, data, refetch} = useQuery<WordActivityLogQuery>(gqlw`
    ${activityLogEntryFragment}
    query WordActivityLogQuery($id: Int!) {
      word(id: $id) {
        id,        
        createdBy { id, username },
        createdAt
      }
      
      activityLog(wordId: $id) {
        edges {
          node {
            id,
            ...ActivityLogEntryFragment
          }
        }        
      }
    }
  `, {
    variables: {id: wordId},
    client: 'words'
  });

  return {
    loading,
    refetch,
    error,
    changes: data ? data.activityLog?.edges.map(edge => edge?.node) : null,
    word: data ? data.word : null,
  }
}


export function LogTab(props: {wordId: any}) {
  const {changes, word, loading, error, refetch} = useLog(props.wordId);

  const stateComponent = handleApolloState({loading, error});
  if (stateComponent) {
    return stateComponent;
  }

  const groupedEntries = changes ? groupLogEntriesByUserAndTime(changes) : [];

  return  <div>
    {word ? <div>
      Created:{" "}
      <strong>
        {formatDistanceToNow(parseISO(word.createdAt))} ago
      </strong> on the {" "}
      <strong><DateTime date={word.createdAt} /></strong>
      {" "} by {" "}
      <strong>{word.createdBy ? (word.createdBy.username || word.createdBy.id) : "unknown"}</strong>
    </div> : null}

    {groupedEntries.map((group, idx: number) => {
      return <ActivityLogGroupedEntry
        entry={group}
        key={""+group.time}
        hideTitle={true}
      />
    })}
  </div>
}
