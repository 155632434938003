import React, {Fragment, useContext} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';


type Props = {
  onDialogStackChanged?: (args: {currentDialog: any}) => void
}


type State = {
  currentDialog: {
    klass: any,
    props: any,
    key: number
  }|null
};


export type OKCallback<D> = (data: D) => void;


// TODO: Find a way to remove the any from OKCallback
//type ShowFunction =

type Omit<T, K extends keyof T> = Pick<T, ({ [P in keyof T]: P } & { [P in K]: never } & { [x: string]: never, [x: number]: never })[keyof T]>;


export interface DialogProps {
  isOpen: boolean,
  onRequestClose: () => void
}

type DialogComponent<O extends DialogProps> = React.ComponentType<O>;


export type ContextAPI = {
  show: <O extends DialogProps>(DialogClass: DialogComponent<O>, callbackOrProps?: OKCallback<any>|Omit<O, keyof DialogProps>) => void;
}


export const DialogManagerContext = React.createContext<ContextAPI|null>(null);


/**
 * To access the dialog manage, to show dialogs.
 */
export function useDialogs() {
  return useContext(DialogManagerContext)!;
}

/**
 * Default dialog UI.
 */
export class BaseDialog extends React.Component<{
  title: any,
  actions: any,
  style?: any,

  disableBackdropClick?: boolean,
  fullWidth?: any,
  maxWidth?: any
} & DialogProps> {
  render() {
    const props = this.props;

    return <Dialog
      open={props.isOpen}
      onClose={props.onRequestClose}
      disableBackdropClick={props.disableBackdropClick}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent style={props.style}>
        {props.children}
      </DialogContent>
      <DialogActions>
        {props.actions}
      </DialogActions>
    </Dialog>
  }
}


/**
 * Hoists the state of which dialogs are visible up into the tree.
 */
export class DialogManager extends React.Component<Props> {
  state: State = {
    currentDialog: null
  };

  idCounter: number = 0;

  render() {
    let content;
    if (this.state.currentDialog) {
      const {klass: Klass, props, key} = this.state.currentDialog;
      content = <Fragment key={key}>
        <Klass {...props} />
      </Fragment>
    }

    return <DialogManagerContext.Provider value={this.contextAPI}>
      {content}
      {this.props.children}
    </DialogManagerContext.Provider>
  }

  show = <O, D>(DialogClass: React.ComponentType<DialogProps & O>, callbackOrProps?: OKCallback<D>|O) => {
    const baseProps: DialogProps = {
      isOpen: true,
      onRequestClose: () => {
        this.closeDialog();
      },
    };

    const argIsProps = typeof callbackOrProps === 'object';
    const extraProps = argIsProps
      ? {...callbackOrProps}
      : {
        onOK: (data: any) => {
          (callbackOrProps as any)(data);
          this.closeDialog();
        }
      };

    let instance = {
      key: this.getNextId(),
      klass: DialogClass,
      props: {
        ...baseProps,
        ...extraProps
      }
    }
    this.setState({
      currentDialog: instance
    }, this.triggerStackChangeEvent)
  }

  private getNextId() {
    this.idCounter++;
    return this.idCounter;
  }

  isDialogVisible = () => {
    return (!!this.state.currentDialog && this.state.currentDialog.props.isOpen);
  }

  private readonly contextAPI: ContextAPI = {
    show: this.show as any
  }

  private closeDialog() {
    if (!this.state.currentDialog) {
      return;
    }
    this.setState({
      currentDialog: {
        ...this.state.currentDialog,
        props: {
          ...this.state.currentDialog.props,
          isOpen: false
        }
      }
    }, this.triggerStackChangeEvent)
  }

  triggerStackChangeEvent = () => {
    if (this.props.onDialogStackChanged) {
      this.props.onDialogStackChanged({currentDialog: this.state.currentDialog});
    }
  }
}