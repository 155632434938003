import {useMutation} from "multi-apollo";
import gqlw from "graphql-tag";
import { useRef, useEffect, useState } from "react";
import { EventEmitter } from "events";
import loglevel from 'loglevel';


const log = loglevel.getLogger("RecordingWorkflow/useUploader");
log.setLevel("DEBUG");


function useAddRecording(sessionId: string|null) {
  const [addRecording, {data: mutationData}] = useMutation(gqlw`
    mutation AddRecording($upload: Upload!, $wordId: Int!, $sessionId: ID) {
      addRecording(data: $upload, wordId: $wordId, sessionId: $sessionId) {
        item {
          id,
          isComplete
        }
      }
    }
  `, {
    variables: {}
  });

  return (wordId: number, blob: Blob) => {
    log.info("Submitting addRecording mutation for blob of size: " + blob.size)
    return addRecording({
      variables: {
        upload: blob,
        wordId: wordId,
        sessionId: sessionId
      }
    })
  }
}


type Upload = {
  start: () => void,
  promise?: Promise<any>,
  isActive: boolean,
  success?: boolean,
  error?: boolean
}


export type WordUploadStatus = {
  numComplete: number,
  isUploading: boolean,
  hasFailed: boolean,
};


export class UploadManager extends EventEmitter {
  uploadsByWordId: {[key: number]: Upload[]} = {};
  private addRecording: ReturnType<typeof useAddRecording>;

  constructor(addRecording: ReturnType<typeof useAddRecording>) {
    super();
    this.setMaxListeners(100);
    this.addRecording = addRecording;
  }

  protected add(wordId: number, upload: Upload) {
    if (!this.uploadsByWordId[wordId]) {
      this.uploadsByWordId[wordId] = [];
    }
    this.uploadsByWordId[wordId].push(upload);
  }

  public getStatusForWord(wordId: number): WordUploadStatus {
    const uploads = this.uploadsByWordId[wordId] || [];

    return {
      numComplete: uploads.filter(upload => !upload.isActive).length,
      isUploading: uploads.filter(upload => upload.isActive).length > 0,
      hasFailed: uploads.filter(upload => !upload.isActive && upload.error).length > 0,
    }
  }

  retryAllFailed = () => {
    for (const uploads of Object.values(this.uploadsByWordId)) {
      for (const upload of uploads) {
        if (!upload.isActive && upload.error) {
          upload.start();
        }
      }
    }
  }

  public uploadFile(wordId: number, blob: Blob) {
    const upload: Upload = {
      start: () => {
        let promise = this.addRecording(wordId, blob);

        upload.promise = promise;
        upload.success = undefined;
        upload.error = undefined;
        upload.isActive = true;

        promise.then(
          x => {
            upload.success = true;
          },
          e => {
            upload.error = e;
          }
        ).finally(
          () => {
            upload.isActive = false;
            this.emit("update", wordId)
          }
        )
      },
      isActive: false
    }

    upload.start();

    this.add(wordId, upload);
    this.emit("update", wordId)

    return upload
  }

  getFailedCount = () => {
    let sum = 0;
    for (const uploads of Object.values(this.uploadsByWordId)) {
      sum += uploads.filter(upload => !!upload.error).length;
    }
    return sum;
  }
}


export function useWordUploadStatus(uploader: UploadManager, wordId: number) {
  const [wordStatus, setWordStatus] = useState<WordUploadStatus>(uploader.getStatusForWord(wordId));
  const handleUpdate = (wId: number) => {
    if (wordId == wId) {
      setWordStatus(uploader.getStatusForWord(wordId));
    }
  };
  useEffect(() => {
    uploader.addListener('update', handleUpdate);
    return () => {
      uploader.removeListener('update', handleUpdate)
    }
  }, [uploader])

  return wordStatus;
}



export function useUploader(sessionId: string|null): [any, UploadManager] {
  const addRecording = useAddRecording(sessionId);
  // const addRecording = async () => {
  //   await delay(4000);
  // }
  const uploaderRef = useRef(new UploadManager(addRecording));
  const uploader = uploaderRef.current;

  const [uploadState, setUploadState] = useState({
    failedCount: 0
  })

  const handleUpdate = (wId: number) => {
    setUploadState({failedCount: uploader.getFailedCount()})
  };
  useEffect(() => {
    uploader.addListener('update', handleUpdate);
    return () => {
      uploader.removeListener('update', handleUpdate)
    }
  }, [uploader])

  return [uploadState, uploader];
}