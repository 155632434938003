import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';
import {Meaning} from "./Meaning";
import gqlw from 'graphql-tag';
import {MeaningFragment} from "../../../types/MeaningFragment";
import {useMutation} from "multi-apollo";
import {CreateMeaning} from "../../../types/CreateMeaning";


export const MeaningQueryFragment = gqlw`
  fragment MeaningFragment on Meaning {    
    id,
    descriptor,
    definitions {
      id, 
      text,
      words,
      properties {
        key,
        value
      }
    }
  }
`;

export function DefinitionsTab(props: {
  wordId: number,
  meanings: MeaningFragment[],
  refetch: () => any
}) {
  const createMeaning = useCreateMeaning(props.wordId);
  const handleAddMeaning = () => { createMeaning(); props.refetch(); }

  return  <div style={{marginTop: '1em'}}>

    {props.meanings.map((meaning: MeaningFragment, idx) => {
      return <Meaning
        key={meaning.id!}
        reload={props.refetch}
        number={idx+1}
        meaning={meaning}
      />
    })}

    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '20px'}}>
      <DefaultButton onClick={handleAddMeaning}>Add another definition</DefaultButton>
      &nbsp;&nbsp;&nbsp;
      <span style={{color: 'gray'}}>Please consult the documentation to make sure that a new meaning is the correct approach. Sometimes, a new word should be added instead.</span>
    </div>
  </div>
}

function useCreateMeaning(wordId: number) {
  const query = gqlw`
      mutation CreateMeaning($wordId: ID!, $descriptor: String!) {
        createMeaning(wordId: $wordId, descriptor: $descriptor) {          
          meaning {
            id,
            descriptor
          }
        }
      }
    `;
  const [createMeaning, ] = useMutation<CreateMeaning>(query, {
    variables: {
      wordId,
      descriptor: ""
    }
  });

  return createMeaning;
}

