/**
 * Talks to our "hosting service" where you can upload videos.
 */

import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import React, {useMemo} from "react";
import {graphqlQuery} from "../Market/api";


// @ts-ignore Does not work when this is compiled via the market-admin project.
export const HOSTING_API = window?.CONFIG?.hostingUrl ? `${window.CONFIG.hostingUrl}/api/` : "";


type ConfigOpts = {
  endpoint?: string
};


export function useHostingServer() {
  const httpLink = createHttpLink({uri: HOSTING_API});
  const cache = new InMemoryCache({});

  return useMemo(() => new ApolloClient({link: httpLink, cache}), []);
}


export async function queryGoogleSpeechOperation(id: string): Promise<{ isDone: boolean, results: any }> {
  const query = `
    query GetGoogleSpeechOp {
      googleSpeechApiOperation(operationId: "${id}") {
        isDone,
        results
      }
    }
  `;

  let {isDone, results} = (await graphqlQuery({query, endpoint: HOSTING_API})).googleSpeechApiOperation;
  return {
    isDone,
    results: JSON.parse(results)
  }
}


/**
 * For a given upload url, return the video url.
 */
export async function getUploadUrl(id: string, opts?: ConfigOpts): Promise<{url: string, audioOnlyUrl: string, isProcessingComplete: boolean}> {
  const query = `
    query GetUploadUrl {
      upload(id: "${id}") {
        url,
        isProcessingComplete,
        audioOnlyUrl: url(format: "mp3")
      }
    }
  `;

  let {url, audioOnlyUrl, isProcessingComplete} = (await graphqlQuery({query, endpoint: opts?.endpoint ?? HOSTING_API})).upload;
  return {url, audioOnlyUrl, isProcessingComplete};
}



export async function fetchUploadCaptions(id: string): Promise<any> {
  const query = `
    query GetUploadCaptions {
      upload(id: "${id}") {
        captions
      }
    }
  `;

  let {captions} = (await graphqlQuery({query, endpoint: HOSTING_API})).upload;
  return JSON.parse(captions);
}
