import {IAnnotationLoader} from "../../annotations/loader";
import {Vocable} from "../../models";
import {isNumeralNode, isWordNode, StructureNode} from "../../models/Node";
import {default as React, useContext, useCallback} from "react";
import {PopupContext} from "./PopupContext";
import {Breadcrumbs} from "./parts/Breadcrumbs";
import {FetchAnnotations} from "./FetchAnnotations";
import {Container, PopupContent} from "./PopupContent";
import {AudioManager, AudioManagerAPI, AudioManagerContext} from "./AudioManager";
import {ErrorMessage, NoResultsMessage} from "./Message";
import {ActionButtons} from "./parts/ActionButtons";
import {ErrorBoundary} from "./ErrorBoundary";
import {Page} from "./Page";
import {OnPageOpenedEvent, WordBrowserHistory} from "./parts/WordBrowserHistory";


export type PopupBrowserProps = {
  loader: IAnnotationLoader,
  learnerLangs: string[],

  // If not given, vocable/nodes are used as a page.
  initialPage?: Page,
  // This is the vocable the user clicked on.
  vocable?: Vocable,
  // These are the  nodes that cover the vocables the user clicked on.
  nodes?: StructureNode[] | null,

  // If a language
  // - We can request annotations in that language.
  // - We can tell which annotations are "source lang", which are "reader lang".
  // If not given, read from the context
  defaultLanguage?: string,

  onPageOpened?: OnPageOpenedEvent,
  ActionButtons?: ActionButtons
};


/**
 * This handles the breadcrumbs and page browser logic within the popup.
 *
 * TODO: This currently handles the logic of rewriting "nodes" and "vocables" to a "page".
 * We probably want to move that further up. However, we do use the vocable currently for
 * another thing as well so sort of expect this to be passed in manually for now :(
 * It appears to be solely used to be able to submit the vocable id in the stats submitter.
 */
export function PopupBrowser(props: PopupBrowserProps) {
  const {defaultLanguage: defaultLanguageContext} = useContext(PopupContext);
  const defaultLanguage = props.defaultLanguage || defaultLanguageContext;
  const audioManager = React.useRef<AudioManagerAPI|null>(null);

  const handleOnPageOpened = useCallback<OnPageOpenedEvent>((page) => {
    audioManager.current?.reportNewPage();
    props.onPageOpened?.(page);
  }, [props.onPageOpened, audioManager]);

  // Figure out what the page is that we are going to display in the popup.
  let rootPage: Page | null = props.initialPage ?? null;

  // If a supported structure node is given, this is the page we show.
  if (!rootPage) {
    const wordNode = props.nodes ? props.nodes.filter(n => isWordNode(n) || isNumeralNode(n))[0] : null;
    if (wordNode) {
      rootPage = wordNode;
    }

    // Otherwise, show a page for the clicked vocable text.
    else if (props.vocable) {
      if (!defaultLanguage) {
        throw new Error("Cannot show this page because the vocable has no language and there is no default language set.")
      }
      rootPage = {
        text: props.vocable.text,
        lang: defaultLanguage
      };
    }
  }

  if (!rootPage) {
    return <Container>
      <NoResultsMessage />
    </Container>;
  }

  return <WordBrowserHistory
    rootPage={rootPage}
    rootVocable={props.vocable}
    onPageOpened={handleOnPageOpened}
  >
    {
      (currentPage, allPages, currentPageIndex) => {
        return <ErrorBoundary>
          <AudioManager ref={audioManager}>
            <Breadcrumbs
              pages={allPages}
              currentPageIndex={currentPageIndex}
            />
            <FetchAnnotations
              {...props}
              target={currentPage}
            >
              {
                (result) => {
                  if (result.isError) {
                    return <Container>
                      <ErrorMessage refetch={result.refetch} />
                    </Container>;
                  }
                  return <PopupContent
                    defaultLanguage={defaultLanguage!}
                    target={currentPage}
                    learnerLangs={props.learnerLangs}
                    isLoading={result.isLoading}
                    cache={result.cache}
                    ActionButtons={props.ActionButtons}
                  />
                }
              }
            </FetchAnnotations>
          </AudioManager>
        </ErrorBoundary>
      }
    }
  </WordBrowserHistory>
}