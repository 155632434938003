import rough from 'roughjs/bin/rough';
import React from 'react';
import {RenderPathProps} from "./svg.web";


function useRough() {
  const gen = React.useMemo(() => rough.generator(), []);
  return gen;
}

// It's nice, but the only problem is that things such as curves and in particular
// little circles drawn via a SVG path are more messy then real circle objects.
export function RoughPath(props: RenderPathProps) {
  const gen = useRough();

  const pathInfos = React.useMemo(() => {
    const drawable = gen.path(props.path, {
      fill: props.fill,
      stroke: props.stroke,
      strokeWidth: props.strokeWidth,

      roughness: 0.5,
      hachureGap: 5,
      hachureAngle: -41,
      fillStyle: 'zigzag',
      fillWeight: 2,
      //simplification: 0,
      //curveFitting: 1,
      //curveStepCount: 10,
      //bowing: 0,
    })
    return gen.toPaths(drawable);
    // It might be speedier not to regen (and lose randomization, at least use the same seed!)
    // Instead, we could try to replace the colors afterwards. Or, we use the same seed!
  }, [props.fill, props.stroke, props.strokeWidth, props.path]);

  const id = React.useMemo(() => {
    // Same as roughjs/svg.ts
    return `rough-${Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER || 999999))}`;
  }, []);

  // https://github.com/pshihn/rough/blob/master/src/svg.ts
  return <>
    {/*https://github.com/pshihn/rough/issues/148*/}
    {/*<defs>*/}
    {/*  {pathInfos.map((info, idx) => {*/}
    {/*    if (!info.pattern) {*/}
    {/*      return;*/}
    {/*    }*/}
    {/*    return <pattern*/}
    {/*      key={idx}*/}
    {/*      id={`${id}-${idx}`}*/}
    {/*      viewBox={info.pattern.viewBox}*/}
    {/*      width={info.pattern.width}*/}
    {/*      height={info.pattern.height}*/}
    {/*      x={info.pattern.x}*/}
    {/*      y={info.pattern.y}*/}
    {/*      patternUnits={info.pattern.patternUnits}*/}
    {/*    >*/}
    {/*      <path*/}
    {/*        d={info.path.d}*/}
    {/*        fill={info.path.fill}*/}
    {/*        stroke={info.path.stroke}*/}
    {/*        strokeWidth={info.path.strokeWidth}*/}
    {/*      />*/}
    {/*    </pattern>*/}
    {/*  })}*/}
    {/*</defs>*/}
    {pathInfos.map((pathInfo, idx) => {
      return <path
        d={pathInfo.d}
        fill={pathInfo.fill}
        stroke={pathInfo.stroke}
        strokeWidth={pathInfo.strokeWidth}
      />
    })}
  </>
}