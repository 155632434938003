import React from "react";
import {Scale} from "./coordinates";
import * as PIXI from 'pixi.js'
import {PixiComponent} from '@inlet/react-pixi'
import {Graphics} from 'pixi.js';


type Props = {
  x: number,
  y: number,
  width: number,
  height: number,

  scale: Scale,

  onTap: (offsetSeconds: number) => void,
  onMoveStart: (offsetSeconds: number) => void,
  onMoving: (newOffsetSeconds: number) => void,
  onMoveDone: () => void,
};



export const Viewport  = PixiComponent('Viewport', {
  create: (props: Props) => {
    let isDragging = false;
    let isDown = false;
    let dragStartPosition: number|null = null;

    const g = new PIXI.Graphics()
    g.interactive = true;

    // All properties must be allowed to change, but this one in particular.
    (g as any)._scale = props.scale;

    // If not set, then events will only be processed on the "drawn" shape!
    g.hitArea = new PIXI.Rectangle(0, 0, props.width, props.height);

    g.on('pointerdown', (e: any) => {
      isDown = true;
      dragStartPosition = e.data.getLocalPosition(e.currentTarget.parent).x;
    });
    g.on('pointermove', (e: any) => {
      if (isDown) {
        if (!isDragging) {
          const pixelPos = e.data.getLocalPosition(e.currentTarget.parent).x;
          const pixelMovement = pixelPos  - dragStartPosition!;
          // Require a minimum movement to start the drag.
          if (Math.abs(pixelMovement) > 8) {
            const offsetSeconds = (g as any)._scale.pixelsToTime(pixelPos);
            isDragging = true;
            props.onMoveStart(offsetSeconds);
            return;
          }
        }

        if (isDragging) {
          const newPosition = e.data.getLocalPosition(e.currentTarget.parent);
          const shift = newPosition.x - dragStartPosition!;
          const newOffset = (g as any)._scale.pixelsToTime(shift);
          props.onMoving(-newOffset);
        }
      }
    });

    g.on('pointerup', (e: any) => {
      if (isDown && !isDragging) {
        if (props.onTap) {
          const offsetSeconds = getSecondsOffsetFromEvent(e, (g as any)._scale);
          props.onTap(offsetSeconds)
        }
        isDown = false;
        return;
      }

      isDragging = false;
      isDown = false;
      props.onMoveDone();
    });
    g.on('pointerupoutside', () => {
      isDragging = false;
      isDown = false;
      if (isDragging) {
        props.onMoveDone();
      }
    });
    return g;
  },

  applyProps: (g: Graphics, oldProps: Props, newProps: Props) => {
    // Point to the new scale.
    (g as any)._scale = newProps.scale;
  }
})


function getSecondsOffsetFromEvent(e: any, scale: Scale) {
  const pixelPos = e.data.getLocalPosition(e.currentTarget.parent).x;
  return scale.pixelsToTime(pixelPos);
}