// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__root--308II{border:1px solid #717171;display:flex;flex-direction:row;min-height:15px}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf{border:1px solid transparent;border-right:1px solid #717171;flex:1;padding:1px;cursor:pointer}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf:hover{opacity:.5}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf:last-child{border-right:none}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__complete--1hsxR{background:#8bc34a}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__hasIssues--c89UX{background:#ff8f07}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__partial--1-AU_{background:#ffc107}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__empty--2he3u{background:#F44336}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__current--3F2o3{border:1px solid black}._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf._-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__current--3F2o3::before{content:\"✘\"}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__root--308II",
	"line": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__line--1FDUf",
	"complete": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__complete--1hsxR",
	"hasIssues": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__hasIssues--c89UX",
	"partial": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__partial--1-AU_",
	"empty": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__empty--2he3u",
	"current": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-MiniMap__current--3F2o3"
};
module.exports = exports;
