import React, {useMemo, useState, useEffect} from 'react';
import {
  Captions,
  exportLineToVocables,
  getWordNodeForVocable,
  removeWordNode,
  setVocableAlternates,
  setWordNode
} from "../../models/Captions";
import {getLineCount, getLineGroup, setVocableText} from "../../models/Captions/slateApi";
import {getWordNodeForVocables, WordLikeNode} from "../../models/Captions/wordNodes";
import {Vocable} from "languagetool-player/src/models";
import {TrackInterface} from "../Words/assignScreen";
import { useUpdateEffect } from 'react-use';
import {StructureNodeStore} from "../../models/StructureNodeStore";
import {CaptionTrackRoot} from "../../models/Captions/types";
import {createSlateEditor} from "../../models/ICaptionsEditor";
import { isEqual, isUndefined, omit, omitBy } from "lodash";
import {
  isTextPart,
  SimplePictureBook,
  TextPart
} from "languagetool-player/src/models/formats/SimplePictureBook";
import produce from "immer"
import {IdGenerator} from "../../components/VocableBasedSlate/withKeyGen";
import {UnitDataSetter} from "../../models/Unit";


export type CaptionsInterface = ReturnType<typeof useCaptions>;

/**
 * Wrap the `Captions` instance into a Slate.js `Editor`, which gives us the ability
 * to edit it using Slate.js transforms.
 *
 * When the slate editor updates, we write those change back to the `Captions`
 * instance. Will also return a `lines` property which is always up-to-date.
 */
function useCaptions(captions: Captions) {
  // Copy the caption nodes to local state, refresh when they update.
  const [nodes, setNodes] = useState<StructureNodeStore>(captions.nodes);
  useEffect(() => {
    const handler = (nodes: StructureNodeStore) => { setNodes(nodes); }
    captions.on('nodesChanged', handler);
    return () => { captions.off('nodesChanged', handler) };
  }, [captions]);

  // Copy the lines to local state, refresh when they update
  const [lines, setLines] = useState<CaptionTrackRoot>(captions.lines);
  useEffect(() => {
    const handler = (lines: CaptionTrackRoot) => { setLines(lines); }
    captions.on('linesChanged', handler);
    return () => { captions.off('linesChanged', handler) };
  }, [captions]);

  // Create an editor over the caption lines. Write any changes to the source. Essentially we expose
  // the Slate.js editor interface in this way, and allow it's transforms to be used.
  const editor = useMemo(() => {
    const editor = createSlateEditor();
    editor.children = lines;
    editor.onChange = () => {
      // Copy the new version to the `Captions instance`, which will trigger the udpates from above.
      captions.setLines(editor.children as CaptionTrackRoot);
    };
    return editor;
  }, [lines])

  return useMemo(() => ({
    editor,
    nodes,
    setLines,

    // Write changed nodes to Captions, which then updates the local state
    setNodes: (n: StructureNodeStore) => captions.setNodes(n),
  }), [editor, captions]);
}

export function useGetTrackAPIForCaptions(captions: Captions): TrackInterface {
  // Error if props.track changes.
  useUpdateEffect(() => {
    console.error("WordAssignScreen: Warning: Changing the track after the first render has no " +
      "effect. Use a key to reset the component.")
  }, [captions]);

  const captionsAPI = useCaptions(captions);
  const {editor} = captionsAPI;

  return useMemo(() => ({
    setVocableText: (vid: string, text: string) => setVocableText(editor, vid, text),
    setVocableAlternates: (vid: string, alternates: { [key: string]: string|undefined }) => setVocableAlternates(editor, vid, alternates),
    getWordNodeForVocables: (vids: string[], opts?: {allowPartialMatch?: boolean}) => {
      if (opts?.allowPartialMatch) {
        if (vids.length > 1) { throw new Error("Not supported with more than one.")}
        return getWordNodeForVocable(captions, vids[0]);
      }
      return getWordNodeForVocables(captions, vids);
    },
    setWordNode: (node: WordLikeNode) => { setWordNode(captionsAPI, node); },
    removeWordNode: (vocableId: string) => { removeWordNode(captionsAPI, vocableId); },
    getLineCount: () => getLineCount(captions.lines),
    getLine: (idx: number) => {
      const line = getLineGroup(captions.lines, idx);
      if (!line) {
        return [];
      }

      let result: Vocable[] = [];
      for (const insideLine of line.children) {
        result.splice(result.length, 0, ...exportLineToVocables(insideLine));
      }
      return result;
    }
  }), [captions, editor]);
}


function *iterateParagraphs(book: SimplePictureBook) {
  for (const page of book.pages) {
    for (const part of Object.values(page.parts)) {
      if (isTextPart(part)) {
        for (const par of (part as TextPart).paragraphs) {
          yield par;
        }
      }
    }
  }
}


// Iterate over all elements that have an id!
function *iterateIdElements(book: SimplePictureBook) {
  for (const page of book.pages) {
    yield page;
    for (const part of Object.values(page.parts)) {
      yield part;
      if (isTextPart(part)) {
        for (const par of (part as TextPart).paragraphs) {
          yield par;
          for (const blob of par.blobs) {
            yield blob;
            for (const element of blob.elements) {
              yield element;
            }
          }
        }
      }
    }
  }

  for (const node of book.nodes) {
    yield node;
  }
}


export function getPictureBookMaxId(book: SimplePictureBook) {
  return Array.from(iterateIdElements(book)).reduce((max, element) => Math.max(max, parseInt(element.id) || 0), 0);
}


function findVocableInPictureBook(book: SimplePictureBook, vocableId: string) {
  for (const page of book.pages) {
    for (const part of Object.values(page.parts)) {
      if (isTextPart(part)) {
        for (const par of (part as TextPart).paragraphs) {
          for (const blob of par.blobs) {
            for (const element of blob.elements) {
              if (element.id === vocableId) {
                return element;
              }
            }
          }
        }
      }
    }
  }

  return null;
}

/**
 * Returns an interface used by the word assign screen which to work with and modify a SimplePictureBook.
 */
export function useGetTrackInterfaceForSimplePictureBook(
  book: SimplePictureBook,
  setData: UnitDataSetter<SimplePictureBook>,
  idGen: IdGenerator
): TrackInterface {
  return useMemo(() => ({
    setVocableText: (vid: string, text: string) => {
      setData(book => produce(book, draft => {
        const vocable = findVocableInPictureBook(draft, vid);
        if (vocable) {
          vocable.text = text;
        }
      }));
    },

    setVocableAlternates: (vid: string, alternates: { [key: string]: string|undefined }) => {
      setData(book => produce(book, draft => {
        const vocable = findVocableInPictureBook(draft, vid);
        if (vocable) {
          vocable.alternates = omitBy({...vocable.alternates, ...alternates}, x => x === undefined) as any;
        }
      }));
    },

    getWordNodeForVocables: (vids: string[], opts?: {allowPartialMatch?: boolean}) => {
      if (opts?.allowPartialMatch) {
        if (vids.length > 1) { throw new Error("Not supported with more than one.")}

        const matching = book.nodes.filter(node => {
          // Then ensure the node covers exactly the vocables queried
          return node.vocables.indexOf(vids[0]) > -1;
        }) as WordLikeNode[];
        return matching[0] ?? null;
      }

      const matching = book.nodes.filter(node => {
        // Then ensure the node covers exactly the vocables queried
        return isEqual(new Set(node.vocables), new Set(vids));
      }) as WordLikeNode[];
      return matching[0] ?? null;
    },

    setWordNode: (nodeToSet: WordLikeNode) => {
      setData(book => produce(book, draft => {
        // Remove all nodes that involve this vocable.
        draft.nodes = draft.nodes.filter(existingNode => {
          for (const vid of existingNode.vocables) {
            // If the node to be set applies to the vocables of this existing node, delete the existing.
            if (nodeToSet.vocables.indexOf(vid) > -1) {
              return false;
            }
          }
          return true;
        });

        // Add the new node.
        draft.nodes.push({
          ...nodeToSet,
          id: idGen.getId()
        })
      }));
    },

    removeWordNode: (vocableId: string) => {
      setData(book => produce(book, draft => {
        // Remove all nodes that involve this vocable.
        draft.nodes = draft.nodes.filter(node => {
          return node.vocables.indexOf(vocableId) === -1;
        })
      }));
    },
    getLineCount: () => {
      return Array.from(iterateParagraphs(book)).length;
    },
    getLine: (idx: number) => {
      const para = Array.from(iterateParagraphs(book))[idx];
      return para.blobs.flatMap(blob => {
        return blob.elements;
      });
    }
  }), [book, setData, idGen]);
}
