import React from 'react';
import {Dropdown, IDropdownOption, TextField} from 'office-ui-fabric-react';
import styles from './Filters.module.css';
import {FilterOptions} from "./WordList";


export function Filters(props: {
  value: FilterOptions,
  onChange: (value: any) => void
}) {
  const handleChange = (option: string, value: any): void => {
    props.onChange({
      ...props.value,
      [option]: value
    });
  };

  return <div className={styles.root}>
    <Dropdown
      label="Language"
      placeholder="(unset)"
      options={[
        { key: '', text: '(unset)' },
        { key: 'fa', text: 'Persian' },
        { key: 'en', text: 'English' },
      ]}
    />
    <Dropdown
      label="Word Type"
      selectedKey={props.value.wordType}
      placeholder="Select"
      onChange={(event: React.FormEvent<HTMLDivElement>, item: any): void => {
        handleChange('wordType', item.key);
      }}
      options={[
        { key: '', text: '(unset)' },
        { key: 'noun', text: 'Noun' },
        { key: 'verb', text: 'Verb' },
      ]}
    />
    <ReviewStatus
      value={props.value.isReviewed}
      onChange={handleChange}
    />
    <Dropdown
      label="How often used"
      selectedKey={''}
      placeholder="(unset)"
      options={[
        { key: '', text: '(unset)' },
        { key: 'never', text: 'Never' },
        { key: 'once', text: 'At least once' },
      ]}
    />
    <InternalDictionary
      value={props.value.internalDictionary}
      onChange={handleChange}
    />
    <ExternalDictionary
      value={props.value.externalDictionaries}
      onChange={handleChange}
    />
    <TextField
      label={"Search"}
      placeholder={"Search for word"}
      value={props.value.search}
      onChange={(e, newValue) => handleChange('search', newValue)}
    />
    <CreatedBy
      value={props.value.createdBy}
      onChange={handleChange}
    />
  </div>
}


type FilterProps<T=any> = {
  onChange: (name: string, value: T) => void,
  value: T
}


function ExternalDictionary(props: FilterProps<'has'|'has-not'|'good'|undefined>) {
  const handleChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    props.onChange('externalDictionaries', item.key);
  };

  return <Dropdown
    label="External Dictionary"
    selectedKey={props.value}
    placeholder="(unset)"
    onChange={handleChange}
    options={[
      { key: '' as any, text: '(unset)' },
      { key: 'has-not', text: 'No entries' },
      { key: 'has', text: 'At least one entry' },
      { key: 'good', text: 'Wiktionary/Langenscheidt' },
    ]}
  />;
}


function InternalDictionary(props: FilterProps<'has'|'has-not'|undefined>) {
  const handleChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    props.onChange('internalDictionary', item.key);
  };

  return <Dropdown
    label="Internal Dictionary"
    selectedKey={props.value}
    placeholder="(unset)"
    onChange={handleChange}
    options={[
      { key: '' as any, text: '(unset)' },
      { key: 'has-not', text: 'No entries' },
      { key: 'has', text: 'At least one entry' },
    ]}
  />;
}


function CreatedBy(props: FilterProps<number|undefined>) {
  const handleChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    props.onChange('createdBy', item.key);
  };

  return <Dropdown
    label="Created By"
    selectedKey={props.value}
    placeholder="(unset)"
    onChange={handleChange}
    options={[
      { key: 1, text: '1' },
      { key: 16, text: '16' },
    ]}
  />;
}


function ReviewStatus(props: FilterProps) {
  const handleChange = (event: React.FormEvent<HTMLDivElement>, item: any): void => {
    props.onChange('isReviewed', item.key === 'true' ? true : item.key === "false" ? false : undefined);
  };

  return <Dropdown
    label="Review Status"
    selectedKey={props.value ? 'true' : props.value === false ? 'false' : ''}
    onChange={handleChange}
    placeholder="(unset)"
    options={[
      { key: "", text: '(unset)' },
      { key: "true", text: 'Yes' },
      { key: "false", text: 'No' },
    ]}
  />
}