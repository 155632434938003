// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Market-CaptionTrackSource__editor--1zZ5R{flex:1;display:flex;flex-direction:column}._-languagetool-editor-src-js-integrations-Market-CaptionTrackSource__editor--1zZ5R .CodeMirror{flex:1}\n", ""]);
// Exports
exports.locals = {
	"editor": "_-languagetool-editor-src-js-integrations-Market-CaptionTrackSource__editor--1zZ5R"
};
module.exports = exports;
