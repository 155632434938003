import React from 'react';
import {FlashOn} from '@material-ui/icons';
import MediaEditor from '../MediaPlayer/MediaEditor';
import {SlateEditorControl} from '../CaptionEditor';
import styles from './Workspace.cssm';
import {UnitAPI, DocumentState, isUnitState, isNewUnitState} from "../../models/Unit";
import {PlayerAPI} from 'languagetool-player/src/ui/Video';
import PanelGroup from 'react-panelgroup';
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import {UIState, UserInterfaceAPI} from "./index";
import {CircularProgress} from "@material-ui/core";
import {
  WordAssignScreenCaptionsTrack,
  WordAssignScreenSimplePictureBook
} from "../../integrations/Words/assignScreen";
import {CaptionTrackSourceView, NewUnitStateSourceView} from "../../integrations/Market/CaptionTrackSource";
import {SimplePictureBook} from "languagetool-player/src/models/formats/SimplePictureBook";
import {SimplePictureBookEditor} from "../SimplePictureBookEditor";


type Props = {
  unit: DocumentState|null,
  unitAPI: UnitAPI,
  uiAPI: UserInterfaceAPI,
  playerAPI: PlayerAPI|null,
  onPlayerApiReady: (api: PlayerAPI|null) => void,
  uiState: UIState,
  captionsEditor: ICaptionsEditor|null,
  isLoading: boolean
}



// The workspace is the main area where of the app, not including
// toolbars and other chrome.
class Workspace extends React.Component<Props> {

  render() {
    const {unit} = this.props;

    if (this.props.isLoading) {
      // See https://github.com/mui-org/material-ui/issues/10327 for disableShrink
      return <div className={styles.workspaceEmpty}>
        <CircularProgress disableShrink />
        <p>Loading...</p>
      </div>
    }

    if (!unit) {
      return <div className={styles.workspaceEmpty}>
        <FlashOn style={{height: "100"}}/>
        <p>No unit is loaded. <br/>
          Press "New Unit" to create a new one.</p>
      </div>
    }

    let children;
    if (this.props.uiState.screen === 'WordAssign') {
      if (isNewUnitState(unit)) {
        children = <WordAssignScreenSimplePictureBook
          book={unit.data as SimplePictureBook}
          apiUrl={window.CONFIG.wordsApiUrl}
          setData={(this.props.unitAPI as UnitAPI<SimplePictureBook>).setUnitData}
          unitState={unit}
          language={unit.language}
          trackVersionId={""}
        />
      }
      else {
        children = <WordAssignScreenCaptionsTrack
          captions={unit!.captions!}
          trackVersionId={unit!.captions?.versionId!}
          language={unit!.language}
          translation={unit!.translation}
          mediaTrack={unit!.media!}
          unitId={unit!.id}
          apiUrl={window.CONFIG.wordsApiUrl}
        />;
      }
    }

    else if (this.props.uiState.screen === 'CaptionSource') {
      if (!isUnitState(unit)) {
        children = <NewUnitStateSourceView
          state={unit}
          unitAPI={this.props.unitAPI}
        />
      }
      else {
        children = <CaptionTrackSourceView
          track={unit.captions!}
        />
      }
    }

    else {
      if (!isUnitState(unit)) {
        children = <SimplePictureBookEditor
          unit={unit}
          unitAPI={this.props.unitAPI}
        />
      }
      else {
        const slateControl = this.props.captionsEditor ? <SlateEditorControl
          uiState={this.props.uiState}
          playerAPI={this.props.playerAPI}
          captionsEditor={this.props.captionsEditor}
          readOnly={this.props.uiState.isTimingMode}
        /> : <div />;

        children = <PanelGroup direction={"column"}>
          <MediaEditor
            unit={unit!}
            unitAPI={this.props.unitAPI}
            uiAPI={this.props.uiAPI}
            uiState={this.props.uiState}
            onPlayerApiReady={this.props.onPlayerApiReady}
            playerAPI={this.props.playerAPI}
            captionsEditor={this.props.captionsEditor}
          />
          {slateControl}
        </PanelGroup>;
      }

    }

    return <div className={styles.workspace}>
      {children}
    </div>
  }
}

export default Workspace;