export const splitLangCode = (code: string) => {
  let [main, sub] = code.split('-', 1);
  return {main, sub, complete: code};
}


export function isLanguageRtl(langCode: string) {
  // TODO: Switch to use CLDR
  if (splitLangCode(langCode).main == 'fa') {
    return true;
  }
  return false;
}


export function getLanguageDirAttr(langCode: string|null|undefined) {
  if (!langCode) {
    return "";
  }
  return isLanguageRtl(langCode) ? 'rtl' : 'ltr';
}
