import * as React from "react";
import {Field, FieldAttributes, FieldProps} from "formik";
import {NestingContext} from "./NestingContext";
import {useContext} from "react";


/**
 * This is like a formik <Field>, but supports being inside a nested form context.
 *
 * Note that formik itself has no deprecated the "component" property of <Field>, but we still expose this
 * interface externally here in <NestableField>: We recreate it to both hide the warning + to be able to
 * pass through the "meta" structure which is missing in Formik's own backwards-compatibility "component" prop support.
 */
export function NestableField(props: FieldAttributes<any>) {
  const nesting = useContext(NestingContext);

  const {
    name,
    component: Component,
    ...passToFieldProps
  } = props;

  const {
    validate,
    render,
    as,
    ...passToComponentProps
  } = passToFieldProps;

  return <Field
    {...passToFieldProps}
    name={nesting.prefix ? `${nesting.prefix}.${name}` : name}
  >
    {
      ({meta, field, form}: FieldProps) => {
        return <Component
          field={field}
          meta={meta}
          form={form}
          {...passToComponentProps}
        />
      }
    }
  </Field>
}