// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__root--3WPkd{display:inline-grid;grid-template-rows:auto auto;grid-gap:0 5px;justify-items:center;border:1px dotted silver;padding:2px}._-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__rtl--tnAFr{direction:rtl}._-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__part--1I7s2{grid-row:1}._-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__explainer--uhaWE{grid-row:2;font-size:0.8em;color:gray}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__root--3WPkd",
	"rtl": "_-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__rtl--tnAFr",
	"part": "_-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__part--1I7s2",
	"explainer": "_-languagetool-editor-src-js-integrations-Words-assignScreen-WordStructureTable__explainer--uhaWE"
};
module.exports = exports;
