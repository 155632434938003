import React from 'react'
import {Svg, Path} from 'react-native-svg';

export const GoogleTranslateIcon = React.memo((props: any) => (
  <Svg width="16" height="16" viewBox="0 0 48 48" {...props}>
    <Path
      d="M15 13h25c1.105 0 2 .895 2 2v25c0 1.105-.895 2-2 2H26z"
      fill="#cfd8dc"
    />
    <Path
      d="M26.832 34.855l-.918-1.777.89-.46c.063-.028 6.102-3.208 9.044-9.102l.445-.895 1.793.89-.45.895c-3.241 6.496-9.644 9.852-9.913 9.992z"
      fill="#546e7a"
    />
    <Path
      d="M38.02 34l-.872-.488c-.207-.117-5.09-2.903-8.496-7.668l1.63-1.164c3.136 4.394 7.804 7.062 7.85 7.09l.868.492zM26 22h14v2H26z"
      fill="#546e7a"
    />
    <Path d="M32 20h2v4h-2z" fill="#546e7a" />
    <Path
      d="M33 35H8c-1.105 0-2-.895-2-2V8c0-1.105.895-2 2-2h14z"
      fill="#2196f3"
    />
    <Path d="M26 42l-3-7h10z" fill="#3f51b5" />
    <Path
      d="M19.172 24h-4.36l-1.007 3H11l4.766-13h2.441L23 27h-2.805zm-3.727-2h3.102l-1.563-4.715z"
      fill="#fff"
    />
  </Svg>
));
