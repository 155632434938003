import * as Immutable from 'immutable';
import {StructureNode, StructureNodeId} from "languagetool-player/src/models/Node";
import {List} from "immutable";
import {IdGenerator} from "../components/VocableBasedSlate/withKeyGen";
import {VocableId} from "languagetool-player/src/models/formats/CaptionTrack";


type ByVocableMap = Immutable.Map<VocableId, List<StructureNodeId>>;

type StructureNodeStoreParams = {
  byId: Immutable.Map<StructureNodeId, StructureNode>,
  idGen: IdGenerator,
  byVocable?: ByVocableMap,
};


// We want to subclass Immutable.Record
// We need to define the Record fields in Typescript, so we may access them.
// When we do so, Babel generates a defineProperty() in our constructor which destroys the fields that the
//    Record base class creates.
// All the tutorial about this seem to be outdated (or get it wrong?)
// My solution is, create our "class inheriting the record" twice. Once here, were the bad constructor is generated.
// But then do not use this class in JavaScript, just use it in typing.
class FakeBaseRecord extends Immutable.Record({}) {
  public readonly idGen!: IdGenerator
  public readonly byId!: Immutable.Map<StructureNodeId, StructureNode>
  public readonly byVocable!: Immutable.Map<VocableId, List<StructureNodeId>>
}

const BaseRecordType: typeof FakeBaseRecord = Immutable.Record({
  byId: Immutable.Map(),
  byVocable: Immutable.Map(),
  idGen: IdGenerator
}) as any;


/**
 * Will be stored in the `document.data` section.
 */
export class StructureNodeStore extends BaseRecordType implements StructureNodeStoreParams {

  setIdGen(idGen: IdGenerator): StructureNodeStore {
    // @ts-ignore
    return this.set("idGen", idGen);
  }

  getNodeById(nodeId: string): StructureNode|null {
    return this.byId.get(nodeId) || null;
  }

  getNodesByVocableId(vocableId: string): Immutable.List<StructureNode> {
    const list = this.byVocable.get(vocableId);
    if (!list) {
      return Immutable.List();
    }
    return list.map(nodeId => {
      return this.byId.get(nodeId!);
    }) as Immutable.List<StructureNode>;
  }

  /**
   * Remove all the structure nodes attached to the given vocable.
   */
  removeAllFromVocable(vocableId: VocableId): StructureNodeStore {
    const nodeIds = this.byVocable.get(vocableId);
    if (!nodeIds) {
      return this;
    }

    return this
      .updateIn(['byId'], byId => {
        // @ts-ignore Not sure why
        for (const nodeId of nodeIds) {
          byId = byId.remove(nodeId);
        }
        return byId;
      })
      .updateIn(['byVocable'], byVocable => {
        return byVocable.delete(vocableId);
      }) as StructureNodeStore;
  }

  addNew(word: StructureNode): StructureNodeStore {
    const newId = this.idGen.getId();
    const node: StructureNode = {
      ...word,
      id: newId,
    };
    return this.addExisting(node);
  }

  addExisting(node: StructureNode): StructureNodeStore {
    return this
      .updateIn(['byId'], byId => {
        return byId.set(node.id, node);
      })
      .updateIn(['byVocable'], byVocable => {
        for (const vocableId of node.vocables) {
          byVocable = byVocable.updateIn([vocableId], (value: List<StructureNodeId>) => {
            if (!value) {
              value = Immutable.List()
            }
            return value.push(node.id);
          });
        }
        return byVocable;
      }) as StructureNodeStore;
  }

  change(nodeId: StructureNodeId, changes: any): StructureNodeStore {
    return this.mergeIn(['byId', nodeId], changes) as StructureNodeStore;
  }
}



