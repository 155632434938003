import React from 'react';
import styles from "./MediaEditor.cssm";
import {VideoPlayerClass, DefaultPlayer, PlayerAPI, PlayerState} from 'languagetool-player/src/ui/Video';
import {MediaSource as PlayerMediaSource} from 'languagetool-player/src/models/MediaSource';
import {PlayerControls} from "./PlayerControls";
import {CaptionOverlay} from "./CaptionOverlay";
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import {UIState} from "../MainScreen";


const VideoClass: VideoPlayerClass = DefaultPlayer;


type Props = {
  track: PlayerMediaSource,
  captionsEditor: ICaptionsEditor|null,
  uiState: UIState,

  controlRef: (control: PlayerAPI|null) => void
}


/**
 * Wraps the languagetool-player component, with the following additions:
 *
 * - Also renders a seekbar + basic controls.
 * - Renders a caption overlay.
 *
 * In the future, this might want to put the player API into the context, but for now,
 * we just pass it through via props.
 */
export class Player extends React.Component<Props> {
  state: {
    playerAPI?: PlayerAPI|null,
    playerState?: PlayerState
  } = {}

  render() {
    return <div className={styles.videoAndControls}>
      <div className={styles.videoAbsolutePositioner}>
        <VideoClass
          className={styles.videoPlayerItself}
          track={this.props.track}
          controlRef={this.setControlRef}
          onStateChange={this.handleStateChange}
        />

        <CaptionOverlay
          currentTime$={this.state.playerAPI ? this.state.playerAPI.time$ : null}
          captionsEditor={this.props.captionsEditor}
          uiState={this.props.uiState}
        />
      </div>
      <div className={styles.controlsAbsolutePositioner}>
        <PlayerControls
          api={this.state.playerAPI || null}
          state={this.state.playerState || null}
        />
      </div>
    </div>
  }

  setControlRef = (r: PlayerAPI|null) => {
    this.setState({playerAPI: r});
    this.props.controlRef(r);
  }

  componentWillMount(): void {
    // <VideoClass.controlRef> should already be called with null, but just to be sure, set it to null;
    // if we do not do this, and keep an older playerAPI state around, it is well possible that other
    // parts of the UI will setup the wrong subscriptions.
    this.props.controlRef(null);
  }

  handleStateChange = (state: PlayerState) => {
    this.setState({playerState: state});
  }
}
