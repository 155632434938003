import {useContext} from "react";
import {useFormikContext, getIn} from "formik";
import {NestingContext} from "./NestingContext";


export function useNestedPrefix(): string {
  const nested = useContext(NestingContext);
  if (nested.prefix) {
    return nested.prefix;
  }
  return "";
}


/**
 * Get the values for the current nested form level.
 */
export function useNestedValues() {
  const formik = useFormikContext<any>();
  const nested = useContext(NestingContext);

  if (nested.prefix) {
    return getIn(formik.values, nested.prefix) || {};
  }

  return formik.values;
}


export function useNestedFieldToken(name: string): [any, (v: any) => void] {
  const nested = useContext(NestingContext);

  let fullPath = name;
  if (nested.prefix) {
    fullPath = `${nested.prefix}.${fullPath}`;
  }

  const formik = useFormikContext<any>();
  const value = getIn(formik.values, fullPath);

  return [
    value,
    (v) => formik.setFieldValue(fullPath, v)
  ]
}