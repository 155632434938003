import {FarsiNounPossessive, FarsiVerbForm, FarsiVerbPerson} from "./index";
import React from "react";


// Get a nice name for each word type. in most cases, the internal name is fine!
export function getWordType(form: string): string {
  const cases: {[key: string]: string} = {
    'noun': 'noun',
    'proper_noun': 'proper noun'
  }

  return cases[form] || form;
}


// To learn more about each case, look in the Python code where they are defined.
export function getVerbFormName(form: FarsiVerbForm) {
  if (form === 'present-imperfect-indicative') {
    return 'present';
  } else if (form === 'present-simple-indicative') {
    return 'present simple';
  } else if (form === 'past-simple-indicative') {
    return 'simple past';
  } else if (form === 'past-imperfect-indicative') {
    return 'past imperfect';
  } else if (form === 'present-simple-subjunctive') {
    return 'subjunctive';
  } else if (form === 'past-narrative-subjunctive') {
    return 'subjunctive past';
  } else if (form === 'past-narrative-indicative') {
    return 'past narrative';
  } else if (form === 'future-indicative') {
    return 'future';
  } else if (form === 'infinitive') {
    return 'infinitive';
  } else if (form === 'present-simple-imperative') {
    return 'imperative';
  } else if (form === 'present-progressive-indicative') {
    return 'progressive';
  } else {
    const _exhaustiveCheck: never = form;
  }
}


const VerbPersonName: {[form in FarsiVerbPerson]: any} = {
  '1st-singular': {
    text: '1st person singular',
    sample: 'I',
  },
  '2nd-singular': {
    text: '2nd person singular',
    sample: 'you',
  },
  '3rd-singular': {
    text: '3rd person singular',
    sample: 'he/she/it',
  },
  '1st-plural': {
    text: '1st person plural',
    sample: 'we',
  },
  '2nd-plural': {
    text: '2nd person plural',
    sample: 'you',
  },
  '3rd-plural': {
    text: '3rd person plural',
    sample: 'they',
  }
};


export function getVerbPersonName(form: FarsiVerbPerson) {
  const data = VerbPersonName[form];
  return data ? data.text : "-";
}

export function getBudanForm(form: FarsiVerbPerson) {
  const p = {
    style: {
      fontSize: '.9em',
      color: 'gray'
    }
  }
  if (form === '1st-singular') {
    return <>hastam</>;
  } else if (form === '2nd-singular') {
    return <>hasti</>;
  } else if (form === '3rd-singular') {
    return <>hast</>;
  } else if (form === '1st-plural') {
    return <>hastim</>;
  } else if (form === '2nd-plural') {
    return <>hastid</>;
  } else if (form === '3rd-plural') {
    return <>hastand</>;
  } else {
    const _exhaustiveCheck: never = form;
  }
}


export const PossessivePronounMapping: {[form in FarsiNounPossessive]: {
  pronoun: {
    base: string
  }
}} = {
  '1st-singular': {
    pronoun: {
      'base': 'من',
    }
  },
  '2nd-singular': {
    pronoun: {
      'base': 'تو',
    }
  },
  '3rd-singular': {
    pronoun: {
      'base': 'او',
    }
  },
  '1st-plural': {
    pronoun: {
      'base': 'ما',
    }
  },
  '2nd-plural': {
    pronoun: {
      'base': 'شما',
    }
  },
  '3rd-plural': {
    pronoun: {
      'base': 'آنها',
    }
  }
}

export function getNounPossessive(form: FarsiNounPossessive) {
  const p = {
    style: {
      fontSize: '.9em',
      color: 'gray'
    }
  }
  if (form === '1st-singular') {
    return <>1st per. singular possessive <em {...p}>(mine)</em></>;
  } else if (form === '2nd-singular') {
    return <>2nd per. singular possessive <em {...p}>(yours)</em></>;
  } else if (form === '3rd-singular') {
    return <>3rd per. singular possessive <em {...p}>(his/hers/its)</em></>;
  } else if (form === '1st-plural') {
    return <>1st per. plural possessive <em {...p}>(ours)</em></>;
  } else if (form === '2nd-plural') {
    return <>2nd per. plural possessive <em {...p}>(yours)</em></>;
  } else if (form === '3rd-plural') {
    return <>3rd per. plural possessive <em {...p}>(theirs)</em></>;
  } else {
    const _exhaustiveCheck: never = form;
  }
}

