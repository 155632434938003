import React, {Fragment, useCallback, useContext} from 'react';
import {Button, Tooltip, IconButton, } from '@material-ui/core';
import {PlayerAPI} from 'languagetool-player/src/ui/Video';
import {UIState} from "./index";
import {ShortcutDescription} from "../ShortcutHelp";
import {Redo, Undo} from "@material-ui/icons";
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import {useBaconObservable} from "../../utils";
import {Actions} from "./KeyboardShortcuts";
import {ContextAPI, DialogManagerContext} from "../dialogs/DialogManager";
import {StartTimingDialog} from "../dialogs/StartTimingDialog";


type Props = {
  playerAPI: PlayerAPI|null,
  uiState: UIState,
  editorAPI: ICaptionsEditor|null,
  actions: Actions<any>,

  onModeChanged: (isTiming: boolean, whichLine: 'first'|'selected') => void
}

export class CenterButtons extends React.Component<Props> {
  render() {
    if (!this.props.playerAPI) {
      return null;
    }
    return <Fragment>
      <UndoButton editorAPI={this.props.editorAPI} actions={this.props.actions} />
      <RedoButton editorAPI={this.props.editorAPI} actions={this.props.actions} />

      <StartTimingButton
        uiState={this.props.uiState}
        onModeChanged={this.props.onModeChanged}
      />
    </Fragment>
  }
}


function StartTimingButton(props: {
  uiState: UIState,
  onModeChanged: (isTiming: boolean, whichLine: 'first'|'selected') => void
}) {
  const dialogs: ContextAPI = useContext(DialogManagerContext)!;

  const handleClick = useCallback(() => {
    if (!props.uiState.isTimingMode) {
      dialogs.show(StartTimingDialog, ({startWithSelected}) => {
        props.onModeChanged(true, startWithSelected ? 'selected' : 'first');
      });
    }

    else {
      props.onModeChanged(false, 'first');
    }
  }, [dialogs, props.onModeChanged, props.uiState.isTimingMode]);

  const button = <Button color={"inherit"} onClick={handleClick}>
    {props.uiState.isTimingMode ? "Stop timing" : "Start timing"}
  </Button>;

  return <Tooltip
    enterDelay={1500}
    title={<>
      <div>
        <strong>Timing Mode</strong>:
        While the media is playing, you press keys to set the time of each line
        to match what you are hearing.
      </div>
      <ShortcutDescription keyName={"left"} label={"Set Line Start Time"} />
      <ShortcutDescription keyName={"right"} label={"Set Line End Time"} />
    </>}
  >
    {button}
  </Tooltip>;
}


function UndoButton(props: {editorAPI: ICaptionsEditor|null, actions: Actions<any>}) {
  const onClick = useCallback(() => {
    props.actions.execute('undo')
  }, [props.actions]);

  // The history itself has no change events, but should not change unless the value does!
  const undos$ = props.editorAPI ? props.editorAPI.observe().map(value => {
    const undos = props.editorAPI?.slateEditor.history.undos;
    return !!undos && undos.length;
  }) : null;
  const hasUndos = useBaconObservable(undos$, false);

  return <Tooltip  title={"Undo"}><div>
    <IconButton color={"inherit"} onClick={onClick} disabled={!hasUndos}>
      <Undo />
    </IconButton>
  </div></Tooltip>;
}


function RedoButton(props: {actions: Actions<any>, editorAPI: ICaptionsEditor|null}) {
  const onClick = useCallback(() => {
    props.actions.execute('redo')
  }, [props.actions]);

  // The history itself has no change events, but should not change unless the value does!
  const redos$ = props.editorAPI ? props.editorAPI.observe().map(value => {
    const redos = props.editorAPI?.slateEditor.history.redos;
    return !!redos && redos.length;
  }) : null;
  const hasRedos = useBaconObservable(redos$, false);

  return <Tooltip  title={"Redo"}><div>
    <IconButton color={"inherit"} onClick={onClick} disabled={!hasRedos}>
      <Redo />
    </IconButton>
  </div></Tooltip>;
}
