import React, {useCallback, useEffect, useRef, useState} from "react";
import pDebounce from 'p-debounce';
import { filter, map } from "lodash";


const RootKey = '';
export type WarningsStorage = {[fieldPath: string]: string[]};


type WarningsActions = {
  addWarning: (warning: string, fieldPath?: string) => void
};


/**
 * This is basically a list of warnings as state. You get ack>
 *
 * - A list of current warnings.
 * - A function to trigger a warning check.
 *
 * The latter will really call into your callback, where you can add warnings to the state.
 *
 * This really just manages and holds the state, and debounces the warning check calls.
 */
export function useWarningSystem(
  validateFunc: (values: any, actions: WarningsActions) => Promise<any>,
  opts?: {ignoredIds?: string[]}
) {
  // The current known warnings. We store in as a ref too.
  const [warnings, setWarnings] = useState<WarningsStorage>({});
  const lastKnownWarnings = useRef<WarningsStorage>({});

  // The last form values we ran the validation against. Used to detect changes.
  const lastCheckedValues = useRef(null);

  // The validate function may change, we do not want to "resetup" checkWarnings if we do, so store it
  // in a ref and use that.
  const lastUserValidateHandler = useRef(validateFunc);
  useEffect(() => {
    lastUserValidateHandler.current = pDebounce(validateFunc, 200);
  });

  const checkingWarningPromise = useRef<Promise<any> | null>(null);

  const filtered = (warningsStore: WarningsStorage): WarningsStorage => {
    // @ts-ignore: Typings make this an array, even if the code does not.
    return filter(
      map(
        warningsStore,
        warningIds => warningIds.filter(id => (opts?.ignoredIds?.indexOf(id) ?? -1) == -1)
      ),
      warningIds => !!warningIds.length
    )
  }

  // This function will, debounced, run the warning validation function. To minimize the number of
  // calls, it will be a noop if you call it twice with the same values (it caches the last copy).
  const checkWarnings = useCallback(async (values: any): Promise<{warnings: WarningsStorage, hasWarnings: boolean}> => {
    // Only if the values changed.
    if (lastCheckedValues.current === values) {
      let filteredWarnings = filtered(lastKnownWarnings.current);
      return {
        warnings: filteredWarnings,
        hasWarnings: !!Object.keys(filteredWarnings).length
      };
    }

    lastCheckedValues.current = values;

    let warningsInProgress: WarningsStorage = {};
    checkingWarningPromise.current = lastUserValidateHandler.current(values, {
      addWarning: (warning: string, fieldPath?: string) => {
        if (!fieldPath) {
          fieldPath = RootKey;
        }

        if (!warningsInProgress[fieldPath]) {
          warningsInProgress[fieldPath] = [];
        }

        warningsInProgress[fieldPath].push(warning);
      }
    });
    await checkingWarningPromise.current;

    // Hold the warnings found in state + local cache.
    setWarnings(warningsInProgress);
    lastKnownWarnings.current = warningsInProgress;

    let filteredWarnings = filtered(warningsInProgress);
    return {
      warnings: filteredWarnings,
      hasWarnings: !!Object.keys(filteredWarnings).length
    };
  }, [checkingWarningPromise, lastCheckedValues, setWarnings]);

  return {
    warnings: filtered(warnings),
    checkWarnings,
  }
}


/**
 * Return true if the warning with the key `key` is in the `WarningsStorage`.
 */
export function hasWarning(warnings: WarningsStorage, key: string): boolean {
  for (const listOfKeys of Object.values(warnings)) {
    if (listOfKeys.indexOf(key) > -1) {
      return true;
    }
  }
  return false;
}
