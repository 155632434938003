// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-player-src-ui-WordPopup-WordPopupVisual__wordPopup--1dDo9{max-height:600px;background-color:rgba(251,251,251,0.99);color:#222222;border-radius:3px;border:1px solid rgba(0,0,0,0.1);box-shadow:1px 1px 3px 0px rgba(0,0,0,0.8);overflow:auto;font-size:20px;width:350px}@media (max-width: 350px){._-languagetool-player-src-ui-WordPopup-WordPopupVisual__wordPopup--1dDo9{width:95vw}}\n", ""]);
// Exports
exports.locals = {
	"wordPopup": "_-languagetool-player-src-ui-WordPopup-WordPopupVisual__wordPopup--1dDo9"
};
module.exports = exports;
