import React, { useState } from "react";
import {Formik, FormikProps} from 'formik';
import {CreateWord, FarsiWordData, getAPI, WordsAdminAPI} from "../wordsApi";
import {Form} from "../../../components/Form";
import {FarsiWordForm, FarsiWordFormLogic} from "../langpacks/fa/WordForm/WordFormAndLogic";
import {WordTypeField} from "./fields/WordTypeField";
import {hasWarning, useWarningSystem, WarningsStorage} from "../../../components/FormikWarnings";
import {validateWordWarnings, WarningsField} from "../langpacks/fa/WordForm/Warnings";
import {MeaningDescriptorField} from "./fields/MeaningDescriptorField";
import {WordBaseField} from "./fields/WordBaseField";
import {FormValues} from "../langpacks/fa/WordForm/FormValues";
import { WordDef } from "../assignScreen";


export type WordFormLogicProps = {
  lang: string,
  onWordCreated?: (wordId: WordDef) => void,
  onWordChanged?: () => void,

  // This puts the form into "edit" mode.
  wordId?: number,

  // The default values in the form.
  wordText?: string,
  wordType?: string,
  wordDescription?: string,
  // NB: This is not the same as the internal form field keys, it's the database word data blob.
  // For example, the form field key is isCompound, but in the word data it will be is_compound.
  // There is no way to pass a field key. You have to know the corresponding word data blob key.
  // TODO: It would be nice to use FarsiWordData instead of any here, but some code then has to test
  // for each subtype.
  wordData?: {[key: string]: any},

  // A word that the word must match against
  matchText?: string,

  // Allow certain warnings to be ignored
  ignoredWarningIds?: string[],

  api: WordsAdminAPI,
  children: (formik: FormikProps<any>, context: NewWordEntryLogic) => any,
};


export type WordFormProps = {
  allowInvariantSubtypes?: boolean,
  showDescriptionField?: boolean
}

/**
 * The WordForm with the right fields for the given language.
 */
export function WordForm(props: {
  lang: string,
} & WordFormProps) {
  const {lang, ...restProps} = props;
  if (props.lang == 'fa') {
    return <FarsiWordForm {...restProps} />;
  }

  return <DefaultWordForm {...restProps} />
}


/**
 * The default version of the WordForm if no language-specific version is available.
 */
export function DefaultWordForm(props: {
  allowInvariantSubtypes?: boolean
}) {
  return <Form>
    <WordTypeField allowAdvancedTypes={props.allowInvariantSubtypes} />
    <WordBaseField />
    <MeaningDescriptorField />

    <WarningsField />
  </Form>;
}


/**
 * Implements the <Formik> root to hold the form state.
 *
 * Implementation-wise it delegates to the language-specific implementation.
 */
export function WordFormLogic(props: WordFormLogicProps) {
  let LogicComponent;
  if (props.lang == 'fa') {
    LogicComponent = FarsiWordFormLogic;
  }
  else {
    LogicComponent = DefaultWordFormLogic;
  }

  return <LogicComponent {...props} />
}


/**
 * From the form values, create a structure to submit to the createWord API request.
 */
export function prepareWord(
  values: any,
  opts: {
    lang: string,
    prepareSubmission?: (values: any, obj: CreateWord) => any
  }): CreateWord
{
  let data = {
    base: values.base,
    type: values.type,
    lang: opts.lang,
    description: values.description,
    descriptor: values.meaningDescriptor
  };
  if (opts.prepareSubmission) {
    data = opts.prepareSubmission(values, data);
  }
  return data;
}


export type NewWordEntryLogic = {
  warnings: WarningsStorage,
  transliterated?: string[]|null
};

export const NewWordEntryLogicContext = React.createContext<NewWordEntryLogic|null>(null);


/**
 * A formik "form" to create a new word. The children render the UI, so this is up to you, this
 * only implements the form logic. The idea is that based the language and word type, you may wish
 * to customize this.
 */
export function DefaultWordFormLogic(props: WordFormLogicProps & {
  extraInitialValues?: Partial<FormValues>,
  prepareSubmission?: (values: any, obj: any) => any,
  validate?: (values: any) => any
}) {
  const [transliterated, setTransliterated] = useState<string[]|null>(null);

  // Setup the warning system. Gives you the current warnings + a callback to trigger validation.
  const {warnings, checkWarnings} = useWarningSystem(async (values, {addWarning}) => {
    if (values.base) {
      let word = prepareWord(values, props);

      const {transliterated} = await validateWordWarnings(word, addWarning, {
        textToMatch: props.matchText || null,
        wordId: props.wordId,
        api: props.api
      });
      setTransliterated(transliterated || null);
    }
  }, {ignoredIds: props.ignoredWarningIds});

  const context = {
    warnings: warnings,
    transliterated: transliterated
  };

  return <NewWordEntryLogicContext.Provider
    value={context}
  >
    <Formik<FormValues>
      initialValues={
        { base: props.wordText || "",
          type: props.wordType || "",
          description: props.wordDescription || "",
          meaningDescriptor: "",
          ignoreWarnings: false,
          ...props.extraInitialValues
        } as FormValues
      }
      validate={async (values) => {
        let errors = {};

        // Validate
        if (props.validate) {
          errors = {...errors,...(await props.validate(values))};
        }

        // Run warnings checks, but only if we have a type, otherwise the API would fail to be valid GraphQL
        if (values.type) {
          try {
            const {hasWarnings, warnings} = await checkWarnings(values);
            const allowWarningsToBeIgnored = !hasWarning(warnings, 'could-not-parse');

            if (hasWarnings && (!values.ignoreWarnings || !allowWarningsToBeIgnored)) {
              Object.assign(errors, {ignoreWarnings: "There are warnings"});
            }
          } catch (e) {
            // Because formik seems to swallow any errors.
            console.log('Failed to execute warnings check', e);
            Object.assign(errors, {ignoreWarnings: "Failed to validate all warnings"});
          }
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          let data = prepareWord(values, props);

          // Either create new or edit
          if (props.wordId) {
            await props.api.editWord(props.wordId, data);
            if (props.onWordChanged) { props.onWordChanged(); }
          }
          else {
            const [newWordId, newMeaningId] = await props.api.createWord(data);
            if (props.onWordCreated) {
              props.onWordCreated({
                id: newWordId,
                meaningId: newMeaningId
              });
            }
          }
        }
        catch(e) {
          alert("Could not save.")
        }
        finally {
          setSubmitting(false)
        }
      }}
    >
      {formik => props.children(formik, context)}
    </Formik>
  </NewWordEntryLogicContext.Provider>
}
