import React from "react";
import {ErrorMessage} from "./Message";
import { Container } from "./PopupContent";


export class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(error, errorInfo);
    // I think the console error should be enough to report it to sentry, so we do not need
    // to introduce a sentry dependency in languagetool-player?
    //Sentry.captureException(err);
  }

  render() {
    if (this.state.hasError) {
      return <Container><ErrorMessage /></Container>
    }

    return this.props.children;
  }
}