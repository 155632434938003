import {StructureNodeStore} from "../StructureNodeStore";
import {
  ForeignWordNode, isForeignWordNode,
  isNumeralNode,
  isWordNode,
  NumeralNode,
  StructureNode,
  WordNode
} from "languagetool-player/src/models/Node";
import { isEqual } from "lodash";
import {Captions} from "./index";
import {VocableId} from "languagetool-player/src/models/formats/CaptionTrack";
import {CaptionsInterface} from "../../integrations/Market/useGetTrackInterface";


// We call this everything in this api "word node", but actually allow both WordNodes and NumeralNodes to be
// used. This is because according to our logic, those two structure nodes are mutually exclusive, while
// for example a vocable is allowed to have both a WordNode AND an Expression Node assigned.
export type WordLikeNode = WordNode|NumeralNode|ForeignWordNode;


function isWordLikeNode(node?: StructureNode): node is WordLikeNode {
  return isWordNode(node) || isNumeralNode(node) || isForeignWordNode(node);
}

/**
 * For the given vocable, return the word node attached to it. Should only be one.
 */
export function getWordNodeForVocable(captions: Captions, vocableId: string): WordLikeNode | null {
  const structureNodes: StructureNodeStore = captions.nodes;
  const nodes = structureNodes.getNodesByVocableId(vocableId).filter(isWordLikeNode).toList();
  return nodes.size ? nodes.get(0) as WordLikeNode : null;
}


export function getWordNodeForVocables(captions: Captions, vocableIds: string[]): WordLikeNode | null {
  // Since there can only be one node, check whatever is applied to the first.
  const node = getWordNodeForVocable(captions, vocableIds[0]);
  if (!node) {
    return null;
  }

  // Then ensure the node covers exactly the vocables queried
  return isEqual(new Set(node.vocables), new Set(vocableIds)) ? node : null;
}

/**
 * Add a new word node to the store.
 */
export function setWordNode(api: CaptionsInterface, word: WordLikeNode) {
  let newStructureNodes = api.nodes;

  // Remove any existing word node first from any of the affected vocables.
  word.vocables.forEach(vocableId => {
    newStructureNodes = newStructureNodes.removeAllFromVocable(vocableId);
  });

  // Add the new word node.
  newStructureNodes = newStructureNodes.addNew(word);

  api.setNodes(newStructureNodes);
}


/**
 * Remove the current word node of the given vocable.
 *
 * TODO: Currently, this removes all nodes, not just the WordLikeNode. Consider in the future we might
 * allow two wordnodes, so this may become more difficult to implement.
 */
export function removeWordNode(api: CaptionsInterface, vocable: VocableId): VocableId[] {
  const structureNodes = api.nodes;

  // Look at the node attached to `vocable` - which other vocables is it attached to.
  const affectedVocables: VocableId[] = structureNodes.getNodesByVocableId(vocable).map((v: any) => v.vocables).flatMap(x => x).toJS();

  // Remove all nodes from al affected vocables.
  let newStructureNodes = structureNodes;
  affectedVocables.forEach(vocableId => {
    newStructureNodes = newStructureNodes.removeAllFromVocable(vocableId);
  });

  api.setNodes(newStructureNodes);

  // Return those vocables affected.
  return Array.from(new Set(affectedVocables));
}