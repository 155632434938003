import {
  FarsiAdjective,
  FarsiInflectionAnnotation,
  FarsiNoun,
  FarsiNounPossessive, FarsiPreposition,
  FarsiVerb,
  FarsiVerbPerson
} from "./index";
import React, { Fragment } from "react";
import {componentJoin} from "../../ui/utils";
import {WordStructure, WordStructurePart} from "../../models/Node";
import {getVerbFormName, getVerbPersonName, getWordType, PossessivePronounMapping} from "./formatters";
import {forceLemmaWordId, isWordData} from "../../annotations";
import {ISimpleCache} from "../../cache";
import {WordDataAnnotation} from "../../annotations/WordData";
import {WordLink} from "../../ui/WordPopup/parts/WordLink";
import {Text} from 'react-native';


function isContraction(part: WordStructurePart) {
  // noinspection RedundantIfStatementJS
  if (part.type == 'budan') {
    return true;
  }
  return false;
}


/**
 * Sometimes <FarsiGrammarForm> does not render anything. This is supposed to tell you in advance,
 * so you can avoid rendering a header or a tab.
 */
export function shouldRenderGrammar(a: {
  inflection?: any,
  structure?: WordStructure
}) {
  const form = a.inflection;

  if (anyKindOfSuffixOrPrefix(a)) {
    return true;
  }

  if (a.structure && a.structure.parts.filter((part: WordStructurePart) => isContraction(part)).length) {
    return true;
  }

  if (form.type == 'verb') {
    return true;
  }

  else if (form.type == 'noun') {
    if (form.plural || anyKindOfSuffixOrPrefix(a)) {
      return true;
    }
    return false;
  }

  return false;
}


/**
 * Renders an InflectionAnnotation using few lines, but as many as necessary.
 */
export const FarsiGrammarDescription: React.FunctionComponent<{
  annotation: FarsiInflectionAnnotation,

  // This is for information about the base words reference in the grammar.
  relatedAnnotations?: ISimpleCache,
}> = (props) => {
  const {annotation: a} = props;
  const form = a.inflection;
  const wordData = props.relatedAnnotations ? props.relatedAnnotations.get(a.wordId).filter(isWordData)[0] : undefined;

  const parts: any[] = [];

  if (form.type == 'verb') {
    parts.push.apply(parts, Array.from(renderVerb(a, wordData)));
  }

  else if (form.type == 'noun' || form.type == 'proper_noun') {
    parts.push.apply(parts, Array.from(renderNoun(a, wordData)));
  }

  else if (form.type == 'adjective') {
    parts.push.apply(parts, Array.from(renderAdjective(a, wordData)));
  }

  else if (form.type == 'preposition') {
    parts.push.apply(parts, Array.from(renderPreposition(a, wordData)));
  }

  else {
    // This should only happen for those that have no grammar rules
    parts.push(
      <Text>
        {getWordType(form.type)}{" "}
        <WordLink
          to={a.wordId}
          wordData={wordData}
        />
      </Text>
    );
  }

  if (a.structure) {
    for (const part of a.structure.parts) {
      if (part.type === 'budan') {
        parts.push(renderBudanContraction(part));
      }
      if (part.type === 'indefinite') {
        parts.push(renderIndefinite(part))
      }
      if (part.type === 'ezafe') {
        parts.push(renderEzafe(part))
      }
      if (part.type === 'ro') {
        parts.push(renderRo(part))
      }
    }
  }

  return parts.map((part, idx) => {
    const el = React.cloneElement(part, {key: idx});
    if (idx === 0) {
      return <Text key={idx}>{el}</Text>;
    }
    else if (idx === 1) {
      return <Text key={idx}>, with {el}</Text>;
    }
    else if (idx === parts.length-1) {
      return <Text key={idx}> and {el}</Text>;
    }
    else {
      return <Text key={idx}>, {el}</Text>
    }
  }) as any;
}



function renderBudanContraction(part: WordStructurePart) {
  const person = getVerbPersonName(part.value as any);
  return <Text>{person} of <WordLink to={{text: 'بودن', lang: 'fa'}} /></Text>
}


function renderIndefinite(part: WordStructurePart) {
  return <Text>indefinite marker</Text>
}

function renderEzafe(part: WordStructurePart) {
  if (part.orth === "") {
    return <Text>an unwritten ezafe</Text>;
  }
  return <Text>an ezafe</Text>
}

function renderRo(part: WordStructurePart) {
  return <Text>direct object marker</Text>
}


/**
 * For a noun, output the plural state.
 */
function *renderNoun(annotation: FarsiInflectionAnnotation, wordData?: WordDataAnnotation) {
  const form = annotation.inflection as FarsiNoun;

  // If the noun is plural, output it, and like to the lemma.
  if (form.plural) {
    yield <>
      plural of {getWordType(form.type)}
      {" "}
      <WordLink
        to={annotation.wordId}
        wordData={wordData}
      />
    </>
  }

  else {
    yield <>
      {getWordType(form.type)}
      {" "}
      <WordLink
        to={annotation.wordId}
        wordData={wordData}
      />
    </>;
  }

  if (form.possessive) {
    const pronoun = PossessivePronounMapping[form.possessive];
    yield <>
      pronoun <WordLink to={{
        text: pronoun.pronoun.base,
        lang: 'fa'
    }}/>
    </>
  }
}


/**
 * For an adjective.
 */
function *renderAdjective(annotation: FarsiInflectionAnnotation, wordData?: WordDataAnnotation) {
  const form = annotation.inflection as FarsiAdjective;

  if (form.comparative || form.superlative) {
    const type = form.comparative ? "comparative" : "superlative";
    yield <>
      {type} of
      {" "}
      <WordLink
        to={annotation.wordId}
        wordData={wordData}
      />
    </>
  }

  else {
    yield <>
      adjective
      {" "}
      <WordLink
        to={annotation.wordId}
        wordData={wordData}
      />
    </>;
  }
}


/**
 * For a preposition.
 */
function *renderPreposition(annotation: FarsiInflectionAnnotation, wordData?: WordDataAnnotation) {
  const form = annotation.inflection as FarsiPreposition;

  yield <>
    {getWordType(form.type)}
    {" "}
    <WordLink
      to={annotation.wordId}
      wordData={wordData}
    />
  </>;

  const enclitic = (annotation.structure?.parts ?? []).filter(x => x.type == 'possessive')[0];

  if (enclitic) {
    const object = PossessivePronounMapping[enclitic.value as FarsiNounPossessive];
    yield <>
      object <WordLink to={{
      text: object.pronoun.base,
      lang: 'fa'
    }}/>
    </>
  }
}


/**
 * If the word has a suffix or prefix that we cannot consider it to be in the base lemma shape.
 * This basically detects information such as an added ezafe that we only have in the "word structure"
 * object, to determine if we have to tell the user about this in grammar analysis.
 */
function anyKindOfSuffixOrPrefix(form: {
  structure?: WordStructure
}) {
  return form.structure && form.structure.parts.length > 1;
}


/**
 * For a verb, render the tense and person.
 */
function *renderVerb(
  annotation: FarsiInflectionAnnotation,
  wordData?: WordDataAnnotation
) {
  const form = annotation.inflection as FarsiVerb;

  const flags = [];
  flags.push(getVerbFormName(form.form));
  flags.push(getVerbPersonName(form.person));
  if (form.negated) {
    flags.push('negated')
  }
  yield <>
    {componentJoin(', ', flags)} of {" "}
    <WordLink
      to={forceLemmaWordId(annotation.wordId)}
      wordData={wordData}
    />
  </>;

  if (annotation.structure) {
    const pronounParts = annotation.structure.parts.filter(p => p.type == 'possessive');
    if (pronounParts.length > 0) {
      const pronounPart = pronounParts[0];
      const pronoun = PossessivePronounMapping[pronounPart.value as FarsiNounPossessive];
      yield <>
        pronoun <WordLink to={{
        text: pronoun.pronoun.base,
        lang: 'fa'
      }}/>
      </>
    }
  }
}


