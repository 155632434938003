import React from 'react';
import {Formik, Field, FieldArray, FieldProps} from 'formik';
import styles from './Meaning.module.css';
import {TextField, IconButton, ComboBox, PrimaryButton} from 'office-ui-fabric-react';
import {MeaningFragment} from "../../../types/MeaningFragment";
import {useMutation} from "multi-apollo";
import gqlw from 'graphql-tag';
import {SaveMeaningDefinition, SaveMeaningDefinitionVariables} from "../../../types/SaveMeaningDefinition";


function useSaveMeaningMutation(meaningId: string, definitionId?: string) {
  const [saveMeaningDefinition] = useMutation<SaveMeaningDefinition, Pick<SaveMeaningDefinitionVariables, 'data'>>(gqlw`
    mutation SaveMeaningDefinition($meaningId: ID!, $definitionId: ID, $data: MeaningDefinitionProps!) {
      saveMeaningDefinition(meaningId: $meaningId, data: $data, definitionId: $definitionId) {          
        meaning {
          id,
          descriptor
        }
      }
    }
  `, {
    variables: {
      meaningId,
      definitionId,
    } as any
  });

  return saveMeaningDefinition;
}


export function Meaning(props: {
  number: number,
  meaning: MeaningFragment,
  reload: () => any
}) {
  const definition = props.meaning.definitions[0];
  const saveMeaning = useSaveMeaningMutation(props.meaning.id, definition?.id);

  return <Formik
    initialValues={{
      words: definition?.words ?? [],
      properties: (definition?.properties ?? []).map(({key, value}: any) => ({key, value: JSON.parse(value)})),
      text: definition?.text || "",
      descriptor: props.meaning.descriptor
    }}
    enableReinitialize={true}
    onSubmit={async values => {
      await saveMeaning({
        variables: {
          data: {
            lang: 'en',
            words: values.words,
            text: values.text,
            descriptor: values.descriptor,
            properties: values.properties.map(({key, value}: any) => ({key, value: JSON.stringify(value)}))
          }
        }
      });
      props.reload();
    }}
  >
    { ({submitForm, dirty}) => {
      return <div className={styles.root}>
        <div className={styles.title}>
          <span className={styles.number}>{props.number}</span>
          <div style={{flex: 1}}>
            <Field
              as={TextField}
              name="descriptor"
              placeholder={"Very short (1-3 words) to help differentiate this meanining from others. Can be a synonym"}
            />

            <div className={styles.grid}>
              <div className={styles.label}>Words</div>
              <div>
                <WordsArray/>
              </div>
              <div className={styles.label}>Text</div>
              <div>
                <Field
                  name={"text"}
                >
                  {({field}: FieldProps) => {
                    return <TextField multiline autoAdjustHeight style={{width: '100%'}} {...field} />
                  }}
                </Field>
              </div>
              <div className={styles.label}>Properties</div>
              <div>
                <PropertiesArray/>
              </div>
            </div>
          </div>
          {dirty ? <PrimaryButton onClick={submitForm}>Save</PrimaryButton> : null}
        </div>
      </div>
    }}
  </Formik>
}


function WordsArray() {
  return <FieldArray
    name="words"
    render={arrayHelpers => {
      const words = arrayHelpers.form.values.words || [];
      return <div className={styles.wordsArray}>
        {words.map((friend: any, index: number) => (
          <div key={index} style={{display: 'flex', alignItems: 'center', padding: '5px'}}>
            <Field
              name={`words.${index}`}
              as={TextField}
            />
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              onClick={() => arrayHelpers.remove(index)}
            />
          </div>
        ))}

        <IconButton
          iconProps={{ iconName: 'AddTo' }}
          onClick={() => arrayHelpers.push('')}
        />
      </div>
    }}
  />
};


function PropertiesArray() {
  return <FieldArray
    name="properties"
    render={arrayHelpers => {
      const properties = arrayHelpers.form.values.properties || [];
      return <div>
        {properties.map((friend: any, index: number) => (
          <div key={index} style={{display: 'flex', alignItems: 'center', padding: '5px'}}>
            <Field
              name={`properties.${index}.key`}
            >
              {
                ({field, form, meta}: FieldProps<any>) => {
                  return <ComboBox
                    selectedKey={field.value}
                    onChange={(e, option) => {
                      form.setFieldValue(field.name, option?.key ?? "");
                    }}
                    options={[
                      { key: 'A', text: 'Option A' },
                      { key: 'B', text: 'Option B' },
                    ]}
                  />
                }
              }
            </Field>
            &nbsp;
            <Field
              name={`properties.${index}.value`}
              as={TextField}
            />
            <IconButton
              iconProps={{ iconName: 'Delete' }}
              onClick={() => arrayHelpers.remove(index)}
            />
          </div>
        ))}

        <IconButton
          iconProps={{ iconName: 'AddTo' }}
          onClick={() => arrayHelpers.push({key: '', value: ''})}
        />
      </div>
    }}
  />
};