import {Path} from "opentype.js";
import {annotate} from "./annotations2";
import {BoundingBox} from "./useRender";



type Arrow = {
  start: any,
  end: any
}

export type Annotation = {
  left: number,
  top: number,
  width: number,
  height: number
  arrow?: Arrow
}


/**
 * This places annotations.
 *
 * The return value is annotation and paths, all shifted.
 */
export function useAnnotations(props: {
  paths: Path[]|null,
  boundingBox: BoundingBox|null,
  annotations: {width: number, height: number}[]|undefined,
  mode?: "below"|"mix",
  distance?: number
}) {
  if (!props.paths || !props.annotations || !props.boundingBox) {
    return {
      //paths: props.paths,
      annotations: props.annotations as Annotation[],
      shift: {x: 0, y: 0}
    };
  }

  const input = props.paths.map((path, idx) => {
    const annotation = props.annotations![idx];
    const b = path.getBoundingBox()
    return {
      left: b.x1,
      top: b.y1,
      width: b.x2 - b.x1,
      height: b.y2 - b.y1,
      annotation: annotation
    }
  });

  // Run the algorithm
  const {annotations} = annotate({boxes: input}, props.mode, props.distance ?? 40, 0.7);

  // Figure out how far we extend beyond the original bounds by adding these annotations.
  // The calculate annotation positions can be negative, based on our input coordinate system at (0, 0) top/left.

  // Find the largest values (these are like the total bounds, and they can be negative  - mostly the minimum ones).
  const minX =
    Math.min(...annotations.map(a => Math.min(a.left, a.arrow?.start.x ?? Number.MAX_SAFE_INTEGER, a.arrow?.end.x ?? Number.MAX_SAFE_INTEGER)));
  const minY =
    Math.min(...annotations.map(a => Math.min(a.top, a.arrow?.start.y ?? Number.MAX_SAFE_INTEGER, a.arrow?.end.y ?? Number.MAX_SAFE_INTEGER)));
  const maxX = Math.max(...annotations.map((a, idx) => {
    const {width} = props.annotations![idx];
    return Math.max(a.left + width, a.arrow?.start.x ?? 0, a.arrow?.end.x ?? 0);
  }));
  const maxY = Math.max(...annotations.map((a, idx) => {
    const {height} = props.annotations![idx];
    return Math.max(a.top + height, a.arrow?.start.y ?? 0, a.arrow?.end.y ?? 0);
  }));

  // So, the shift is how much we have to move the whole coordinate system to the right/bottom, to make sure
  // that none of the annotations we added have negative coordinate value. If the minimum values are not negative,
  // then the annotation boxes are within the original box and there is no need for a shift.
  const shift = {
    x: Math.max(0, 0 - minX),
    y: Math.max(0, 0 - minY)
  };

  // How much do we extend to the right beyond the original width/height?
  const bb = props.boundingBox;
  const bbWidth = (bb.x2 - bb.x1);
  const bbHeight = (bb.y2 - bb.y1);
  const realWidth = Math.max(bbWidth, maxX) + shift.x;
  const realHeight = Math.max(bbHeight, maxY) + shift.y;
  const size = {width: realWidth, height: realHeight};

  // Add a padding
  const padding = 0;
  shift.x += padding;
  shift.y += padding;
  size.width = size.width + padding * 2;
  size.height = size.height + padding * 2;

  return {
    annotations: annotations as Annotation[],
    shift,
    size
  }
}