import {
  Annotation,
  AnnotationQuery,
  forceLemmaWordId,
  isQueryByString,
} from "../../annotations";
import {ISimpleCache, SimpleCache} from "../../cache";
import * as React from "react";
import {CustomNode, isNumeralNode, isWordNode, WordNode} from "../../models/Node";
import {InflectionAnnotation} from "../../annotations/Inflection";
import Bacon from "baconjs";
import {PopupBrowserProps} from "./PopupBrowser";
import { useState, useCallback } from "react";
import {useDeepCompareEffect} from "../../utils/useDeepCompareEffect";
import {Page, pageIsNode} from "./Page";


type State = {
  cache: ISimpleCache,
  isLoading: boolean,
  isError: boolean
};

type Actions = {
  refetch: () => void
}


interface Props extends PopupBrowserProps {
  // What to show.
  target?: Page | null,

  children: (result: State & Actions) => any
}


/**
 * This returns the data structure to send to the words-api to query for the given particular popup `Page`.
 * Recall that a page can be a database word entry, a word structure node, or a simple text query.
 *
 * This data structure is also used as a key when caching server responses.
 *
 * If you pass the `noStructure` option, then in those cases where the page is a structure node, rather than
 * querying for the structure, we return a query for the database word entry. (In practice, the difference
 * is that for a structure query, the server sometimes returns an actual english form).
 */
export function getAnnotationQueryForPage(target: Page, opts?: {noStructure: boolean}): AnnotationQuery|undefined {
  let toQuery: AnnotationQuery|undefined = undefined;

  if (pageIsNode(target)) {
    if (isWordNode(target)) {
      if (target.structure && !opts?.noStructure) {
        toQuery = {
          wordId: target.wordId,
          structure: target.structure,
          inflection: target.inflection
        };
      }
      else {
        toQuery = target.wordId;
      }
    }
    else if (isNumeralNode(target) && target.wordId) {
      toQuery = target.wordId;
    }
  } else {
    // target is either a database word id, or a "text def" structure.
    toQuery = target;
  }

  return toQuery;
}


export function FetchAnnotations(props: Props) {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [cache, setCache] = useState<ISimpleCache>(new SimpleCache());

  const fetchAnnotations = useCallback(() => {
    const {target, loader} = props;
    if (!target) {
      setLoading(false);
      return;
    }

    // See if we have a custom node
    const customNode: CustomNode | null = /*isCustomNode(target) ? target : */null;

    if (customNode) {
      // this.setState({
      //   isLoading: false,
      //   annotations: customNode.annotations
      // })
    } else {
      // Otherwise, load us some.
      setLoading(true);

      // Figure out what to query for. If the popup is showing a WordNode here, then we query for the word
      // id behind it, that is, the base word behind a particular grammar structure.
      let toQuery: AnnotationQuery|undefined = undefined;
      toQuery = props.target ? getAnnotationQueryForPage(props.target) : undefined;

      // If the target already has a grammar, then we are querying the lemma and do not want any further
      // grammar suggestions.
      let targetHasItsOwnGrammer = (pageIsNode(target) && isWordNode(target) && target.inflection);
      if (targetHasItsOwnGrammer && toQuery && isQueryByString(toQuery)) {
        toQuery = forceLemmaWordId(toQuery);
      }

      // In the case of numbers, we do not have a word, and so we do not have to query.
      if (!toQuery) {
        let cache = new SimpleCache();
        setCache(cache);
        setLoading(false);
        return;
      }

      const loadingAnnotations =
        loader.follow$(toQuery)
          .debounce(200);

      return loadingAnnotations.subscribe((event: Bacon.Event<ISimpleCache>) => {
        if (event.hasValue) {
          // @ts-ignore: In the bacon.js types this is a function for some reason
          const cache: ISimpleCache = event.value;

          setCache(cache);
        }
        else if (event.isError) {
          // @ts-ignore Not sure why this is invalid, it seems to exist
          console.log(event.error);
          setError(true);
        }
        // @ts-ignore Not sure why this is invalid, it seems to exist
        else if (event.isEnd) {
          setLoading(false);
        };
      });
    }
  }, [props.target, props.loader]);

  const refetch = useCallback(() => {
    setCache(new SimpleCache());
    setLoading(false);
    setError(false);

    return fetchAnnotations();
  }, [fetchAnnotations, setCache, setLoading, setError]);

  useDeepCompareEffect(() => {
    // unsubscribe when a new thing starts to run!
    return refetch();
  }, [props.learnerLangs, props.target, refetch]);

  return props.children({
    isError,
    isLoading,
    cache,
    refetch
  });
}


export function getInflectionAnnotationFromPage(target: Page): InflectionAnnotation|null {
  // If the target is a WordNode with grammar attached, we will display that grammar.
  // If there is an inflection attached to the word itself, use it.
  let targetHasItsOwnGrammer = (pageIsNode(target) && isWordNode(target) && target.inflection);

  // Inject that grammar as a custom annotation.
  let annotationBasedOnWordNode: Annotation | null;
  if (targetHasItsOwnGrammer) {
    const nodeTarget = target as WordNode;
    annotationBasedOnWordNode = {
      type: 'inflection',
      target: {
        wordId: nodeTarget.wordId,
        inflection: nodeTarget.inflection,
        structure: nodeTarget.structure
      },
      wordId: nodeTarget.wordId,
      inflection: nodeTarget.inflection,
      structure: nodeTarget.structure
    } as InflectionAnnotation
  } else {
    annotationBasedOnWordNode = null
  }

  return annotationBasedOnWordNode;
}