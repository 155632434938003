// Persian Wordifier
// Version: 1.2
// Author: Salman Arab Ameri
// Publish: 2019-09-11
// with use of ideas in http://www.dotnettips.info/post/626/%D8%AA%D8%A8%D8%AF%DB%8C%D9%84-%D8%B9%D8%AF%D8%AF-%D8%A8%D9%87-%D8%AD%D8%B1%D9%88%D9%81
//
// From: https://github.com/SalmanAA/wordifyfa/blob/master/wordifyfa.js
//
// Other solutions:
// - https://github.com/Navid2zp/NumberToPersianWords
// - https://github.com/5j9/num2fawords

export function wordifyNumber(num: number, level: number = 0): string {

  level = level || 0;


  // convert negative number to positive and get wordify value
  if (num<0) {
    num = num * -1;
    return "منفی " + wordifyNumber(num, level);
  }

  if (num === 0) {
    if (level === 0) {
      return "صفر";
    } else {
      return "";
    }
  }

  let
    yekan = [" یک ", " دو ", " سه ", " چهار ", " پنج ", " شش ", " هفت ", " هشت ", " نه "],
    dahgan = [" بیست ", " سی ", " چهل ", " پنجاه ", " شصت ", " هفتاد ", " هشتاد ", " نود "],
    sadgan = [" یکصد ", " دویست ", " سیصد ", " چهارصد ", " پانصد ", " ششصد ", " هفتصد ", " هشتصد ", " نهصد "],
    dah = [" ده ", " یازده ", " دوازده ", " سیزده ", " چهارده ", " پانزده ", " شانزده ", " هفده ", " هیجده ", " نوزده "];

  let result: string = "";

  if (level > 0) {
    result += " و ";
    level -= 1;
  }

  if (num < 10) {
    result += yekan[num - 1];
  } else if (num < 20) {
    result += dah[num - 10];
  } else if (num < 100) {
    result += dahgan[Math.floor(num / 10) - 2] +  wordifyNumber(num % 10, level + 1);
  } else if (num < 1000) {
    result += sadgan[Math.floor(num / 100) - 1] + wordifyNumber(num % 100, level + 1);
  } else if (num < 1000000) {
    result += wordifyNumber(Math.floor(num / 1000), level) + " هزار " + wordifyNumber(num % 1000, level + 1);
  } else if (num < 1000000000) {
    result += wordifyNumber(Math.floor(num / 1000000), level) + " میلیون " + wordifyNumber(num % 1000000, level + 1);
  } else if (num < 1000000000000) {
    result += wordifyNumber(Math.floor(num / 1000000000), level) + " میلیارد " + wordifyNumber(num % 1000000000, level + 1);
  } else if (num < 1000000000000000) {
    result += wordifyNumber(Math.floor(num / 1000000000000), level) + " تریلیارد " + wordifyNumber(num % 1000000000000, level + 1);
  }
  return result;
};
