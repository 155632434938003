import * as React from "react";
import {useContext} from "react";


export type NestingContextType = {
  prefix?: string
};


export const NestingContext = React.createContext<NestingContextType>({});


export function NestedFormProvider(props: {
  prefix: string,
  children: any
}) {
  const parentNestingContext = useContext(NestingContext);

  let subPrefix;
  if (parentNestingContext.prefix) {
    subPrefix = `${parentNestingContext.prefix}.${props.prefix}`;
  } else {
    subPrefix = props.prefix;
  }

  return <NestingContext.Provider value={{prefix: subPrefix}}>
    {props.children}
  </NestingContext.Provider>;
}
