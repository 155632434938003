import 'tippy.js/dist/tippy.css';
import * as React from 'react';
import Tippy from '@tippy.js/react';
import {CharDef, getPersianCharDef, isUnconnectedCharacter} from "./persian";
import styles from './LetterDetails.module.css';
import classnames from 'classnames';


export function Popover(props: any) {
  return (
    <Tippy
      animation="fade"
      theme="translucent"
      arrow={true}
      delay={150}
      {...props}
    />)
}


export function LetterDetails(props: {
  letter: string
}) {
  const def = getPersianCharDef(props.letter);
  if (!def) {
    return <div>Unknown character.</div>
  }

  return <div className={styles.root}>
    <div className={styles.header}>
      {props.letter} &nbsp; <em>i</em>
    </div>
    <CharacterTable def={def} />
  </div>
}

function CharacterTable(props: {
  def: CharDef
}) {
  const {def} = props;

  if (isUnconnectedCharacter(def)) {
    return <div className={styles.table}>
      <div className={classnames(styles.value, styles.initial)}>
        {def.initialOrIsolated}
      </div>
      <div className={classnames(styles.value, styles.final)}>
        {def.finalMedial}
      </div>
      <div className={classnames(styles.label, styles.initial)}>
        initial
      </div>
      <div className={classnames(styles.label, styles.final)}>
        final
      </div>
    </div>;
  }
  else {
    return <div className={styles.table}>
      <div className={classnames(styles.value, styles.initial)}>
      {def.initial}
      </div>
      <div className={classnames(styles.value, styles.medial)}>
      {def.medial}
      </div>
      <div className={classnames(styles.value, styles.final)}>
      {def.final}
      </div>
      <div className={classnames(styles.label, styles.initial)}>
      initial
      </div>
      <div className={classnames(styles.label, styles.medial)}>
      medial
      </div>
      <div className={classnames(styles.label, styles.final)}>
      final
      </div>
    </div>;
  }
}