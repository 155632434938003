import * as React from 'react';
import styles from "./LoadingIndicator.cssm";
import classnames from 'classnames';


export function LoadingIndicator(props: {
  className?: string
}) {
  return <div className={classnames(styles.spinnerWrapper, props.className)}>
    <span className={styles.spinner} />
  </div>;
}