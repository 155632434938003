import {useTransliterationStyle} from "../GlobalSettings";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";

export function useTransliterator() {
  const style = useTransliterationStyle();

  return (value: string | string[]) => {
    let parsed: string[];
    if (typeof value === "string") {
      parsed = JSON.parse(value);
    } else {
      parsed = value;
    }
    return romanizeSounds(parsed, {style});
  };
}
