import {WordId} from "../../../annotations";
import {isWordNode, WordNode} from "../../../models/Node";
import {WordDataAnnotation} from "../../../annotations/WordData";
import {pageIsNode, pageIsWordId} from "../Page";
import {TitleForStructureNode, WordIdTitle} from "./WordDisplay";
import React from "react";
import {Text} from 'react-native';
import {WordLinkContext, WordLinkContextValue} from "./WordLinkHandler";


/**
 * A link that will open the given page in the WordBrowser.
 *
 * A page can either be a word by id, or a WordNode (that is, a particular shape of a word).
 */
export class WordLink extends React.Component<{
  to: WordId | WordNode,

  // Can get the text of the word from here.
  wordData?: WordDataAnnotation,

  // Force a custom text.
  title?: string,

  className?: string,
  onClick?: (context: WordLinkContextValue) => void
}> {
  static contextType = WordLinkContext;

  context!: React.ContextType<typeof WordLinkContext>;

  render() {
    let linkText;

    if (this.props.children) {
      linkText = this.props.children;
    }

    // If no link text was given, try to generate one based on the target.
    else {
      if (pageIsNode(this.props.to) && isWordNode(this.props.to)) {
        linkText = <TitleForStructureNode
          word={this.props.to}
        />
      } else if (pageIsWordId(this.props.to)) {
        linkText = <WordIdTitle
          wordId={this.props.to}
          wordData={this.props.wordData}
        />
      }
    }
    ;

    return <Text
      style={{color: '#607D8B'}}
      // @ts-ignore
      title={this.props.title}
      onPress={this.handleClick}
    >
      {linkText}
    </Text>
  }

  handleClick = (e: any) => {
    e.preventDefault();

    if (!this.context) {
      return;
    }

    if (this.props.onClick) {
      this.props.onClick(this.context);
      return;
    }

    this.context.goToWord(this.props.to);
  }
}