import {useAudioElement} from "./useAudioElement";
import { useState, useMemo } from "react";
import {useAudioTime} from "./useAudioTime";
import {State, UseAudioPlayer} from "./useAudioPlayer.shared";



const emptyCallback = (_: number) => {};


/**
 * A simple object with play(), pause() and play state.
 */
export const useAudioPlayer: UseAudioPlayer = (opts) => {
  const [audioElement, playState] = useAudioElement();

  // TODO: We would rather not do any callback if not necessary - allow passing undefined instead.
  useAudioTime(audioElement, opts?.onTime ?? emptyCallback, [opts?.onTime]);

  const int = useMemo(() => {
    return {
      play: async (url: string) => {
        audioElement.src = url;
        audioElement.play();
      },
      pause: () => {
        audioElement.pause();
      },
    };
  }, [audioElement]);

  return useMemo(() => ({
    ...int,
    isPlaying: playState,
  }), [int, playState]);
}