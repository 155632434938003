import {NestableField} from "../../../../components/NestedFormik";
import * as React from "react";
import {SelectOption, useUIControls} from "../../../../components/FormUISwitch";


const EzafeOptions: SelectOption[] = [
  {
    value: 'included',
    label: "Included",
    subtitle: <>the base form contains an ezafe (e.g. 'tu-ye')</>
  },
  {
    value: 'disallowed',
    label: "Disallowed",
    subtitle: <>an ezafe is never allowed (e.g. 'tu')</>
  },
  {
    value: 'default',
    label: "Default",
    subtitle: <>an ezafe is optional, but is the default (e.g. 'mesl')</>
  },
  {
    value: 'optional',
    label: "Optional",
    subtitle: <>an ezafe is optional</>
  },
];


export function EzafeModeField(props: any) {
  const UI = useUIControls();

  return <NestableField
    name={"ezafe"}
    component={UI.Select}

    label="Ezafe Mode"
    options={EzafeOptions}

    {...props}
  />;
}