import React, {useState, useEffect, useCallback, useReducer, useRef, useMemo} from 'react';


type State<T> = {
  index: number,
  data: T[],
  sliceStart: number
}


type Action<T> =
  {type: 'set-index', index: number}|
  {type: 'add-data', data: T[]}|
  {type: 'change-num-visible', numVisible: number}
;



// Use a factory only to solve the <T> typing issue.
function makeReducer<T>(numVisible: number) {
  return function reducer(state: State<T>, action: Action<T>) {
    switch (action.type) {
      case 'set-index':
        let sliceStart = state.sliceStart;

        let threshold = Math.min(numVisible, 2);
        if (action.index - sliceStart <= threshold) {
          sliceStart = Math.max(0, action.index - threshold);
        }
        if ((sliceStart + numVisible) - action.index <= threshold) {
          sliceStart = Math.max(0, action.index - numVisible + threshold);
        }

        let index = Math.max(0, Math.min(action.index, state.data.length-1));

        return {
          ...state,
          index: index,  // if the mouse is used this is helpful: + (state.sliceStart - sliceStart),
          sliceStart
        };

      case 'add-data':
        return {
          ...state,
          data: [
            ...state.data,
            ...action.data
          ]
        };

      case 'change-num-visible':
        return {
          ...state,
          numVisible: action.numVisible
        };

      default:
        throw new Error();
    }
  }
}


export type LoadMoreFunc<T> = (opts: {after: number, first: number}) => Promise<T[]>;


export function useForeverScroll<T>(opts: {
  numVisible: number,
  loadNext: LoadMoreFunc<T>
}) {
  const reducer = useMemo(() => makeReducer<T>(opts.numVisible), [opts.numVisible]);
  const [state, dispatch] = useReducer(reducer, {
    index: 0,
    data: [],
    sliceStart: 0,
  });
  const hasRequest = useRef<boolean>(false);

  useEffect(() => {
    // Load more if we do not have more than numVisible*2 prepared
    if ((state.sliceStart + opts.numVisible * 2) <= state.data.length) {
      return;
    }

    if (hasRequest.current) {
      return;
    }
    hasRequest.current = true;
    opts.loadNext({after: state.data.length - 1, first: opts.numVisible})
      .then(data => dispatch({type: 'add-data', data: data}))
      .finally(() => {
      hasRequest.current = false;
    });
  }, [dispatch, state.index, opts.numVisible]);

  useEffect(() => {
    dispatch({type: 'change-num-visible', numVisible: opts.numVisible})
  }, [dispatch, opts.numVisible]);

  const setCurrentIndex = useCallback((idx: number) => {
    dispatch({type: 'set-index', index: idx})
  }, [dispatch]);

  return {
    isLoading: false,
    firstIndex: state.sliceStart,
    items: state.data.slice(state.sliceStart, state.sliceStart + opts.numVisible),
    currentIndex: state.index,
    setCurrentIndex
  }
}
