// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__taskList--3PYSa{display:grid;grid-template-columns:auto auto;align-items:center;column-gap:1em}._-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__firstColumn--B5BW9{border-left:1px solid black;padding-left:5px}._-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__description--3gwAk{grid-column:1 / span 3;color:gray;margin-bottom:1em}\n", ""]);
// Exports
exports.locals = {
	"taskList": "_-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__taskList--3PYSa",
	"firstColumn": "_-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__firstColumn--B5BW9",
	"description": "_-languagetool-editor-src-js-integrations-Market-Tasks-ManageDialog__description--3gwAk"
};
module.exports = exports;
