/** A single word in the captions, to which a timestamp can be attached.
 *
 * This component renders a Vocable "inline" inside the Slate.js editor, showing the attached timestamp.
 **/

import * as React from "react";
import classnames from 'classnames';
import styles from './Vocable.cssm';
import {LineGroupContext, useTimeMatch} from "../../ui/CaptionEditor/ui/LineGroup";
import {useCallback, useContext} from "react";
import {isEmpty} from "../../utils";
import {useSelected} from "slate-react";


type Props = {
  children: any,
  attributes: any,
  time?: number,
  duration?: number,
  vocableId: string,

  onClick?: any
};


const timeFormat = Intl.NumberFormat('en-US', { maximumFractionDigits: 2 });


export const Vocable = (props: Props) => {
  const lineGroupTime = useContext(LineGroupContext);
  const realTime = (!isEmpty(lineGroupTime) && !(isEmpty(props.time))) ? lineGroupTime + props.time : null;

  const matchesTimestamp = useTimeMatch(realTime, props.duration);

  const handleClick = useCallback(() => {
    props.onClick(props.vocableId)
  }, [props.vocableId, props.onClick])

  const isSelected = useSelected();

  const {attributes, children, time} = props;

  return <span
    {...attributes}
    className={classnames({
      [styles.vocable]: true,
      [styles.isFocused]: isSelected,
      [styles.matchesCurrentTime]: matchesTimestamp
    })}
    onDoubleClick={props.onClick ? handleClick : null}
  >
      {children}
    <span
      className={styles.timestamp}
      contentEditable={false}
    >
      {!isEmpty(realTime) ? timeFormat.format(realTime) : null}
    </span>
  </span>;
}