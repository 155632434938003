import * as React from "react";
import {useState} from "react";
import {DictionaryAnnotation} from "../../../annotations";
import {View, Text, TouchableOpacity} from 'react-native';
import {PopupCommonStyles} from "../styles";
import { TextBlock } from "../../Base/Text";


// TODO: Ensure we split by source
export class Dictionary extends React.Component<{
  language: string,
  dictionaries: DictionaryAnnotation[]
}> {

  render() {
    const {dictionaries} = this.props;

    const items = dictionaries.map((dict, idx) => {
      return {
        dictionary: dict,
        children: <DictionaryEntry dictionary={dict} sourceLanguage={this.props.language} />
      }
    });

    return items.map((item, idx) => {
      return <View style={{marginBottom: 12}} key={idx}>
        {item.children}
      </View>
    })
  }
}


const SUPERSCRIPTS: any = {
  '0': '⁰',
  '1': '¹',
  '2': '²',
  '3': '³',
  '4': '⁴',
  '5': '⁵',
  '6': '⁶',
  '7': '⁷',
  '8': '⁸',
  '9': '⁹'
}

export function superScriptNumber(num: number) {
  const numStr = num.toString()
  return numStr.split('').map(function(c) {
    const supc = SUPERSCRIPTS[c]
    if (supc) {
      return supc
    }
    return '';
  }).join('')
}

/**
 * Render a single annotation.
 */
function DictionaryEntry(props: {
  dictionary: DictionaryAnnotation,
  sourceLanguage: string
}) {
  const [isExpanded, setExpanded] = useState(false);

  const dictionary = props.dictionary;

  let content: any;
  let samples: any = [];

  // The entry tells us the target language and is otherwise mono-lingual.
  const targetLang = dictionary.lang || props.sourceLanguage;

  // Because we show multiple definitions of the annotation inline, i.e. we merge them into one block element
  // we kind of have to merge all the samples for all definitions as well - even though strictly speaking we
  // actually know each example belongs to a specific definition.
  dictionary.definitions.forEach(def => {
    if (def.examples && def.examples.length) {
      const renderedExamples = def.examples.map((example, idx) => {
        return <View key={idx} style={{marginTop: 5}}>
          <TextBlock
            // className={getDirectionClass(props.sourceLanguage)}
          >{example.example}</TextBlock>
          <TextBlock
            // className={getDirectionClass(targetLang)}
            style={{color: 'gray'}}>{example.translation}</TextBlock>
        </View>
      });
      samples.push(renderedExamples);
    }
  });

  if (dictionary.definitions.length == 1) {
    const def = dictionary.definitions[0];
    content = <TextBlock style={PopupCommonStyles.defaultText}>{def.text}</TextBlock>;
  } else {
    content = <TextBlock style={PopupCommonStyles.defaultText}>
      {dictionary.definitions.map((def, idx) => {
        return <Text key={idx}>
          <Text style={{color: 'gray', fontStyle: 'italic'}}>{superScriptNumber(idx+1)}</Text>
          {" "}
          <Text>{def.text}</Text>
          {" "} {" "}
        </Text>;
      })}
    </TextBlock>;
  }

  const isExpandable = samples.length > 0;

  return <View>
    <TouchableOpacity style={{flexDirection: 'row'}} onPress={() => setExpanded(!isExpanded)}>
      <TextBlock style={{flex: 1}}>
        <Text style={{color: 'gray', fontSize: 16, textTransform: 'lowercase'}}>{dictionary.wordType}</Text>
        {" "} {" "}
        {content}
      </TextBlock>

      {isExpandable
        ? <View>
          <TextBlock style={{color: '#607D8B', fontSize: 12}}>
            {isExpanded ? "⬆" : "⬇"}
          </TextBlock>
        </View>
        : null
      }
    </TouchableOpacity>

    {(samples.length && isExpanded)
      ? <View style={{
          borderLeftWidth: 1,
          borderLeftColor: "#c7c7c7",
          paddingLeft: 10,
          marginLeft: 10
      }}>
        {samples}
      </View>
      : null}
  </View>
}