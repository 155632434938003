import {Tab, Tabs, Typography} from "@material-ui/core";
import React from "react";
import { withStyles } from '@material-ui/core';


export const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none',
  }
}))(Tabs)


export const MyTab = withStyles(theme => ({
  selected: {
    color: 'tomato',
    borderBottom: '2px solid tomato'
  }
}))(Tab);


export function TabContainer(props: any) {
  return (
    <Typography component="div" style={{ padding: '0 24px', flex: 1 }}>
      {props.children}
    </Typography>
  );
}