import {useState, useEffect, useRef} from 'react';
import Bacon from 'baconjs';


export const useBaconObservable = <T, K>(observable$: Bacon.Observable<any, T>|null, initialValue: K): T|K => {
  const [value, update] = useState<T|K>(initialValue);

  useEffect(() => {
    if (!observable$) {
      return;
    }
    return observable$.onValue(update)
  }, [observable$]);

  return value;
}


export function isEmpty(time: number|null|undefined): time is Exclude<typeof time, number> {
  return time === null || time === undefined;
}


const OBJECT_ID_MAP = new Map();
let OBJECT_ID_COUNTER = 1;

/**
 * Can be useful for debugging if you want to log the "identity" of an object to easily see
 * in the log if it changes. Returns a unique id.
 */
export function getObjectId(obj: any) {
  if (OBJECT_ID_MAP.has(obj)) {
    return OBJECT_ID_MAP.get(obj);
  }
  else {
    const id = OBJECT_ID_COUNTER++;
    OBJECT_ID_MAP.set(obj, id);
    return id;
  }
}


/**
 * A wrapper around the useRef() hook which will make the given value available on the instance,
 * via `.current`.
 */
export function useAsInstanceVar<T>(value: T) {
  const ref = useRef(value);
  // TODO: Is this ok, or should we use useEffect()?
  ref.current = value;
  return ref;
}


export function forceNative(x: any): any {
  if (x && x.toJS) {
    return x.toJS();
  }
  return x;
}


// https://stackoverflow.com/questions/10179815/get-loop-counter-index-using-for-of-syntax-in-javascript
export function* enumerate<T>(iterable: Iterable<T>): Iterable<[number, T]> {
  let i = 0;

  for (const x of iterable) {
    yield [i, x];
    i++;
  }
}