// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-MainScreen-RightAlignedButtons__tooltip--LlQ_5{background-color:#f5f5f9   !important;color:rgba(0,0,0,0.87) !important}._-languagetool-editor-src-js-ui-MainScreen-RightAlignedButtons__HelpContent--3qZgF{padding:10px;font-size:14px}._-languagetool-editor-src-js-ui-MainScreen-RightAlignedButtons__HelpContent--3qZgF h4:first-child{margin-top:0}\n", ""]);
// Exports
exports.locals = {
	"tooltip": "_-languagetool-editor-src-js-ui-MainScreen-RightAlignedButtons__tooltip--LlQ_5",
	"HelpContent": "_-languagetool-editor-src-js-ui-MainScreen-RightAlignedButtons__HelpContent--3qZgF"
};
module.exports = exports;
