import {LoadableFontSpecs, useFont} from "./font";
import {createPathsFromHarfBuzz, runShaper, useHarfbuzz} from "./harfbuzz-shaper";
import {useMemo} from "react";
import {Path, BoundingBox as OTBoundingBox} from "opentype.js";
import React from "react";


// Like the one from opentype.js, but without the exdra methods
export type BoundingBox = {
  x1: number,
  x2: number,
  y1: number,
  y2: number
}

/**
 * Given a font, text and size, will return rendered opentype.js vector paths.
 */
export function useRender(props: {
  font: LoadableFontSpecs,
  text: string,
  fontSize?: number,
}) {
  const [font] = useFont(props.font);
  const [hb, {error: hbError}] = useHarfbuzz();
  const fontSize = props.fontSize || 100;

  React.useEffect(() => {
    if (hbError) {
      console.log(hbError);
    }
  }, [hbError])


  const instructions = useMemo(() => {
    if (!font || !hb) {
      return null;
    }
    return runShaper(hb, font, props.text, {size: fontSize});
  }, [font, props.text, hb, fontSize]);

  // This should ideally match "text", but sometimes harfbuzz puts to characters together.
  // Sometimes the font does not contain a unicode codepoint for a glyph. Investigate further.
  const chars = useMemo(() => {
    if (!instructions || !font) {
      return null;
    }
    return instructions.map(instruction => font.font.glyphs.get(instruction.g).unicode).map(code => code ? String.fromCodePoint(code) : undefined);
  }, [instructions, font]);

  // Calculate the line height, see: https://github.com/opentypejs/opentype.js/issues/277#issuecomment-295255305
  const lineHeight = useMemo(() => {
    if (!font) { return 0; }
    const fontScale = 1 / font.font.unitsPerEm * fontSize;
    const topY = -(font.font.ascender * fontScale);
    const bottomY = -(font.font.descender * fontScale);
    return {
      top: Math.abs(topY),
      height: Math.abs(topY) + bottomY
    };
  }, [font]);

  const paths = useMemo(() => {
    if (!instructions || !font || !lineHeight) {
      return null;
    }
    return createPathsFromHarfBuzz(font.font, instructions, {size: fontSize, ascender: lineHeight.top});
  }, [instructions, font, fontSize]);

  const fullBoundingBox = useMemo<BoundingBox|null>(() => {
    if (!paths || !lineHeight) { return null; }
    return {
      ...calculateBoundingBox(paths.map(p => p.getBoundingBox())),
      y1: 0,
      y2: lineHeight.height
    }
  }, [paths, lineHeight]);
  return {
    boundingBox: fullBoundingBox,
    paths: paths,
    chars: chars
  }
}


function calculateBoundingBox(paths: OTBoundingBox[]): OTBoundingBox {
  const path = new Path();
  for (const p of paths) {
    path.extend(p);
  }
  return path.getBoundingBox();
}
