import * as React from "react";
import {isEqual, groupBy} from 'lodash';
import {Button, Checkbox, List, ListItem, ListItemText, ListSubheader} from "@material-ui/core";
import {
  isForeignWordNode,
  isNumeralNode,
  isWordNode,
  StructureNode,
  WordNode
} from "languagetool-player/src/models/Node";
import {getWordTypeName, WordNodeOption} from "./WordNodeOption";
import {isQueryByWordId, WordId} from "languagetool-player/src/annotations";
import {
  AnalyzeResult,
  isForeignWordNodeResult,
  isNumeralNodeResult,
  isWordNodeResult
} from "../wordsApi";
import {getCanonicalFromwWordId} from "languagetool-player/src/ui/WordPopup/parts/WordDisplay";
import { Fragment } from "react";
import {useQuery} from "multi-apollo";
import {gql as gqlw} from "@apollo/client";
import {NumeralNodeOption} from "./NumeralNodeOption";
import {ForeignWordNodeOption} from "./ForeignWordNodeOption";
import {useDialogs} from "../../../ui/dialogs/DialogManager";
import {CustomWordDialogResult, CustomWordNodeDialog} from "./CustomWordNodeDialog";
import {FarsiVerb, FarsiWordNode} from "languagetool-player/src/langpacks/fa";
import {OptionPickerWordDataQuery} from "../../../../types/OptionPickerWordDataQuery";
import {Vocable, VocableId} from "languagetool-player/src/models/formats/CaptionTrack";


type Props = {
  // The set of vocables for which we want to pick a word node to assign.
  vocables: Vocable[],
  // These are suggestions by the server for what nodes match the vocable text here.
  suggestedNodes?: AnalyzeResult[],
  // This is the current word node assigned to the vocable(s) in question.
  currentNode: StructureNode|null,

  language: string,
  vocableText: string,
  extraButtons?: any,
  showJSON?: boolean,

  onCreateWordClick: (word: string, vocableIds: VocableId[]) => void,
  onEditWordClick: (wordId: number, vocableText: string) => void,
  onOptionSelected: (vocableIds: VocableId[], option: AnalyzeResult|null) => void,
  onAssignCustomNode: (vocableIds: VocableId[], node: WordNode) => void
}


function isSameWordId(a: WordId, b: WordId) {
  if (isQueryByWordId(a) && isQueryByWordId(b)) {
    return a.id === b.id;
  }
  return isEqual(a, b);
}



/**
 * Shows a list of word node options suggested by the server.
 *
 * Let the user pick one of the given word nodes by checking it.
 */
export const NodeOptionPicker = React.memo((props: Props) => {
  const {currentNode} = props;
  let {suggestedNodes} = props;

  const dialogs = useDialogs();

  const showCustomNodeDialog = React.useCallback(() => {
    dialogs.show(CustomWordNodeDialog, {
      subtitleText: props.vocableText,
      onOk: (data: CustomWordDialogResult) => {
        props.onAssignCustomNode(props.vocables.map(v => v.id), {
          id: '',
          vocables: props.vocables.map(v => v.id),
          type: 'word',
          wordId: {id: data.wordId},
          isManual: {
            diacritization: data.diacritization
          }
        })
      }
    });
  }, [props.vocableText, props.onAssignCustomNode, props.vocables])

  // We started to move those behind the "create word" dialog
  const hideWordListNodes = true;
  if (hideWordListNodes && suggestedNodes) {
    suggestedNodes = suggestedNodes.filter(result => !isWordNodeResult(result) || isQueryByWordId(result.node.wordId));
  }

  type InlineNode = {node: StructureNode, unlinkedNodeResult?: boolean};

  // This is a bit complicated, but we really have to do this, and here.
  // We have to merge the node suggestions from the server with the nodes attached
  // in the unit itself, such that if the attached node is one of the server nodes,
  // we collapse them, but if the attached node is no longer returned from the server,
  // we still show it separately.
  let selectedResult: InlineNode|undefined|null = (currentNode && suggestedNodes) ? suggestedNodes.find(result => {
    if (isNumeralNodeResult(result)) {
      if (!isNumeralNode(currentNode)) {
        return false;
      }
      const {node: newNode} = result;
      return (
        newNode.quantity === currentNode.quantity &&
        newNode.isOrdinal === currentNode.isOrdinal &&
        newNode.type === currentNode.type
      )
    }

    if (isForeignWordNodeResult(result)) {
      return true;
    }

    if (!isWordNode(currentNode)) {
      return false;
    }

    // Always keep the manual node.
    if (result.node.isManual) {
      return true;
    }

    const node = result.node;
    // noinspection RedundantIfStatementJS
    if (
      isSameWordId(node.wordId, currentNode.wordId) &&
      isEqual(node.inflection, currentNode.inflection) &&
      isEqual(node.structure, currentNode.structure)
    ) {
      return true;
    }
    return false;
  }) : null;

  const allResults: AnalyzeResult[] = [...(suggestedNodes || [])];

  // If there is a node assigned, and we could not match that node to a server result, then insert that node
  // into the server result set, and mark it has "no longer matching".
  if (!selectedResult && currentNode) {
    let result = {
      node: currentNode,
      unlinkedNodeResult: (isWordNode(currentNode) && currentNode.isManual) ? false : true
    };
    // @ts-ignore: InlineResult and AnlayzeResult are just not compatible
    allResults.push(result);
    selectedResult = result;
  };

  if (!allResults || !allResults.length) {
    // This basically means that either our logic to "deduce" the base form of a word is a problem, because
    // it could not deal with this (and needs fixing), or, more likely, this is a word which is not in our
    // database; as our algorithm relies on a word database to gain confidence which of many possible
    // combinations of morphenes is the right one.
    // For editing purposes, it only makes sense to assume a missing word, so we provide the user with the
    // ability to add one.
    return <div>
      <div><em>This word is not known.</em></div>

      <Button
        variant="contained"
        color="primary"
        size={"small"}
        // So we pass along any suggested nodes from the server here
        onClick={() => props.onCreateWordClick(props.vocableText, props.vocables.map(v => v.id))}
      >
        Create this word
      </Button>

      <Button
        variant="contained"
        size={"small"}
        onClick={() => showCustomNodeDialog()}
      >
        Custom Node
      </Button>

      {props.extraButtons}
    </div>
  }

  // For clarity, we want to group the results by word id
  const groupedByWord = Object.values(groupBy(allResults, 'node.wordId.id'));
  const hasMultipleGroups = groupedByWord.length > 1;

  return <List disablePadding>
    {groupedByWord.map((results, idx) => {
      const isFirst = idx == 0;
      const firstResult = results[0];
      const wordId = isWordNode(firstResult.node) ? firstResult.node.wordId : undefined;

      const editButton = isWordNodeResult(firstResult) && (isQueryByWordId(wordId) && (!firstResult.wordData?.isReviewed))
        ? <Button
            key={'editButton'}
            size={"small"}
            style={{
              marginLeft: '30px'
            }}
            onClick={(e) => {
              e.stopPropagation();
              props.onEditWordClick(wordId.id, props.vocableText);
            }}
          >
            Edit word
          </Button>
        :
          null;

      return <Fragment key={idx}>

        {isFirst
          ?
            <div style={{
              position: 'absolute',
              zIndex: 50,
              right: 0
            }}>
              <Button
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  showCustomNodeDialog();
                }}
              >
                Custom
              </Button>

              <Button
                size={"small"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onCreateWordClick(props.vocableText, props.vocables.map(v => v.id))
                }}
              >
                Create New Word
              </Button>

              {!hasMultipleGroups ? [editButton] : null}
              {props.extraButtons}
            </div>
          : null}

        {hasMultipleGroups
          ? <OptionGroupHeader
              result={results[0]}
              editButton={editButton}
            />
          : null}

        {(isWordNodeResult(results[0]) && results[0].wordData?.editorConfig?.warning && results.indexOf(selectedResult as any) > -1) ? <div  style={{
          marginLeft: '17px',
          marginBottom: '15px',
          maxWidth: '500px',
          backgroundColor: '#ffcdd2',
          padding: '10px'
        }}>
          <strong><span style={{color: '#f44336'}}>IMPORTANT. Please double check this entry.</span> The entry you selected
            has additional instructions:</strong>
          <div style={{marginTop: '10px'}}>
            {results[0].wordData?.editorConfig?.warning}
          </div>
        </div> : null}

        {
          results.map((result, jdx) => {
            const isSelected = result === selectedResult;
            return <SingleItem
              hasIndent={hasMultipleGroups}
              result={result}
              key={`${idx}-${jdx}`}
              isSelected={isSelected}
              showJSON={props.showJSON}
              {...props}
            />
          })
        }
      </Fragment>
    })}
  </List>
});


export type WordDataQueryResult = {
  base?: string,
  description: string,
  isReviewed: boolean,
  sounds: string[]
};

function useQueryWordData(wordId: number|undefined): WordDataQueryResult|null {
  const {data, error, loading, refetch} = useQuery<OptionPickerWordDataQuery>(gqlw`
    query OptionPickerWordDataQuery($wordId: Int!) {
      word(id: $wordId) {
        base,
        description,
        isReviewed,
        baseTransliteration
      }
    }
  `, {
    client: 'words',
    skip: !wordId,
    variables: {
      wordId
    }
  });

  return data?.word ? {
    base: data.word.base || "",
    description: data.word.description || "",
    isReviewed: data.word.isReviewed || false,
    sounds: JSON.parse(data.word.baseTransliteration || "[]")
  } : null;
}


function useWordData(result: AnalyzeResult): WordDataQueryResult|undefined {
  // Mostly, further information about the word is attached to the server result
  const wordDataFromNode = isWordNodeResult(result) ? result?.wordData : null;

  // Sometimes the data comes from storage, and we do not have the data
  const wordDataFromQuery = useQueryWordData(
    (isWordNodeResult(result) && !wordDataFromNode)
      ? result.node.wordId.id
      : undefined
  );

  return wordDataFromQuery || wordDataFromNode || undefined;
}


/**
 * Multiple Grammar Results are grouped by word id, this is the header describing the word in the group.
 */
function OptionGroupHeader(props: {result: AnalyzeResult, editButton?: any}) {
  const {result} = props;

  const wordData = useWordData(result);

  let content;
  if (isWordNodeResult(result)) {
    const isReviewed = wordData?.isReviewed;
    const {node} = result;

    let title;
    title = <>
      <span style={{
        textTransform: 'uppercase',
        fontSize: '.8em'
      }}>
        {node.inflection ? getWordTypeName((node.inflection as FarsiVerb).type) : "unknown"}
      </span>: {(wordData as WordDataQueryResult)?.base || getCanonicalFromwWordId(node.wordId)}
    </>;

    content = <>
      {title}
      {
        (wordData?.description)
          ? <em style={{}}> - {wordData.description}</em>
          : null
      }

      {isReviewed ? <CheckIcon style={{paddingLeft: '5px'}} /> : null}
    </>
  }
  else {
    content = "Number"
  }

  return <ListSubheader>
    <span style={{
      color:  'black',
      fontWeight: 'bold',
      borderBottom: "1px solid black"
    }}>
      {content}
    </span>
    {props.editButton}
  </ListSubheader>
}


export function SingleItem(props: {
  result: AnalyzeResult,
  isSelected: boolean,

  onOptionSelected: (vocableIds: VocableId[], option: AnalyzeResult|null) => void,
  // It might be better to keep this further up.
  language: string,
  vocables: Vocable[],

  hasIndent?: boolean,
  showJSON?: boolean
}) {
  const {isSelected, result} = props;

  const wordData = useWordData(result);

  const wordId = isWordNode(result.node) ? result.node.wordId : undefined;

  let style = {
    color: (isWordNode(result.node) && !isQueryByWordId(wordId)) ? '#616060' : undefined,
  };

  let display;
  if (isWordNode(result.node)) {
    display = <WordNodeOption
      wordNode={result.node as FarsiWordNode}
      wordData={wordData}
      language={props.language}
      showReviewedMark={!props.hasIndent}
    />
  }
  else if (isForeignWordNode(result.node)) {
    display = <ForeignWordNodeOption
      node={result.node}
    />;
  }
  else if (isNumeralNode(result.node)) {
    display = <NumeralNodeOption
      node={result.node}
    />;
  }
  else {
    display = <div>Not supported</div>
  }

  return <ListItem
    dense
    button
    data-wordid={wordId ? wordId.id : null}
    onClick={() => {
      if (isSelected) {
        props.onOptionSelected(props.vocables.map(v => v.id), null);
      } else {
        props.onOptionSelected(props.vocables.map(v => v.id), result);
      }
    }}
    style={{
      backgroundColor: result.unlinkedNodeResult ? '#ffcdd2' : undefined,
      marginLeft: props.hasIndent ? '30px' : undefined,
      // Without this, marginLeft sometimes causes a scrollbar to appear because the default is width: 100%. Annoyingly,
      // not always, and not sure if there is a better fix.
      width: 'auto'
    }}
  >
    <Checkbox
      checked={isSelected}
      tabIndex={-1}
      disableRipple
      style={{padding: 0}}
    />
    <ListItemText
      disableTypography={true}
      style={style}
      primary={
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}>
          <div style={{flex: 1}}>
            {display}
            {props.showJSON ? <pre style={{userSelect: 'text'}} onMouseDown={(e) => { e.stopPropagation(); }} onClick={(e) => { e.stopPropagation(); }}>
              {JSON.stringify(result.node, null, 4)}</pre> : null}
          </div>
        </div>
      }
    />
  </ListItem>
}


export const  CheckIcon = (props: any) => (
  <svg width={"1em"} height={"1em"} viewBox="0 0 512 512" {...props}>
    <path
      fill="#32BEA6"
      d="M504.1 256C504.1 119 393 7.9 256 7.9S7.9 119 7.9 256 119 504.1 256 504.1 504.1 393 504.1 256z"
    />
    <path
      fill="#FFF"
      d="M392.6 172.9c-5.8-15.1-17.7-12.7-30.6-10.1-7.7 1.6-42 11.6-96.1 68.8-22.5 23.7-37.3 42.6-47.1 57-6-7.3-12.8-15.2-20-22.3-22.1-22.1-46.8-37.3-47.8-37.9-10.3-6.3-23.8-3.1-30.2 7.3-6.3 10.3-3.1 23.8 7.2 30.2.2.1 21.4 13.2 39.6 31.5 18.6 18.6 35.5 43.8 35.7 44.1 4.1 6.2 11 9.8 18.3 9.8 1.2 0 2.5-.1 3.8-.3 8.6-1.5 15.4-7.9 17.5-16.3.1-.2 8.8-24.3 54.7-72.7 37-39.1 61.7-51.5 70.3-54.9h.3s.3-.1.8-.4c1.5-.6 2.3-.8 2.3-.8-.4.1-.6.1-.6.1v-.1c4-1.7 11.4-4.9 11.5-5 11.1-4.8 14.8-16.8 10.4-28z"
    />
  </svg>
)