import {Page} from "../Page";
import {Vocable} from "../../../models";
import {WordId} from "../../../annotations";
import {WordNode} from "../../../models/Node";
import {WordLinkHandler} from "./WordLinkHandler";
import { useState, useEffect } from "react";
import React from "react";

export type OnPageOpenedEvent = (page: Page, data?: { vocable?: Vocable }) => void;
/**
 * This holds the history of pages and makes browsing with <WordLink>s work.
 */
type WordBrowserHistoryProps = {
  rootPage: Page,
  rootVocable?: Vocable,
  children: (currentPage: Page, allPages: Page[], currentPageIndex: number) => any,

  // onPageChange event
  onPageOpened?: OnPageOpenedEvent
};

export function WordBrowserHistory(props: WordBrowserHistoryProps) {
  const [pages, setPages] = useState([props.rootPage]);
  const [currentIndexFromRight, setCurrentIndexFromRight] = useState(0);

  useEffect(() => {
    setPages([props.rootPage]);
    setCurrentIndexFromRight(0)
  }, [props.rootPage])

  let indexFromLeft = pages.length - 1 - currentIndexFromRight;
  let currentPage: Page = pages[indexFromLeft];

  useEffect(() => {
    if (props.onPageOpened) {
      props.onPageOpened(currentPage, {vocable: props.rootVocable});
    }
  }, [currentPage]);

  const handleWordClicked = (page: WordId | WordNode) => {
    const newPages = [...pages];
    if (currentIndexFromRight === 0) {
      newPages.push(page);
    } else {
      newPages.splice(
        pages.length - currentIndexFromRight,
        99,
        page
      )
    }
    setPages(newPages);
    setCurrentIndexFromRight(0)
  }

  const handleGoToIndex = (idxFromLeft: number) => {
    setCurrentIndexFromRight(pages.length - idxFromLeft)
  }

  return <WordLinkHandler
    onWordClicked={handleWordClicked}
    onGoBackToIndex={handleGoToIndex}
  >
    {props.children(currentPage, pages, indexFromLeft)}
  </WordLinkHandler>
}