import React, {PureComponent} from "react";
import {
  ICaptionOps,
  moveLine,
  newEditorOps,
  newValueOps,
  setLineBeginning,
  setLineEnd,
} from "../../models/Captions";
import * as PIXI from 'pixi.js'
import {Container, Text, withPixiApp} from '@inlet/react-pixi'
import {string2hex} from "./utils";
import {TextStyle} from 'pixi.js';
import {Scale} from "./coordinates";
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import {Rectangle} from "./Reactangle";
import {CaptionTrackRoot} from "../../models/Captions/types";
import {isEmpty} from "../../utils";
import {getText} from "../../models/Captions/slateApi";


type Props = {
  offsetSeconds: number,
  maxSeconds: number,
  captions: CaptionTrackRoot,
  scale: Scale,
  captionsEditor: ICaptionsEditor,
  onLineSelect: (lineIdx: number) => void,
  selectedLine: number
};


type Move = {
  mode: 'end'|'start'|'move',
  seconds: number
};


export class CaptionOverlay extends PureComponent<Props> {
  render() {
    const { offsetSeconds, maxSeconds } = this.props;

    let items: React.ReactNode[] = [];

    const visibleLines = Array.from(getVisibleLinesAndWords(this.props.captions, offsetSeconds, maxSeconds));
    visibleLines.forEach((line, idx) => {
      const x = this.props.scale.secondsToPixel(line.time - offsetSeconds)
      let width;
      if (line.duration) {
        width = this.props.scale.secondsToPixel(line.duration);
      }
      else {
        width = this.props.scale.secondsToPixel(.5);
      }

      const text = getText(line!.line);
      items.push(<Vocable
        text={text}
        x={x}
        width={width}
        // We we still had a real key for the line, I think we could be more performant...
        key={line.index}
        itemId={line.index}
        onMove={this.handleMove}
        onMoveDone={this.handleMoveDone}
        onSelect={this.props.onLineSelect}
        isSelected={this.props.selectedLine === line.index}
      />);
    })

    // visibleWords.forEach((word, idx) => {
    //   const time = word!.data.get('time');
    //   const duration = word!.data.get('duration');
    //
    //   const x = this.props.scale.secondsToPixel(time - offsetSeconds)
    //   let width;
    //   if (duration) {
    //     width = this.props.scale.secondsToPixel(duration);
    //   }
    //   else {
    //     width = this.props.scale.secondsToPixel(.5);
    //   }
    //
    //   const v = <Vocable
    //     text={word!.text}
    //     x={x}
    //     width={width}
    //     key={word!.key}
    //     wordId={word!.key}
    //     onMove={this.handleMove}
    //   />;
    //   items.push(v);
    // })

    return items;
  }

  lastMove: Move|null = null;

  handleMove = (lineIdx: any, pixelPos: number, mode: 'end'|'start'|'move') => {
    const posAsSeconds = this.props.scale.pixelsToTime(pixelPos);
    const newSeconds = parseFloat((this.props.offsetSeconds + posAsSeconds).toFixed(2));
    this.lastMove = {
      seconds: newSeconds,
      mode: mode
    };

    const ops = newValueOps(this.props.captionsEditor.committedValue);
    CaptionOverlay.applyMove(this.lastMove, ops, lineIdx);
    this.props.captionsEditor.setShadowValue(ops.value);
  }

  handleMoveDone = async (lineIdx: any) => {
    const lastMove = this.lastMove;
    if (lastMove === null) {
      return;
    }

    // We now want to clear the shadow value, and apply the desired operations,
    // with undo handling, directly to the editor. Because we have to rely on
    // the Slate.js UI here to do the undo, this is hacky. We have to make sure
    // that `props.captionsEditor`, that is, the Slate.js control, has been updated
    // with the last "committed value", after we set the shadow value to null.
    this.props.captionsEditor.setShadowValue(null);
    // XXX I am convinced we no longer need this, as all Slate.js operations no run without
    // involving the DOM. But keep it for a while still, until we can delete it.
    //await hack_waitForSlateUIControlHavingShadowValueUnapplied(this.props.captionsEditor);
    const ops = newEditorOps(this.props.captionsEditor);
    CaptionOverlay.applyMove(lastMove, ops, lineIdx);
  }

  private static applyMove(move: Move, ops: ICaptionOps, idx: number) {
    if (move.mode === 'end') {
      setLineEnd(ops, idx, move.seconds);
    }
    else if (move.mode === 'start') {
      setLineBeginning(ops, idx, move.seconds);
    }
    else {
      moveLine(ops, idx, move.seconds);
    }
  }
}


// See call site for documentation. The real solution is to have the ability to do undos without
// needing to involve the UI at all, so we can just operate on a data structure. Maybe use
// the raw-controller, with our plugins?
async function hack_waitForSlateUIControlHavingShadowValueUnapplied(editor: ICaptionsEditor) {
  const isDone = () => (editor.committedValue === editor.slateEditor.children);

  const startTime = new Date().getTime();

  return new Promise((resolve, reject) => {
    function checkAndRepeat() {
      if (isDone()) {
        resolve();
        return;
      }

      if ((new Date().getTime() - startTime) > 500) {
        reject('Syncing the Slate.js editor with the committed value should not take this long')
      }

      // We have to make this 0, as little as possible, as otherwise we might see in the Slate.js UI
      // a flicker - a jump form the current shadow value, to the last committed value, until we
      // commit the changes, to match the last shadow state.
      window.setTimeout(() => { checkAndRepeat(); }, 0);
    }

    checkAndRepeat();
  });
}


const textStyle = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12,
  fill: '#222222'
});


const textStyleSelected = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12,
  fill: '#FFFFFF'
});


type VocableProps = {
  text: string,
  x: number,
  width: number,
  itemId: any,
  onMove: (wordId: any, time: number, mode: 'start'|'end'|'move') => void,
  onMoveDone: (wordId: any) => void,
  onSelect?: (wordId: any) => void,
  onClick?: (wordId: any) => void,
  isSelected: boolean,
  app: PIXI.Application
}

export const Vocable = withPixiApp(
  // @ts-ignore
  class Vocable extends React.PureComponent<VocableProps> {

  state = {
    textToRender: ''
  };

  componentDidMount() {
    this.measure();

    this.props.app.renderer.plugins.interaction.cursorStyles['resizeLeft'] = 'col-resize'; // e-resize
    this.props.app.renderer.plugins.interaction.cursorStyles['resizeRight'] = 'col-resize'; // e-resize
  }

  componentDidUpdate(prevProps: VocableProps) {
    if (
      (prevProps.width !== this.props.width) ||
      (prevProps.text !== this.props.text)
    ) {
      this.measure();
    }
  }

  measure() {
    // See also: https://github.com/pixijs/pixi.js/issues/3449#issuecomment-428648004
    const measureStyle = textStyle.clone();
    measureStyle.wordWrap = true;
    measureStyle.wordWrapWidth = this.props.width;

    const { lines, lineWidths } = PIXI.TextMetrics.measureText(this.props.text, measureStyle);
    if (lineWidths[0] > this.props.width) {
      this.setState({textToRender: ""});
      return;
    }
    this.setState({textToRender: lines[0]})
  }

  render() {
    const bg = <Rectangle
      fill={string2hex(this.props.isSelected ? '#2196f3' : '#fbfcfd')}
      borderColor={string2hex(this.props.isSelected ? '#fbfcfd' : '#2196f3')}
      x={0}
      y={0}
      width={this.props.width}
      height={20}
    />

    const text = <Text
      x={2}
      y={2}
      text={this.state.textToRender}
      style={this.props.isSelected ? textStyleSelected : textStyle}
    />;

    // Note: Do not set the width/height here. For Pixi, this is just another way of setting "scale"
    // on the container, and sometimes there seems to be a mismatch/race condition between the container
    // size update and the child size update, leading to a scale other than 1, rendering things
    // incorrectly.
    return <Container
      x={this.props.x}
      y={30}

      interactive={true}
      pointerdown={this.handlePointerDown}
      pointermove={this.handlePointerMove}
      pointerover={this.handlePointerOver}
      pointerout={this.handlePointerOut}
      pointerup={this.handlePointerUp}
      pointerupoutside={this.handlePointerUpOutside}
    >
      {bg}
      {text}
    </Container>
  }

  private isDown: boolean = false;
  private isOver: boolean = false;
  private resizeMode: 'left'|'right'|null = null;
  private isDragging: boolean = false;
  private dragStartXInside: number = 0;
  private dragEndXInside: number = 0;

  determineModeFromPointerEvent(e: any) {
    const pos = e.data.getLocalPosition(e.currentTarget);
    const fromLeft = pos.x;
    const fromRight = this.props.width - pos.x;

    if (fromLeft < 10) {
      return 'left';
    }
    else if (fromRight < 10) {
      return 'right';
    }
    else {
      return null;
    }
  }

  handlePointerDown = (e: any) => {
    this.isDown = true;

    // Remember the resize mode at "pointer down" time, so it remains stable even if the mouse
    // moves outside of the area of where we consider a resize to take place.
    this.resizeMode = this.determineModeFromPointerEvent(e);

    // This is important to remember, because it is the base of the pointer movements.
    this.dragStartXInside = e.data.getLocalPosition(e.currentTarget).x;
    this.dragEndXInside = this.props.width - this.dragStartXInside;

    if (this.props.onSelect) {
      this.props.onSelect(this.props.itemId);
    }
  }

  handlePointerMove = (e: any) => {
    // Is it on top of us? Change the cursor - but only if not dragging. Once we are dragging,
    // we already have our desired mode, and moving temporarily outside of that region during the
    // drag should not change the cursor anymore.
    if (this.isOver && !this.isDragging) {
      const mode = this.determineModeFromPointerEvent(e);

      if (mode === 'left') {
        e.currentTarget.cursor = 'resizeLeft';
      }
      else if (mode === 'right') {
        e.currentTarget.cursor = 'resizeRight';
      }
      else {
        e.currentTarget.cursor = null;
      }
    }


    if (this.isDown) {
      this.isDragging = true;
      const mouseXOutside = e.data.getLocalPosition(e.currentTarget.parent).x;

      if (this.resizeMode === 'left') {
        this.props.onMove(this.props.itemId, mouseXOutside - this.dragStartXInside, 'start');
      }
      else if (this.resizeMode === 'right') {
        this.props.onMove(this.props.itemId, mouseXOutside + this.dragEndXInside, 'end');
      }
      else {
        this.props.onMove(this.props.itemId, mouseXOutside - this.dragStartXInside, 'move');
      }
    }
  }

  handlePointerOver = () => {
    this.isOver = true;
  }

  handlePointerOut = () => {
    this.isOver = false;
  }

  handlePointerUp = (e: any) => {
    if (this.isDown && !this.isDragging) {
      if (this.props.onClick) {
        this.props.onClick(this.props.itemId);
      }
    }

    this.handlePointerUpOutside(e);
  }

  handlePointerUpOutside  = (e: any) => {
    if (this.isDragging) {
      this.props.onMoveDone(this.props.itemId);
    }
    this.isDragging = false;
    this.isDown = false;
  }
})


function* getVisibleLinesAndWords(lines: CaptionTrackRoot, visibleFrom: number, visibleTo: number) {
  for (let i=0; i <= lines.length-1; i++) {
    const line = lines[i];
    const {time, duration} = line.data;
    if (isEmpty(time) || isEmpty(duration)) {
      continue;
    }
    const lineEnd = time + duration;

    const isVisible = (
      (time >= visibleFrom && lineEnd <= visibleTo) ||
      (time <= visibleFrom && lineEnd > visibleFrom) ||
      (time < visibleTo && lineEnd >= visibleTo)
    );

    if (isVisible) {
      yield {
        index: i,
        line: line,
        time: time,
        duration: duration
      }
    }
  }
}