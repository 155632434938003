import * as React from "react";
import DictionaryIcon from "../../Icons/DictionaryIcon";
import {View, Text, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import {GoogleTranslateIcon} from "../../Icons/GoogleTranslateIcon";
import {WiktionaryIcon} from "../../Icons/WiktionaryIcon";
import {LangenscheidtIcon} from "../../Icons/LangenscheidtIcon";
import {GrammarIcon} from "../../Icons/GrammarIcon";
import { useState, useCallback } from "react";
import { TextLink } from "../../Base/TextLink";
import { TextBlock } from "../../Base/Text";


type PanesProps = {
  panes: {
    title: any,
    content: any
  }[],
  onSelect: any,
};


const styles = StyleSheet.create({
  paddingBottom: {
    paddingBottom: 10
  },

  paneTitle: {
    flexDirection: 'row',
    marginBottom: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'silver',
    ...(Platform.OS == 'web' ? {borderBottomStyle: 'dotted'} : {})
  },
  paneTitleText: {
    fontSize: 12,
    color: 'gray',
  }
});


/**
 * Renders as a set of tabs.
 */
export function Panes(props: {
  panes: {
    key: string,
    label?: string,
    url?: string,
    source?: {
      id: string,
      name?: string
    },
    type?: string,
    content: any,
    hideTitle?: boolean
  }[],
  defaultExpandedPanes?: string[]
}) {
  const [expandedPanes, setExpandendPanes] =
    useState<Set<string>>(new Set(props.defaultExpandedPanes ? props.defaultExpandedPanes : undefined));

  const handleClick = useCallback((selectedKey: string) => {
    if (expandedPanes.has(selectedKey)) {
      const newExpandedKeys = new Set([...expandedPanes])
      newExpandedKeys.delete(selectedKey);
      setExpandendPanes(newExpandedKeys);
    } else {
      const newExpandedKeys = new Set([...expandedPanes, selectedKey])
      setExpandendPanes(newExpandedKeys);
    }
  }, [expandedPanes, setExpandendPanes]);
  
  let content = <View>
    {props.panes.map((pane, idx) => {
      const isCollapsed = !expandedPanes.has(pane.key) && !pane.hideTitle;

      let title;
      if (!pane.hideTitle) {
        title = <SourcePaneTitle
          source={pane.source}
          type={pane.type}
          label={pane.label}
          id={pane.key}
          url={pane.url}
          onClick={handleClick}
        />
      }

      const isLast = idx === props.panes.length - 1;
      return <View style={isLast ? null : styles.paddingBottom} key={pane.key}>
        {title}
        {!isCollapsed ? pane.content : null}
      </View>
    })}
  </View>;

  return <View>
    {content}
  </View>
}


/**
 * Renders the icon and name of a source.
 */
class SourcePaneTitle extends React.Component<{
  id: string,
  label?: string,
  url?: string,
  source?: {
    id: string,
    name?: string
  },
  type?: string,
  onClick: (id: string) => void
}> {
  render() {
    const IconClass = (this.props.source && SourcesToIcon[this.props.source.id])
      || TypeToIcon[this.props.type || ""]
      || DictionaryIcon;

    const title = this.props.label
      || (this.props.source &&
        (this.props.source.name || SourcesToLabel[this.props.source.id] || this.props.source.id)
      );
    const titleEl = <View style={{flexDirection: 'row'}}>
      <IconClass />
      <TextBlock style={[styles.paneTitleText, {marginLeft: 3}]}>{title}</TextBlock>
    </View>;

    return <View style={styles.paneTitle}>
      <TouchableOpacity onPress={() => this.props.onClick(this.props.id)} style={{flex: 1}}>
        {titleEl}
      </TouchableOpacity>
      {this.props.url ? <TextLink href={this.props.url} blank><Text style={{fontSize: 10}}>Open</Text></TextLink> : null}
    </View>
  }
}


const SourcesToLabel: {[key: string]: string} = {
  'google-translate': 'Google Translate'
}

const SourcesToIcon: {[key: string]: any} = {
  'google-translate': GoogleTranslateIcon,
  'wiktionary': WiktionaryIcon,
  'langenscheidt': LangenscheidtIcon
}

const TypeToIcon: {[key: string]: any} = {
  'grammar': GrammarIcon,
}