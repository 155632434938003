import {Editor, Transforms, NodeEntry, Element} from 'slate';
import {isWordInline} from "../../models/Captions/types";


/**
 * Ensures the given node only has Vocable elements and texts.
 */
export function removeEmptyVocables(editor: Editor, entry: NodeEntry) {
  const [element, path] = entry;

  if (isWordInline(element)) {
    if (!element.children.length || element.children[0].text == "") {
      console.log('remove empty vocable', editor.selection?.focus)
      Transforms.unwrapNodes(editor, {at: path});
      console.log('post remove empty vocable', editor.selection?.focus)
      return true;
    }
  }
}


/**
 * Slate expects there to be at least an empty text element.
 */
export function ensureEmptyVocableArrayHasTextElement(editor: Editor, entry: NodeEntry) {
  const [node, path] = entry;

  if ((node as Element).children.length === 0) {
    console.log('insert empty text')
    Transforms.insertNodes(editor, {text: ""}, {at: path.concat(0)})
    return true;
  }
}