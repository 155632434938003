import { Linking } from "react-native";

/**
 * Supposed to be something that can be a touchable on native and a normal link in the browser.
 * The children should also be text nodes.
 */
export type LinkTouchableProps = {
  children?: any;
  href: string;
  blank?: boolean,
  dataSet?: any,
  onPress?: any
};

export const isExternal = (url: string) => url.startsWith("http")

export const openLinkNative = (href: string) => {
  Linking.canOpenURL(href).then(supported => {
    if (supported) {
      Linking.openURL(href);
    } else {
      console.log("Don't know how to open URI: " + href);
    }
  });
}