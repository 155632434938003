import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Auth0Provider} from "./utils/Auth0Provider";
import {createWordsAdminClient} from "./ApolloRoot";
import {gql} from '@apollo/client';


const config = {
  "domain": "languagetool.eu.auth0.com",
  "clientId": "7fMZIvMPjiVUTK2OSgVqgBLCOIM04o37"
};


// This must be the one configured in Auth0 as the callback url for login and logout.
function getRootUrl() {
  // Also see https://stackoverflow.com/a/1368295
  const parsedUrl = new URL(window.location.href);
  parsedUrl.search = "";
  parsedUrl.pathname = "";
  return parsedUrl.toString();
}


async function handleLoggedIn(opts: {token: string, user: any}) {
  const client = createWordsAdminClient(async () => `Bearer ${opts.token}`);
  await client.mutate({
    mutation: gql`
      mutation UpdateUserDataAfterLogin($blob: String!) {
        updateUserData(auth0Blob: $blob) {
          user { id, username }
        }
      }
    `,
    variables: {
      blob: opts.user ? JSON.stringify(opts.user) : null
    }
  })

}


ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    getRedirectUri={getRootUrl}
    audience={"https://api.languagetool.xyz"}
    onLoggedIn={handleLoggedIn}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);
