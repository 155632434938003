import {useEffect, useState} from "react";


export async function loadFile(url: string) {
  const font = await fetch(url);
  if (!font.ok) {
    throw new Error(font.statusText)
  }
  return font.arrayBuffer();
}


export function useLoadFile(url: string | null): [ArrayBuffer | null, { loading: boolean, error: any }] {
  const [loadedBuffer, setLoadedBuffer] = useState<null | ArrayBuffer>(null);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!url) {
      setLoadedBuffer(null);
      setError(null);
      return;
    }

    setLoading(true);
    loadFile(url).then(buffer => {
      setLoadedBuffer(buffer);
      setError(null);
    }).catch(e => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    })
  }, [url]);

  return [loadedBuffer, {error, loading}];
}