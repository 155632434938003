import React from 'react'

const ErrorIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
    <path
      d="M87.215 56.71A15.423 15.423 0 0 0 89 49.5c0-6.621-4.159-12.257-10.001-14.478L79 35c0-11.598-9.402-21-21-21-9.784 0-17.981 6.701-20.313 15.757A14.982 14.982 0 0 0 33 29c-7.692 0-14.023 5.793-14.89 13.252C12.906 43.353 9 47.969 9 53.5 9 59.851 14.149 65 20.5 65c.177 0 .352-.012.526-.022-.004.175-.026.346-.026.522C21 76.822 30.178 86 41.5 86c6.437 0 12.175-2.972 15.934-7.614A11.948 11.948 0 0 0 66 82c4.65 0 8.674-2.65 10.666-6.518 1.052.335 2.171.518 3.334.518 6.075 0 11-4.925 11-11 0-3.311-1.47-6.273-3.785-8.29z"
      fill="#c7ede6"
    />
    <path
      d="M65.125 46.5H59a4.5 4.5 0 0 1-4.5-4.5c0-2.333 1.782-4.229 4.055-4.455-.022-.181-.055-.358-.055-.545a4.5 4.5 0 0 1 4.5-4.5c1.438 0 2.703.686 3.527 1.736.14-2.636 2.302-4.736 4.973-4.736a5 5 0 0 1 5 5c0 .446-.077.87-.187 1.282A3.475 3.475 0 0 1 79 34.5a3.489 3.489 0 0 1 3.455 3.055c.181-.022.358-.055.545-.055a4.5 4.5 0 0 1 0 9H68.125v.5h-3v-.5z"
      fill="#fdfcef"
    />
    <path
      d="M71.5 29c3.033 0 5.5 2.467 5.5 5.5v.047A3.933 3.933 0 0 1 79 34a3.998 3.998 0 0 1 3.858 3.005A2.22 2.22 0 0 1 83 37c2.757 0 5 2.243 5 5s-2.243 5-5 5H68.125a.5.5 0 0 1 0-1H83c2.206 0 4-1.794 4-4s-1.794-4-4-4c-.117 0-.23.017-.343.032l-.141.019a.502.502 0 0 1-.557-.433A2.996 2.996 0 0 0 79 35c-.885 0-1.723.401-2.301 1.1a.503.503 0 0 1-.614.126.5.5 0 0 1-.254-.573A4.45 4.45 0 0 0 76 34.5c0-2.481-2.019-4.5-4.5-4.5-2.381 0-4.347 1.872-4.474 4.263a.5.5 0 0 1-.893.282A3.97 3.97 0 0 0 63 33c-2.206 0-4 1.794-4 4 0 .117.017.23.032.343l.019.141a.5.5 0 0 1-.446.558A3.976 3.976 0 0 0 55 42c0 2.206 1.794 4 4 4h6.125a.5.5 0 0 1 0 1H59c-2.757 0-5-2.243-5-5a4.966 4.966 0 0 1 4.003-4.877A5.006 5.006 0 0 1 63 32c1.176 0 2.293.416 3.183 1.164A5.486 5.486 0 0 1 71.5 29z"
      fill="#472b29"
    />
    <path
      d="M73 36c1.403 0 2.609.999 2.913 2.341A2.344 2.344 0 0 1 77.125 38c1.202 0 2.198.897 2.353 2.068a2.63 2.63 0 0 1 .585-.068 2.94 2.94 0 0 1 2.918 2.732.252.252 0 0 1-.232.268h-.018a.25.25 0 0 1-.249-.232 2.44 2.44 0 0 0-2.419-2.268c-.229 0-.47.042-.738.127a.222.222 0 0 1-.067.01.26.26 0 0 1-.258-.262 1.877 1.877 0 0 0-1.875-1.875c-.447 0-.885.168-1.231.473a.252.252 0 0 1-.165.063.231.231 0 0 1-.093-.019.247.247 0 0 1-.155-.212C75.377 37.512 74.288 36.5 73 36.5c-.208 0-.425.034-.682.107a.25.25 0 0 1-.137-.481A2.94 2.94 0 0 1 73 36zM59.117 37.5c1.326 0 2.508.897 2.874 2.182a.25.25 0 1 1-.481.136A2.505 2.505 0 0 0 59.117 38c-.117 0-.23.014-.342.029a.251.251 0 0 1-.281-.214.249.249 0 0 1 .214-.281c.134-.018.27-.034.409-.034z"
      fill="#472b29"
    />
    <path
      d="M26.405 37H16.5a.5.5 0 0 1 0-1h9.905a.5.5 0 0 1 0 1zM29.5 37h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1zM34.491 39H25.5a.5.5 0 0 1 0-1h8.991a.5.5 0 0 1 0 1zM23.5 39h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1zM20.5 39h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM26.5 41h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM29.5 32h-1a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1zM29.5 34h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM34.5 36h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"
      fill="#fff"
    />
    <g>
      <path
        d="M85.5 59h-10a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1zM89.5 59h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM94.5 61h-10a.5.5 0 0 1 0-1h10a.5.5 0 0 1 0 1zM82.5 61h-1a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1zM79.375 61H77.5a.5.5 0 0 1 0-1h1.875a.5.5 0 0 1 0 1zM88.5 57h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zM85.5 63h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1z"
        fill="#fff"
      />
    </g>
    <g>
      <path
        d="M28.955 67.3a1.24 1.24 0 0 1-1.089-.625 1.21 1.21 0 0 1 .001-1.223L48.913 29.14c.226-.389.632-.621 1.087-.621s.861.232 1.087.622l21.045 36.312c.222.383.222.84.001 1.223a1.24 1.24 0 0 1-1.089.625H28.955z"
        fill="#f15b6c"
      />
      <path
        d="M50 29.218c.121 0 .344.035.481.273l21.045 36.312a.504.504 0 0 1 .001.522.534.534 0 0 1-.482.274h-42.09a.536.536 0 0 1-.482-.274.505.505 0 0 1 .001-.522l21.045-36.312a.538.538 0 0 1 .481-.273m0-1.4c-.659 0-1.317.324-1.693.971L27.262 65.101c-.747 1.29.192 2.899 1.693 2.899h42.09c1.501 0 2.44-1.609 1.693-2.899L51.693 28.789A1.939 1.939 0 0 0 50 27.818z"
        fill="#472b29"
      />
    </g>
    <g>
      <path
        d="M66.242 63.591a.254.254 0 0 1-.225.13H33.983a.254.254 0 0 1-.225-.13.272.272 0 0 1-.005-.261L49.77 35.016c.094-.162.367-.162.461 0L66.248 63.33a.269.269 0 0 1-.006.261z"
        fill="#fef6aa"
      />
    </g>
    <g>
      <path
        d="M66.017 63.882H33.983a.752.752 0 0 1-.659-.38.757.757 0 0 1-.017-.733.5.5 0 0 1 .932.113h31.368l-8.9-15.535a.5.5 0 0 1 .869-.497l9.107 15.897a.77.77 0 0 1-.012.766.75.75 0 0 1-.654.369zm-.215-.659h.01-.01zM55.809 45.259a.502.502 0 0 1-.435-.251l-1.427-2.493a.5.5 0 0 1 .869-.497l1.427 2.493a.5.5 0 0 1-.434.748zM35.912 59.722a.499.499 0 0 1-.434-.749l13.857-24.207c.272-.462 1.06-.457 1.326-.005l3.05 5.319a.499.499 0 1 1-.867.497L50 35.619 36.347 59.47a.503.503 0 0 1-.435.252z"
        fill="#472b29"
      />
    </g>
    <g>
      <circle cx={50} cy={58.454} r={1.754} fill="#f15b6c" />
      <path
        d="M50 60.458c-1.105 0-2.004-.899-2.004-2.003s.899-2.003 2.004-2.003c1.104 0 2.003.899 2.003 2.003s-.899 2.003-2.003 2.003zm0-3.507c-.829 0-1.504.674-1.504 1.503s.675 1.503 1.504 1.503 1.503-.674 1.503-1.503-.674-1.503-1.503-1.503z"
        fill="#472b29"
      />
    </g>
    <g>
      <path
        d="M48.835 43.543h2.328c.329 0 .593.247.584.547l-.265 9.671c-.008.29-.267.521-.584.521H49.1c-.317 0-.576-.231-.584-.521l-.265-9.671c-.008-.3.256-.547.584-.547z"
        fill="#f15b6c"
      />
      <path
        d="M50.899 54.532H49.1c-.457 0-.823-.335-.834-.764l-.265-9.671a.757.757 0 0 1 .218-.549.862.862 0 0 1 .616-.255h2.329c.234 0 .458.093.616.255.146.15.224.346.218.549l-.265 9.671c-.011.429-.378.764-.834.764zm-2.064-10.739a.36.36 0 0 0-.258.104.255.255 0 0 0-.077.187l.265 9.671c.004.153.154.277.334.277h1.799c.18 0 .331-.125.334-.277l.265-9.671a.256.256 0 0 0-.077-.187.359.359 0 0 0-.257-.104h-2.328z"
        fill="#472b29"
      />
    </g>
    <g>
      <path
        d="M33.5 73.5H37a3.5 3.5 0 0 0 3.5-3.5 3.489 3.489 0 0 0-3.055-3.455c.028-.179.055-.358.055-.545a3.5 3.5 0 0 0-3.5-3.5c-1.032 0-1.95.455-2.59 1.165a3.999 3.999 0 0 0-7.91.835c0 .191.03.374.056.558A2.473 2.473 0 0 0 22 64.5a2.496 2.496 0 0 0-2.455 2.055A3.495 3.495 0 0 0 19 66.5a3.5 3.5 0 1 0 0 7h7.5v.5h7v-.5z"
        fill="#fdfcef"
      />
      <path
        d="M35.25 69a.25.25 0 0 1-.25-.25 2.22 2.22 0 0 1 2.218-2.218c.034.009.737-.001 1.244.136a.25.25 0 0 1-.13.482c-.444-.12-1.1-.12-1.113-.118A1.72 1.72 0 0 0 35.5 68.75a.25.25 0 0 1-.25.25z"
        fill="#472b29"
      />
      <circle cx={28.5} cy={73.5} r={0.5} fill="#472b29" />
      <path
        d="M37 74h-3.5a.5.5 0 0 1 0-1H37c1.654 0 3-1.346 3-3a2.996 2.996 0 0 0-2.618-2.959.502.502 0 0 1-.43-.573c.023-.154.048-.308.048-.468 0-1.654-1.346-3-3-3-.85 0-1.638.355-2.219 1a.498.498 0 0 1-.86-.231A3.514 3.514 0 0 0 27.5 61c-1.93 0-3.5 1.57-3.5 3.5 0 .143.021.28.041.418.029.203-.063.438-.242.54-.179.102-.396.118-.556-.01A1.97 1.97 0 0 0 22 65c-.966 0-1.792.691-1.963 1.644a.498.498 0 0 1-.569.405C19.314 67.025 19.16 67 19 67c-1.654 0-3 1.346-3 3s1.346 3 3 3h7.5a.5.5 0 0 1 0 1H19c-2.206 0-4-1.794-4-4a4.004 4.004 0 0 1 4.174-3.994A2.993 2.993 0 0 1 22 64c.349 0 .689.061 1.011.18A4.506 4.506 0 0 1 27.5 60a4.523 4.523 0 0 1 4.153 2.774A3.979 3.979 0 0 1 34 62a4.004 4.004 0 0 1 3.996 4.142A3.999 3.999 0 0 1 41 70c0 2.206-1.794 4-4 4z"
        fill="#472b29"
      />
      <path d="M31.5 73h-1a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z" fill="#472b29" />
    </g>
  </svg>
)

export default ErrorIcon;
