/**
 * Sometimes we do not have a word in the database to match the current vocable in the caption
 * track to. And sometimes the word we match to comes from a static word list. In both cases, we
 * want to add it to our word database.
 *
 * This is the dialog to do so.
 */


import {Button, List, ListItem, DialogActions, Dialog, DialogTitle, DialogContent, ListItemText} from "@material-ui/core";
import {DialogProps} from "../../../ui/dialogs/DialogManager";
import React, { useState } from "react";
import {WordForm, WordFormLogic} from "../WordForm/WordFormAndLogic";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {useAPI, WordNodeResult} from "../wordsApi";
import {getCanonicalFromwWordId} from "languagetool-player/src/ui/WordPopup/parts/WordDisplay";
import {WordNodeOption} from "./WordNodeOption";
import {WordDef} from "./index";
import {FarsiWordForms, FarsiWordNode} from "languagetool-player/src/langpacks/fa";


export type InitialFormValues = {
  text?: string,
  type?: string,
  wordData?: {[key: string]: any},
}


export interface BaseAddEditWordDialogProps extends DialogProps {
  onSave: (word?: WordDef) => void,

  // The language of that word.
  language: string,

  // The word being edited - when set, edit  mode, otherwise create mode.
  wordId?: number,

  // A word that the word must match against
  matchText?: string,

  // Whatever values to show in the form when opening.
  initialValues?: InitialFormValues,
};


export function getInitialValuesFromResult(result: WordNodeResult<FarsiWordForms>): InitialFormValues {
  return {
    text: getCanonicalFromwWordId(result.node.wordId),
    type: result.node.inflection?.type,
    wordData: result.extra,
  }
}

export type AddEditWordDialogProps = BaseAddEditWordDialogProps & {
  // Or, instead, a list of nodes from which a default can be picked.
  templateResults?: WordNodeResult[]|null
};


export function AddEditWordDialog(props: AddEditWordDialogProps) {
  const [showForm, setShowForm] = useState(!(props.templateResults && props.templateResults.length));
  const [initialValues, setInitialValues] = useState(props.initialValues);

  const handleTemplateSelect = (result: WordNodeResult<any>|null) => {
    if (result) {
      setInitialValues(getInitialValuesFromResult(result));
    }
    setShowForm(true);
  };

  let content: any;
  // Unless we have initial values set, and if there is a choice of templates, show the template selector first
  if (!showForm) {
    content = <>
      <DialogContent>
        <SelectChoice
          templateResults={props.templateResults!}
          onSelect={handleTemplateSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button key={"cancel"} onClick={props.onRequestClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  }
  else {
    content = <AddEditWordDialogContent
      {...props}
      initialValues={initialValues}
    />
  }

  return <Dialog
    open={props.isOpen}
    onClose={props.onRequestClose}
    disableBackdropClick={true}
  >
    <DialogTitle>Word Definition</DialogTitle>
    {content}
  </Dialog>
}


/**
 * This renders the word dialog content with a form and a particular node pre-selected.
 */
function AddEditWordDialogContent(props: BaseAddEditWordDialogProps) {
  const {initialValues} = props;
  const api = useAPI();

  const content = <WordForm
    lang={props.language}
  />;

  return <WordFormLogic
    api={api}
    onWordCreated={(w: WordDef) => {
      props.onSave(w);
      props.onRequestClose();
    }}
    onWordChanged={() => {
      props.onSave();
      props.onRequestClose();
    }}
    wordId={props.wordId}
    wordText={initialValues ? initialValues.text : undefined}
    wordType={initialValues ? initialValues.type : undefined}
    wordData={initialValues ? initialValues.wordData : undefined}
    lang={props.language}
    matchText={props.matchText}
  >
    {(formik, context) => {
      return <>
        <DialogContent style={{width: '500px'}}>
          {content}
        </DialogContent>
        <DialogActions>
          <Button
            key={"ok"}
            variant={'contained'}
            onClick={() => formik.handleSubmit()}
            color="primary"
          >
            {props.wordId ? "Save" : "Create"}
            {context.transliterated ? <>
              &nbsp;&nbsp;"{romanizeSounds(context.transliterated) || '----'}"
            </> : null}
          </Button>
          <Button key={"cancel"} onClick={props.onRequestClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </>
    }}
  </WordFormLogic>
}


export function SelectChoice(props: {
  templateResults: WordNodeResult[],
  onSelect: (result: WordNodeResult|null) => void
}) {
  return <div>
    <p>Choose from one of these results:</p>
    <List disablePadding>
      {props.templateResults.map((result, idx) => {
        return <ListItem
          dense
          key={`${idx}`}
          button
          onClick={() => props.onSelect(result)}
        >
          <ListItemText>
            <WordNodeOption
              wordNode={result.node as FarsiWordNode}
              language={"fa"}
            />
          </ListItemText>
        </ListItem>
      })}
      <ListItem
        dense
        button
        onClick={() => props.onSelect(null)}
      >
        <ListItemText>No, create by myself.</ListItemText>
      </ListItem>
    </List>
  </div>
}
