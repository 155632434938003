import React, {Fragment, useState, useMemo, useCallback} from "react";
import {Form} from "../../../../../components/Form";
import {getFarsiInfinitive} from "../utils";
import {NestableField, useNestedValues} from "../../../../../components/NestedFormik";
import {CompoundVerbPrimaryField} from "./fields/CompoundVerbPrimaryField";
import {
  SimpleVerbAdvancedFieldNames,
  SimpleVerbAdvancedFields,
  SimpleVerbFields
} from "./fields/SimpleVerbFields";
import {CompoundVerbVectorField} from "./fields/CompoundVerbVectorField";
import {WarningsField} from "./Warnings";
import {
  CreateWord,
  FarsiWordData,
  FarsiWordDataCompoundVerb,
  FarsiWordDataPreposition,
  FarsiWordDataSingleVerb
} from "../../../wordsApi";
import {FormValues, isNestedForm} from "./FormValues";
import {DefaultWordFormLogic, prepareWord, WordFormLogicProps, WordFormProps} from "../../../WordForm/WordFormAndLogic";
import {WordBaseField} from "../../../WordForm/fields/WordBaseField";
import {WordTypeField} from "../../../WordForm/fields/WordTypeField";
import {useUIControls} from "../../../../../components/FormUISwitch";
import {WordDescriptionField} from "../../../WordForm/fields/WordDescriptionField";
import {PluralsField} from "./fields/PluralsField";
import {useFormikContext} from "formik";
import useDeepCompareEffect from 'use-deep-compare-effect';
import {WordNumeralQuantityField} from "../../../WordForm/fields/WordNumeralQuantityField";
import {AliasTypeField} from "../../../WordForm/fields/AliasTypeField";
import {WordAliasReferenceField} from "../../../WordForm/fields/WordAliasReferenceField";
import {SoundsField} from "./fields/SoundsField";
import {PluralEnabledField} from "../../../WordForm/fields/PluralEnabledField";
import {EzafeModeField} from "../../../WordForm/fields/EzafeModeField";
import {StemsField} from "./fields/StemsField";


/**
 * Prepare the data for the `createWord` API request.
 */
function prepareSubmission(values: FormValues, word: CreateWord<FarsiWordData>): CreateWord<FarsiWordData> {
  // This is the data object we send to the server with createWord.

  if (word.type === 'verb') {
    // TODO:
    word.base = getFarsiInfinitive(values.pastStem)

    if (values.isCompound) {
      let vector;
      if (isNestedForm(values.vector)) {
        vector = prepareWord(values.vector, {lang: 'fa', prepareSubmission: prepareSubmission});
      }
      else {
        vector = values.vector as {id: number};
      }

      let primary = values.primary?.map(p => {
        if (isNestedForm(p)) {
          return prepareWord(p, {lang: 'fa', prepareSubmission: prepareSubmission});
        }
        else {
          return p as {id: number};
        }
      });

      word.data = {
        is_compound: true,
        vector: vector!,
        primary: primary!
      }
    }

    else {
      word.data = {
        is_compound: false,
        present_stem: getTrimmed(values.presentStem),
        past_stem: getTrimmed(values.pastStem),
        prefixes: handleCommaSeparated(values.prefixes),
        irregular_present_stems: handleCommaSeparated(values.irregularPresentStems),
        irregular_past_stems: handleCommaSeparated(values.irregularPastStems)
      }
    }
  }

  else if (word.type === 'noun') {
    word.data = {
      plurals: handleCommaSeparated(values.plurals)
    }
  }

  else if (word.type === 'pronoun') {
    word.data = {
      plural_enabled: values.pluralEnabled
    }
  }

  else if (word.type === 'preposition') {
    word.data = {
      ezafe: values.ezafe
    }
  }

  else if (word.type === 'numeral') {
    word.data = {
      quantity: parseInt(values.quantity)
    }
  }

  else if (word.type === 'alias') {
    word.data = {
      alias: {
        type: values.aliasType,
        word: values.aliasWordRef
      }
    }
  }

  // Always set the sounds.
  if (!word.data) {
    word.data = {};
  }
  (word.data as any).sounds = handleCommaSeparated(values.sounds);
  (word.data as any).stems = handleCommaSeparated(values.stems)

  return word;
}


export function handleCommaSeparated(list: string): string[]|undefined {
  const result = list
    ? list.split(',').map((x: string) => x.trim()).filter((x: any) => !!x)
    : undefined;
  if (result && result.length == 0) {
    return undefined;
  }
  return result;
}


/**
 * Validate specific to Farsi words.
 */
function validateForm(values: any) {
  let errors: any = {};

  // On OSX, a tashdid sometimes put at the end actually goes to the front.
  if (values.base && values.base[0] == '\u0651') {
    errors.base = "A tashdid must not be at the start of a word. This is the case here, even if it looks like it is at the end."
  }

  if (values.type === 'verb' && values.isCompound) {
    if (!values.vector) {
      errors.vector = 'Needs to be provided.'
    }
    if (!values.primary) {
      errors.primary = 'Needs to be provided.'
    }
  }

  return errors;
}


function getTrimmed(s: any) {
  if (!s) {
    return "";
  }
  return s.trim();
}


/**
 * This mainly changes the entry for verbs so that the user has to
 * enter the present and past stem of a verb, rather than the infinitive.
 */
export function FarsiWordFormLogic(props: WordFormLogicProps) {
  let wordData/*: FarsiWordData*/ = props.wordData || {};

  // TODO: If we can make this a switch that checks that all values are tested, that would be great.

  return <DefaultWordFormLogic
    {...props}
    extraInitialValues={{
      // Because formik on submit resets the full touch status of the form
      // based on the initial values, we therefore have to initialize all
      // fields here for correct touchness behaviour.
      presentStem: (wordData as FarsiWordDataSingleVerb).present_stem || "",
      pastStem: (wordData as FarsiWordDataSingleVerb).past_stem || "",
      primary: (wordData as FarsiWordDataCompoundVerb).primary?.map(p => {
        return {
          id: p,
          type: '',
          base: "",
          presentStem: "",
          pastStem: "",
          isCompound: false
        };
      }) ?? [
        {id: true}
      ],
      vector: {
        id: wordData.vector ? wordData.vector : true,
        type: "",
        base: "",
        presentStem: "",
        pastStem: "",
        isCompound: false
      },
      isCompound: wordData.is_compound || false,
      prefixes: wordData.prefixes ? wordData.prefixes.join(', ') : "",
      irregularPastStems: wordData.irregular_past_stems ? wordData.irregular_past_stems.join(', ') : "",
      irregularPresentStems: wordData.irregular_present_stems ? wordData.irregular_present_stems.join(', ') : "",
      // For nouns:
      plurals: wordData.plurals ? wordData.plurals.join(', ') : "",
      // For numerals:
      quantity: wordData.quantity ? wordData.quantity : "",
      // For a pronoun
      pluralEnabled: wordData.pluralEnabled,
      // For a preposition
      ezafe: (wordData as FarsiWordDataPreposition).ezafe,
      // For an alias
      aliasType: wordData.alias?.type ||  "",
      aliasWordRef: wordData.alias?.word || "",
      // For all words
      sounds: wordData.sounds ? wordData.sounds.join(", ") : "",
      stems: wordData.stems ? wordData.stems.join(", ") : ""
    } as Partial<FormValues>}
    prepareSubmission={prepareSubmission}
    validate={validateForm}
  />
}

/**
 * The fields in a field group a visible if the user makes them so, or if
 * there are errors in one of the fields.
 *
 * The field names have to be passed so the group can be expanded on a validation error.
 */
function useFieldGroup(fieldNames: string[]) {
  const [isVisible, setIsVisible] = useState(false);
  const {values, errors, touched, isValidating, isSubmitting} = useFormikContext();

  // TODO: If the validation state does not change, but the user clicks "submit" again,
  // then also show expand the group.
  // console.log(errors, touched, isValidating, isSubmitting);
  // https://codesandbox.io/s/formik-v2-onvalidationfail-s7zy1

  // Any of the fields has errors?
  const fieldErrorState = useMemo(
    // @ts-ignore
    () => fieldNames.filter(name => touched[name]).map(name => errors[name]),
    [fieldNames, errors, touched]);

  // We can also look at the isSubmitting, is Validating props.
  useDeepCompareEffect(() => {
    const hasErrors = fieldErrorState.filter(x => !!x).length;
    if (hasErrors) {
      setIsVisible(true);
    }
  }, [fieldErrorState, setIsVisible]);

  const show = useCallback(() => setIsVisible(true), [setIsVisible]);
  const hide = useCallback(() => setIsVisible(false), [setIsVisible]);

  return {isVisible, show, hide};
}


export type FarsiWordFormConfig = {
  allowedWordTypes?: string[],
  disallowedWordTypes?: string[],
  disallowCompoundVerb?: boolean,
  autoSounds?: string[]
}

type FarsiWordFormProps = {
  config?: FarsiWordFormConfig,
  noFormTag?: boolean,
} & WordFormProps;
export const FarsiWordForm = (props: FarsiWordFormProps) => {
  const values = useNestedValues();
  const {type: wordType, isCompound} = values;

  const rtlField = useMemo(() => ({
    style: {
      direction: 'rtl',
      textAlign: 'left'
    }
  }), []);

  const defaultFields: any[] = [];
  const advancedFields: any[] = [];
  const advancedFieldNames: string[] = [];

  if (wordType === 'verb') {
    defaultFields.push(<Fragment key={"base"}>
      {!props.config || !props.config.disallowCompoundVerb ? <IsCompoundCheckbox /> : null}
      {isCompound ? <CompoundVerbPrimaryField /> : null}
      {isCompound ? <CompoundVerbVectorField /> : null}
      {!isCompound ? <SimpleVerbFields /> : null}
      <AutogeneratedBaseField />
    </Fragment>);

    if (!isCompound) {
      advancedFieldNames.splice(0, 0, ...SimpleVerbAdvancedFieldNames);
      advancedFields.push(<SimpleVerbAdvancedFields key={"prefixes"} />)
    }
    if (props.showDescriptionField) {
      advancedFieldNames.push("description");
      advancedFields.push(<WordDescriptionField
        inputProps={rtlField}
        key={"description"}
      />)
    }
  }
  else {
    defaultFields.push(<Fragment key={"base"}>
      <WordBaseField
        inputProps={rtlField}
      />
      {props.showDescriptionField ? <WordDescriptionField
        inputProps={rtlField}
      /> : null}
    </Fragment>)

    advancedFieldNames.push('sounds');
    advancedFields.push(<SoundsField
      key={"sounds"}
      defaultSounds={props.config ? props.config.autoSounds : undefined}
    />)

    advancedFieldNames.push('stems');
    advancedFields.push(<StemsField key={"stems"} />)
  }

  if (wordType === 'noun') {
    advancedFieldNames.push('plurals');
    advancedFields.push(<PluralsField key={"plurals"} />)
  }

  if (wordType === 'pronoun') {
    advancedFieldNames.push('pluralEnabled');
    advancedFields.push(<PluralEnabledField key={"pluralEnabled"} />)
  }

  if (wordType === 'preposition') {
    advancedFieldNames.push('ezafe');
    advancedFields.push(<EzafeModeField key={"ezafe"} />)
  }

  if  (wordType === 'numeral') {
    defaultFields.push(<WordNumeralQuantityField key={"quantity"} />);
  }

  if (wordType ===  'alias') {
    defaultFields.push(<WordAliasReferenceField key={"wordId"} />)
    defaultFields.push(<AliasTypeField key={"aliasType"} />)
  }

  const fields = <>
    {defaultFields}

    <AdvancedFieldToggle
      fields={advancedFields}
      names={advancedFieldNames}
    />
  </>

  let allFields = <>
    <WordTypeField
      allowedTypes={props.config ? props.config.allowedWordTypes : undefined}
      disallowedTypes={props.config ? props.config.disallowedWordTypes : undefined}
      allowAdvancedTypes={props.allowInvariantSubtypes}
    />
    {fields}
    <WarningsField />
  </>;

  if (props.noFormTag) {
    return allFields;
  }

  return <Form>
    {allFields}
  </Form>
};


function AdvancedFieldToggle(props: {
  fields: any[],
  names: string[]
}) {
  const advancedGroup = useFieldGroup(props.names);

  if (!props.names || !props.names.length) {
    return null;
  }

  return <>
    <a href={""} style={{marginTop: '.5em'}} onClick={(e: any) => {
      e.preventDefault();
      if (advancedGroup.isVisible) {
        advancedGroup.hide();
      } else {
        advancedGroup.show();
      }
    }}>
      {advancedGroup.isVisible ? "Hide advanced options" : "Show advanced options"}
    </a>

    <div style={{
      display: advancedGroup.isVisible ? 'grid' : 'none',
    }}>
      {props.fields}
    </div>
  </>;
}


function IsCompoundCheckbox(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={`isCompound`}
    label={"This is a compound verb"}
    component={UI.Checkbox}
    margin="normal"
  />;
}



function AutogeneratedBaseField(props: {}) {
  const UI = useUIControls();

  // In Farsi, the infinitive is always "past stem + an":
  //     "The past stem always obtains regularly by removing -an from the infinitive:"
  //     http://www.jahanshiri.ir/fa/en/verb-stem
  const values = useNestedValues();
  return <UI.Text
    label="Infinitive"
    field={{}}
    form={{}}
    margin="normal"
    inputProps={{dir: 'rtl'}}
    disabled={true}
    // TODO
    value={getFarsiInfinitive(values.pastStem, values.primaryComponents)}
  />;
}