import React, { useCallback, useContext, useState } from 'react';
import styles from './WordList.module.css';
import {Link} from "react-router-dom";
import gql from "graphql-tag";
import {Button} from '@material-ui/core';
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import { keyBy } from 'lodash';
import {format, parseISO} from 'date-fns';
import {CheckIcon} from "./CheckIcon";
import { __RouterContext as RouterContext } from "react-router";
import {useQuery} from "multi-apollo";
import {getPaginationModel, ITEM_TYPES, PaginationModelItem} from 'ultimate-pagination';
import {useLocalStorage} from 'react-use';
import {pick} from 'lodash';


const GET_WORDS = gql`
  query(
    $sortBy: String, 
    $filter: WordFilter, 
    $first: Int, 
    $after: Int,
    $search: String,
    $onlyTypes: [WordType!]
  ) {
    words(sortBy: $sortBy, filter: $filter, first: $first, after: $after, search: $search, onlyTypes: $onlyTypes) {
      count,
      edges {
        node {
          id,
          createdAt,
          type,
          base,
          baseTransliteration,
          annotationCounts {
            source, count
          },
          useCount(database: "market"),
          isReviewed
        }        
      }
    }
  }
`;


export type FilterOptions = {
  wordType?: string,
  isReviewed?: boolean,
  externalDictionaries?: 'has'|'has-not'|'good'
  internalDictionary?: 'has'|'has-not'
  search?: string,
  createdBy?: number
};


export function WordList(props: {
  sortBy?: string,
  filters?: FilterOptions,
  onChangeSort?: (newValue: string) => void
}) {
  const [page, setPage] = useLocalStorage("wordui-wordlist-page", 0);
  const perPage = 50;

  const {search, ...otherFilters} = props.filters || {};
  const {data, loading, error} = useQuery(GET_WORDS, {
    variables: {
      sortBy: search ? 'rank' : props.sortBy,
      filter: pick(otherFilters, 'isReviewed', 'externalDictionaries', 'internalDictionary', 'createdBy'),
      after: ((page ?? 1) * perPage) - 1,
      first: perPage,
      search: search,
      onlyTypes: otherFilters.wordType ? [otherFilters.wordType] : undefined
    }
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;

  const totalPages = Math.ceil(data.words.count / perPage);

  return <>
    <WordListComponent
      words={data.words}
      onChangeSort={props.onChangeSort}
      sortBy={props.sortBy}
    />
    <Paginator
      currentPage={page ?? 1}
      totalPages={totalPages}
      onPageChange={setPage}
    />
  </>
}


export function WordListComponent(props: {
  words: any,
  style?: any,
  onChangeSort?: (newValue: string) => void,
  sortBy?: string
}) {
  const router = useContext(RouterContext);
  const handleRowClick = useCallback((e) => {
    e.preventDefault();
    const wordId = e.currentTarget.dataset['word'];
    router.history.push(`/word/${wordId}`)
  }, [router]);

  const table = <table style={props.style} className={styles.table}>
    <thead>
      <tr>
        <th>
          <SortableColumn
            name={"base"}
            currentSort={props.sortBy}
            onChangeSort={props.onChangeSort}
          >
            Word
          </SortableColumn>
        </th>
        <th>
          <SortableColumn
            name={"annotationCounts"}
            onChangeSort={props.onChangeSort}
            currentSort={props.sortBy}
          >
            External Dictionaries
          </SortableColumn>
        </th>
        <th>
          Our Dictionary
        </th>
        <th>
          <SortableColumn
            name={"useCount"}
            onChangeSort={props.onChangeSort}
            currentSort={props.sortBy}
          >
            Uses
          </SortableColumn>
        </th>
        <th>
          <SortableColumn
            name={"createdAt"}
            onChangeSort={props.onChangeSort}
            currentSort={props.sortBy}
          >
            Added at
          </SortableColumn>
        </th>
      </tr>
    </thead>
    <tbody>
      {props.words.edges.map(({node: word}: any) => {
        const counts = keyBy(word.annotationCounts, 'source');
        return <tr key={word.id} data-word={word.id} onClick={handleRowClick}>
          <td>
            {word.isReviewed ? <CheckIcon className={styles.icon} /> : null}
            <Link to={`/word/${word.id}`}>
              {word.base ? word.base : <em>missing</em>}
            </Link>
            <span className={styles.wordType}>{word.type}</span>
            <span className={styles.transliteration}>
              {romanizeSounds(JSON.parse(word.baseTransliteration))}
            </span>
          </td>
          <td>
            {counts.wiktionary ? counts.wiktionary.count : 0}
          </td>
          <td>0</td>
          <td>
            {word.useCount}
          </td>
          <td>{!word.createdAt ? "" : format(parseISO(word.createdAt), 'yyyy-MM-dd')}</td>
        </tr>
      })}
    </tbody>
  </table>;

  return <div>
    Words: {props.words.count}
    {table}
  </div>
}


function SortableColumn(props: {
  name: string,
  currentSort?: string,
  onChangeSort?: (newValue: string) => void,
  children?: any
}) {
  const onChangeSort = props.onChangeSort;
  let {currentSort} = props;

  if (!onChangeSort) {
    return props.children;
  }

  let isReversed = false;
  if (currentSort && currentSort[0] == '!') {
    currentSort = currentSort.slice(1);
    isReversed = true;
  }

  const isSorted = currentSort === props.name;

  return <a href={""} onClick={(e) => {
    e.preventDefault();
    if (isReversed) {
      onChangeSort(props.name);
    } else {
      onChangeSort('!' + props.name);
    }
  }}>
    {props.children}
    {isSorted
      ? isReversed ? <span>↓</span> :  <span>↑</span>
      : null
    }
  </a>
}


function Paginator(props: {
  currentPage: number,
  totalPages: number,
  onPageChange: (page: number) => void
}) {
  if (props.totalPages === 0) {
    return null;
  }
  const paginationModel = getPaginationModel({
    // Required
    totalPages: props.totalPages,
    currentPage: Math.min(props.currentPage + 1, props.totalPages),

    // Optional
    boundaryPagesRange: 1,
    siblingPagesRange: 1,
    hideEllipsis: false,
    hidePreviousAndNextPageLinks: false,
    hideFirstAndLastPageLinks: false
  });

  return <div style={{textAlign: 'center', marginTop: '20px'}}>
    {paginationModel.map(item => {
      const result = getItemComponent(item);
      if (result.comp !== undefined) {
        return result.comp;
      }
      let {label} = result;
      return <Button
        key={item.key}
        onClick={() => props.onPageChange(item.value-1)}
        variant={(item.isActive && item.type == ITEM_TYPES.PAGE) ? "contained" : undefined}
      >
        {label}
      </Button>
    })}
  </div>
}


function getItemComponent(
  item: PaginationModelItem,
): {
  label?: string,
  comp?: React.ReactElement
}{
  switch (item.type) {
    case ITEM_TYPES.ELLIPSIS:
      return {comp: <span style={{padding: '0 20px'}} key={item.key}>...</span>};
    case ITEM_TYPES.PAGE:
      return {label: ""+item.value};
    case ITEM_TYPES.FIRST_PAGE_LINK:
      return {label: "First"};
    case ITEM_TYPES.PREVIOUS_PAGE_LINK:
      return {label: "Previous"};
    case ITEM_TYPES.NEXT_PAGE_LINK:
      return {label: "Next"};
    case ITEM_TYPES.LAST_PAGE_LINK:
      return {label: "Last"};
  }
}