import {getGlobalAuthKey, graphqlQuery} from "../Market/api";
import React, {useMemo} from "react";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import {ApolloClient, createHttpLink, InMemoryCache} from "@apollo/client";
import {
  ForeignWordNode,
  isForeignWordNode,
  isNumeralNode,
  isWordNode,
  NumeralNode,
  WordNode
} from "languagetool-player/src/models/Node";
import { pick } from "lodash";
import {useLanguageToolAuth} from "../LanguagetoolAuth";
import {setContext} from "@apollo/client/link/context";


export type FarsiWordDataDefault = null;

// Note that there some words (numerals, verbs, ...) which currenlty do *not* inherit from this.
export type FarsiWordDataBase = {
  sounds?: string[],
  stems?: string[]
}

export interface FarsiWordDataNoun extends FarsiWordDataBase {
  plurals?: string[]
};

export type FarsiWordDataSingleVerb = {
  is_compound: false,
  present_stem: string,
  past_stem: string,
  prefixes?: string[]|null
  irregular_present_stems?: string[]|null
  irregular_past_stems?: string[]|null
}

export type FarsiWordDataCompoundVerb = {
  is_compound: true,
  primary: (number|{id: number}|CreateWord<FarsiWordData>)[],
  vector: number|{id: number}|CreateWord<FarsiWordData>,
}

export type FarsiWordDataNumeral = {
  quantity: number
}

export interface FarsiWordDataPronoun extends FarsiWordDataBase {
  plural_enabled: boolean
}

export interface FarsiWordDataPreposition extends FarsiWordDataBase {
  ezafe: 'included'|'disallowed'|'optional'|'default'
}

export interface FarsiWordDataAlias extends FarsiWordDataBase {
  alias: {
    type: string,
    word: number
  }
}

// This is the data structure supported by the API request. This is almost, but note quite the same as
// the one actually stored in the API.
export type FarsiWordData = (
  FarsiWordDataDefault|
  FarsiWordDataBase|
  FarsiWordDataSingleVerb|FarsiWordDataCompoundVerb|FarsiWordDataNoun|FarsiWordDataNumeral|
  FarsiWordDataPronoun|FarsiWordDataPreposition|
  FarsiWordDataAlias
);


export type Meaning = {id: number, descriptor: string};
type WordDataOut = any;
export type Word = {id: number, base: string, lang: string, type: string, data?: WordDataOut};


export type CreateWord<D extends FarsiWordData = any> = {
  base: string, lang: string, type: string, descriptor: string, data?: D|{id: number}
};

export type WordEditorConfig = {
  warning?: string
};


/**
 * A single grammar parse result returned from the server.
 */
export type WordNodeResult<D=object> = {
  // The node to assign
  node: WordNode<D>,

  // Alternative values for the vocables involved
  alternates?: {[vocableId: string]: {[key: string]: string}},

  // Admin-extra data
  extra?: {[key: string]: string},
  // Admin-information about the word
  wordData?: {
    description: string,
    isReviewed: boolean,
    sounds: string[],
    editorConfig?: WordEditorConfig
  }|null,

  // Special flag set for when the result comes from the node itself, is currently attached.
  unlinkedNodeResult?: boolean
};

export type NumeralNodeResult = {
  node: NumeralNode,

  // Alternative values for the vocables involved
  alternates?: {[vocableId: string]: {[key: string]: string}},

  // Special flag set for when the result comes from the node itself, is currently attached.
  unlinkedNodeResult?: boolean
}
export type ForeignWordNodeResult = {
  node: ForeignWordNode,

  // Special flag set for when the result comes from the node itself, is currently attached.
  unlinkedNodeResult?: boolean
}

export type AnalyzeResult = WordNodeResult|NumeralNodeResult|ForeignWordNodeResult;




export function isNumeralNodeResult(result: AnalyzeResult): result is NumeralNodeResult {
  return result ? isNumeralNode(result.node) : false; }
export function isWordNodeResult(result: AnalyzeResult): result is WordNodeResult {
  return result ? isWordNode(result.node) : false; }
export function isForeignWordNodeResult(result: AnalyzeResult): result is ForeignWordNodeResult {
  return result ? isForeignWordNode(result.node) : false; }


/**
 * Ask the dbserver to have a look at this particular set of vocables, and let us know which
 * words and grammar structures might be behind it.
 */
export async function analyzeListOfWords(
  words: {id: string, text: string, pre?: string, post?: string}[],
  language: string,
  mergedVocables: Set<string>[] = [],
  opts: {
    apiUrl: string
  }
) {
  const response = await fetch(`${opts.apiUrl}/analyze_words`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'authorization': `${getGlobalAuthKey()}`
    },
    body: JSON.stringify({
      //pick() because there are in practice other fields we prefer not to send
      vocables: words.map(w => pick(w, ['id', 'text', 'pre', 'post'])),
      lang: language,
      merged_vocables: mergedVocables.map(x => Array.from(x))
    })
  });

  const data: {
    suggested_nodes: AnalyzeResult[]
  } = await response.json();
  return data.suggested_nodes;
}



function getEndpoint(): string {
  // @ts-ignore
  return (window.CONFIG && window.CONFIG.wordsAdminUrl)
    // @ts-ignore
    ? window.CONFIG.wordsAdminUrl
    : 'http://localhost:5000/graphql';
}


/**
 * Hook which returns an ApolloClient connected to the "words admin" (the dbserver analyzing words).
 */
export function useWordsServer() {
  const {getTokenSilently} = useLanguageToolAuth();

  return useMemo(() => {
    const cache = new InMemoryCache();

    // apollo-link-context is the right thing to have an async call to prepare the headers. See  more information
    // here on how I deduced this and how the links work:
    // https://www.apollographql.com/docs/link/overview/
    // https://github.com/apollographql/apollo-link/blob/c32e170b72ae1a94cea1c633f977d2dbfcada0e1/packages/apollo-link-http/src/httpLink.ts#L84
    // https://github.com/apollographql/apollo-client/issues/2441
    // TODO: BatchHttpLink is desired but currently not supported by Ariadne:
    //   https://github.com/mirumee/ariadne/pull/60
    const httpLink = createHttpLink({
      uri: getEndpoint()
    });

    // This will resolve the auth token dynamically, then inject it.
    const injectAuthLink = setContext(async (request, {headers}) => {
      return { headers: {
          'Authorization': `${await getTokenSilently()}`,
          ...headers
        }};
    });

    // So then this is our full link: first auth header inject, then http.
    const link = injectAuthLink.concat(httpLink);

    return new ApolloClient({
      link,
      cache,
    });
  }, [getTokenSilently]);
}


type QueryFunc = (query: string, args: any) => any;


// This is the old approach. We might want to migrate this step for step to using apollo.
export class WordsAdminAPI {

  private query: QueryFunc;
  private mutate: QueryFunc;

  constructor(query: QueryFunc, mutate: QueryFunc) {
    this.query = query;
    this.mutate = mutate
  }

  async searchWords(search: string, opts?: {onlyTypes?: string[], options?: string[]}): Promise<Word[]> {
    const query = `
      query SearchWords($search: String!, $onlyTypes: [WordType], $options: [String]) {
        words(search: $search, lang: "fa", onlyTypes: $onlyTypes, options: $options) {
          edges {
            node {
              id,
              base,
              lang,
              type
            }
          }
        }
      }
    `;

    const words = (await this.query(query, {
      search: search,
      onlyTypes: opts ? opts.onlyTypes : null,
      options: opts ? opts.options : null
    })).words.edges;

    return words.map((edge: any) => edge.node);
  }

  async getWord(wordId: number): Promise<Word> {
    const query = `
      query GetWord($wordId: Int!) {
        word(id: $wordId) {   
          id,       
          base,
          lang,
          type,
          dataBlob
        }
      }
    `;

    const word = (await this.query(query, {
      wordId
    })).word;

    return {
      ...word,
      data: JSON.parse(word.dataBlob)
    }
  }

  async getMeaningsForWordId(wordId: number): Promise<Meaning[]> {
    const query = `
      query GetMeaningsForWord($wordId: ID!) {
        word(id: $wordId) {          
          meanings {
            id,
            descriptor
          }
        }
      }
    `;

    return (await this.query(query, {
      wordId
    })).word.meanings;
  }

  async validateWord(word: CreateWord, lang: string, textToMatch: string) {
    const query = `
      query ValidateWord($word: CreateWordProps!, $wordStr: String!, $lang: String!, $textToMatch: String, $hasWordString: Boolean!) {
        validateWord(word: $word, lang: $lang, textToMatch: $textToMatch) {          
          dictionaries,
          couldParse,
          transliterated
        }
        words(base: $wordStr, lang: $lang) @include(if: $hasWordString) {          
          edges {
            node {
              base
            }
          }
        }
      }
    `;

    const result = (await this.query(query, {
      word: {
        ...word,
        data: word.data ? JSON.stringify(word.data) : null
      },
      wordStr: word.base,
      hasWordString: !!word.base,
      lang,
      textToMatch
    }));

    const {couldParse, dictionaries, transliterated} = result.validateWord;

    const parsed = JSON.parse(dictionaries);
    let dictResultsFound = 0;
    Object.values(parsed).forEach((results: any) => {
      dictResultsFound += results.length;
    });

    const existingWords = result.words ? result.words.edges.map((edge: any) => edge.node) : [];

    return {
      hasDictionaryResults: dictResultsFound > 0,
      existingWords,
      couldParse,
      data: {
        transliterated: transliterated ? JSON.parse(transliterated) : null
      }
    };
  }

  async createWord(props: CreateWord): Promise<[number, number]> {
    const query = `
      mutation($word: CreateWordProps!) {
        createWord(word: $word) {          
          word {
            id,
            meanings {
              id
            }
          }
        }
      }
    `;

    const word = (await this.mutate(query, {
      word: {
        ...props,
        data: props.data ? JSON.stringify(props.data) : null
      }
    })).createWord.word;

    return [word.id, word.meanings[0].id];
  }

  async editWord(id: number, props: {base: string, lang: string, type: string, descriptor: string, data?: any}): Promise<{}> {
    const query = `
      mutation($wordId: Int!, $word: CreateWordProps!) {
        editWord(wordId: $wordId, word: $word) {          
          word {
            id            
          }
        }
      }
    `;

    const word = (await this.mutate(query, {
      wordId: id,
      word: {
        ...props,
        data: props.data ? JSON.stringify(props.data) : null
      }
    })).editWord.word;

    return {};
  }
}

/**
 * Will use auth and endpoint as configured for the editor project.
 */
export function getAPI() {
  const query = async (query: string, args?: any) => {
    return await graphqlQuery({
      query,
      args,
      endpoint: getEndpoint(),
      credentials: 'omit'
    });
  }

  return new WordsAdminAPI(query, query);
}


export function useAPI() {
  return useMemo(() => {
    return getAPI();
  }, []);
}