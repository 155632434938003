/**
 * The popup configuration holds switches such as "should we show a transliterated version?", "should we show a Farsi
 * word with vowels?", "should we show a dictionary entry in the source language, if we can?".
 *
 * The config is made available via context, because many nested components, such as every <WordLink /> need to be
 * able to access it to determine what form of a word to show.
 */


import * as React from "react";
import {useContext} from "react";
import {defaultConfig, PopupConfig} from "./PopupConfig";
import {StructureNode} from "../../models/Node";
import {Vocable, VocableId} from "../../models/formats/CaptionTrack";


/**
 * This essentially abstracts away the "caption track"  of our media sources and gives
 * the popup a limited interface to map word ids and vocable ids to nodes.
 */
export interface NodeResolver {
  resolveVocable(vocableId: VocableId): Vocable,
  resolveNode(nodeId: string): StructureNode,
  getNodesForVocable(vocableId: VocableId): StructureNode[]
}

export type PopupContextValue = {
  config: PopupConfig,
  resolver?: NodeResolver,

  // The language used; only used a a fallback for when a word has no language attached.
  defaultLanguage?: string,
}


export const PopupContext = React.createContext<PopupContextValue>({
  config: defaultConfig
})


export function usePopupConfig(): PopupConfig {
  const {config} = useContext(PopupContext);
  return config;
}


export function useResolver(): NodeResolver {
  const {resolver} = useContext(PopupContext);
  if (!resolver) {
    throw new Error("No resolver is in the PopupContext. Provide a resolver so that the popup can follow " +
      "references to vocable ids and node ids of the unit.")
  }
  return resolver;
}


export function PopupContextProvider(props: {
  config?: PopupConfig,
  defaultLanguage: string,
  resolver: NodeResolver,
  children: any,
}) {
  const value = {
    config: defaultConfig,
    ...props
  };

  return <PopupContext.Provider value={value}>
    {props.children}
  </PopupContext.Provider>
}
