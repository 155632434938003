import React, {useMemo, useContext, useState} from "react";
import {MessageBar, MessageBarType} from "office-ui-fabric-react";

type Message = {
  message: string;
  type: "error";
};

export type MessageBarContext = {
  message: Message | null;
  showError: (message: string) => void;
  hide: () => void;
};

export const MessageBarContextValue = React.createContext<MessageBarContext>({
  showError: () => null,
  hide: () => null,
  message: null
});

export function MessageBarProvider(props: {children?: any}) {
  const [state, setState] = useState<null | Message>(null);

  const funcs = useMemo(() => {
    return {
      showError: (message: string) => {
        setState({message, type: "error"});
      },
      hide: () => {
        setState(null);
      }
    };
  }, [setState]);

  const value = useMemo(() => {
    return {
      message: state,
      ...funcs
    };
  }, [state, funcs]);

  return (
    <MessageBarContextValue.Provider value={value}>
      {props.children}
    </MessageBarContextValue.Provider>
  );
}

export function useMessageBar(): MessageBarContext {
  return useContext(MessageBarContextValue);
}

export function MessageBarRender(props: {}) {
  const messageBar = useMessageBar();

  if (!messageBar.message) {
    return null;
  }

  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      isMultiline={false}
      onDismiss={() => messageBar.hide()}
    >
      {messageBar.message?.message}
    </MessageBar>
  );
}
