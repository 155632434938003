import { useContext, useEffect, useCallback, useState, useMemo } from "react";
import { EventEmitter } from "events";
import React from "react";
import useDebounce from 'react-use/lib/useDebounce';
import {useAudioPlayer} from "../Audio/useAudioPlayer";


// Copied from 'events' as it is not properly exported there. We could use the opportunity to make it generic.
export type Listener = (...args: any[]) => void;

type AudioFile = string;


export type AudioManagerAPI = {
  // Request to play a particular item
  play(url: AudioFile): void,

  // Registering audios allows the first one to be played automatically for example.
  registerUrl: (url: AudioFile) => void,
  unregisterUrl: (url: AudioFile) => void,
  // A new page will trigger a new "play the first audio registered" cycle.
  reportNewPage: () => void,

  on(type: string, listener: Listener): void;
  off(type: string, listener: Listener): void;
};


type OnStateEvent = (state: {audio: AudioFile|null, isPlaying: boolean}) => void;


export const AudioManagerContext = React.createContext<AudioManagerAPI>({
    play: (url: AudioFile) => {
      console.log('Cannot play, as now parent <AudioManagerContext> has been setup.')
    },
    registerUrl: (url: AudioFile) => {
    },
    unregisterUrl: (url: AudioFile) => {
    },
    reportNewPage: () => {
    },
    on: () => {
    },
    off: () => {
    }
  }
);

/**
 * Sits at the root level of the WordPopup.
 *
 * Multiple components inside the component may want to play audio or offer to play audio.
 *
 * This will ensure only one will be played at any one time.
 */
export const AudioManager = React.forwardRef((props: {
  children: any
}, ref) => {
  const [urls, setUrls] = useState<AudioFile[]>([]);
  const [hasAutoPlayed, setHasAutoPlayed] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<string>("");

  const events = useMemo(() => new EventEmitter(), []);

  const {play, isPlaying} = useAudioPlayer();

  // trigger on/off events
  useEffect(() => {
    events.emit('change', {audio: currentAudio, isPlaying: isPlaying});
  }, [currentAudio, isPlaying, events]);

  // We debounce mostly to give multiple sub components a chance to register.
  useDebounce(() => {
    if (urls.length === 0 || hasAutoPlayed) { return; }
    play(urls[0]);
    setHasAutoPlayed(true);
  }, 100, [urls, hasAutoPlayed]);

  const value = useMemo<AudioManagerAPI>(() => {
    return {
      play: (audio: AudioFile) => {
        setCurrentAudio(audio);
        play(audio);
      },

      registerUrl: (url: AudioFile) => {
        setUrls(urls => ([...urls, url]))
      },
      unregisterUrl: (url: AudioFile) => {
        setUrls(urls => {
          const newUrls = [...urls];
          newUrls.splice(newUrls.indexOf(url), 1);
          return newUrls;
        })
      },
      reportNewPage: () => {
        setUrls([]);
        setHasAutoPlayed(false);
      },
      on: (type: string, listener: Listener) => { events.on(type, listener) },
      off: (type: string, listener: Listener) => { events.off(type, listener) },
    }
  }, [setUrls, setHasAutoPlayed, play, events, setCurrentAudio]);

  React.useImperativeHandle(ref, () => value, [value]);

  return <AudioManagerContext.Provider value={value}>
    {props.children}
  </AudioManagerContext.Provider>
});


/**
 * A hook to play an audio file through the audio manager (ensuring only a single file plays at a time).
 */
export function useAudio(audio: AudioFile|undefined) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioAPI = useContext(AudioManagerContext);

  // Register the file with the manager.
  useEffect(() => {
    if (!audio) { return; }
    audioAPI.registerUrl(audio);
    return () => {
      audioAPI.unregisterUrl(audio);
    }
  }, [audio]);

  // Fetch the isPlaying status from the audio manager.
  useEffect(() => {
    const handlePlay: OnStateEvent = (state) => {
      setIsPlaying(state.isPlaying && state.audio === audio);
    };

    audioAPI.on('change', handlePlay);
    return () => {
      audioAPI.off('change', handlePlay);
    }
  }, [audioAPI, audio]);

  const play = useCallback(() => {
    if (!audio) { return; }
    return audioAPI.play(audio);
  }, [audio]);

  return {play, isPlaying};
}