import {StyleSheet, Platform} from 'react-native';
import React, { useCallback } from "react";
import {openLinkNative, isExternal} from "./LinkTouchable.shared";
import {useHistory} from 'react-router';
import { TextBlock } from './Text';

export const TextLinkStyles = StyleSheet.create({
  link: {
    color: Platform.OS == 'web' ?
      '#2E4057'  // As used on farsi.school. TODO: Ultimately, should not be hard-coded here.
      : '#1c80fe', // iOS Blue,
  }
})

export type TextLinkProps = {
  label?: string;
  //children?: string|React.ReactElement<Text>|React.ReactElement<Text>[];
  children?: any,
  href?: string;
  onPress?: (e: any) => void,
  blank?: boolean,
  style?: any,
  root?: boolean
};


/**
 * The key is that this renders a link within a <Text> structure, so it may not use a <View>.
 *
 * Use this if:
 * - We you want a clickable link within a <Text> that has a default link style.
 * - Basically, this is a <a> text link on the web, and looks similar to one on mobile.
 * - If you want a button, this is not the right thing.
 * - You just want to render something that looks like a link, even if the actual link is a higher-level
 *   component such as <LinkTouchable>.
 *
 * TODO: On the web, this should probably be the same as LinkTouchable?
 */
export function TextLink(props: TextLinkProps) {
  const {style, onPress, root, href} = props;
  const history = useHistory();

  const handlePress = useCallback((e: any) => {
    if (onPress) {
      onPress(e);
    }

    if (!href) {
      return;
    }

    if (isExternal(href)) {
      openLinkNative(href)
    } else {
      history.push(href);
    }
  }, [onPress, href]);

  return <TextBlock
    style={[TextLinkStyles.link, removeUndefinedKeys(style)]}
    onPress={handlePress}
  >
    {props.label || props.children}
  </TextBlock>;
}


export function removeUndefinedKeys<T>(obj: T) {
  if (!obj) {
    return obj;
  }
  return Object.keys(obj).reduce((acc, key) => {
    const _acc = acc;
    // @ts-ignore
    if (obj[key] !== undefined) _acc[key] = obj[key];
    return _acc;
  }, {});
}
