/**
 * These are helpers to post-process the "Vocable" structure that is used across our data formats.
 *
 * It needs post processing because:
 *
 * - You need to pick which "alternate" you want to use (with diacritics?).
 * - You need to format the transliteration instructions.
 * - You need to reverse punctuation in rtl/ltr text.
 */

import {Vocable} from "./index";

export type VocableFormat = {
  useAlternates?: string[],
  direction?: 'ltr'|'rtl',
  fallbackToMain?: boolean,
  altFormatter?: (s: any) => string,
  prePostFormatter?: (value?: string) => string
};

export function getVocableText(
  el: Vocable,
  format?: VocableFormat
): string {
  if (format?.useAlternates) {
    const text = getAlternatesText(el.alternates, format?.useAlternates, format);
    if (text) {
      return text;
    }

    if (format?.fallbackToMain) {
      return el.text;
    } else {
      return '_'.repeat(el.text.length);
    }
  }

  return el.text;
}


export function getAlternatesText(
  alternates: {[key: string]: string}|undefined,
  keys: string[],
  props: {
  altFormatter?: (s: {}|string) => string,
}): string|null {
  if (!alternates) {
    return null;
  }

  for (const altKey of keys) {
    if (alternates && alternates[altKey]) {
      let text = alternates[altKey];
      if (props.altFormatter) {
        text = props.altFormatter(text);
      }
      return text;
    }
  }

  return null;
}