import React from "react";
import {CheckIcon} from "../screens/WordList/CheckIcon";
import styles from "./WordWithDetails.module.css";
import {useTransliterator} from "./transliterate";
import gqlw from "graphql-tag";
import Link from "../components/Link";

export const WordWithDetailsFragment = gqlw`
  fragment WordWithDetails on Word {
    id,
    base,
    type,
    description,
    isReviewed,
    baseTransliteration
  }
`;

export type WordWithDetailsProps = {
  base: string;
  type: string;
  description: string;
  isReviewed: boolean;
  baseTransliteration: string;

  hideReviewedMark?: boolean;
};

export function WordWithDetails(props: WordWithDetailsProps) {
  const {isReviewed, type, base, description, baseTransliteration} = props;
  const transliterate = useTransliterator();

  return (
    <span>
      {isReviewed && !props.hideReviewedMark ? (
        <CheckIcon className={styles.icon} />
      ) : null}
      <span className={styles.wordType}>{type}</span>
      {base}
      <span className={styles.transliteration}>
        {transliterate(baseTransliteration)}
      </span>
      {description ? (
        <em className={styles.description}>({description})</em>
      ) : null}
    </span>
  );
}

export function WordLinkWithDetails(
  props: WordWithDetailsProps & {id: string | number}
) {
  return (
    <Link href={`/word/${props.id}`}>
      <WordWithDetails {...props} />
    </Link>
  );
}
