import {useCallback, useEffect} from "react";
import {useRafLoop} from "./useRafLoop";

/**
 * A raf callback only while the audio is playing. It uses the `timeupdate` event as a fallback for when the
 * current browser tab is not n the foreground and a raf frame is usually not called.
 */
export function useAudioTime(
  audio: HTMLAudioElement,
  callback: (time: number) => void,
  deps: any[]
) {
  const handleTimeUpdate = useCallback(() => {
    callback(audio.currentTime);
  }, [deps]);

  const [startRaf, stopRaf] = useRafLoop(handleTimeUpdate);

  useEffect(() => {
    audio.addEventListener("play", startRaf);
    audio.addEventListener("pause", stopRaf);
    audio.addEventListener("ended", stopRaf);
    return () => {
      audio.removeEventListener("play", startRaf);
      audio.removeEventListener("pause", stopRaf);
      audio.removeEventListener("ended", stopRaf);
    };
  }, [audio, startRaf, stopRaf]);

  useEffect(() => {
    audio.addEventListener("timeupdate", handleTimeUpdate);
    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
    };
  }, [audio, handleTimeUpdate]);
}
