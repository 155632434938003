import React, {useCallback, useState, useMemo} from "react";
import {DefaultButton as Button, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton} from 'office-ui-fabric-react';
import {useMutation, useQuery} from "multi-apollo";
import gql from "graphql-tag";


type WordCopyStruct = {base: string, type: string, data: object, lang: string};


export function CopyButton(props: {
  wordId: number,
  onCopied: (newWordId: number) => void
}) {
  const [isVisible, setIsVisible] = useState(false);

  const createWord = useCreateWordMutation();

  const handleCopy = useCallback(async (word: WordCopyStruct) => {
    // Hide it immediately.
    setIsVisible(false);

    const data = await createWord.createWord({
      variables: {
        word: {
          base: word.base,
          type: word.type,
          lang: word.lang,
          // Due to a bug on the server in graphene, we cannot pass a JSON-null
          data: word.data ? JSON.stringify(word.data) : null,
        }
      }
    });
    props.onCopied(data.data.createWord.word.id)
  }, []);

  const handleClick = useCallback(() => {
    setIsVisible(true)
  }, [setIsVisible]);

  return <Button
    style={{marginRight: '1em'}}
    onClick={handleClick}
    disabled={createWord.loading}
  >
    Copy
    {isVisible ? <ConfirmDialog
      wordId={props.wordId}
      onRequestClose={() => setIsVisible(false)}
      onCopy={handleCopy}
      hidden={!isVisible}
    /> : null}
  </Button>
}


function useWord(wordId: number): {
  word: {
    id: string,
    lang: string,
    base: string,
    data: object,
    dataBlog: string,
    type: string
  },
  loading: boolean,
  error: any
} {
  const {data, error, loading, refetch} = useQuery(gql`
    query WordQuery($wordId: Int!) {
      word(id: $wordId) {        
        id,
        lang,
        base,
        type,
        dataBlob
      }
    }
  `, {
    variables: {
      wordId
    }
  });

  const parsedWord = useMemo(() => {
    if (!data) {
      return null;
    }
    return {
      ...data.word,
      data: JSON.parse(data.word.dataBlob)

    }
  }, [data]);

  return {word: parsedWord, loading, error}
}


function useCreateWordMutation() {
  const [createWord, {data: mutationData, loading}] = useMutation<any, {
    word: {
      base: string,
      type: string,
      lang: string,
      data: string|null,
    }
  }>(gql`
    mutation($word: CreateWordProps!) {
      createWord(word: $word) {          
        word {
          id,          
        }
      }
    }
  `, {
  });

  return {createWord, loading};
}


function ConfirmDialog(props: {
  wordId: number,
  onRequestClose: any,
  onCopy: (word: WordCopyStruct) => void,
  hidden: boolean
}) {
  const {word, loading, error} = useWord(props.wordId);

  return <Dialog
    hidden={props.hidden}
    onDismiss={props.onRequestClose}
    dialogContentProps={{
      type: DialogType.normal,
      title: 'Are you sure?',
      subText: 'Are you sure you want to copy this word?'
    }}
    modalProps={{
      isBlocking: false,
      styles: { main: { maxWidth: 450 } }
    }}
  >
    <DialogFooter>
      <PrimaryButton
        onClick={() => props.onCopy(word)}
        text="Copy"
        disabled={loading || error}
      />
      <DefaultButton onClick={props.onRequestClose} text="Don't copy" />
    </DialogFooter>
  </Dialog>
}