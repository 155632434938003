/**
 * The scale is a measurement of how many pixels we need to represent
 * a single second in the timeline, in other words, the zoom.
 *
 * It then allows conversion between the amount of seconds and the
 * amount of pixels.
 */
import {WaveformData} from "waveform-data";

export class Scale {
  public readonly pixelsPerSecond: number;

  constructor(pixelsPerSecond: number) {
    this.pixelsPerSecond = pixelsPerSecond;
  }

  secondsToPixel(seconds: number): number {
    return seconds * this.pixelsPerSecond;
  }

  pixelsToTime(pixels: number): number {
    return pixels / this.pixelsPerSecond;
  }
}


export function getSamplesPerSecond(data: WaveformData): number {
  return data.adapter.sample_rate / data.adapter.scale;
}

/**
 * Which index in the waveform amplitude array represents the sample at second X?
 */
export function getSampleIndexForTime(seconds: number, data: WaveformData): number {
  return Math.floor(seconds * getSamplesPerSecond(data));
}


// Return the sample index at this time.
export function getTimeAtSampleIndex(sampleIdx: number, data: WaveformData): number {
  return sampleIdx / getSamplesPerSecond(data);
}


/**
 * Returns a string such as:
 *    0:04   (for 4 seconds)
 *    1:04   (for 64 seconds)
 */
export function formatTime(time: number, dropHundredths: boolean = false) {
  const parts: number[] = [];

  const hundredths = Math.floor((time % 1) * 100);
  const seconds = Math.floor(time);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    parts.push(hours); // Hours
  }
  parts.push(minutes % 60); // Mins
  parts.push(seconds % 60); // Seconds

  let result: string[] = [];
  for (var i = 0; i < parts.length; i++) {
    result[i] = zeroPad(parts[i]);
  }

  let finalString = parts.join(':');

  if (!dropHundredths) {
    finalString += '.' + zeroPad(hundredths);
  }
  return finalString;
}


function zeroPad(number: number): string {
  return number < 10 ? '0' + number : ""+number;
}