import * as React from "react";
import {useMemo, Fragment} from "react";
import {TitleForStructureNode, WordIdTitle} from "./parts/WordDisplay";
import {Dictionary} from "./parts/Dictionary";
import {
  Annotation, isQueryByWordId,
  isDefinition,
  isDictionary,
  isInflection,
  isTranslation,
  isWordData, isPronunciation,
} from "../../annotations";
import {Definition} from "./parts/Definition";
import {AnnotationSource, BaseAnnotation} from "../../annotations/base";
import {GrammarDescription} from "./parts/GrammarDescription";
import {shouldRenderGrammar} from "../../langpacks/fa/GrammarDescription";
import {SectionTitle} from "./parts/ContentSection";
import {ISimpleCache} from "../../cache";
import {Panes} from "./parts/Panes";
import {Translation} from "./parts/Translation";
import {NoResultsMessage} from "./Message";
import {ActionButtons} from "./parts/ActionButtons";
import {getAnnotationQueryForPage, getInflectionAnnotationFromPage} from "./FetchAnnotations";
import {Page, pageIsNode, pageIsWordId} from "./Page";
import {View, Text} from 'react-native';
import {PopupCommonStyles} from "./styles";
import { TextBlock } from "../Base/Text";
import {WordDataAnnotation} from "../../annotations/WordData";
import {PronunciationAnnotation} from "../../annotations/Pronunciation";


type Props = {
  // What to show.
  target: Page,

  // The language used; only used a a fallback for when a word has no language attached.
  defaultLanguage: string,
  // The languages the learner knows; translations etc. happen into these target languages.
  learnerLangs: string[],

  cache: ISimpleCache,
  isLoading?: boolean,

  ActionButtons?: ActionButtons
};


export function GrammarExplainer(props: Props & {
  annotations: Annotation[],
  // TODO: What is the difference?
  structureAnnotations: Annotation[],
  titleConfig: RenderSectionTitleConfig,
}) {
  let {cache, annotations, structureAnnotations} = props;

  const grammar = <GrammarOverview
    cache={cache}
    annotations={structureAnnotations}
  />;

  const trans = structureAnnotations.filter(isTranslation);

  const wordDataNode = (annotations || []).filter(isWordData)[0];

  // This will be the title for a word node, we know this because otherwise we would not be asked to render
  let titleText: any;
  if (pageIsNode(props.target)) {
    // wordDataNode is not used at all here!!??
    titleText = <TitleForStructureNode word={props.target} />;
  }

  return renderSectionTitle({
    titleConfig: props.titleConfig,
    // The target will be the structure node here, for sure.
    target: props.target,
    titleText: titleText,
    annotations,
    moreContent: <Fragment>
      {grammar}
      {trans.length ? <TextBlock style={[{marginTop: 10}, PopupCommonStyles.defaultText]}>
        {trans[0].text}
      </TextBlock> : null}
    </Fragment>
  })
}

/**
 * This renders a base lemma lookup of some form, that is, if we are not aware of a grammatical structure.
 */
function BaseLemma(props: Props & {annotations: Annotation[], titleConfig: RenderSectionTitleConfig}) {
  let {annotations} = props;

  let content = <BaseLemmaContent {...props} annotations={annotations} />;

  const wordDataNode = (annotations || []).filter(isWordData)[0];

  // If the page is not a word id, then it was a structure node, but since we want to show a base lemma here,
  // we modify the target to the word behind the structure.
  let actualTarget;
  if (pageIsWordId(props.target)) {
    actualTarget = props.target
  }
  else if (wordDataNode) {
    actualTarget = wordDataNode.target;
  }

  let titleText;
  if (!actualTarget) {
    // This can happen for numeral words for example - in this case we want to render the structure, if we have one,
    // based on what is in the unit - as least until we get back an actual word definition from the server.
    actualTarget = props.target;
    if (pageIsNode(props.target)) {
      titleText = <TitleForStructureNode word={props.target}/>;
    }
  }
  else {
    titleText = <WordIdTitle
      wordId={actualTarget}
      wordData={wordDataNode}
      showWordType={true}
    />;
  }

  // It sometimes happens that the target arrives later with the annotations, until then, do not render.
  const title = (actualTarget && titleText) ? renderSectionTitle({
    titleConfig: props.titleConfig,
    annotations: annotations,
    titleText: titleText,
    target: actualTarget,
  }) : null;

  return <View>
    {title}
    {content}
  </View>
}


/**
 * This renders, given a set of annotations, the popup contents for a particular word.
 */
export function PopupContent(props: Props) {
  // Partly for backwards-compatibility, get the annotations for the word itself (
  const annotations = props.cache.get(
    getAnnotationQueryForPage(props.target,
      {noStructure: true})) || [];

  const structureAnnotations = [
    ...(props.cache.get(getAnnotationQueryForPage(props.target)) || []),
  ];
  const inflect = getInflectionAnnotationFromPage(props.target);
  if (inflect) {
    structureAnnotations.push(inflect);
  }

  let sections = [];

  const mainTitleConfig: RenderSectionTitleConfig = {
    isLoading: props.isLoading,
    placement: 'primary',
    ActionButtons: props.ActionButtons,
  }

  // See if the target has an inflection. If so, we show the grammar at the top, the base lemma below.
  const grammarForms = structureAnnotations.filter(isInflection);
  if (grammarForms.length && shouldRenderGrammar(grammarForms[0])) {
    sections.push(
      <GrammarExplainer {...props}
        titleConfig={mainTitleConfig}
                        annotations={annotations} structureAnnotations={structureAnnotations}
      />
    );
    sections.push(<BaseLemma {...props} annotations={annotations} titleConfig={{placement: 'secondary'}} />);
  }

  // Otherwise, we are simply rendering the results as a base lemma.
  else {
    sections.push(
      <BaseLemma
        {...props}
        annotations={annotations}
        titleConfig={mainTitleConfig}
      />
    )
  }

  return <View>
    {sections.map((section, idx) => {
      return <Container backgroundColor={(sections.length > 1 && idx == 0) ? undefined : "white"} key={idx}>
        {section}
      </Container>
    })}
  </View>
}


type RenderSectionTitleConfig = {
  isLoading?: boolean,
  ActionButtons?: ActionButtons,
  placement: 'primary'|'secondary',
}

/**
 * This renders the title of a popup section, via a <SectionTitle>. Ads a bit of logic to assemble the parts.
 */
function renderSectionTitle(
  props: {
    // This is the actual title text (and any extra subtitle content).
    titleText: any,
    moreContent?: any,

    // Could possibly be flattened. Props that are changed by the caller of the caller.
    titleConfig: RenderSectionTitleConfig

    // All known annotations? We have to be more clear about which ones we want here.
    annotations?: Annotation[],

    // What object this section represents. Is really either a grammar/structure node, or a word.
    target: Page,
  }
) {
  const {annotations, moreContent, target, titleConfig} = props;

  // Instantiate the action button component.
  let actions;
  if (titleConfig.ActionButtons) {
    actions = <titleConfig.ActionButtons
      placement={titleConfig.placement}
      target={target}
      annotations={annotations}
    />;
  }

  return <SectionTitle
    isLoading={titleConfig.isLoading}
    isSmall={titleConfig.placement != "primary"}
    audioAnnotations={annotations?.filter(a => {
      if (!isPronunciation(a)) {
        return false;
      }
      // If the annotation has no target node, the meaning is that it targets the current page.
      if (!a.target) {
        return true;
      }
      if (isQueryByWordId(a.target) && pageIsWordId(target) && isQueryByWordId(target) && a.target.id == target.id) {
        return true;
      }
    }) as PronunciationAnnotation[]}
    title={props.titleText}
    moreContent={moreContent}
    actions={actions}
  />
}


export function BaseLemmaContent(props: {
  defaultLanguage: string,
  annotations: Annotation[],
  isLoading?: boolean,
  allInitiallyExpanded?: boolean,

  target?: Page,
}) {
  const annotations = props.annotations || [];

  const translations = annotations.filter(isTranslation);
  const sourceLangDefinitions = annotations.filter(isDefinition).filter(def => !def.lang || def.lang == props.defaultLanguage);
  const readerLangDefinitions = annotations.filter(isDefinition).filter(def => def.lang && def.lang != props.defaultLanguage);

  const sourceLangDictionaries = groupBySource(
    annotations.filter(isDictionary).filter(def => !def.lang || def.lang == props.defaultLanguage)
  );
  const readerLangDictionaries = groupBySource(
    annotations.filter(isDictionary).filter(def => def.lang && def.lang != props.defaultLanguage)
  );

  const extraInfo: PaneDef[] = [];
  const tabs: PaneDef[] = [];

  // There is something for us to show in the source language.
  if (sourceLangDefinitions.length) {
    tabs.push({
      label: 'Source',
      component: <Definition
        definitions={sourceLangDefinitions}
      />
    })
  };

  if (readerLangDefinitions.length) {
    extraInfo.push({
      source: normalizeSource(readerLangDefinitions[0].source),
      component: <View style={{marginTop: 10}}><Definition
        definitions={readerLangDefinitions}
      /></View>
    })
  };

  // Maybe there is a dictionary entry in the source language?
  sourceLangDictionaries.forEach(dictionaries => {
    tabs.push({
      type: 'dictionary',
      source: normalizeSource(dictionaries[0].source),
      url: dictionaries[0].url,
      component: <Dictionary
        language={props.defaultLanguage}
        dictionaries={dictionaries}
      />
    });
  });

  readerLangDictionaries.forEach(dictionaries => {
    tabs.push({
      type: 'dictionary',
      source: normalizeSource(dictionaries[0].source),
      url: dictionaries[0].url,
      component: <Dictionary
        language={props.defaultLanguage}
        dictionaries={dictionaries}
      />
    });
  });

  if (translations.length) {
    tabs.push({
      source: normalizeSource(translations[0].source),
      component: <Translation
        translations={translations}
      />
    });
  }

  const final = [];

  // We want a different padding for those than the tabs
  if (extraInfo.length >= 0) {
    final.push(<View  key={"info"}>
      {extraInfo.map(info => {
        return <View style={{marginTop: 10}}>
          {info.component}
        </View>
      })}
    </View>)
  }

  if (tabs.length >= 0) {
    final.push(<View style={{marginTop: 20}} key={"tabs"}>
      <PanesWithSmartAutoExpand
        panes={tabs}
        autoExpandKey={props.target}
        allInitiallyExpanded={props.allInitiallyExpanded}
      />
    </View>)
  }

  if (final.length) {
    return <>{final}</>;
  }

  // If the reason we found nothing is because we are still loading, skip for now. A loading indicator is visible elsewhere.
  if (props.isLoading) {
    return null;
  }

  return <View style={{marginTop: 20}}><NoResultsMessage /></View>;
}


type PaneDef = {
  type?: string,
  label?: string,
  source?: AnnotationSource,
  component: any,
  url?: string,
};


function PanesWithSmartAutoExpand(props: {
  panes: PaneDef[],
  autoExpandKey: any,
  allInitiallyExpanded?: boolean,
}) {
  // Order tabs by source preference; also affects what is auto-expanded
  const SourceAdjustments: {[key: string]: number} = {
    'aryanpour': -50,
    'google-translate': -50,
    'langenscheidt': 10,
    'wiktionary': 10,
    'farsi.school': 50,
  }

  const withScores = props.panes.map(pane => {
    return {
      pane,
      score: SourceAdjustments[pane.source ? pane.source.id : ""] || 0
    };
  });

  withScores.sort((a, b) => {
    let aScore = a.score;
    let bScore = b.score
    return bScore - aScore;
  });

  type T = typeof withScores;
  const maxScoredItems = withScores.reduce<T>((base, item) => {
    if (base.length && item.score < base[base.length-1].score) {
      return base;
    }
    else {
      base.push(item);
      return base;
    }
  }, []);

  const defaultExpandedPanes = useMemo(() => {
    if (props.allInitiallyExpanded) {
      return props.panes.map((pane, idx) => `${pane.source ? pane.source.id : idx}`);
    }
    return maxScoredItems.map(({pane}, idx) => `${pane.source ? pane.source.id : idx}`)
  }, [props.autoExpandKey, props.panes]);

  // We do not want to show panes to early as otherwise "defaultExpandedPanes" does no longer trigger.
  if (!props.panes.length) {
    return null;
  }

  return <Panes
    defaultExpandedPanes={defaultExpandedPanes}
    panes={
      withScores.map((item, idx) => {
        let tab = item.pane;
        return {
          key: `${tab.source ? tab.source.id : idx}`,
          source: tab.source,
          type: tab.type,
          url: tab.url,
          label: tab.label,
          content: tab.component,
          hideTitle: idx == 0 && (tab.source && tab.source.id) === 'farsi.school'
        }
      })
    }
  />
}


/**
 * Used when a node requires it's grammar explained.
 *
 * From the annotations, will find the "inflection annotation".
 */
function GrammarOverview(props: {
  annotations: Annotation[],
  cache?: ISimpleCache,
}) {
  const grammarForms = props.annotations.filter(isInflection);
  if (grammarForms.length) {
    const shouldRender = grammarForms.filter((g: any) => shouldRenderGrammar(g)).length > 0;
    if (shouldRender) {
      let grammar = <GrammarDescription
        forms={grammarForms}
        relatedAnnotations={props.cache}
      />;

      return <View style={{marginTop: 10}}>
        {grammar}
      </View>
    }
  }

  return null;
}

/**
 * A "section" within the popup.
 */
export function Container(props: {
  backgroundColor?: string,
  children: any
}) {
  return <View style={{padding: 15, backgroundColor: props.backgroundColor ?? '#f5f5f5'}}>
    {props.children}
  </View>
}


function groupBySource<T extends BaseAnnotation>(annotations: T[]) {
  const bySourceId: {[id: string]: T[]} = {};

  for (const annotation of annotations) {
    const sourceId = typeof annotation.source === 'string' ? annotation.source : annotation.source ? annotation.source.id : "";
    if (!bySourceId[sourceId]) {
      bySourceId[sourceId] = [];
    }
    bySourceId[sourceId].push(annotation);
  }

  return Object.values(bySourceId);
}


function normalizeSource(source: undefined|string|AnnotationSource) {
  if (typeof source === 'string') {
    return {
      id: source
    }
  }
  return source;
}
