import {useMutation, useQuery} from "multi-apollo";
import {gql as gqlm} from '@apollo/client';
import {ContentIssuesQuery, ContentIssuesQuery_unit_contentIssues_edges_node} from "../../../types/ContentIssuesQuery";
import {StructureNodeStore} from "../../models/StructureNodeStore";
import {getLineIndexForVocableId} from "../../models/Captions/slateApi";
import {useMemo} from "react";
import {CreateContentIssue} from "../../../types/CreateContentIssue";
import {ContentIssueInputLocation} from "../../../types/globalTypes";
import {SaveContentIssue} from "../../../types/SaveContentIssue";
import {Captions} from "../../models/Captions";


export interface ContentIssue extends ContentIssuesQuery_unit_contentIssues_edges_node {};


const CommonContentIssueFieldsFragment = gqlm`
  fragment CommonContentIssueFields on ContentIssue {
    id,
    description,
    assignedTo { id, name }
    isResolved,
    tags,
    location {
      ... on ContentIssueLocationLine {
        lineIndex
      }
      ... on ContentIssueLocationNode {
        nodeId
      }
      ... on ContentIssueLocationVocable {
        vocableId
      }
    }
  }
`


const UnitContentIssuesQuery = gqlm`
  ${CommonContentIssueFieldsFragment}
  query ContentIssuesQuery($unitId: ID!) {
    unit(id: $unitId) {
      id,
      contentIssues(includeRecentlyResolved: true) @connection(key: "contentIssues")  {
        edges {
          node {
            ...CommonContentIssueFields
          }
        }
      }        
    }
  }
`;


/**
 * Load the content issues for this file. Returns them as a list.
 */
export function useContentIssues(unitId: string|undefined|null): [ContentIssue[], boolean] {
  // The first time this is called on the thing, we reload it.
  const {data, loading, error} = useQuery<ContentIssuesQuery>(UnitContentIssuesQuery, {
    client: 'market',
    skip: !unitId,
    variables: {
      unitId: unitId,
    }
  });

  let fixedData: ContentIssue[];
  if (data) {
    fixedData = data.unit?.contentIssues?.edges?.map(edge => edge?.node!) || [];
  }
  else {
    fixedData = [];
  }

  return [fixedData, loading];
}


/**
 * A mutation to mark a content issue as resolved.
 */
export function useMarkIssueAsResolved() {
  const [func, _] = useMutation(gqlm`    
    mutation SetContentIssueResolvedState($issueId: ID!, $isResolved: Boolean!) {
      setContentIssueResolvedState(issueId: $issueId, isResolved: $isResolved) {
        contentIssue {
          id,
          isResolved
        }
      }
    }
  `,
    {
      client: 'market',
      variables: {}
    });
  return func;
}


export function useCreateContentIssue() {
  const [createContentIssue, _] = useMutation<CreateContentIssue>(gqlm`
    ${CommonContentIssueFieldsFragment}
     mutation CreateContentIssue($props: CreateContentIssueProps!) {
       createContentIssue(props: $props) {
         contentIssue {
           id,
           ...CommonContentIssueFields,
           unit {
            id
           }
         }
       }
     }
  `, {
    client: 'market',
  });

  return (props: {
    description: string,
    captionTrackVersionId: string,
    location: ContentIssueInputLocation
  }) => createContentIssue({
    variables: {
      props: {
        description: props.description,
        captionTrackVersionId: props.captionTrackVersionId,
        location: props.location
      }
    },
    update(cache, result) {
      const unitId = result?.data?.createContentIssue.contentIssue.unit.id;
      const existingRecord = cache.readQuery<ContentIssuesQuery>({ query: UnitContentIssuesQuery, variables: {unitId: unitId} });
      if (!existingRecord) { return; }
      existingRecord.unit?.contentIssues?.edges?.push({
        __typename: "ContentIssueOnUnitEdge",
        node: result?.data?.createContentIssue.contentIssue!
      });
      cache.writeQuery({
        query: UnitContentIssuesQuery,
        data: existingRecord
      });
    }
  });
}


export function useSaveContentIssue() {
  const [saveContentIssue, _] = useMutation<SaveContentIssue>(gqlm`
     mutation SaveContentIssue($props: SaveContentIssueProps!) {
       saveContentIssue(props: $props) {
         contentIssue {
           id,
           description
         }
       }
     }
  `, {
    client: 'market',
  });

  return (props: {
    description: string,
    issueId: string
  }) => saveContentIssue({
    variables: {
      props: {
        description: props.description,
        id: props.issueId
      }
    }
  });
}


export type IndexedContentIssue = {
  issue: ContentIssue,
  lineIndex: number|null,
  targetAvailable: boolean
}
export type IndexedContentIssues = {
  byLine: {[line: number]: IndexedContentIssue[]},
  byVocable: {[line: string]: IndexedContentIssue[]},
  unresolvedByLine: {[line: number]: IndexedContentIssue[]},
  asList: IndexedContentIssue[]
};


/**
 * Return the content issues for a file, indexed in various ways for access.
 */
export function useIndexedContentIssues(unitId: string|undefined|null, track: Captions|undefined): [IndexedContentIssues|undefined, boolean] {
  const [contentIssues, loading] = useContentIssues(unitId);

  const contentIssuesByLineIndex = useMemo(() => {
    if (!track) {
      return;
    }

    const byLine: {[line: number]: IndexedContentIssue[]} = {};
    const byVocable: {[line: string]: IndexedContentIssue[]} = {};
    const unresolvedByLine: {[line: number]: IndexedContentIssue[]} = {};
    const asList: IndexedContentIssue[] = [];

    contentIssues?.forEach(issue => {
      const structureNodes: StructureNodeStore = track.nodes;
      let lineIndex: number|null;
      let vocableId: null|string = null;
      if ("nodeId" in issue.location) {
        const node = structureNodes.getNodeById(issue.location.nodeId);
        lineIndex = node ? getLineIndexForVocableId(track.lines, node?.vocables[0]!) : null;
      }
      else if ("lineIndex" in issue.location) {
        lineIndex = issue.location.lineIndex;
      }
      else if ("vocableId" in issue.location) {
        lineIndex = getLineIndexForVocableId(track.lines, issue.location.vocableId);
        vocableId = issue.location.vocableId;
      }
      else {
        return;
      }

      const indexedIssue: IndexedContentIssue = {
        issue,
        lineIndex: lineIndex,
        targetAvailable: lineIndex !== null
      };

      asList.push(indexedIssue);

      if (vocableId) {
        byVocable[vocableId] = byVocable[vocableId] || [];
        byVocable[vocableId].push(indexedIssue);
      }

      if (lineIndex) {
        byLine[lineIndex] = byLine[lineIndex] || [];
        byLine[lineIndex].push(indexedIssue);
        if (!indexedIssue.issue.isResolved) {
          unresolvedByLine[lineIndex] = byLine[lineIndex] || [];
          unresolvedByLine[lineIndex].push(indexedIssue);
        }
      }
    });

    return {
      byLine,
      byVocable,
      unresolvedByLine,
      asList
    };
  }, [contentIssues, track]);

  return [contentIssuesByLineIndex, loading];
}