import {createEditor, Path, Transforms} from 'slate';
import Bacon from 'baconjs';
import {useEffect, useMemo, useRef, useState} from "react";
import {CaptionTrackRoot} from "./Captions/types";
import {Captions} from "./Captions";
import {withSchema} from "../ui/CaptionEditor/slate-plugins/withSchema";
import {withVocableControl} from "../components/VocableBasedSlate/withVocableControl";
import {IdGenerator, KeyGenEditor, withKeyGen} from "../components/VocableBasedSlate/withKeyGen";
import {ReactEditor, withReact} from 'slate-react';
import {HistoryEditor, withHistory} from 'slate-history';
import {getObjectId} from "../utils";


/**
 * This is a limited interface we use in various places of the app (which are not the Slate.js editor itself),
 * to modify the captions (= the Slate.js editor value). In many cases, this will be a simple wrapper around
 * the Slate.js "editor"/"controller" value.
 *
 * It exists mainly because right now we essentially have to push up the editor react control up the tree,
 * then use it across the app to modify the document with Undo support. We'd rather be able to modify the
 * document even if the Slate.js editor is not in the DOM. This exists to not too tightly couple the editor.
 *
 * Recent Slate.js versions (starting with 0.50) decouple the editor control value, but we still need our custom
 * interface supporting an observable, the shadow value etc.
 */
export interface ICaptionsEditor {
  // Like slate.js: editor.value. If a shadow value is set, it overrides the committed value here.
  value: CaptionTrackRoot,

  // The last committed value - ignore any shadow value set.
  committedValue: CaptionTrackRoot,

  // Like slate.js: editor.setNodeByPath
  setNodeByPath(path: Path, properties: any): CaptionTrackRoot,

  // This is a helper to set a temporary value. Useful for overriding something during a drag&drop operation,
  // which then needs to be resettable "as a whole". For example, drag & drop night cause side-effects to
  // the time/duration values of sibling nodes, but those changes are "elastic" while the drag&drop is going on.
  setShadowValue(value: CaptionTrackRoot|null): void,

  // Set the ciommited value
  setValue(value: CaptionTrackRoot|null): void,

  // Listen to changes of the visible value.
  observe(): Bacon.Property<any, CaptionTrackRoot>

  // Direct access to the editor object itself.
  slateEditor: SlateEditor
}


export type SlateEditor = ReactEditor & HistoryEditor & KeyGenEditor;


/**
 * Create the headless Slate.js editor object. Slate.js decouples this from the actual UI controls.
 */
export function createSlateEditor(opts?: {idGen?: IdGenerator}): SlateEditor {
  return withReact(
    withHistory(
      withSchema(
        withVocableControl(
          withKeyGen(
            createEditor(),
            {idGen: opts?.idGen}
          )
        )
      )
    )
  );
}

/**
 * createSlateEditor() as a hook.
 */
export function useSlateEditor(opts?: {idGen?: IdGenerator}, deps?: React.DependencyList) {
  return useMemo(() => createSlateEditor(opts), deps ?? []);
}


// NB: A difficult to debug issue here:
// - You use the Slate.js editor API to make a change
// - Slate.js schedules the onChange callback for the next tick (they call it flush).
// - You do something else which causes the editor to re-render.
// - You do something else which causes the editor to re-render.
// - Now, slate.js basically gets the old caption state set as a prop. since it is different, it
//   overrides the to-be-flushed state.


type BaconBusAndPropertyPair<T> = {
  bus: Bacon.Bus<any, T>,
  prop: Bacon.Property<any, T>,
  value: T
};


/**
 * Creates a ref which contains a Bacon Bus and a Bacon Property hooked up to the bus.
 *
 * This allows us to send data to that bus, and have other parts subscribe to it.
 *
 * Note that the return object will remain stable as long as the dependencies do, despite
 * the `.value` property updating.
 */
function useBaconBusPropertyPair<T>(defaultValue: T, deps: any[]): BaconBusAndPropertyPair<T> {
  const pair = useRef<BaconBusAndPropertyPair<T>|null>(null);

  pair.current = useMemo(() => {
    const bus = new Bacon.Bus<any, T>();
    const prop = bus.toProperty(defaultValue);
    return {bus, prop, value: defaultValue};
  }, deps);

  // To allow for sync access, because Bacon does not provide this option for Properties
  useEffect(() => {
    return pair.current!.prop.onValue(v => {
      pair.current!.value = v;
    })
  }, [pair.current]);

  return pair.current;
}


/**
 * Subscribe to both the committed value and the shadow value, and return what should
 * currently be rendered.
 */
function useVisibleCaptionTrackState(
  committedValuePair: BaconBusAndPropertyPair<CaptionTrackRoot>,
  shadowValuePair: BaconBusAndPropertyPair<CaptionTrackRoot|null>
) {
  const [captionsTrack, setCaptionsTrackLowLevel] = useState<CaptionTrackRoot|null>(null);
  useEffect(() => {

    const visible$ = committedValuePair.prop.combine(shadowValuePair.prop.debounce(200), (c, s) => {
      return s ? s : c;
    }).skipDuplicates();

    return visible$.onValue(v => {
      setCaptionsTrackLowLevel(v)
    });
  }, [committedValuePair, shadowValuePair]);

  return captionsTrack;
}


/**
 * This takes a reference to a slate.js `Editor`, and wraps it into a `ICaptionsEditor` interface, which
 * is our" simplified and extended object we like to pass around instead.
 *
 * Makes sure that any change to the value is synced to the `captions` object.
 */
function useEditorAPI(
  committedValuePair: BaconBusAndPropertyPair<CaptionTrackRoot>,
  shadowValuePair: BaconBusAndPropertyPair<CaptionTrackRoot|null>,
  editorRef: SlateEditor|null,
  captions: Captions,
): ICaptionsEditor|null {
  // Push any change from there back to the editor
  useEffect(() => {
    const handler = (lines: CaptionTrackRoot) => {
      committedValuePair.bus.push(lines);
    }
    captions.on('linesChanged', handler);
    return () => { captions.off('linesChanged', handler) };
  }, [captions]);

  return useMemo(() => {
    if (!editorRef) {
      return null;
    }

    // Combine the two pairs
    // NB: The shadow track can change very often, and very quickly, more than we are able to update the slate
    // editor. We debounce/throttle for performance. Note: that since the shadow track really only ever
    // changes the time codes, it might be in general much better to hold those separately, or at least the
    // shadow values separately, since it is really only a display issue with regards to the times. However,
    // note that right now we get undo/redo for free with Slate.js. (TODO).
    let visible$ = committedValuePair.prop.combine(shadowValuePair.prop.debounce(5), (c, s) => {
      return s ? s : c;
    })
    visible$ = visible$.skipDuplicates();

    return {
      get value() {
        return shadowValuePair.value || committedValuePair.value;
      },
      get committedValue() {
        return committedValuePair.value!;
      },
      setNodeByPath(path: Path, properties: any) {
        Transforms.setNodes(editorRef!, properties, {at: path})
        // Return the new value right away
        return editorRef!.children as CaptionTrackRoot;
      },
      setShadowValue(value: CaptionTrackRoot): void {
        shadowValuePair.bus.push(value);
      },
      setValue(value: CaptionTrackRoot): void {
        // NB: While we have a shadow value, do not do this. It sometimes happens that Slate.js
        // triggers an onChange after we set a shadow value, which would then cause us to save the
        // shadow value as the committed one. This has a lot of bad effects: It messes with undo,
        // it causes subsequent shadow changes to be based on that now "committed" value during
        // drag&drop etc.
        if (shadowValuePair.value) {
          return;
        }
        committedValuePair.bus.push(value);

        // Sync it to the captions
        captions.setLines(value);
      },
      observe() {
        return visible$;
      },
      // There is a difference here between slate:Editor and slate-react:Editor, didn't quite figure it out yet,
      // hence the any cast.
      slateEditor: (editorRef as any)
    }
  }, [editorRef, committedValuePair, shadowValuePair]);
}


/**
 * - Returns an onChange handler for you to give to slate.js.
 * - Writes any changes coming in to the `captions` object.
 *
 * And:
 *
 * - Wraps the slate.js editor controller with an ICaptionsEditor API.
 *
 * And:
 *
 * Returns a caption track object that is either the current one from the editor, or the shadow
 * one that can be enabled via the ICaptionsEditor interface.
 */
export function useCaptionsEditor(captions: Captions, editor: SlateEditor): ICaptionsEditor|null {
  // Have two Bacon properties, one for the regular captions data (because we want to be able to subscribe to it),
  // another one for the "shadow overlay".
  const committedValuePair = useBaconBusPropertyPair(captions.lines, [captions]);
  const shadowValuePair = useBaconBusPropertyPair<CaptionTrackRoot|null>(null, [captions]);

  // Construct an editor API we can pass around to allow modifying the captions, by wrapping
  // the Slate.js editor interface.
  return useEditorAPI(committedValuePair, shadowValuePair, editor, captions);
}


/**
 * Return whatever the current document value is => either the shadow value, or the main one.
 * TODO: Replace this with useBaconObservable().
 */
export function useCaptionTrackFromEditor(editor: ICaptionsEditor|null): CaptionTrackRoot|null {
  const [captionsTrack, setCaptionsTrack] = useState<CaptionTrackRoot|null>(null);

  // If the captions are changed from the outside, we take that change.
  useEffect(() => {
    if (!editor) {
      return;
    }

    return editor.observe().onValue(captions => {
      setCaptionsTrack(captions);
    });
  }, [editor]);

  return captionsTrack;
}
