type WordRef = {
  id: number|null|true
};


// The data type used by the form
export type FormValues = {
  type: string,
  base: string,
  sounds: string,
  stems: string,

  // For verbs
  presentStem: string,
  pastStem: string,
  isCompound: boolean,
  primary?: (WordRef|FormValues)[],
  vector?: WordRef|FormValues,
  prefixes: string,
  irregularPresentStems: string,
  irregularPastStems: string,

  // For numerals
  quantity: string,

  // For plurals
  plurals: string,

  // For pronouns
  pluralEnabled: boolean,

  // For prepositions
  ezafe: 'included'|'disallowed'|'optional'|'default',

  // For alias
  aliasType: string,
  aliasWordRef: number,

  ignoreWarnings: boolean,
  description: string,
  meaningDescriptor: string,
};


/**
 * Has to decide if a particular `primary` or `vector` field in `FormValues`
 * is a word reference of a sub form.
 *
 * Part of the difficulty here is that we have to be able to support
 * "word reference, but without a value yet".
 *
 * However, due to a Formik issue, when initializing the form, we have to
 * provide all possible value fields, so we cannot just make the answer
 * dependant on whether a field exists or not.
 *
 * The hack is that id=true means we are in word reference mode.
 */
export function isNestedForm(x: WordRef|FormValues|null|undefined): x is FormValues {
  if (!x || isWordRefEnabled((x as WordRef)["id"])) {
    return false;
  }
  return true
}

/**
 * Given the id field of a WordRef, are we in nested form mode or word reference mode?
 *
 * A number indicates a reference, as does "true" (a as-of-yet unspecified reference).
 *
 * "null" indicates that there is instead a nested form.
 */
export function isWordRefEnabled(wordRefId: number|null|true) {
  return !!wordRefId;
}