import React, {Fragment} from 'react';
import {Button, DialogContentText} from "@material-ui/core";
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {BaseDialog} from "../../../ui/dialogs/DialogManager";
import {useMutation, useQuery} from 'multi-apollo';
import gqlm from 'graphql-tag';
import styles from './ManageDialog.cssm';
import {TaskKind, TaskStatus, TaskStatusIcon} from "./Labels";
import {
  GetManageDialogTasks,
  GetManageDialogTasks_tasks,
  GetManageDialogTasks_tasks_edges_node
} from "../../../../types/GetManageDialogTasks";



type Props = {
  isOpen: boolean,
  onRequestClose: () => void,

  unitId: string,
  onOK: (data: {startWithSelected: boolean}) => void,
}


const GET_TASKS = gqlm`
  query GetManageDialogTasks($unitId: ID!) {
    tasks(types: ["captioning", "grammar"], unitId: $unitId) {
      edges {
        node {
          id,
          kind,
          reportedTime,
          status,
          description
        }
      }
    }
  }
`;


const SET_TASK_STATUS = gqlm`
  mutation DialogSetTaskStatus($taskId: ID!, $status: TaskStatus!, $time: Int) {
    setTaskStatus(taskId: $taskId, status: $status, reportedTime: $time) {
      task {
        id,
        status,
        reportedTime
      }
    }
  }
`;


export function ManageDialog(props: Props) {
  return (
    <BaseDialog
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      title={"Manage Tasks"}
      actions={[
        <Button key="cancel" onClick={props.onRequestClose} color="primary">
          Cancel
        </Button>
      ]}
    >
      <Content unitId={props.unitId} />
    </BaseDialog>
  );
};



function Content(props: {
  unitId: string
}) {
  const { data, error, loading } = useQuery<GetManageDialogTasks>(
    GET_TASKS, {variables: {unitId: props.unitId}, client: 'market'});

  if (loading) {
    return <div>Loading...</div>;
  };
  if (error) {
    return <div>Error! {error.message}</div>;
  };

  const tasks = data?.tasks?.edges?.map((e: any) => e.node) ?? [];
  return <div>
    {tasks.length == 0 ? "No current tasks" : null}

    {tasks.length > 0 ? <>
      <TaskList tasks={tasks} />
      </> : null}
  </div>;
}



function TaskList(props: {tasks: GetManageDialogTasks_tasks_edges_node[]}) {
  const [setStatus, ] = useMutation(SET_TASK_STATUS, {client: 'market'});

  const list = props.tasks.map((task: any) => {
    return <Fragment key={task.id}>
      <div>
        <TaskKind kind={task.kind} />
      </div>
      <div>
        <ToggleButtonGroup
          value={task.status}
          exclusive
          onChange={(event, newStatus) => {
            // Always require one selection
            if (!newStatus) {
              return;
            }

            let minutes: string|null = null;
            if (newStatus == 'review') {
              while (true) {
                minutes = prompt("How many minutes are you billing for this task?");
                if (!isNaN(minutes as any)) {
                  break
                }
              }
            }
            setStatus({
              variables: {
                taskId: task.id,
                status: newStatus,
                time: minutes !== null ? parseInt(minutes) : null
              }
            });
          }}
        >
          <ToggleButton value={"pending"}>
            <TaskStatusIcon status={'pending'} />
          </ToggleButton>
          <ToggleButton value={"started"}>
            <TaskStatusIcon status={'started'} />
          </ToggleButton>
          <ToggleButton value={"review"}>
            <TaskStatusIcon status={'review'} />
          </ToggleButton>
        </ToggleButtonGroup>

        <TaskStatus status={task.status} />

        {task.reportedTime ? <>{" "}({task.reportedTime} mins)</> : null}

      </div>
      <div className={styles.description}>
        {task.description}
      </div>
    </Fragment>
  })

  return <div className={styles.taskList}>{list}</div>
}