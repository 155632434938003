import React, { useMemo, useState } from 'react';
import {useQuery} from "multi-apollo";
import { gql as gqlw } from "@apollo/client";
import {RecordingsOfWordQuery} from "../../types/RecordingsOfWordQuery";
import { Button, IColumn, DetailsList, SelectionMode, Icon, IconButton } from 'office-ui-fabric-react';
import {useAudioPlayer} from "languagetool-player/src/ui/Audio/useAudioPlayer";
import {RecorderControls} from "../RecordingWorkflow/RecordingItem";
import {RecordingItemDefinition, RecordingPropsBundle} from "../RecordingWorkflow/ListControl";
import {useAudioContext} from "../../components/Audio/Visualizer";
import {useMediaRecorder} from "../../components/Audio/Recorder";
import {useUploader} from "../RecordingWorkflow/useUploader";


function useRecordingsQuery(wordId: number) {
  const {data, loading, error} = useQuery<RecordingsOfWordQuery>(gqlw`
    query RecordingsOfWordQuery($wordId: Int!) {
      word(id: $wordId) {
        id,
        recordings {
          id,
          processedUrl: url(key: "main"),
          originalUrl: url(key: "original"),
          wasProcessed,
          speaker {
            id,
            user {
              id,
              username
            }
          },
          session {
            id,
            isOpen,
            isAccepted                       
          }
        }
      }
    }
  `, {
    variables: {
      wordId
    }
  });
  return {recordings: data?.word?.recordings, loading, error};
}



export function RecordingsTab(props: {word: {id: number}, reload: any}) {
  const {recordings, error} = useRecordingsQuery(props.word.id);
  const player = useAudioPlayer();

  if (error) {
    return <div>Error loading.</div>
  }

  const columns: IColumn[] = [
    {
      key: 'sessionStatus',
      name: 'Session',
      minWidth: 30,
      maxWidth: 100,
      onRender: (item: any) => {
        return <div>
          {item.session?.isOpen ? <Icon iconName="SkypeCircleClock" style={{color: "pink"}} /> : null}
          {!item.session ? null : item.session?.isAccepted ? <Icon iconName="Checkmark" style={{color: "green"}} /> : <Icon iconName="Cancel" style={{color: "red"}} />}
        </div>
      },
    },
    {
      key: 'sessionId',
      name: 'Session ID',
      minWidth: 30,
      maxWidth: 80,
      onRender: (item: any) => { return item.session?.id; },
    },
    {
      key: 'url',
      name: 'URL',
      minWidth: 30,
      maxWidth: 100,
      onRender: (item: any) => { return <>
        <div>
          <IconButton iconProps={{iconName: "BoxPlaySolid"}}  onClick={() => { player.play(item.originalUrl); }}/>
          <a href={item.originalUrl} title={item.id}>Original</a>
        </div>
        {item.wasProcessed  ? <div>
          <IconButton iconProps={{iconName: "BoxPlaySolid"}}  onClick={() => { player.play(item.processedUrl); }}/>
          <a href={item.processedUrl} title={item.id}>Processed</a>
        </div> : null}
      </> },
    },
    {
      key: 'recordingId',
      name: 'Recording ID',
      minWidth: 30,
      maxWidth: 50,
      onRender: (item: any) => { return item.id.slice(0, 6) },
    },
    {
      key: 'user',
      name: 'Speaker',
      minWidth: 40,
      maxWidth: 60,
      onRender: (item: any) => { return item.speaker.user.username },
    },
    {
      key: 'actions',
      name: 'Actions',
      minWidth: 40,
      maxWidth: 150,
      onRender: (item: any) => { return <>
        <Button>Process</Button>
      </>},
    },
  ];

  const items = recordings ?? [];

  const table = <DetailsList
    items={items}
    columns={columns}
    selectionMode={SelectionMode.none}
  />

  return <div>
    <InlineRecordingOption wordId={props.word.id} />

    {table}
  </div>
}


function InlineRecordingOption(props: {
  wordId: number
}) {
  const [controlsVisible, setControlsVisible] = useState(false);
  const [, uploader] = useUploader(null);

  const audioContext = useAudioContext();
  const analyzer = useMemo(() => { return audioContext.createAnalyser(); }, [audioContext]);

  const {isRecording, controller, getCurrentBlob, numChunks} = useMediaRecorder({
    disabled: false,
    onSetup: async (stream) => {
      // They are suspended if there was no user-interaction ont he page yet.
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyzer);
    }
  });

  const stateBundleForActiveItem = useMemo<RecordingPropsBundle>(() => {
    return {
      canRecord: true,
      record: () => controller.start(),
      stop: () => controller.stop(),
      getBlob: getCurrentBlob,
      isRecording,
      analyzer
    }
  }, [controller, getCurrentBlob, isRecording, analyzer]);


  const item: RecordingItemDefinition = {
    isComplete: false,
    existingRecordings: [],
    word: {
      id: props.wordId
    } as any
  };

  return <div style={{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }}>
    {!controlsVisible
      ? <Button onClick={() => setControlsVisible(true) }>Add Recording</Button>
      : <>
        <RecorderControls recorder={stateBundleForActiveItem} item={item} />
        {numChunks > 0 ? <Button onClick={async () => {
          await uploader.uploadFile(item.word.id, getCurrentBlob()!);
          alert("Done!")
        }}>Upload</Button> : null}
      </>}
  </div>
}