/**
 * To inoculate us from changes in the Slate.js API, which seem to happen quite frequently, we prefer to
 * use these helpers where possible, rather than accessing the Immutable.js data structures directly.
 */

import {Node, Text, Path, Transforms, Editor} from "slate";
import {getLineIndexFromLineRef, LineRef} from "./lineRef";
import {ICaptionOps} from "./opsInterface";
import {isEmpty} from "../../utils";
import {getLineIndexForTime, LineIndexResult} from "languagetool-player/src/models";
import {
  CaptionElement,
  CaptionTrackRoot,
  isLineGroup,
  isWordInline,
  LineData,
  LineGroup,
  WordInline
} from "./types";
import {SlateEditor} from "../ICaptionsEditor";
import {VocableId} from "languagetool-player/src/models/formats/CaptionTrack";


/**
 * Returns the `LineGroup` Slate.js object.
 */
export function getLineGroup(captions: CaptionTrackRoot, index: number): LineGroup {
  if (typeof index === 'object' && isLineGroup(index)) {
    return index;
  }

  return captions[index];
}


export function getLineCount(captions: CaptionTrackRoot): number {
  return captions.length;
}

export function getText(line: CaptionElement): string {
  return Array.from(Node.texts(line)).map(x => x[0].text).join("")
}

/**
 * Return the currently selected line index.
 */
export function getSelectedLineGroupIndex(captions: SlateEditor, defaultIdx?: number): number | null {
  const selectionPath = captions.selection?.anchor.path;
  if (!selectionPath) {
    if (!defaultIdx) {
      return null;
    }

    return defaultIdx;
  }

  // Top-most entry in the path is the line (group).
  return selectionPath[0];
}


export function* getNextLinesWithTime(captions: CaptionTrackRoot, lineIndex: number): Iterable<number> {
  for (let i = lineIndex + 1; i < captions.length; i++) {
    const {time: startTime, duration} = getLineGroup(captions, i).data;
    if (!isEmpty(startTime)) {
      yield i;
    }
  }
}


export function* getPreviousLinesWithTime(captions: CaptionTrackRoot, lineIndex: number): Iterable<number> {
  for (let i = lineIndex - 1; i >= 0; i--) {
    const {time: startTime, duration} = getLineGroup(captions, i).data;
    if (!isEmpty(startTime)) {
      yield i;
    }
  }
}


/**
 * Low-level function to modify the start or end time of a particular line.
 */
export function setLineData(editor: ICaptionOps, lineRef: LineRef, data: null | Partial<LineData>) {
  const lineIdx = getLineIndexFromLineRef(editor.value, lineRef);

  if (data && data.time !== undefined && data.time < 0) {
    data.time = 0;
  }

  let newData = {...editor.value[lineIdx].data};
  if (!data) {
    delete newData.duration;
    delete newData.time;
  } else {
    newData = {...newData, ...data}
  }

  editor.setNode([lineIdx], {
    data: newData
  });
}

export function getVocableId(node: WordInline) {
  return node.key;
}


export function* iterateLineWords(linegroup: LineGroup, opts: {reverse?: boolean} = {}): Iterable<[WordInline, Path]> {
  const reverse = opts.reverse;

  for (let lineIdx=0; lineIdx < linegroup.children.length; lineIdx++) {
    const insideLine = linegroup.children[lineIdx];

    let subject = insideLine.children;
    if (reverse) {
      subject = [...subject].reverse();
    }

    for (const [result, path] of Node.nodes(insideLine, {reverse: true})) {
      if (!isWordInline(result)) { continue; }
      yield [result, path];
    }
  }
}


export function getCurrentLine(captions: CaptionTrackRoot, time: number): LineIndexResult {
  // TODO: We can cache this object.
  return getLineIndexForTime({
    length: captions.length,
    getDuration: (index: number): number | null => {
      const node = getLineGroup(captions, index);
      // NB: Some code expects null, not undefined, so be sure to return null.
      return node.data.duration || null;
    },
    getTime: (index: number): number | null => {
      const node = getLineGroup(captions, index);
      // NB: Some code expects null, not undefined, so be sure to return null.
      return node.data.time || null;
    }
  }, time);
}


export function getNextLineIndex(captions: CaptionTrackRoot, lineIndex: number): number | null {
  return lineIndex < captions.length-1 ? lineIndex + 1 : null;
}


export function getPrevLineIndex(captions: CaptionTrackRoot, lineIndex: number): number | null {
  return lineIndex > 0 ? lineIndex - 1 : null;
}


/**
 * Return the Slate.js element that represents the given vocable.
 *
 * This used to be easy and natural in old slate versions, where slate.js maintained that mapping for us.
 * Now, slate.js no longer has keys. For now, we just search the whole document, but we should really
 * keep a mapping around instead.
 */
export function findPathOfVocableIdOrNull(captions: CaptionTrackRoot, vocableId: string): Path|null {
  for (const [node, path] of Node.nodes({children: captions})) {
    if ((node as WordInline).key === vocableId) {
      return path;
    }
  }
  return null;
}
export function findPathOfVocableId(captions: CaptionTrackRoot, vocableId: string): Path {
  const path = findPathOfVocableIdOrNull(captions, vocableId);
  if (!path) {
    throw new Error("vocable not found")
  }
  return path;
}


/**
 * Return the line that this vocable is on.
 */
export function getLineIndexForVocableId(captions: CaptionTrackRoot, vocableId: string): number|null {
  const path = findPathOfVocableIdOrNull(captions, vocableId);
  return path?.[0] ?? null;
}


export function setVocableAlternates(editor: SlateEditor, vocableId: string, alternates: { [key: string]: string|undefined }) {
  const path = findPathOfVocableId(editor.children as CaptionTrackRoot, vocableId);
  const vocableNode = Node.get(editor, path) as WordInline;

  const newData = {...vocableNode.data, alternates};
  Transforms.setNodes(editor, {data: newData}, {at: path});
}


export function setVocableText(editor: SlateEditor, vocableId: string, text: string) {
  const path = findPathOfVocableId(editor.children as CaptionTrackRoot, vocableId);
  // This actually replaces the text inside the path/node.
  Transforms.insertText(editor, text, {at: path});
}
