import React from 'react';
import { PrimaryButton, TextField } from 'office-ui-fabric-react';
import {useAuth0} from "../../utils/Auth0Provider";


export function ToolsTab(props: {word: {id: number}}) {
  return <div>
    <VideoTool word={props.word} />
  </div>
}

function VideoTool(props: {
  word: any
}) {
  const AEHost = 'https://ae.srvpl.de';
  const videoRenderServiceKey = 'daeg3zai6iaregau6eeghoodeeyeiqu9ieW2piedi4Aechie6oreedie3quakasa';
  const wordsBasedUrl = 'https://words-admin.languagetool.xyz';
  // const AEHost = 'http://localhost:3090';
  // const videoRenderServiceKey = 'foobar';
  // const wordsBasedUrl = 'http://localhost:3000';

  const {getTokenSilently} = useAuth0();
  const [active, setActive] = React.useState(false);
  const [fileId, setFileId] = React.useState("");
  const [translation, setTranslation] = React.useState("");
  const urlWithoutAuth = `${wordsBasedUrl}/render/animate-word/${props.word.id}?translation=${translation}`;

  return (
    <div>
      Translation to use: <TextField value={translation} onChange={(e: any) => {
        setTranslation(e.target.value);
      }} />
      <PrimaryButton onClick={async () => {
        setActive(true);
        try {
          const token = await getTokenSilently();
          const url = `${urlWithoutAuth}&token=${token}`
          const result = await fetch(`${AEHost}/api/generate?url=${encodeURIComponent(url)}`, {
            headers: {'Authorization': videoRenderServiceKey}});
          const data = await result.json();

          while (true) {
            try {
              let result2 = await fetch(`${AEHost}/api/status?id=${data.id}`, {
                headers: {'Authorization': videoRenderServiceKey}});
              break;
            } catch (e) {
            }
          }

          setFileId(data.id);
        } catch(e) {
          console.log(e);
          alert("An error occurred");
        } finally {
          setActive(false);
        }
      }} disabled={active}>Create Video</PrimaryButton>

      <a href={urlWithoutAuth} target={"_blank"}>Try First</a>

      {active ? <div>generating...</div> : null}

      {fileId ? <div>
        Download: <a href={`${AEHost}/api/download?id=${fileId}`}>{fileId}</a>
      </div> : null}
    </div>
  )
}
