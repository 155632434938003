/**
 * The captions model is driven by the Slate.js model we use for the text editor.
 *
 * Creating an editor where each word is identifiable is a token is a challenge, and we are glad we could
 * bend Slate.js to our will here. Obviously, the model driving a ready-made text-based editor such as Slate
 * is not our actual captions format, but we have to play the hand we are dealt.
 *
 * We don't want to translate between the Slate.js model and our model on every keystroke, so instead, we
 * let the Slate model drive everything internally, including the media player, wave form renderer and so on.
 */

import {StructureNodeStore} from "../StructureNodeStore";
import {CaptionTrackRoot} from "./types";
import {CaptionTrack as ExternalCaptionTrack} from "languagetool-player/src/models";
import {EventEmitter} from 'events';
import {getMaxId, IdGenerator} from "../../components/VocableBasedSlate/withKeyGen";
import {loadLinesFromExternalCaptionTrack, loadNodesFromExternalCaptionTrack} from "./convert";


export class Captions extends EventEmitter {
  lines: CaptionTrackRoot;
  nodes: StructureNodeStore;
  idGen: IdGenerator;
  id: string;
  versionId: string;

  static fromJSON(track: ExternalCaptionTrack) {
    const lines = loadLinesFromExternalCaptionTrack(track);
    const nodes = loadNodesFromExternalCaptionTrack(track);
    const maxId = getMaxId(track);
    return new Captions(lines, nodes, {maxId});
  }

  constructor(captions?: CaptionTrackRoot, nodes?: StructureNodeStore, opts?: {maxId: number}) {
    super();
    this.idGen = new IdGenerator({initialMax: opts?.maxId});

    // We need to make sure that whatever structure store we are using, it can access the id gen.
    this.nodes = nodes || new StructureNodeStore()
    this.nodes = this.nodes.setIdGen(this.idGen);

    this.lines = captions || getInitialDocumentValue();
    this.id = "";
    this.versionId = "";
  }

  // NB: Does not currently recheck the maximum id.
  setLines(data: CaptionTrackRoot) {
    this.lines = data;
    this.emit('linesChanged', this.lines);
  }

  // NB: Does not currently recheck the maximum id.
  setNodes(data: StructureNodeStore) {
    this.nodes = data;
    this.emit('nodesChanged', this.nodes);
  }
}


// Work around lack of https://github.com/ianstormtaylor/slate/issues/3465
function getInitialDocumentValue(): CaptionTrackRoot {
  return [
    {
      type: 'linegroup',
      key: '1',
      data: {},
      children: [
        {
          type: 'line',
          key: '2',
          children: [
            { text: '' },
          ]
        }
      ],
    }
  ]
}


export * from './convert';
export * from './lineRef';
export * from './opsInterface';
export * from './setLineTimes';
export {removeWordNode} from "./wordNodes";
export {setWordNode} from "./wordNodes";
export {getWordNodeForVocable} from "./wordNodes";
export {setVocableAlternates} from "./slateApi";
export {findPathOfVocableId} from "./slateApi";
export {getPrevLineIndex} from "./slateApi";
export {getNextLineIndex} from "./slateApi";
export {getCurrentLine} from "./slateApi";