/**
 * Text captions for the unit. This consists of two parts:
 *
 * 1. The lyrics, basically subtitle format with per-word time tags.
 *
 * We could basically use something like ELRC here as well, but a JSON structure is easier to parse,
 * and allows us to easily add the futures we desire:
 *
 * - Give elements ids.
 * - Separate out real vocabulary, which we time, from punctuation, which we do not time.
 *
 * 2.
 */


import {StructureNode} from './Node';
import {getLineIndexForTime, LineIndexResult} from "./getLineIndexForTime";
import {CaptionTrack, Line, Vocable, VocableId} from "./formats/CaptionTrack";


// This used to be either "number" or "string", but since we use Slate.js keys to store them on
// the editor side, and those keys are always strings, it makes more sense to go with strings here

// A single element in the subtitles. Usually a word, but could be less. Should not be
// too much more, as this is the smallest unit we have and should be able to represent
// all annotations. For example, "South Africa" could be one or two elements, it likely
// does not matter, since you only want to represent this element as a unit. If it is
// two elements, you would attach a node to the captions which defines them as a proper
// noun.
//
// Donaudampfschifffahrtskapitänsmütze could be multiple elements without whitespace,


export type LineTimeBoundary = {
  start: number,
  end: number,
  nextStart: number|null,
  prevEnd: number|null
}


// This is a generic interface all TextTrack wrappers should implement.
// The idea is that it supports some kind of "browsing" structure, as in:
//
// 1. split into l ines
// 2. split into pages
//      further split into paragraphs, sentences, vocables
export type GenericCaptions = {
  getSection(): []
}


// This is something that our live tagging API can return, but it is also something that, ideally,
// we would store, pre-created, as a caption/text format to store alongside a media file, and as such,


// An API on top of `CaptionTrack`.
export class Captions {

  language: string;
  data: CaptionTrack;

  private lineTimes: number[];
  private lineDurations: number[];
  private vocablesById: Map<VocableId, Vocable>;
  private nodesByVocableId: Map<VocableId, StructureNode[]>;

  constructor(lang: string, data: CaptionTrack) {
    this.language = lang;
    this.data = data;
    this.vocablesById = new Map();
    this.nodesByVocableId = new Map();

    // This is cached data which we create
    this.lineTimes = data.lines.map(l => l.time);
    this.lineDurations = data.lines.map(l => l.duration);

    data.nodes.forEach(node => {
      node.vocables.forEach(vocableId => {
        if (!this.nodesByVocableId.has(vocableId)) {
          this.nodesByVocableId.set(vocableId, []);
        }
        const list = this.nodesByVocableId.get(vocableId)!;
        list.push(node);
      })
    });

    data.lines.forEach(lineGroup => {
      lineGroup.elements.forEach(line => {
        line.forEach(vocable => {
          this.vocablesById.set(vocable.id, vocable);
        });
      })
    });
  }

  getLine(idx: number): Line {
    return this.data.lines[idx];
  }

  mapLines<T>(callback: (line: Line) => T): T[] {
    return this.data.lines.map(callback);
  }

  getVocable(vocableId: VocableId): Vocable|null {
    return this.vocablesById.get(vocableId) || null;
  }

  getLineForTime(time: number) {
    const idx = this.getLineIndexForTime(time);
    if (idx.current === null) {
      return null;
    }
    const line = this.data.lines[idx.current];
    return {
      ...line
    }
  }

  get length() {
    return this.lineTimes.length;
  }

  getDuration = (index: number): number | null => {
    return this.lineDurations[index];
  }

  getTime = (index: number): number | null => {
    return this.lineTimes[index];
  }

  /**
   * Return the line index for the given `time`, as well as the next line index and the previous
   * line. Note that the given `time` may be exactly between two lines, in which case, `current`
   * is null, or it may be before the first or after the last line.
   */
  getLineIndexForTime(time: number): LineIndexResult {
    return getLineIndexForTime(this, time);
  }

  /**
   * Return for the given line when the line starts and ends.
   *
   * Also, if there are previous or next lines, return when those end and start, respectively.
   */
  getLineTimeBoundary(lineIndex: number): LineTimeBoundary {
    const line = this.data.lines[lineIndex];
    const nextLine = lineIndex < this.data.lines.length - 1 ?
      this.data.lines[lineIndex + 1] : null;
    const prevLine = lineIndex > 0 ? this.data.lines[lineIndex - 1] : null;

    const start = line.time;
    return {
      start,
      end: start + line.duration,
      nextStart: nextLine ? nextLine.time : null,
      prevEnd: prevLine ? prevLine.time + prevLine.duration : null
    }
  }

  /**
   * Return all nodes attached to this vocable.
   */
  getNodesForVocable(vocableId: VocableId): StructureNode[] {
    return this.nodesByVocableId.get(vocableId) || [];
  }
}


export async function tokenizeAndTagForListOfLines(lang: string, lines: string[]): Promise<Vocable[][]> {
  // Fetch and attach PoS info
  // @ts-ignore
  if (!window.CONFIG || !window.CONFIG.wordsApiUrl) {
    throw new Error();
  }

  // @ts-ignore
  const response = await fetch(`${window.CONFIG.wordsApiUrl}/pos`, {
    method: 'POST',
    body: JSON.stringify({
      'lang': lang,
      'lines': lines
    }),
    headers: {
      'Content-Type': 'application/json'
    }});
  const responseData = await response.json();
  return responseData.result;
}