/**
 * A text component used within the player library UI + a mechanism to configure it from the outside via context.
 */
import React, { useContext } from 'react';
import {Text as RNText, TextProps, Platform} from 'react-native';
import {removeUndefinedKeys} from "./TextLink";


interface FontConfig {
  // On native, only the first one is used, but on web you can provide a fallback
  fontFamily: string[],
  sizeMultiplier?: number
}

export interface ITextStyleContext {
  defaultFont?: FontConfig,
  fonts?: {[id: string]: FontConfig}
}

export const TextStyleContext = React.createContext<ITextStyleContext>({})


interface TextBlockProps extends TextProps {
  kind?: string,
  children: any,
}

export function TextBlock(props: TextBlockProps) {
  const {kind, style: propsStyle, ...others} = props;
  const textStyle = useContext(TextStyleContext);

  const fontConfig = (props.kind ? textStyle.fonts?.[props.kind] : null) ?? textStyle.defaultFont;

  const defaultStyle = {
    fontFamily: Platform.OS == 'web'
      ? fontConfig?.fontFamily
      : (fontConfig?.fontFamily[0] || undefined)
  }
  let userStyle = Array.isArray(props.style)
    ? props.style
    : typeof props.style === "number"
      ? [props.style]
      : [removeUndefinedKeys(props.style)];

  return <RNText
    {...others}
    style={[defaultStyle, ...userStyle]}
  />;
}
