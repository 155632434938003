/**
 * Old Slate version had a "key" for every node. This is no longer the case, so we generate one instead. We use
 * this among other things as the actual "vocable id" that we end up storing.
 */

/**
 * Another approach at a solution:
 *
 * 1. The whole document is dirty until a mapping is created.
 * 2. if the lowest-block level possible is edited, make it dirty
 * 3. when inserting a new vocalbe, generate id directly.
 * 4. when pasting, reparse all dirty ones, then check the map: is the id uniqe?
 * 5. if not, we might actually print a dialog message!
 */

import {CaptionTrack as ExternalCaptionTrack} from "languagetool-player/src/models";
import {Editor, Path, Transforms, Node} from "slate";
import {isLine, isLineGroup, isWordInline} from "../../models/Captions/types";
import {isEqual} from 'lodash';


export interface KeyGenEditor {
  keygen: IdGenerator
  keyMapping: KeyMapping
}

/**
 * This ensures that we attach a unique id to every vocable. It must make sure the ids are unique.
 * This can be challenging because Slate.js in some cases will copy an inline (i.e. when splitting it),
 * and will then cause both ids to be the same. We keep a cache ofthe  id <-> path mapping, and will
 * update it during normalization.
 *
 * It didn't seem like there was a reliable way available to hook into exactly those slate actions
 * which might cause duplicate keys.
 */
export function withKeyGen<T extends Editor>(editor: T, opts?: {idGen?: IdGenerator}): T & KeyGenEditor {
  type Base = (T & KeyGenEditor);

  // Make the id generator available on the editor as a property.
  (editor as Base).keygen = opts?.idGen ?? new IdGenerator();

  // The mapping cache
  (editor as Base).keyMapping = new KeyMapping(editor);

  // Logs operations: Can be useful for debugging when we want to see what is going on
  // const {apply} = editor;
  // editor.apply = (op , ...args) => {
  //   console.log(op.type)
  //   return apply(op, ...args);
  // }

  const {normalizeNode} = editor
  editor.normalizeNode = entry => {
    const [node, path] = entry;

    // All of our objects have keys.
    // NB: Our line group and line objects allow for keys as well, but since we do not currently save those
    // in the output, we prefer not to generate them either, as it can only slow down the initial load,
    // in increase the total id everytime the file is opened.
    if (isWordInline(node) /* || isLineGroup(node) || isLine(node) */) {
      let needsNewId;
      if (!node.key) {
        needsNewId = true;
      }
      else {
        needsNewId = (editor as Base).keyMapping.reportMapping(path, node.key);
      }

      if (needsNewId) {
        const newKey = (editor as Base).keygen.getId();
        (editor as Base).keyMapping.reportMapping(path, newKey);
        Transforms.setNodes(editor, {key: newKey}, {at: path})
        return;
      }
    }

    // Fallback
    normalizeNode(entry)
  }

  return editor as (T & KeyGenEditor);
}

function path2str(s: Path) {
  return s.join(":");
}

/**
 * The key mapping keeps track of node paths and their key ids, so we can figure out
 * when duplicates are introduced and fix them. The way this works:
 *
 * - Call reportMapping() for every node passing normalization.
 * - If a node is being split, for example, both nodes pass normalization.
 * - And both have now the same id.
 * - But only one is in the same path as before.
 *
 * Note that you cannot rely on the path/key mappings at all. If we introduce some new
 * nodes at the beginning of a line, the path indices of the nodes after will change, but
 * NOT be updated in the cache.
 *
 * XXX Copy & Paste! can still copy in duplicates if we managed to shift out of it. E.g.
 *
 * 1. write a vocable in the first line
 * 2. insert new vocables BEFORE it
 * 3. now copy that initial vocable into a new line
 * 4. you have a duplicate it, because the cache was not updated, so the logic here
 *    thought the vocable moved and a new one was not inserted.
 *
 * Possibily fixes:
 * - work with Path.transform() to shift all stored paths on every operation
 *   being executed (we can obviously short cut it in some cases for performance).
 *   we might look into using rangeref/pointRef, though then we do not have any short-circuiting
 * - an insert text op will use Path.levels() to make all paths in the hierarchy dirty.
 *   that means we cannot at this point know which one is the "original" change.
 *   however it points us to the right direction: that we really have to hook into the
 *   operation logic, detect the dirty paths from it,
 *
 *   - https://github.com/ianstormtaylor/slate/blob/0bbe121d76c5c2313d939de8a7ebed3bfd37f62d/packages/slate/src/interfaces/editor.ts#L724
 *   - https://github.com/ianstormtaylor/slate/blob/0bbe121d76c5c2313d939de8a7ebed3bfd37f62d/packages/slate/src/create-editor.ts#L32
 *
 * - We could access the operations on editor.operations during normalization/while flushing.
 */
class KeyMapping {
  public KeyByPath: {[pathID: string]: string} = {};
  public PathByKey: {[key: string]: Path} = {};

  // Beause of this field we have trouble when slate.js for error messages does JSON.stringify(). It will cause
  // a recursive error.
  // Probably the best way would be to replace this class with a prototype object.
  private editor: Editor;

  constructor(editor: Editor) {
    this.editor = editor;
  }

  /**
   * Add a new mapping. If this detects a duplicate, it will return true, which
   * means you have to create a new id/key for this node.
   */
  reportMapping(path: Path, key: string): boolean {
    // Previously, this key was in a different path:
    if (this.PathByKey[key] && !isEqual(this.PathByKey[key], path)) {
      let oldPath = this.PathByKey[key];

      // Is it still there? If so, we have to give this one a new key!
      const oldNode = Node.has(this.editor, oldPath) ? Node.get(this.editor, oldPath) : undefined;
      if (oldNode?.key === key) {
        return true;
      }

      delete this.KeyByPath[path2str(oldPath)];
    }

    // Store path for this key.
    this.PathByKey[key] = path;
    // Store key for this path
    this.KeyByPath[path2str(path)] = key;

    return false;
  }
}


/**
 * Every document has ids for both vocables and structure nodes. Each id is unique. This helper class
 * is essentially responsible for giving us new, unused ids. It does so by finding the largest id used,
 * and then issuing larger ones. It does not try to fill holes.
 */
export class IdGenerator {
  private maxId = 1;

  constructor(opts?: {
    initialValue?: ExternalCaptionTrack,
    initialMax?: number
  }) {
    if (opts?.initialValue) {
      this.ensureNoDuplicateWith(opts.initialValue)
    }
    if (opts?.initialMax) {
      this.makeIdKnown(opts.initialMax);
    }
  }

  ensureNoDuplicateWith(value: Partial<ExternalCaptionTrack>) {
    this.makeIdKnown(getMaxId(value));
  }

  makeIdKnown(someId: string|number) {
    const asNum = parseKeyAsId(someId);

    if (asNum && asNum > this.maxId) {
      this.maxId = asNum;
    }
  }

  getId(): string {
    this.maxId++;
    console.log('new id generated: ' + ""+this.maxId)
    return ""+this.maxId;
  }
}

export function getMaxId(value: Partial<ExternalCaptionTrack>): number {
  let max = 0;

  // Check all the vocable ids.
  value.lines?.forEach(lineGroup => {
    lineGroup.elements.forEach(line => {
      line.forEach(vocable => {
        max = Math.max(max, parseKeyAsId(vocable.id) ?? 0);
      })
    })
  });

  // Check all the structure node ids.
  value.nodes?.forEach(node => {
    max = Math.max(max, parseKeyAsId(node.id) ?? 0);
  });

  return max;
}


export function parseKeyAsId(someId: string|number): number|undefined {
  if (someId === undefined || someId === null) {
    return;
  }

  if (typeof someId === 'number') {
    return someId;
  }
  else if (someId.match(/^[0-9a-z]+$/)) {
    return parseInt(someId);
  }
  else {
    // This one cannot conflict with our generated ids then.
    return;
  }
}