import React from 'react';
import {useQuery} from "multi-apollo";
import gqlw from 'graphql-tag';
import {
  activityLogEntryFragment, ActivityLogGroupedEntry,
  groupLogEntriesByUserAndTime
} from "../../components/AnnotationLogEntry";
import {ActivityLogQuery} from "../../types/ActivityLogQuery";
import { PrimaryButton } from 'office-ui-fabric-react';


const query = gqlw`
  ${activityLogEntryFragment}
  query ActivityLogQuery($cursor: String, $userId: Int) {
    activityLog(first: 100, after: $cursor, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      },
      edges {
        node {
          id,
          ...ActivityLogEntryFragment            
        }
      }            
    }
  }
`;

function useLog(opts?: {
  userId?: number
}) {
  const variables = {
    userId: opts?.userId ?? undefined
  }
  const {data, error, loading, refetch, fetchMore} = useQuery<ActivityLogQuery>(query, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  const loadMore = () => {
    fetchMore({
      query,
      variables: {
        ...variables,
        cursor: data!.activityLog!.pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult!.activityLog!.edges;
        const pageInfo = fetchMoreResult!.activityLog!.pageInfo;

        return newEdges.length
          ? {
            // Put the new comments at the end of the list and update `pageInfo`
            // so we have the new `endCursor` and `hasNextPage` values
            activityLog: {
              __typename: previousResult.activityLog!.__typename,
              edges: [...previousResult.activityLog!.edges, ...newEdges],
              pageInfo
            }
          }
          : previousResult;
      }
    })
  };

  return {data, error, loading, refetch, fetchMore: loadMore}
}


export function ActivityLog(props: {
  forUser?: number,
  onChangeUser: (userId: number|null) => void,
}) {
  const {data, loading, error, fetchMore} = useLog({
    userId: props.forUser
  });

  const handleLoadMore = () => {
    fetchMore();
  }

  const handleUserClick = (userId: number) => {
    props.onChangeUser(userId);
  };

  if (loading && !data) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;
  if (!data) { return null; }

  const groupedEntries = groupLogEntriesByUserAndTime(data.activityLog!.edges.map(edge => edge!.node!));

  return <div>
    {groupedEntries.map((group, idx: number) => {
      return <ActivityLogGroupedEntry
        entry={group}
        key={""+group.time}
        onUserClick={handleUserClick}
      />
    })}

    <PrimaryButton onClick={handleLoadMore} disabled={loading}>Load More</PrimaryButton>
  </div>
}