import React from 'react';
import styles from './Sentences.module.css';
import {useQuery} from 'multi-apollo';
import gql from "graphql-tag";
import {Link} from 'react-router-dom';
import {WordWithDetails, WordWithDetailsFragment} from "../../utils/WordWithDetails";


const QUERY = gql`
  ${WordWithDetailsFragment}
  query($id: Int!) {
    word(id: $id, allowDeleted: true) {,
      id,
      partOf {
        id,
        base,
        ...WordWithDetails
      }
    }
  }
`;


export const PartOfWords = React.memo((props: {
  wordId: any
}) => {
  const {data, loading, error} = useQuery(QUERY, {variables: {id: props.wordId}, client: 'words'});

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;

  let content: any;
  if (data.word.partOf && data.word.partOf.length) {
    content = data.word.partOf.map((word: any) => {
      return <li key={word.id}>
        <Link to={`/word/${word.id}`}>
          <WordWithDetails {...word} />
        </Link>
      </li>
    });
  }

  if (!content) {
    return null;
  }

  return <div className={styles.root}>
    <h3>Part of these other words</h3>
    {content}
  </div>
});