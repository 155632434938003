export type LineIndexResult = {
  current: number|null,
  previous: number|null,
  next: number|null
};


interface ICaptionLines {
  length: number,
  getTime(index: number): number|null,
  getDuration(index: number): number|null,
}


/**
 * Return the line index for the given `time`, as well as the next line index and the previous
 * line. Note that the given `time` may be exactly between two lines, in which case, `current`
 * is null, or it may be before the first or after the last line.
 */
//generalize it where we allow passing an interface to a function which has get()
// we can then use it for the slate editor
export function getLineIndexForTime(lines: ICaptionLines, time: number): LineIndexResult {
  // There are no lines at all.
  if (!lines.length) {
    return {
      previous: null,
      next: null,
      current: null
    }
  }

  // Time is before the first line
  let firstLineTime = lines.getTime(0);
  if (firstLineTime !== null && time < firstLineTime) {
    return {
      previous: null,
      current: null,
      next: 0
    }
  }

  function makeResultForCurrent(index: number) {
    return {
      current: index,
      next: Math.min(index+1, lines.length - 1),
      previous: Math.max(0, index-1)
    }
  }

  let firstLineWithoutTime: number|null = null;

  // TODO: Can be sped up by bisecting.
  for (let idx = 0; idx < lines.length; idx++) {
    let curTime = lines.getTime(idx);
    let curDuration = lines.getDuration(idx);

    // If the line has no timestamp, we do not have any information right now. We store the first
    // such line encountered (the first after a timestamped-line), and we'll return that first
    // null-line later when we determine that we have not yet progressed into the next known timestamp.
    if (curTime === null) {
      if (!firstLineWithoutTime) {
        firstLineWithoutTime = idx;
      }
      continue;
    }
    else {
      // We know again were we are at, clear.
      firstLineWithoutTime = null;
    }

    // If there is a duration, then we know when the line ends.
    let maxEnd = curDuration ? curTime + curDuration : null;

    // Sometimes we do not have a duration; then the end would be the next line start.
    // Also, sometimes there is an ever so slight overlap in the subtitles (time + duration is larger
    // than the start time of the next). Sometimes it is just a float rounding error. In such a case,
    // we want to use the "next" line, so that pressing NEXT will not be stuck, especially when paused.
    // TODO: Test this
    if (idx + 1 < lines.length) {

      // Get the next start line
      let nextTime = null;
      let lookForwardCounter = 0;
      while (maxEnd === null && nextTime === null) {
        lookForwardCounter++;
        nextTime = lines.getTime(idx + lookForwardCounter);
      }

      // Is there an overlap?
      if (nextTime) {
        maxEnd = maxEnd ? Math.min(nextTime, maxEnd) : nextTime;
      }
    }

    // The time is within the current line
    if (time >= curTime && (!maxEnd || time < maxEnd)) {
      return makeResultForCurrent(idx);
    }

    // Counter-intuitive at first, but if the needle is smaller than the current line time, that
    // means the current line will be the next one. Since there is also no current line, we have
    // have found our position, in between two lines.
    else if (time < curTime) {
      // However, there is a snatch, since we need to support lines without a timestamp (for editing
      // purposes). The first line without a timestamp is always the "next" one, after the needle
      // moved past the most recent line with a timestamp. However, when we encounter a null-line,
      // we don't know yet, so we save it. And then, at this point, we know and we can resolve it.
      if (firstLineWithoutTime) {
        return makeResultForCurrent(firstLineWithoutTime);
      }

      return {
        next: idx,
        previous: idx-1,
        current: null
      }
    }
  }

  // So it is after the last line
  return {
    previous: lines.length - 1,
    current: null,
    next: null
  }
}
