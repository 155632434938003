import * as React from 'react';
import {RecordingItemDefinition, RecordingWorkflowControl} from "./ListControl";
import {LoadMoreFunc} from "./useForeverScroll";
import {useClient} from "multi-apollo";
import gqlw from 'graphql-tag';
import {NextWordsInSession} from "../../types/NextWordsInSession";
import {useUploader} from "./useUploader";
import KeyStyles from './Key.module.css';
import { PrimaryButton, Checkbox } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import {useCounter, useAsync} from 'react-use';
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {PlayerManager} from "./usePlayer";


const query = gqlw`  
  query NextWordsInSession($sessionId: ID!, $first: Int!, $after: Int!, $isComplete: Boolean) {
    recordingSession(id: $sessionId) {
      items(first: $first, after: $after, isComplete: $isComplete) {
        id,
        isComplete,
        existingRecordings: recordings {
          id,
          url: url(key: "original"),
          urlProcessed: url(key: "main"),
          isRejected
        }
        word {
          id,
          base,
          baseTransliteration,
          type,
          description
        }
      }
    }
  }
`;


function Export(props: {handleLoad: any}) {
  const {value: items} = useAsync(async () => {
    const items = await props.handleLoad({after: 0, first: 1000})
    return items;
  });

  return <div style={{padding: '10px'}}>
    <p>Max 1000 Shown!:</p>

    <table>
    {items?.map((item: any, idx: number) => {
      return <tr>
        <td>{idx+1}</td>
        <td>{item.word.type}</td>
        <td>{item.word.base}</td>
        <td>{romanizeSounds(JSON.parse(item.word.baseTransliteration))}</td>
        <td>{item.word.description}</td>
      </tr>
    })}
    </table>
  </div>
}


export function RecordingWorkflowScreen(props: {
  recordingSessionId: string,
  style?: any
}) {
  const client = useClient("words")
  const [reloadCount, {inc: reload}] = useCounter(0);
  const [onlyIncomplete, setOnlyIncomplete] = useState(false);
  const [playProcessed, setPlayProcessed] = useState(false);
  const [autoPlay, setAutoPlay] = useState(false);
  const [uploadState, uploader] = useUploader(props.recordingSessionId);

  useEffect(() => {
    reload();
  }, [onlyIncomplete, reload])

  const handleLoad: LoadMoreFunc<RecordingItemDefinition> = async (opts) => {
      let result;
      try {
        result = await client.query<NextWordsInSession>({
          query,
          variables: {
            ...opts,
            sessionId: props.recordingSessionId,
            isComplete: onlyIncomplete ? false : undefined
          }
        });
        if (!result?.data?.recordingSession) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("Session not found.")
        }
      }
      catch(e) {
        console.error(e);
        return [];
      }
      return result.data.recordingSession.items;
  }

  if (document.location.search == '?export') {
    return <Export handleLoad={handleLoad} />
  }

  return <div style={{
    ...props.style,
    display: 'flex',
    flexDirection: 'column'
  }}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
    }}>
      <div style={{padding: '20px', marginBottom: '20px', flex: 1}}>
        <div><Key label={"▼"} /> Go To Next (Continue Recording)</div>
        <div><Key label={"▲"} /> Go To Prev (Stop Recording)</div>
        <div><Key label={"⏎"} /> Start/Stop Recording</div>
        <div><Key label={"space"} /> Play/Pause Current Recording</div>
      </div>

      <div style={{padding: '20px', marginBottom: '20px', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection:  'column'}}>
        <Checkbox
          label={"Only Incomplete"}
          checked={onlyIncomplete}
          onChange={(e: any) => setOnlyIncomplete(e.currentTarget.checked)} />

        <Checkbox
          label={"Play Processed"}
          checked={playProcessed}
          onChange={(e: any) => setPlayProcessed(e.currentTarget.checked)} />

        <Checkbox
          label={"Auto Play"}
          checked={autoPlay}
          onChange={(e: any) => setAutoPlay(e.currentTarget.checked)} />

        {uploadState.failedCount ?
          <PrimaryButton onClick={uploader.retryAllFailed} style={{marginTop: '20px'}}>
            {uploadState.failedCount} failed uploads. Retry.
          </PrimaryButton>
          : null}
      </div>
    </div>

    <PlayerManager preferredType={playProcessed ? 'processed' : 'original'}>
      <RecordingWorkflowControl
        key={reloadCount}
        style={{flex: 1}}
        loadItems={handleLoad}
        uploader={uploader}
        autoPlay={autoPlay}
      />
    </PlayerManager>
  </div>
}



function Key(props: {
  label: string
}) {
  return <kbd className={KeyStyles.key}>
    {props.label}
  </kbd>
}