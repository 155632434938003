import {useCallback, useMemo, useRef, useState} from "react";
import {useAsync} from 'react-use';
import {useInstanceVar} from "./utils";
import shallowequal from 'shallowequal';
import {useNewPromise} from "../../utils/useNewPromise";
import loglevel from 'loglevel';


const log = loglevel.getLogger("Audio/Recorder");
log.setLevel("DEBUG");


declare class MediaRecorder {
  constructor(a: any, b: any);
  onstart: any;
  onstop: any;
  onpause: any;
  onresume: any;
  ondataavailable: any;
  resume(): void;
  pause(): void;
  start(ms?: number): void;
  stop(): void;
};


async function getStream(): Promise<MediaStream> {
  if (!navigator.mediaDevices) {
    throw new Error("Cannot create stream.")
  }

  const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
  return stream!;
}

export type MediaRecorderState = "stopped"|"recording"|"paused";


export type RecordControl = {
  resume: () => void,
  pause: () => void,
  start: () => void,
  stop: () => Promise<null>,
}


export type UseMediaRecorderOpts = {
  // We could do a "enabled" -> auto flag
  disabled?: boolean,

  // Split recording into that many slices
  timeSlice?: number,

  onSetup?: (stream: MediaStream) => void
};



function useCachedValue<T>() {
  const cachedValue = useRef<T|undefined>(undefined);
  const keyValue = useRef<any|undefined>(undefined);

  return (key: any, makeFunc: () => T) => {
    if (cachedValue.current === undefined || (!shallowequal(key, keyValue.current))) {
      cachedValue.current = makeFunc();
      keyValue.current = key;
    }
    return cachedValue.current!;
  }
}


export function useMediaRecorder(opts?: UseMediaRecorderOpts) {
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder|null>(null);
  const [state, setState] = useState<MediaRecorderState>('stopped');
  const mediaChunks = useRef<Blob[]>([]);
  const onSetup = useInstanceVar(opts?.onSetup);
  const stopPromise = useNewPromise();
  const getCachedValue = useCachedValue<Blob>();

  // Create the recorder on disabled=false, and shut it down on disabled=true.
  // Browser permission request will happen along those lines.
  const result = useAsync(async () => {
    if (opts?.disabled) {
      log.info("Disabling the media recorder");
      setMediaRecorder(null)
    }
    else {
      log.info("Creating MediaRecorder...");
      const stream = await getStream();
      const mediaRecorder = new MediaRecorder(stream, {
        // Firefox ogg has 130 kb (maybe variable).
        // For Chrome webm, my tools show no bitrate, but duration/size indicates about 90 kb
        // Ali is sending me 320 kb/s
        // The maximum allowed in Chrome is 128.000 (then we see a clamping warning).
        audioBitsPerSecond : 128000,
        // e.g. chrome does not support mp3
        //mimeType: 'audio/mp3'
      });
      mediaRecorder.onstart = () => {
        stopPromise.reset();
        mediaChunks.current = [];
        setState("recording");
      }
      mediaRecorder.onresume = () => { setState("recording"); }
      mediaRecorder.onpause = () => { setState("paused"); }
      mediaRecorder.onstop = () => {
        setState("stopped");
        return stopPromise.resolve();
      }
      mediaRecorder.ondataavailable = (event: any) => { mediaChunks.current.push(event.data); }
      setMediaRecorder(mediaRecorder);
      log.info("MediaRecorder created.");

      if (onSetup.current) {
        onSetup.current(stream);
      }
    }
  }, [opts?.disabled, onSetup, stopPromise]);

  // I think useAsync should raise
  if (result.error) {
    console.log(result.error);
  }

  const controller = useMemo<RecordControl>(() => {
    if (mediaRecorder) {
      return {
        resume: () => { mediaRecorder.resume(); },
        pause: () => { mediaRecorder.pause(); },
        start: () => { mediaRecorder.start(opts?.timeSlice); },
        stop: () => {
          mediaRecorder.stop();
          return stopPromise.makeOrKeep();
        },
      }
    }
    else {
      return {
        resume: () => { },
        pause: () => { },
        start: () => { },
        stop: () => { return Promise.resolve(null); },
      }
    }
  }, [mediaRecorder, opts?.timeSlice, stopPromise]);

  // Get the current blob
  const getCurrentBlob = useCallback(() => {
    if (mediaChunks.current.length == 0) {
      return null;
    }
    return getCachedValue(
      [mediaChunks.current, mediaChunks.current.length],
      () => new Blob(mediaChunks.current, { type: "audio/wav" })
    );
  }, [mediaChunks])

  const clearCurrentBlob = useCallback(() => {
    mediaChunks.current = [];
  }, [mediaChunks]);

  return {
    controller,
    state,
    getCurrentBlob: getCurrentBlob,
    clearCurrentBlob,
    numChunks: mediaChunks.current.length,
    isRecording: state == 'recording',
    isPaused: state == 'paused',
  };
}