import {NestedFormProvider} from "../../../../../components/NestedFormik";
import React from "react";
import {FormLabel} from "@material-ui/core";


export function NestedForm(props: {
  title: any,
  prefix: string,
  children: any
}) {
  const form = <div style={{
    border: '1px silver dotted',
    padding: '20px'
  }}>
    <NestedFormProvider prefix={props.prefix}>
      {props.children}
    </NestedFormProvider>
  </div>;

  return <div style={{marginTop: '16px'}}>
    <FormLabel style={{marginBottom: '8px', display: 'inline-block'}}>{props.title}</FormLabel>
    {form}
  </div>
}