import React from "react";
import {View} from 'react-native';
import PlayButton from "./PlayButton";
import {getOneAnnotation} from "../../../annotations";
import {PronunciationAnnotation} from "../../../annotations/Pronunciation";
import {useAudio} from "../AudioManager";


type Props = {
  annotations: PronunciationAnnotation[],
  size?: number
}


export function PronunciationPlayer(props: Props) {
  const annotation = getOneAnnotation(props.annotations, 'pronunciation') as PronunciationAnnotation|null;
  const {isPlaying, play} = useAudio(annotation? annotation.url : undefined);

  if (!annotation) {
    return null;
  }

  return <View  style={{
    paddingRight: 10,
  }}>
    <PlayButton
      size={props.size}
      isPlaying={isPlaying}
      onClick={() => play()}
    />
  </View>
}
