import {NestableField, useNestedValues} from "../../../../components/NestedFormik";
import React from "react";
import {validateRequired} from "../index";
import {SelectOption, useUIControls} from "../../../../components/FormUISwitch";


export const WordTypeLabels = {
  adverb: 'Adverb',
  pronoun: 'Pronoun',
  conjunction: 'Conjunction',
  expression: 'Expression',
  preposition: 'Preposition',
  "proper_noun": 'Proper Noun',
  letter: 'Letter',
  particle: 'Particle',
  interjection: 'Interjection',
  determiner: 'Determiner',
  numeral: 'Numeral',
  suffix: 'Suffix',
  prefix: 'Prefix',
  alias: 'Internal Alias',
}


type Props = {
  allowedTypes?: string[],
  disallowedTypes?: string[],
  // In some cases, we do not want to ask too much grammar knowledge of editors
  // adding new words, so we simply collapse all the invariant types into a
  // single "invariant" option.
  allowAdvancedTypes?: boolean
}

export const WordTypeField = (props: Props) => {
  const UI = useUIControls();
  const values = useNestedValues();
  const currentValue = values.type;

  // If the value is not supported, just render it, do not allow change.
  const supportedTypes = ['noun', 'verb', 'adjective', 'invariant', 'proper_noun'];
  if (!props.allowAdvancedTypes && currentValue && supportedTypes.indexOf(currentValue) == -1) {
    return <div>
      {(WordTypeLabels as any)[currentValue]}
    </div>
  }

  // We require editors to know enough grammar to pick one of the main word forms.
  // TODO: Bring back support for these
  // const baseFields: any[] = [];
  // function add(f: any) {
  //   if (props.allowedTypes && props.allowedTypes.indexOf(f.props.value) == -1) {
  //     return;
  //   }
  //   if (props.disallowedTypes && props.disallowedTypes.indexOf(f.props.value) > -1) {
  //     return;
  //   }
  //   baseFields.push(f);
  // }

  let  options: SelectOption[];
  if (props.allowAdvancedTypes) {
    options = [...BaseOptions, ...DetailedOptions];
  }
  else {
    options = BaseOptions;
  }

  return <NestableField
    name={"type"}
    label={"Type"}
    component={UI.Select}
    style={{
      alignSelf: 'flex-start',
      minWidth: '250px'
    }}
    validate={validateRequired}
    options={options}
  />
};


const BaseOptions = [
  {
    value: 'noun',
    label: "Noun",
    subtitle: <>if it has a plural like <em>-ha</em> or <em>-an</em>.</>
  },
  {
    value: 'verb',
    label: 'Verb',
    subtitle: <>if it has forms ending with <em>-am</em> or <em>-i</em> or <em>-and</em>.</>,
  },
  {
    value: 'adjective',
    label: 'Adjective',
    subtitle: <>if you can add <em>-tar</em> or <em>-tarin</em>.</>,
  },
  {
    value: 'invariant',
    label: 'Invariant',
    subtitle: <>Invariants never change form, and have no suffixes or prefixes.</>,
  }
];


// More detailed types we let other teams add, and once they do, we can display them.
const DetailedOptions = [
  {
    value: 'adverb',
    label: WordTypeLabels.adverb,
    subtitle: <>modifies a verb.</>,
  },
  {
    value: 'pronoun',
    label: WordTypeLabels.pronoun,
    subtitle: <>things like man, to, u.</>,
  },
  {
    value: 'conjunction',
    label: WordTypeLabels.conjunction,
    subtitle: <>the word is always the same, and it is like 'and', 'or', 'while'.</>,
  },
  {
    value: 'preposition',
    label: WordTypeLabels.preposition,
    subtitle: <>the word is always the same, and it is like 'on', 'after', 'in', 'for'.</>,
  },
  {
    value: 'interjection',
    label: WordTypeLabels.interjection,
    subtitle: <>'hey', 'ouch', 'yuck'</>,
  },
  {
    value: 'particle',
    label: WordTypeLabels.particle,
    subtitle: <>A small word with little meaning.</>,
  },
  {
    value: 'letter',
    label: WordTypeLabels.letter,
    subtitle: <>A single letter.</>,
  },
  {
    value: 'prefix',
    label: WordTypeLabels.prefix,
    subtitle: <>A prefix such as bi-..</>,
  },
  {
    value: 'suffix',
    label: WordTypeLabels.suffix,
    subtitle: <>A suffix such as -ha.</>,
  },
  {
    value: 'determiner',
    label: WordTypeLabels.determiner,
  },
  {
    value: 'expression',
    label: WordTypeLabels.expression,
    subtitle: <>if it is more than one word</>,
  },
  {
    value: 'numeral',
    label: WordTypeLabels['numeral'],
    subtitle: <>a number</>,
  },
  {
    value: 'proper_noun',
    label: WordTypeLabels['proper_noun'],
    subtitle: <>a name of a person, city, country, organization etc.</>,
  },
  {
    value: 'alias',
    label: WordTypeLabels['alias'],
    subtitle: <>a form of another word.</>,
  }
]