/**
 * Performance notes:
 *
 * - The main issue is every Pixi frame causing "Update Layer Tree" in Chrome, which takes forever
 *   (40ms) with a large Slate.js document where we use "position:absolute" for the words.
 *
 *   Remember: we have less than 16ms per frame.
 *
 *   - can we prevent "update layer tree" from being triggered?
 *   - can we debug "update layer tree" somehow?
 *       https://developer.mozilla.org/en-US/docs/Mozilla/Performance/Profiling_with_the_Built-in_Profiler
 *       https://github.com/GoogleChrome/lighthouse/pull/2030/files
 *
 * - React-Pixi is pretty fast, but 10ms is not a lot, and 60fps is a lot of calls. We might be able to
 *   reduce CPU usage by forgoing react-pixi, or only using it for some parts (it allows intermixing).
 *
 * - When not playing or moving, we still render at 60fps.
 *    - we might switch to "raf=false" mode then
 *    - we just render on stage update, with props, as in scrolling?
 *
 * - Consider these libs:
 *    - https://davidfig.github.io/pixi-scrollbox/
 *    - https://github.com/davidfig/pixi-cull
 */


/**
 * There are different ways the UI can work. In principal, there are two approaches;
 *
 * 1) When panning the viewport, the play position remains the same - the viewport scrolls.
 * 2) When panning the viewport, we really change the play position - the viewport scrolls as a result of
 *    the play head remaining in a stable position.
 *
 * The Youtube Subtitler uses approach (2).
 *
 * The consequence is that it is not possible to show a subtitle in the timeline which does not fit into the
 * same view as the play position. While scrolling through the captions editor, we have to adjust the play
 * position along with it.
 *
 * An alternative would be two separates behaviours for "while playing" and "while paused".
 */

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import webAudioBuilder from "waveform-data/webaudio";
import {WaveformShape} from "./WaveFormShape";
import {PlayHead} from "./PlayHead";
import {Scale} from "./coordinates";
import {CaptionOverlay} from "./CaptionOverlay";
import {Stage} from '@inlet/react-pixi'
import Measure from 'react-measure';
import {string2hex, useOnCurrentPlayTime} from "./utils";
import {MediaTrack} from "../../models/MediaTrack";
import {LoadingStatus} from "./LoadingStatus";
import {WaveformData} from "waveform-data";
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import {UIState, UserInterfaceAPI} from "../MainScreen";
import {getSelectedLineGroupIndex} from "../../models/Captions/slateApi";
import {TimeScale} from "./TimeScale";
import {Transforms} from 'slate';
import {PlayerAPI} from "languagetool-player/src/ui/Video";
import {Viewport} from "./Viewport";
import {CaptionTrackRoot} from "../../models/Captions/types";


// The react typings always include "null" in "current", which seems wrong.
interface RefObject<T> {
  readonly current: T;
}


type Props = {
  // This is where we get the waveform from.
  source: MediaTrack,
  // This is the current captions we render on top of the wave form.
  captions: CaptionTrackRoot|null,
  // The API to change the wave form.
  captionsEditor: ICaptionsEditor|null,
  uiAPI: UserInterfaceAPI,
  uiState: UIState

  playerAPI: PlayerAPI,

  height?: any
};


const stageOptions = {
  antialias: false,
  resolution: 2,
  backgroundColor: string2hex('#fdf7f7')
}

export function Timeline(props: Props) {
  const [dimensions, setDimensions] = useState<{width: number, height: number}|null>(null);
  const [stageStyle, setStageStyle] = useState<{width?: number, height?: number}>({});

  const handleResize = useCallback((contentRect) => {
    setDimensions(contentRect.bounds);

    // NB: When passing "resolution: 2" to Pixi, the only thing it does is *2 EVERY VALUE, including
    // the width/height of the stage. So basically, we really want to then scale it down with CSS to
    // half the size so we have a true x2 resolution.
    // We cache the proper css style object in state to not cause unnecessary re-renders.
    setStageStyle(contentRect.bounds ? {
      width: contentRect.bounds.width,
      height: contentRect.bounds.height,
    } : {});
  }, [setDimensions, setStageStyle]);

  let stage: any|null = null;

  if (dimensions) {
    stage = (
      <Stage
        width={dimensions.width}
        height={dimensions.height}
        style={stageStyle}
        options={stageOptions}

        // If "raf" is enabled, this is ignored, and always treated as "false" - since we
        // re-render anyway in the background at 60fps. But if this mode is used, Pixi is
        // re-rendered in Stage.componentDidUpdate, regardless of any actual changes in
        // properties. Note further, that what never works is to update the state of any
        // Pixi child object directly.
        //
        // Note that with this mode, and thus without "raf", state updates of children
        // will not cause a refresh, because react-pixi will not repaint the stage if a
        // child component updates, but relies on the ticker to do it.
        renderOnComponentChange={false}
        // This sets up a Pixi background timer. We do not really need this, I think, and
        // it seems to increase the idle CPU usage to 25%.
        raf={true}
      >
        <Contents
          width={dimensions.width}
          height={dimensions.height}
          source={props.source}
          captions={props.captions}
          captionsEditor={props.captionsEditor}
          uiAPI={props.uiAPI}
          uiState={props.uiState}
          playerAPI={props.playerAPI}
        />
      </Stage>
    );
  }

  return (
    <Measure
      bounds
      onResize={handleResize}
    >
      {({ measureRef }) => (
        <div ref={measureRef} style={{width: '100%', height: props.height}}>
          {stage}
        </div>
      )}
    </Measure>
  );
}


type ContentProps = {
  width: number,
  height: number,

  // This is where we get the waveform from.
  source: MediaTrack,
  // This is the current captions we render on top of the wave form.
  captions: CaptionTrackRoot|null,
  // The API to change the wave form.
  captionsEditor: ICaptionsEditor|null,
  uiAPI: UserInterfaceAPI,
  uiState: UIState
  playerAPI: PlayerAPI,
};


function useRefState<T>(defaultValue: T): [T, any, RefObject<T>] {
  const [state, setState] = useState(defaultValue);
  const ref = useRef(state);
  useEffect(() => {
    ref.current = state;
  })
  return [state, setState, ref];
}


function useCopyToRef<T>(value: T) {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref;
}


function useLoadWaveForm(source: MediaTrack): [WaveformData|null, boolean] {
  const [waveFormData, setWaveFormData] = useState<WaveformData|null>(null);
  const [waveFormLoading, setWaveFormLoading] = useState(true);

  const audioContext = useMemo(() => {
    // @ts-ignore: Safari still requires the prefix.
    const AudioContextClass: typeof AudioContext = window.AudioContext || window.webkitAudioContext;
    return new AudioContextClass();
  }, []);

  useEffect(() => {
    if (source.type !== 'url') {
      // For now, we do not have a wave form for those kinds of media files.
      setWaveFormLoading(false);
    }

    else {
      const p = loadWaveformFromUrl(source.url!, audioContext);
      p.then((data: WaveformData) => {
        setWaveFormLoading(false);
        setWaveFormData(data as WaveformData);
      }).catch(err => {
        console.info('Failed to load the wave form data', err);
        setWaveFormLoading(false);
      });
    }
  }, [source]);

  return [waveFormData, waveFormLoading];
}


function useScale(waveFormData: WaveformData|null, width: number): [Scale, number] {
  const [userZoom, setUserZoom] = useState(1);

  const scale = useMemo(() => {
    // If we do not have a waveform yet, make it 40 pixels per second
    return new Scale(40 * userZoom);
  }, [userZoom]);

  const secondsVisible = useMemo(() => {
    return scale.pixelsToTime(width);
  }, [scale, width]);

  return [scale, secondsVisible];
}


function ensureVisible(time: number, secondsVisible: number, setOffsetSeconds: any, offsetSeconds: number) {
  // Logic: Always in the middle. Not clear how that affects the other UI interactions.
  // const desiredMiddleSecond = offsetSeconds + secondsVisible / 2;
  // if (time > desiredMiddleSecond) {
  //   offsetSeconds = Math.max(0, time - (secondsVisible / 2));
  // }
}


function useOffsetSeconds(playerAPI: PlayerAPI, secondsVisible: number): [RefObject<number>, any] {
  const [offsetSeconds, setOffsetSeconds] = useState(0);

  const setOffsetSecondsSafe = useCallback((time: number) => {
    if (!Number.isFinite(time)) {
      throw new Error('offset must be a number')
    }
    const duration = playerAPI.getState().duration;

    const safeOffset = Math.min(Math.max(0, time), duration !== null ? duration - secondsVisible : Infinity);
    setOffsetSeconds(safeOffset);
  }, [setOffsetSeconds, playerAPI, secondsVisible]);

  const lastOffsetSeconds = useRef(offsetSeconds);
  lastOffsetSeconds.current = offsetSeconds;

  return [lastOffsetSeconds, setOffsetSecondsSafe];
}


/**
 * Whenever the play position changes, update the viewport to make sure the playhead is
 * visible. This has two effects:
 *
 * - While playing, the playhead will remain visible. We implement it such that it remains
 *   in a stable position, and we move the viewport, always.
 *
 * - When seeking to a new location, the viewport will be adjusted to show the play head.
 *
 * Note that this used to be implemented as a "function of the play position" - it forced
 * the viewport on every frame to be "correct". However, for now, this is not what we want,
 * since while playback is being paused, we want to allow the user to scroll the viewport
 * away from the play position. Thus, the code here only executes on a play position "change",
 * not on every frame.
 * */
function useMakeOffsetFollowPlayPosition(
  playerAPI: PlayerAPI, secondsVisible: number, offsetSeconds: RefObject<number>, setOffsetSeconds: any
) {
  const seekInProgress = useRef<number|null>(null);

  // This stores the last known play head position
  const [playHeadPosSeconds, setPlayHeadPosSeconds, playHeadPosSecondsRef] = useRefState(0);

  // instead of executing the follow logic on every frame, should it instead subscribe to bacon?
  const onTime = useCallback(time =>
  {
    // Since this is triggered via requestAnimationFrame, not via the playerAPI.time$ callback, we will
    // keep getting an old value after a seek instruction for a couple of frames, until it takes old.
    // We use a hack here to try to hold any changes until the seek is complete.
    // We are lucky this seems to work!
    if (seekInProgress.current) {
      if (seekInProgress.current === time) {
        // The seek is complete, now free the blocker.
        seekInProgress.current = null;
      }
      else {
        return;
      }
    }

    // Attempt to adjust the offset while keeping the play position the same. Note that this
    // will not work anymore when reaching the end of the timeline, but that is ok: the setOffsetSeconds()
    // function will clamp it to the end, and the play head, which we do not actually control via the state,
    // will render in the right place always.
    setOffsetSeconds(time - playHeadPosSecondsRef.current);
  }, [setOffsetSeconds, secondsVisible, offsetSeconds]);

  useOnCurrentPlayTime(playerAPI, onTime);

  const seekWithPlayHead = useCallback((time: number) => {
    seekInProgress.current = time;
    setPlayHeadPosSeconds(time - offsetSeconds.current);
    playerAPI.seekTo(time);
  }, [playerAPI, setPlayHeadPosSeconds, offsetSeconds])


  return [playHeadPosSeconds, seekWithPlayHead];
}


function useOffsetDraggingEvents(offsetSeconds: RefObject<number>, setOffsetSeconds: any) {
  const startOffset = useRef(0);

  const onMoveStart = useCallback(() => {
    startOffset.current = offsetSeconds.current;
  }, [startOffset]);

  const onMoving = useCallback((offsetShift: number) => {
    let newOffset = startOffset.current + offsetShift;
    if (newOffset < 0) {
      newOffset = 0;
    }
    setOffsetSeconds(newOffset);
  }, [setOffsetSeconds, startOffset]);

  const onMoveDone = useCallback(() => {}, [])

  return {
    onMoving,
    onMoveDone,
    onMoveStart,
  }
}

/**
 * Returns event handlers for <Viewport> which have the affect of seeking the player when we scroll the
 * viewport, assuming the play head to remain in the position it is.
 */
function usePlayTimeDraggingEvents(offsetSeconds: RefObject<number>, setOffsetSeconds: any, playerAPI: PlayerAPI) {
  const startPosition = useRef<number>(0);
  const startOffset = useRef<number>(0);
  const wasPlaying = useRef(false);

  const onMoveStart = useCallback((position) => {
    startPosition.current = playerAPI.getCurrentTime();
    startOffset.current = offsetSeconds.current;
    wasPlaying.current = playerAPI.getState().isPlaying;
    playerAPI.pause();
  }, [playerAPI, startPosition])

  const onMoving = (offsetShift: number) => {
    if (startOffset.current! + offsetShift < 0) {
      offsetShift = -startOffset.current!;
    }

    playerAPI.seekTo(startPosition.current + offsetShift);
  }

  const onMoveDone = useCallback(() => {
    if (wasPlaying.current) {
      playerAPI.play();
    }
  }, [playerAPI, wasPlaying])

  return {
    onMoveStart,
    onMoving,
    onMoveDone,
  }
}


// Merge the dragging events for playing and not playing. This is kind of a hack because <Viewport> does not
// accept changes to the events.
function useDraggingEvents(playerAPI: PlayerAPI, playEvents: any, pauseEvents: any) {
  const wasPlayingOnMoveStart = useRef(false);

  const onMoveStart = useCallback((position) => {
    const isPlaying = playerAPI.getState().isPlaying;
    wasPlayingOnMoveStart.current = isPlaying;
    if (isPlaying) {
      playEvents.onMoveStart(position);
    }
    else {
      pauseEvents.onMoveStart(position);
    }
  }, [playerAPI, playEvents, pauseEvents, wasPlayingOnMoveStart])

  const onMoving = useCallback((position) => {
    if (wasPlayingOnMoveStart.current) {
      playEvents.onMoving(position);
    }
    else {
      pauseEvents.onMoving(position);
    }
  }, [playerAPI, playEvents, pauseEvents, wasPlayingOnMoveStart])

  const onMoveDone = useCallback(() => {
    if (wasPlayingOnMoveStart.current) {
      playEvents.onMoveDone();
    }
    else {
      pauseEvents.onMoveDone();
    }
  }, [playerAPI, playEvents, pauseEvents, wasPlayingOnMoveStart])

  return {
    onMoveStart,
    onMoving,
    onMoveDone,
  }
}


/**
 * Return a callback that will change the selected line in `captionsEditor` when invoked.
 *
 * This is used when a subtitle is selected in the timeline.
 */
function useSelectLineInCaptionEditor(captionsEditor: ICaptionsEditor|null) {
  return useCallback((lineIdx: number) => {
    if (!captionsEditor) {
      return null;
    }

    Transforms.select(captionsEditor.slateEditor, [lineIdx]);
  }, [captionsEditor]);
}


function useOnTapWhichChangesPlayheadPosition(offsetSeconds: RefObject<number>, playHeadSeek: any) {
  return useCallback((newPosition: number) => {
    playHeadSeek(newPosition + offsetSeconds.current!);
  }, [offsetSeconds, playHeadSeek]);
}


function useChangeViewportToShowLineSelectedInEditor(
  captions: ICaptionsEditor|null,
  setOffsetSeconds: any,
  secondsVisible: number,
  offsetSeconds: RefObject<number>
) {
  const selectedLineIdx = useMemo(() => {
    return captions ? getSelectedLineGroupIndex(captions.slateEditor) : null;
  }, [captions?.slateEditor.children, captions?.slateEditor.selection]);

  // We have to make sure we trigger our effect only if the selected line changes!
  const captionsRef = useCopyToRef(captions);

  useEffect(() => {
    if (!selectedLineIdx || !captionsRef.current) {
      return;
    }
    const {time} = captionsRef.current.committedValue[selectedLineIdx].data;
    if (time) {
      let newOffset = offsetSeconds.current;

      // Logic: Wrap around when we reach the end of the visible area.
      if (time > offsetSeconds.current + secondsVisible) {
        newOffset = time;
      }

      // Logic: If outside of view on the left, move to center.
      if (time < newOffset) {
        newOffset = Math.max(0, time - (secondsVisible / 2));
      }

      setOffsetSeconds(newOffset);
    }
  }, [captionsRef, selectedLineIdx, secondsVisible, setOffsetSeconds, offsetSeconds]);
}


export function Contents(props: ContentProps) {
  const [waveformData, iswWaveFormLoading] = useLoadWaveForm(props.source);
  const [scale, secondsVisible] = useScale(waveformData, props.width);

  // This is the viewport position.
  const [offsetSeconds, setOffsetSeconds] = useOffsetSeconds(props.playerAPI, secondsVisible);

  // There are a couple of ways the viewport and play position can change. We have to make some decisions.
  //
  // While playing, we follow, keeping the playhead stable, just moving the viewport.
  const [_, playHeadSeek] = useMakeOffsetFollowPlayPosition(props.playerAPI, secondsVisible, offsetSeconds, setOffsetSeconds);

  const draggingEvents = useDraggingEvents(
    props.playerAPI,
    // And if we drag the viewport while playing, the seek position changes, the playhead remains stable.
    usePlayTimeDraggingEvents(offsetSeconds, setOffsetSeconds, props.playerAPI),
    // But if we are paused, and we pan the viewport, then we scroll, and the play position may scroll out of view.
    useOffsetDraggingEvents(offsetSeconds, setOffsetSeconds)
  )

  // And if we select a line in the editor, we scroll the viewport to make that line visible, but ony if not playing.
  useChangeViewportToShowLineSelectedInEditor(props.captionsEditor,  setOffsetSeconds, secondsVisible, offsetSeconds);
  // And if we tap on the viewport, we move the play  head to this position.
  const handleTap = useOnTapWhichChangesPlayheadPosition(offsetSeconds, playHeadSeek);

  // If we select a line in the timeline, mirror that selection to the text editor.
  const handleLineSelect = useSelectLineInCaptionEditor(props.captionsEditor);

  const {width, height} = props;
  const offsetSecondsToRender = offsetSeconds.current;

  return <>
    <Viewport
      x={0}
      y={0}
      height={height}
      width={width}
      scale={scale}
      {...draggingEvents}
      onTap={handleTap}
    />
    {/* Renders too many texts on each update - we need to move them instead. */}
    <TimeScale
      scale={scale}
      offsetSeconds={offsetSecondsToRender}
      x={0}
      y={0}
      height={height}
      width={width}
    />

    {waveformData && (
      <>
        <WaveformShape
          data={waveformData}
          scale={scale}
          offsetSeconds={offsetSecondsToRender}
          x={0}
          y={60}
          height={height - 60}
          width={width}
        />
      </>)}

    <LoadingStatus
      isLoading={iswWaveFormLoading}
      hasData={!!waveformData}
      stageHeight={height}
    />

    {(props.captions && props.captionsEditor) && <CaptionOverlay
      captions={props.captions}
      offsetSeconds={offsetSecondsToRender}
      scale={scale}
      maxSeconds={offsetSecondsToRender + scale.pixelsToTime(width)}
      captionsEditor={props.captionsEditor}
      onLineSelect={handleLineSelect}
      selectedLine={getSelectedLineGroupIndex(props.captionsEditor?.slateEditor, 0)!}
    />}

    <PlayHead
      height={height}
      scale={scale}
      offsetSeconds={offsetSecondsToRender}
      playerAPI={props.playerAPI}
      showText={false}
    />
  </>;
}

// TODO: Bring this back. Difficulty is Pixi not supporting the event, so we have to
// attach it at the State/canvas level - then either sent it down, or we attach it
// at the body in <Contents>, then filter.
// handleWheel = (e: any) => {
//   if (e.deltaMode !== 0 || e.deltaX === 0) {
//     return;
//   }
//
//   this.setOffsetSeconds(this.state.offsetSeconds + e.deltaX);
//   e.preventDefault();
// }
//



async function loadWaveformFromUrl(url: string, audioContext: AudioContext) {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();

  return await new Promise<WaveformData>((resolve, reject) => {
    webAudioBuilder(audioContext, buffer, (err: any, waveform: any) => {
      if (err) {
        reject(err);
        return;
      }

      resolve(waveform as WaveformData);
    });
  })
}