import React, { useMemo } from 'react';
import {ApolloProvider} from "multi-apollo";
import {ApolloClient, InMemoryCache} from '@apollo/client';
import {setContext} from "@apollo/client/link/context";
import {createUploadLink} from 'apollo-upload-client';


export function makeApolloClient(opts: {
  authHeader: string|(() => string|Promise<string>),
  uri: string
}) {
  const {authHeader} = opts;

  // apollo-link-context is the right thing to have an async call to prepare the headers. See  more information
  // here on how I deduced this and how the links work:
  // https://www.apollographql.com/docs/link/overview/
  // https://github.com/apollographql/apollo-link/blob/c32e170b72ae1a94cea1c633f977d2dbfcada0e1/packages/apollo-link-http/src/httpLink.ts#L84
  // https://github.com/apollographql/apollo-client/issues/2441
  const httpLink = createUploadLink({ uri: opts.uri})

  // This will resolve the auth token dynamically, then inject it.
  const injectAuthLink = setContext(async (request, {headers}) => {
    if (typeof opts.authHeader === 'string') {
      return { headers: {
          'Authorization': opts.authHeader,
          ...headers
        }};
    };

    return { headers: {
        'Authorization': await opts.authHeader(),
        ...headers
      }};
  });

  // So then this is our full link: first auth header inject, then http.
  const link = injectAuthLink.concat(httpLink);

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}


export function createWordsAdminClient(getAuthHeader: () => Promise<string>) {
  return makeApolloClient({
    uri: process.env.REACT_APP_WORDS_ADMIN_URL || 'http://localhost:5000/graphql',
    authHeader: getAuthHeader
  })
}


export function createMarketClient(getAuthHeader: () => Promise<string>) {
  return makeApolloClient({
    uri: process.env.REACT_APP_MARKET_API_URL!,
    authHeader: getAuthHeader
  })
}


export function ApolloRoot(props: {
  children: any,
  getAuthHeader: () => Promise<string>
}) {
  const wordsAdminClient = useMemo(() => {
    return createWordsAdminClient(props.getAuthHeader);
  }, [props.getAuthHeader]);

  const marketClient = useMemo(() => {
    return createMarketClient(props.getAuthHeader);
  }, [props.getAuthHeader]);

  return <ApolloProvider
    defaultClient={wordsAdminClient}
    clients={{
      words: wordsAdminClient,
      market: marketClient
    }}
  >
    {props.children}
  </ApolloProvider>
}