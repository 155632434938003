import {Button, Popover, ListItemIcon, Checkbox, makeStyles, List, ListItem, ListItemText} from "@material-ui/core";
import React, { useCallback, Fragment } from "react";
import {IndexedContentIssue, IndexedContentIssues, useMarkIssueAsResolved} from "../../Market/ContentIssues";
import {UserTag} from "./CreateIssueButton";
import {Tag} from "../../Market/ContentIssuesScreen/SingleIssue";


const useStyles = makeStyles(theme => ({
  nodeNotFoundItem: {
    color: 'gray'
  },
  nodeNotFoundItemSecondary: {
    color: theme.palette.primary.light
  }
}));


export const IssueManagerButton = React.memo((props: {
  issues: IndexedContentIssues,
  setCurrentLineIdx: (idx: number) => void
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  if (!props.issues.asList.length) {
    return null;
  }

  return <>
    <Button color="primary" onClick={handleClick}>
      Issues
    </Button>

    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <IssueManager
        issues={props.issues}
        setCurrentLineIdx={props.setCurrentLineIdx}
      />
    </Popover>
  </>
});


export function IssueManager(props: {
  issues: IndexedContentIssues,
  setCurrentLineIdx: (idx: number) => void
}) {
  const classes = useStyles();
  const markAsResolved = useMarkIssueAsResolved();
  const handleClick = (issue: IndexedContentIssue) => {
    if (issue.lineIndex) {
      props.setCurrentLineIdx(issue.lineIndex);
    }
  };

  return <List component="nav" aria-label="main mailbox folders">
    {
      props.issues.asList.map(issue => {
        let textComonent;

        let descriptionLimited = issue.issue.description.slice(0, 200);
        let text = <>
          <UserTag editor={issue.issue.assignedTo} style={{marginRight: '4px'}} />
          {descriptionLimited}
        </>;

        if (issue.targetAvailable) {
          textComonent = <ListItemText
            primary={text}
            secondary={<Fragment>
              {issue.issue.tags?.map(tag => <Tag key={tag} tag={tag} />)}
            </Fragment>}
          />
        }
        else {
          textComonent = <ListItemText
            primary={text}
            primaryTypographyProps={{
              className: classes.nodeNotFoundItem
            }}
            secondary={"The broken link this refers to was not found. Consider this fixed."}
            secondaryTypographyProps={{
              className: classes.nodeNotFoundItemSecondary
            }}
          />
        };

        return <ListItem button key={issue.issue.id} onClick={() => { handleClick(issue) }}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={issue.issue.isResolved}
              tabIndex={-1}
              disableRipple
              onClick={() => {
                markAsResolved({
                  variables: {
                    issueId: issue.issue.id,
                    isResolved: !issue.issue.isResolved
                  }
                })
              }}
            />
          </ListItemIcon>

          {textComonent}

        </ListItem>;
      })
    }
  </List>;
}