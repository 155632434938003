import {Controlled as CodeMirror} from 'react-codemirror2'
import {Captions, loadLinesFromExternalCaptionTrack, slateValueToCaptions} from "../../models/Captions";
import React, {useMemo, useState} from 'react';
import styles from './CaptionTrackSource.cssm';
import 'codemirror/mode/javascript/javascript';
require('codemirror/lib/codemirror.css');

import 'codemirror/addon/search/search.js';
import 'codemirror/addon/dialog/dialog.js';
import 'codemirror/addon/dialog/dialog.css';
import 'codemirror/addon/search/jump-to-line.js';
import 'codemirror/addon/search/match-highlighter.js';
import 'codemirror/addon/search/matchesonscrollbar.css';
import 'codemirror/addon/search/matchesonscrollbar.js';
import 'codemirror/addon/search/searchcursor.js';
import {Button} from '@material-ui/core';
import {NewUnitState, UnitAPI} from "../../models/Unit";

require('codemirror/addon/fold/foldcode');
require('codemirror/addon/fold/foldgutter');
require('codemirror/addon/fold/foldgutter.css');



export function CaptionTrackSourceView(props: {
  track: Captions,
}) {
  const formatted = useMemo(() => JSON.stringify(slateValueToCaptions(props.track), null, 4), []);

  const handleSave = (value: string) => {
    props.track.setLines(loadLinesFromExternalCaptionTrack(JSON.parse(value)))
  }

  return  <SourceView
    defaultValue={formatted}
    onSave={handleSave}
  />
}


export function NewUnitStateSourceView(props: {
  state: NewUnitState<unknown>,
  unitAPI: UnitAPI
}) {
  const formatted = useMemo(() => JSON.stringify(props.state.data, null, 4), []);

  const handleSave = (value: string) => {
    props.unitAPI.setUnitData(x => JSON.parse(value));
  }

  return  <SourceView
    defaultValue={formatted}
    onSave={handleSave}
  />
}


// NB: In the material theme, the fold gutter is not shown
export function SourceView(props: {
  defaultValue: string,
  onSave: (value: string) => void
}) {
  const [value, setValue] = useState(props.defaultValue);

  const editor = <CodeMirror
    className={styles.editor}
    value={value}
    options={{
      mode: {name: "javascript", json: true},
      extraKeys: {"Ctrl-E": function(cm: any){ cm.foldCode(cm.getCursor()); }},
      lineNumbers: true,
      foldGutter: true,
      lineWrapping: true,
      gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
    }}
    onBeforeChange={(editor, data, value) => {
      setValue(value)
    }}
    onChange={(editor, data, value) => {
    }}
  />

  return <>
    <div>
      <Button onClick={() => props.onSave(value)}>Take Changes</Button>
    </div>
    {editor}
  </>
}