import {Element, Node, Text} from "slate";


export type LineData = {
  time?: number,
  duration?: number,
  speakers?: number[]
}


export type WordData = {
  time?: number,
  duration?: number,
  alternates?: any
}


export interface LineGroup extends Element {
  type: 'linegroup',
  key: string,
  data: LineData,
  children: Line[]
}

export interface Line extends Element {
  type: 'line',
  key: string,
  children: (Text|WordInline)[]
}


export interface WordInline extends Element {
  type: 'timed',
  key: string,
  data: WordData,
  children: Text[]
}


export function isWordInline(n: Node): n is WordInline {
  return "type" in n && n.type == "timed";
}

export function isLineGroup(n: Node): n is LineGroup {
  return "type" in n && n.type == "linegroup";
}

export function isLine(n: Node): n is Line {
  return "type" in n && n.type == "line";
}


export type CaptionElement = WordInline|Line|LineGroup;


export type CaptionTrackRoot = LineGroup[];