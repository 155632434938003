import * as React from 'react';
import {useState, useMemo, useContext} from 'react';


type GlobalSettingsType = {
  transliterationStyle: string
};

const DefaultSettings: GlobalSettingsType = {
  transliterationStyle: 'wiktionary'
}


interface GlobalSettingsContextType extends GlobalSettingsType {
  setTransliterationStyle: (style: string) => void
}


const GlobalSettingsContext = React.createContext<GlobalSettingsContextType>({
  setTransliterationStyle: (s: string) => {},
  ...DefaultSettings,
});


export function GlobalSettings(props: {
  children: any,
}) {
  const [value, setValue] = useState<GlobalSettingsType>(DefaultSettings);

  const funcs = useMemo(() => {
    return {
      setTransliterationStyle: (newStyle: string) => {
        setValue({...value, transliterationStyle: newStyle});
      }
    }
  }, [setValue, value]);

  const contextValue = useMemo(() => {
    return {
      ...value,
      ...funcs
    }
  }, [value, funcs]);

  return <GlobalSettingsContext.Provider value={contextValue}>
    {props.children}
  </GlobalSettingsContext.Provider>
}


export function useTransliterationStyle() {
  const ctx = useContext(GlobalSettingsContext);
  return ctx.transliterationStyle;
}