import Bacon from "baconjs";
import {Annotation, isQueryByString, AnnotationQuery, isQueryByInflection, isStringWordId} from "./index";
import {AnnotationsCache, ISimpleCache, SimpleCache} from "../cache";
import React from "react";


type FollowLogic = (annotation: Annotation) => AnnotationQuery[]|undefined;


// A loader is an interface to load annotations for either a word, a vocable or an structure node.
export interface IAnnotationLoader {
  // Say which word to load. For each annotation found, you get followLogic(), with which you can
  // request related annotations. You'll receive an object with which you can access everything loaded.
  follow$(
    word: AnnotationQuery,
    followLogic?: FollowLogic,
  ): Bacon.EventStream<any, ISimpleCache>;
}


export interface AnnotationLoaderConfig {
  wordsApiUrl: string,
  learnerLangs: string[]
}


/**
 * Can query for a wordID.
 */
export class AnnotationLoader implements IAnnotationLoader {

  private cfg: AnnotationLoaderConfig;
  private cache?: AnnotationsCache;

  constructor(cfg: AnnotationLoaderConfig, cache?: AnnotationsCache) {
    this.cache = cache;
    this.cfg = cfg;
  }

  /**
   * Load all annotations for the given query.
   *
   * Via `followLogic`, you can request additional, related annotations.
   *
   * You receive a result object which allows you to see all the returned annotation objects.
   *
   * If the server returns, by it's own devices, any related annotations, then those are
   * also added the result cache.
   *
   * TODO: a cache-only loader would always use what we have, regardless of whether the cache
   * has the full response. So if the user downloads annotations for a certain set of reader languages,
   * but then
   */
  follow$(query: AnnotationQuery, followLogic?: FollowLogic): Bacon.EventStream<any, ISimpleCache> {
    return this._follow$(query, followLogic)
      .map(x => this.cache!)
      .startWith(this.cache!)
      // We debounce this, because currently we get annotations from the server or the cache as an array,
      // then we stream the individual elements here, which causes unnecessarily many updates.
      .debounce(20);
  }

  _follow$(query: AnnotationQuery, followLogic?: FollowLogic): Bacon.EventStream<any, Annotation> {
    let annotations$: Bacon.EventStream<any, Annotation>;

    // If the answer is in the cache, use that.
    if (this.cache && this.cache.hasKnowledge(query)) {
      const localAnnotations = this.cache.get(query);
      annotations$ = Bacon.fromArray(localAnnotations);
    }

    // Otherwise, query the server for annotations.
    else {
      annotations$ = Bacon.fromPromise(loadFromServer(
        this.cfg.wordsApiUrl, query, this.cfg.learnerLangs, this.cache
      ).then(result => {
        // Any related annotations have been added to the cache, but are not returned by us.
        return result.main;
      })).flatMap(x => Bacon.fromArray(x));
    }

    // Let follow function provide us with more queries to do.
    // @ts-ignore: Error in CLI but not in PyCharm?
    return annotations$.flatMap(annotation => {
      if (!followLogic) {
        return Bacon.once(annotation);
      }
      const moreQueries = followLogic(annotation);
      if (!moreQueries) {
        return Bacon.once(annotation);
      }

      return Bacon.fromArray(moreQueries).flatMap(query => {
        return this._follow$(query, followLogic);
      }).startWith(annotation);
    });
  }
}

/**
 * Load annotations for `word`, store them in `cache`.
 */

async function loadFromServer(
  url: string,
  query: AnnotationQuery,
  learnerLangs: string[],
  cache?: AnnotationsCache
): Promise<{
  main: Annotation[]
}> {
  //const filteredLearnerLangs = learnerLangs.filter(lang => lang != sourceLang);

  let annotateRequest;

  if (isQueryByInflection(query)) {
    let extra = {};
    if (isStringWordId(query.wordId)) {
      extra = {
        'text': query.wordId.text,
        'lang': query.wordId.lang,
      }
    } else {
      extra = {
        'word_id': query.wordId.id,
        'meaning_id': query.wordId.meaningId,
      }
    }
    annotateRequest = {
      learner_langs: learnerLangs,
      ...extra,
      structure: query.structure,
      inflection: query.inflection
    }
  }
  else if (!isQueryByString(query)) {
    annotateRequest = {
      word_id: query.id,
      meaning_id: query.meaningId,
      learner_langs: learnerLangs
    }
  } else {
    annotateRequest = {
      text: query.text,
      lang: query.lang,
      learner_langs: learnerLangs,
      is_lemma: query.isLemma
    }
  }

  const response = await fetch(`${url}/annotate`, {
    method: 'POST',
    body: JSON.stringify(annotateRequest),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const data: {
    root: Annotation[],
    related: Annotation[],
  } = await response.json();

  // Add all results to the cache.
  if (cache) {
    cache.add(data.root, {override: true});

    // The server sending related annotations (such as for the base word)
    // is kind of out outdated idea. We add those results to the cache,
    // but such that they do not override any existing data stored there.
    cache.add(data.related, {override: false});

    // Sometimes what happens is that the server returns no annotations for the query.
    // Maybe only annotations for related words/keys that it knows the popup might use.
    // We want to then tell the cache about the queried set being empty so we don't
    // have to query again next time only to get an empty result again.
    cache.ensureKeyExists(query);
  }

  // The server sending related annotations (such as for the base word)
  // is kind of out outdated idea. While we add them to the cache, we do
  // do return them. The caller can look into the cache to see if they exist.
  return {
    main: data.root
  }
}


/**
 * Return a default annotations loader.
 */
export function useAnnotationLoader(props?: {
  url?: string,
  learnerLangs?: string[]
}) {
  const annotationCache = React.useMemo(() => new AnnotationsCache(), []);
  return React.useMemo(
    () =>
      new AnnotationLoader(
        {
          wordsApiUrl: props?.url || 'https://words.languagetool.xyz',
          learnerLangs: props?.learnerLangs ?? ["en"],
        },
        annotationCache
      ),
    [annotationCache, props?.url, props?.learnerLangs]
  );
}