import {NestableField} from "../../../../components/NestedFormik";
import {validateRequired} from "../index";
import React from "react";
import {useUIControls} from "../../../../components/FormUISwitch";


export function WordBaseField(props: any) {
  const UI = useUIControls();

  return <NestableField
    name={"base"}
    validate={validateRequired}
    component={UI.Text}

    label="Base form"
    margin="normal"
    description={"The base form of the word - vowels *must* be included."}

    {...props}
  />;
}