// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-dialogs-StartTimingDialog__selectionButton--1tegh{margin:20px 0 0 !important;display:flex !important}._-languagetool-editor-src-js-ui-dialogs-StartTimingDialog__selection--38psX{padding:10px;flex:1}\n", ""]);
// Exports
exports.locals = {
	"selectionButton": "_-languagetool-editor-src-js-ui-dialogs-StartTimingDialog__selectionButton--1tegh",
	"selection": "_-languagetool-editor-src-js-ui-dialogs-StartTimingDialog__selection--38psX"
};
module.exports = exports;
