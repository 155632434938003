import React from 'react'


const NothingFoundIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
    <path
      d="M86.703 55.884c.3-.616.566-1.264.796-1.943 2.633-7.77-1.349-17.078-9.733-19.325-.906-11.384-8.906-19.193-17.941-20.526-10.341-1.525-19.814 5.044-22.966 15.485-3.799-1.346-7.501-1.182-10.99.857-1.583.732-3.031 1.812-4.33 3.233-1.907 2.086-3.147 4.719-3.652 7.495-.748.118-1.483.236-2.176.484-4.04 1.449-6.589 4.431-7.288 8.923-.435 2.797.443 5.587.933 6.714 1.935 4.455 6.422 6.98 10.981 6.312.227-.033.557.069.752.233.241 7.12 3.698 13.417 8.884 17.014 8.321 5.772 19.027 3.994 25.781-3.921 2.894 2.96 6.338 4.398 10.384 3.876 4.023-.519 7.147-2.739 9.426-6.349 1.053.283 2.051.691 3.083.804 4.042.442 7.297-1.184 9.732-4.8.083-.124.203-.219.306-.328l1.044-2.394c.155-1.211.429-2.423.443-3.632.039-3.255-1.292-6.222-3.469-8.212z"
      fill="#c8ede6"
    />
    <path
      d="M78.528 68.063H90.29a4.888 4.888 0 0 0 1.004-9.673c.026-.206.043-.415.043-.628a5.063 5.063 0 0 0-5.063-5.063 5.049 5.049 0 0 0-4.037 2.017 5.584 5.584 0 0 0-6.27-5.116c-2.379.291-4.346 2.13-4.784 4.486a5.683 5.683 0 0 0 .014 2.177 3.479 3.479 0 0 0-2.557-1.119 3.49 3.49 0 0 0-3.48 3.261 4.686 4.686 0 0 0-2.717.188c-1.84.691-3.15 2.423-3.227 4.387a4.89 4.89 0 0 0 4.885 5.085H75.37"
      fill="#fdfcef"
    />
    <path
      d="M90.29 68.563H78.528a.5.5 0 0 1 0-1H90.29a4.394 4.394 0 0 0 4.389-4.389 4.405 4.405 0 0 0-3.486-4.295.499.499 0 0 1-.394-.55c.023-.187.039-.375.039-.567a4.568 4.568 0 0 0-4.563-4.563 4.53 4.53 0 0 0-3.638 1.818.499.499 0 1 1-.897-.263 5.108 5.108 0 0 0-1.851-3.549 5.058 5.058 0 0 0-3.86-1.109c-2.166.265-3.956 1.943-4.353 4.081a5.162 5.162 0 0 0 .013 1.986.5.5 0 0 1-.857.44 2.96 2.96 0 0 0-2.19-.959 2.995 2.995 0 0 0-2.981 2.794.502.502 0 0 1-.607.455 4.153 4.153 0 0 0-2.433.167c-1.669.626-2.835 2.208-2.903 3.938a4.357 4.357 0 0 0 1.222 3.218 4.352 4.352 0 0 0 3.163 1.347h11.269a.5.5 0 0 1 0 1h-11.27a5.342 5.342 0 0 1-3.884-1.654 5.348 5.348 0 0 1-1.5-3.95c.083-2.126 1.51-4.069 3.55-4.835a5.203 5.203 0 0 1 2.468-.299 4 4 0 0 1 3.905-3.182c.684 0 1.354.18 1.944.51 0-.385.035-.772.106-1.159.477-2.562 2.621-4.573 5.215-4.891a6.059 6.059 0 0 1 4.615 1.328 6.126 6.126 0 0 1 2.038 3.193 5.501 5.501 0 0 1 3.714-1.425 5.57 5.57 0 0 1 5.556 5.812 5.413 5.413 0 0 1 3.848 5.164 5.393 5.393 0 0 1-5.387 5.388z"
      fill="#472b29"
    />
    <path
      d="M75.695 57.294c-1.808-.119-3.365 1.13-3.476 2.789-.014.206-.005.409.025.606a2.127 2.127 0 0 0-1.458-.7c-1.085-.071-2.022.645-2.158 1.62a3.097 3.097 0 0 0-.616-.104c-1.582-.104-2.944.989-3.042 2.441"
      fill="#fdfcef"
    />
    <path
      d="M64.97 64.195h-.017a.25.25 0 0 1-.233-.266c.107-1.586 1.58-2.783 3.308-2.673.135.009.271.026.408.053.274-.968 1.258-1.636 2.365-1.568a2.42 2.42 0 0 1 1.164.382c0-.019.002-.037.003-.055.121-1.794 1.795-3.139 3.742-3.022a.25.25 0 0 1 .233.266.245.245 0 0 1-.266.233c-1.664-.111-3.108 1.038-3.21 2.557-.012.184-.004.37.023.552a.25.25 0 0 1-.434.203 1.892 1.892 0 0 0-1.288-.617c-.94-.062-1.775.556-1.894 1.405a.251.251 0 0 1-.314.207 2.828 2.828 0 0 0-.566-.096c-1.437-.104-2.688.895-2.776 2.208a.247.247 0 0 1-.248.231z"
      fill="#472b29"
    />
    <path
      d="M92.269 58.963c-1.699-.801-3.664-.234-4.389 1.267-.09.186-.157.379-.201.574"
      fill="#fdfcef"
    />
    <path
      d="M87.679 61.053a.25.25 0 0 1-.244-.305c.049-.217.124-.428.22-.627.783-1.622 2.902-2.243 4.72-1.384a.25.25 0 0 1-.213.453c-1.573-.741-3.394-.227-4.057 1.149-.08.165-.142.34-.183.521a.247.247 0 0 1-.243.193z"
      fill="#472b29"
    />
    <g fill="#fff">
      <path d="M23.578 63.789h-10.03a.5.5 0 0 1 0-1h10.03a.5.5 0 0 1 0 1zM26.46 63.789h-1.446a.5.5 0 0 1 0-1h1.446a.5.5 0 0 1 0 1zM30.514 63.789h-2.545a.5.5 0 0 1 0-1h2.545a.5.5 0 0 1 0 1zM30.514 65.655h-9.616a.5.5 0 0 1 0-1h9.616a.5.5 0 0 1 0 1zM19.166 65.655h-.58a.5.5 0 0 1 0-1h.58a.5.5 0 0 1 0 1zM16.789 65.655h-1.456a.5.5 0 0 1 0-1h1.456a.5.5 0 0 1 0 1zM25.925 61.925h-5.027a.5.5 0 0 1 0-1h5.027a.5.5 0 0 1 0 1zM25.925 60.06h-1.257a.5.5 0 0 1 0-1h1.257a.5.5 0 0 1 0 1zM22.656 67.519h-1.759a.5.5 0 0 1 0-1h1.759a.5.5 0 0 1 0 1z" />
    </g>
    <path
      d="M38.537 53.18h3.54c2.11 0 3.821-1.674 3.821-3.739 0-1.802-1.302-3.305-3.035-3.66.02-.158.034-.317.034-.48 0-2.139-1.772-3.873-3.957-3.873-1.29 0-2.433.607-3.155 1.543-.185-2.381-2.358-4.218-4.9-3.913-1.859.223-3.397 1.629-3.739 3.431a4.252 4.252 0 0 0 .011 1.665 2.746 2.746 0 0 0-1.998-.856c-1.446 0-2.627 1.102-2.72 2.494a3.745 3.745 0 0 0-2.123.143c-1.438.528-2.462 1.853-2.522 3.356-.085 2.133 1.657 3.889 3.818 3.889h8.808m1.64 0h.36"
      fill="#fdfcef"
    />
    <path
      d="M42.077 53.68h-3.541a.5.5 0 0 1 0-1h3.541c1.831 0 3.32-1.453 3.32-3.239 0-1.524-1.108-2.857-2.635-3.17a.5.5 0 0 1-.396-.553c.018-.137.029-.276.029-.417 0-1.86-1.551-3.373-3.457-3.373a3.457 3.457 0 0 0-2.759 1.348.5.5 0 0 1-.894-.267 3.716 3.716 0 0 0-1.37-2.604 3.912 3.912 0 0 0-2.973-.852c-1.646.197-3.006 1.442-3.307 3.028-.094.494-.091.988.01 1.471a.5.5 0 0 1-.853.445 2.23 2.23 0 0 0-1.636-.7c-1.169 0-2.145.891-2.221 2.027a.5.5 0 0 1-.605.455 3.212 3.212 0 0 0-1.845.124c-1.262.464-2.144 1.632-2.195 2.906a3.176 3.176 0 0 0 .895 2.345 3.328 3.328 0 0 0 2.423 1.024h8.808a.5.5 0 0 1 0 1H21.61a4.379 4.379 0 0 1-3.145-1.332 4.166 4.166 0 0 1-1.172-3.077c.066-1.674 1.211-3.203 2.849-3.805a4.25 4.25 0 0 1 1.88-.245c.344-1.396 1.629-2.424 3.135-2.424.493 0 .977.113 1.414.323.009-.242.037-.484.083-.726.381-2.009 2.097-3.586 4.17-3.834a4.91 4.91 0 0 1 3.726 1.072 4.725 4.725 0 0 1 1.546 2.302 4.465 4.465 0 0 1 2.843-1.007c2.458 0 4.457 1.961 4.457 4.373l-.001.102c1.764.555 3.003 2.188 3.003 4.038 0 2.34-1.938 4.242-4.321 4.242zm-9.658 0h-.359a.5.5 0 0 1 0-1h.359a.5.5 0 0 1 0 1z"
      fill="#472b29"
    />
    <path
      d="M40.369 47.876a.249.249 0 0 1-.243-.305c.038-.167.095-.327.169-.479.604-1.224 2.275-1.675 3.722-1.007a.25.25 0 0 1-.21.454c-1.201-.555-2.578-.208-3.063.774-.058.116-.101.24-.13.367a.25.25 0 0 1-.245.196zM35.211 53.68h-1.107a.5.5 0 0 1 0-1h1.107a.5.5 0 0 1 0 1z"
      fill="#472b29"
    />
    <g fill="#fff">
      <path d="M69.546 21.643h-10.03a.5.5 0 0 1 0-1h10.03a.5.5 0 0 1 0 1zM72.427 21.643h-1.446a.5.5 0 0 1 0-1h1.446a.5.5 0 0 1 0 1zM76.482 21.643h-2.546a.5.5 0 0 1 0-1h2.546a.5.5 0 0 1 0 1zM74.456 17.914H64.84a.5.5 0 0 1 0-1h9.617a.5.5 0 1 1-.001 1zM63.108 17.914h-.58a.5.5 0 0 1 0-1h.58a.5.5 0 0 1 0 1zM60.731 17.914h-1.456a.5.5 0 0 1 0-1h1.456a.5.5 0 0 1 0 1zM71.893 19.778h-5.027a.5.5 0 0 1 0-1h5.027a.5.5 0 0 1 0 1z" />
      <path d="M71.893 17.914h-1.257a.5.5 0 0 1 0-1h1.257a.5.5 0 0 1 0 1zM65.41 19.778h-1.759a.5.5 0 0 1 0-1h1.759a.5.5 0 0 1 0 1z" />
    </g>
    <path
      d="M39.737 27.268h-.795c-3.011 0-5.474 2.408-5.474 5.35v37.7c0 2.943 2.463 5.35 5.474 5.35h27.289c3.011 0 5.474-2.408 5.474-5.35V39.673L59.013 27.268H47.258"
      fill="#edf7f5"
    />
    <path
      d="M66.231 76.369H38.942c-3.404 0-6.174-2.714-6.174-6.05V32.618c0-3.336 2.77-6.05 6.174-6.05h.794a.7.7 0 1 1 0 1.4h-.794c-2.632 0-4.773 2.085-4.773 4.649v37.701c0 2.564 2.141 4.65 4.773 4.65h27.289c2.632 0 4.773-2.086 4.773-4.65V39.967L58.728 27.968H47.259a.7.7 0 1 1 0-1.4h11.754a.7.7 0 0 1 .489.2l12.691 12.405a.7.7 0 0 1 .211.5v30.646c.001 3.335-2.769 6.05-6.173 6.05z"
      fill="#472b29"
    />
    <path
      d="M58.855 27.574v6.73c0 3.038 2.396 5.523 5.325 5.523h7.08"
      fill="#85cfcc"
    />
    <path
      d="M71.26 40.203h-7.08c-3.143 0-5.7-2.646-5.7-5.898v-6.73a.375.375 0 0 1 .75 0v6.73c0 2.838 2.221 5.148 4.95 5.148h7.081a.375.375 0 1 1-.001.75zM68.257 51.23a.375.375 0 0 1-.375-.375v-1.838a.375.375 0 0 1 .75 0v1.838a.375.375 0 0 1-.375.375zM68.257 56.132a.375.375 0 0 1-.375-.375V52.08a.375.375 0 0 1 .75 0v3.676a.375.375 0 0 1-.375.376zM63.77 72.674H41.403c-2.681 0-4.862-2.144-4.862-4.78V35.629a4.76 4.76 0 0 1 2.15-3.962.375.375 0 0 1 .415.625 4.01 4.01 0 0 0-1.815 3.337v32.265c0 2.222 1.845 4.03 4.112 4.03H63.77c2.267 0 4.112-1.808 4.112-4.03V56.982a.375.375 0 0 1 .75 0v10.912c0 2.636-2.181 4.78-4.862 4.78z"
      fill="#472b29"
    />
    <path
      d="M46.394 28.493l-.057 7.084c0 1.354-1.206 2.412-2.429 2.412-1.223 0-2.529-1.078-2.529-2.432l.019-7.491c0-.8.897-1.523 1.646-1.523.749 0 1.47.704 1.47 1.504v6.417s-.299.613-.617.613-.717-.218-.637-.555v-6.031h-.627l-.021 5.916c0 .845.464 1.225 1.3 1.225.836 0 1.228-.437 1.228-1.283V28.1c0-1.391-.853-2.144-2.154-2.144s-2.234.773-2.234 2.164l.02 7.419c0 1.862 1.331 3.006 3.114 3.006s3.134-1.048 3.134-2.91v-7.141h-.626zM54.467 51.843H40.05a.375.375 0 0 1 0-.75h14.417a.375.375 0 0 1 0 .75z"
      fill="#472b29"
    />
    <g fill="#472b29">
      <path d="M48.825 56.132H40.05a.375.375 0 0 1 0-.75h8.775a.375.375 0 0 1 0 .75zM65.123 56.132H50.079a.375.375 0 0 1 0-.75h15.043a.375.375 0 0 1 .001.75z" />
    </g>
    <g fill="#472b29">
      <path d="M59.481 60.42h-6.895a.375.375 0 0 1 0-.75h6.895a.375.375 0 0 1 0 .75zM65.75 60.42h-5.015a.375.375 0 0 1 0-.75h5.015a.375.375 0 0 1 0 .75zM51.333 60.42H40.05a.375.375 0 0 1 0-.75h11.283a.375.375 0 0 1 0 .75z" />
    </g>
    <g fill="#472b29">
      <path d="M65.123 64.709h-8.148a.375.375 0 0 1 0-.75h8.148a.375.375 0 0 1 0 .75zM55.093 64.709H40.05a.375.375 0 0 1 0-.75h15.043a.375.375 0 0 1 0 .75z" />
    </g>
    <g>
      <path
        d="M50.103 78.328a.5.5 0 0 1-.354-.853l2.785-2.785a.5.5 0 0 1 .707.707l-2.785 2.785a.498.498 0 0 1-.353.146z"
        fill="#472b29"
      />
      <path
        d="M38.233 89.829l-.131-.131a2.73 2.73 0 0 1 0-3.848l8.088-8.088a2.73 2.73 0 0 1 3.848 0l.131.131a2.73 2.73 0 0 1 0 3.848l-8.088 8.088a2.73 2.73 0 0 1-3.848 0z"
        fill="#88ae45"
      />
      <path
        d="M40.156 91.122a3.194 3.194 0 0 1-2.277-.94l-.13-.131c-.606-.605-.939-1.415-.939-2.277s.333-1.671.94-2.277l8.087-8.088a3.224 3.224 0 0 1 4.555 0l.13.13c.607.606.94 1.416.94 2.278 0 .863-.333 1.672-.94 2.278l-8.088 8.088a3.198 3.198 0 0 1-2.278.939zm-1.569-1.646a2.224 2.224 0 0 0 3.141 0l8.088-8.088a2.223 2.223 0 0 0 0-3.141l-.13-.13a2.224 2.224 0 0 0-3.142 0l-8.087 8.088a2.223 2.223 0 0 0 0 3.141l.13.13z"
        fill="#472b29"
      />
      <path
        d="M49.807 78.113c-.923-.923-2.374-.984-3.223-.135l-.154.154.336.336 3.022 3.022.154-.154c.849-.849.789-2.3-.135-3.223z"
        fill="#d1dc82"
      />
      <path
        d="M49.794 82.269a.374.374 0 0 1-.265-.11l-3.581-3.581a.375.375 0 0 1 .53-.53l3.581 3.581a.375.375 0 0 1-.265.64z"
        fill="#472b29"
      />
      <ellipse
        transform="rotate(-45.001 61.666 66.265)"
        cx={61.667}
        cy={66.264}
        rx={9.847}
        ry={9.847}
        opacity={0.74}
        fill="#77cbd2"
      />
      <path
        d="M70.394 57.537c-4.834-4.834-12.672-4.834-17.506 0s-4.834 12.672 0 17.506 12.672 4.834 17.506 0 4.834-12.672 0-17.506zm-1.79 15.715c-3.845 3.845-10.08 3.845-13.925 0-3.845-3.845-3.845-10.08 0-13.925 3.845-3.845 10.08-3.845 13.925 0 3.845 3.846 3.845 10.08 0 13.925z"
        fill="#fdfcef"
      />
      <path
        d="M61.641 79.162a12.835 12.835 0 0 1-9.106-3.766c-2.433-2.432-3.772-5.667-3.772-9.106s1.34-6.674 3.772-9.106c5.021-5.021 13.191-5.021 18.213 0 5.021 5.021 5.021 13.191 0 18.213a12.84 12.84 0 0 1-9.107 3.765zm0-24.745a11.842 11.842 0 0 0-8.399 3.473 11.8 11.8 0 0 0-3.479 8.399c0 3.172 1.236 6.156 3.479 8.399 4.63 4.63 12.167 4.631 16.799 0 4.631-4.631 4.631-12.167 0-16.799a11.849 11.849 0 0 0-8.4-3.472zm0 22.215c-2.65 0-5.299-1.009-7.316-3.026-4.034-4.034-4.034-10.598 0-14.632 4.034-4.034 10.598-4.035 14.632 0 4.034 4.034 4.034 10.599 0 14.632a10.314 10.314 0 0 1-7.316 3.026zm0-19.685a9.317 9.317 0 0 0-6.609 2.733c-3.644 3.645-3.644 9.574 0 13.218 3.645 3.645 9.574 3.644 13.218 0s3.644-9.574 0-13.218a9.323 9.323 0 0 0-6.609-2.733zM39.535 90.673a.125.125 0 0 1-.119-.087l-1.378-4.28a.125.125 0 0 1 .238-.076l1.378 4.28a.125.125 0 0 1-.119.163zM40.407 90.375a.125.125 0 0 1-.12-.089l-1.561-5.244a.124.124 0 0 1 .084-.155.124.124 0 0 1 .155.084l1.561 5.244a.124.124 0 0 1-.119.16zM40.933 88.533a.125.125 0 0 1-.12-.089l-1.307-4.389a.124.124 0 0 1 .084-.155.122.122 0 0 1 .155.084l1.307 4.389a.124.124 0 0 1-.119.16zM41.465 86.608a.125.125 0 0 1-.12-.089l-.921-3.094a.125.125 0 1 1 .239-.071l.921 3.094a.124.124 0 0 1-.119.16z"
        fill="#472b29"
      />
    </g>
  </svg>
)

export default NothingFoundIcon
