import {NumeralNode} from "languagetool-player/src/models/Node";
import * as React from "react";
import {wordifyNumber} from "../langpacks/fa/wordifyNumber";


/**
 * Show a single numeral node in the <LineTable /> for the user to select.
 */
export function NumeralNodeOption(props: {
  node: NumeralNode
}) {
  const {quantity, isOrdinal} = props.node;

  return <div>
    {isOrdinal ? "Ordinal" : "Cardinal"}: {quantity}, {wordifyNumber(quantity)}
  </div>
}
