import React, {useContext} from 'react';
import styles from './styles.module.css';
import {Pivot, PivotItem, IPivotItemProps, Icon, Spinner} from 'office-ui-fabric-react';
import gqlw from "graphql-tag";
import {Sentences} from "./Sentences";
import {CheckIcon} from "../WordList/CheckIcon";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {ReviewStatusCheckbox} from "./ReviewStatusCheckbox";
import {ExternalAnnotations} from "./ExternalAnnotations";
import { __RouterContext as RouterContext, __RouterContext } from "react-router";
import {DeleteButton} from "./DeleteButton";
import {EditWordForm} from "./Form";
import {SimilarWords} from "./SimilarWords";
import {InfoBox} from "./InfoBox";
import { PartOfWords } from './PartOfWords';
import {CopyButton} from "./CopyButton";
import {useQuery} from "multi-apollo";
import {NotesTab} from "./NotesTab";
import {LogTab} from "./LogTab";
import {DefinitionsTab, MeaningQueryFragment} from "./DefinitionsTab";
import {EditWordQuery, EditWordQuery_word} from "../../types/EditWordQuery";
import {RecordingsTab} from "./Recordings";
import { ToolsTab } from './Tools';


const GET_WORD = gqlw`
  query EditWordQuery($id: Int!) {
    word(id: $id, allowDeleted: true) {
      id, 
      type,
      base,
      lang,
      description,
      dataBlob,
      baseTransliteration,
      isReviewed,
      deletedAt,
      meanings {
        ...MeaningFragment
      },
      numNotes: numInternalNotes(isTodo: false),
      numTodos: numInternalNotes(isTodo: true),
    }
  }
  
  ${MeaningQueryFragment}
`;

export function useWord(wordId: string) {
  const {data, loading, error, refetch} = useQuery<EditWordQuery>(GET_WORD, {
    variables: {id: parseInt(wordId)}
  });
  return {data, loading, error, refetch}
}

export function EditWord(props: any) {
  const router = useContext(RouterContext);

  const wordId = props.match.params.id;
  const {data, loading, error, refetch} = useWord(wordId);

  if (loading) return <Spinner />;
  if (error) return <div>{`Error! ${error.message}`}</div>;

  let content;
  if (!data?.word) {
    content = <div>
      This word was not found. Here is the log for the entry:

      <LogTab wordId={parseInt(wordId)} />
    </div>
  } else {
    content = <EditWordComponent
      word={data!.word}
      reload={refetch}
      onRequestClose={() => {
        router.history.replace('/')
      }}
    />;
  }

  return <div style={{margin: '20px'}}>
    {content}
  </div>;
}

export function EditWordComponent(props: {
  word: EditWordQuery_word,
  onRequestClose: () => any,
  reload: any
}) {
  const router = useContext(__RouterContext);
  const {word} = props;

  const isDeleted = !!props.word.deletedAt;

  return <div className={isDeleted ? styles.isDeleted : undefined}>
    {isDeleted ? <div className={styles.deletedFlag}>
      This word has been deleted.
    </div> : null}

    <div className={styles.rootWrapper}>
      <div className={styles.splitViewMainSection}>
        <div className={styles.title}>
          <div style={{flex: 1, display: 'flex', alignItems: 'center'}}>
            {word.isReviewed ? <CheckIcon className={styles.icon} /> : null}
            <span className={styles.wordType}>{word.type}</span>
            {props.word.base}
            <span className={styles.transliteration}>
              {romanizeSounds(JSON.parse(word.baseTransliteration))}
            </span>
            {props.word.description ? <em className={styles.description}>
              ({props.word.description})
            </em> : null}
          </div>

          {!props.word.deletedAt ?
            <div className={styles.controls}>
              <CopyButton wordId={props.word.id!} onCopied={(wordId) => {
                router.history.push(`/word/${wordId}`);
              }} />
              <DeleteButton wordId={props.word.id!} onDeleted={props.onRequestClose} />
              <ReviewStatusCheckbox
                isReviewed={word.isReviewed!}
                wordId={word.id!}
              />
            </div> : null}
        </div>
        <div style={{padding: '0'}}>IPA: /{romanizeSounds(JSON.parse(props.word.baseTransliteration), {style: 'ipa'})}/</div>

        <Pivot>
          <PivotItem
            headerText="Overview"
          >
            <OverviewTab word={props.word} />
          </PivotItem>
          <PivotItem headerText="Definitions">
            <DefinitionsTab
              wordId={props.word.id!}
              meanings={props.word.meanings?.map(x => x!)!}
              refetch={props.reload}
            />
          </PivotItem>
          <PivotItem headerText="Properties">
            <PropertiesTab word={props.word} reload={props.reload} />
          </PivotItem>
          <PivotItem headerText="Recordings">
            <RecordingsTab word={props.word} reload={props.reload} />
          </PivotItem>
          <PivotItem headerText="Tools">
            <ToolsTab
              word={props.word}
            />
          </PivotItem>
        </Pivot>
      </div>

      <div className={styles.notesSidebar}>
        <div className={styles.dictionaries}>
          <ExternalAnnotations
            wordId={props.word.id}
          />

          <h3>Notes</h3>
          <NotesTab word={props.word}/>

          <h3>Log</h3>
          <LogTab wordId={props.word.id} />
        </div>
      </div>
    </div>
  </div>
}

function PropertiesTab(props: {word: any, reload: any}) {
  return  <div>
    <EditWordForm
      language={"fa"}
      word={props.word}
      wordData={JSON.parse(props.word.dataBlob)}
      reload={props.reload}
    />
  </div>
}

function OverviewTab(props: {word: any}) {
  return <div>
    <div className={styles.main}>
      <div style={{flex: 1}}>
        <InfoBox word={{...props.word, data: JSON.parse(props.word.dataBlob)}} />
        <PartOfWords wordId={props.word.id} />
        <SimilarWords wordId={props.word.id} />
        <Sentences wordId={props.word.id} />
      </div>
    </div>
  </div>
}