import {useMemo, useRef} from "react";


// Create a new promise which can either be fullfilled from the outside, or reset.
export function useNewPromise() {
  const stopPromise = useRef<any | null>(null);

  return useMemo(() => ({
    reset: () => {
      stopPromise.current = null;
    },
    reject: () => {
      if (stopPromise.current) {
        stopPromise.current.reject();
      }
    },
    resolve: () => {
      if (stopPromise.current) {
        stopPromise.current.resolve();
      }
    },
    makeOrKeep: () => {
      if (stopPromise.current) {
        return stopPromise.current;
      }
      return new Promise((resolve, reject) => {
        stopPromise.current = {resolve, reject};
      })
    },
    makeNew: (opts?: {reject: boolean}) => {
      if (stopPromise.current) {
        if (opts?.reject) {
          stopPromise.current.reject();
        } else {
          stopPromise.current.resolve();
        }
      }
      return new Promise((resolve, reject) => {
        stopPromise.current = {resolve, reject};
      })
    }
  }), [stopPromise]);
}