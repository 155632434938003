import {default as wrapInHBJS} from 'harfbuzzjs/hbjs';
import {Font} from "./font";
import {useEffect, useState} from "react";


/**
 * TODO: Known issues.
 *
 * 1) The space character rect/path seems to be printed in very strange places...
 *
 * 2) In some cases, such as tashakkor, two diacritic marks are merged into one.
 * This might be related to clustering. Read more:
 * - https://harfbuzz.github.io/clusters.html
 * - https://lists.freedesktop.org/archives/harfbuzz/2016-January/005443.html
 * - https://github.com/harfbuzz/harfbuzz/blob/ad954870383a880262edcd01acd8e171c4614a68/docs/usermanual-shaping-concepts.xml
 *
 * However, we cannot try this right now, as harfbuzzjs does not expose the function. This fails:
 *
 *    // HB_BUFFER_CLUSTER_LEVEL_CHARACTERS=2
 *    exports.hb_buffer_set_cluster_level(ptr, 2);
 *
 * It is under active development though so I am sure we can figure something out. Also read:
 * https://github.com/harfbuzz/harfbuzzjs/issues/15
 */

export type HarfbuzzJS = any;

// https://increment.com/programming-languages/unplain-text-primer-on-non-latin/
// https://github.com/nasser/harfbuzz-js/blob/7705c24e732f8d752d75c4cdf9d40bf4194a6d70/api.js#L47
//     (similar code as the harfbuzzjs/hbjs.js).
export type HarfBuzzInstruction = {
  // Codepoint (actually unicode, not the glyph index as the article claims).
  g: number,
  // "Cluster" (?). There is also something called "mask", which is not exposed by hbjs.js
  cl: number,
  // How far to advance the cursor x/y
  ax: number,
  ay: number,
  // How far from the cursor this should be drawn
  dx: number,
  dy: number
}


// This version always gives us ax as 0. It returns the unicode codepoint for HarfBuzzInstruction.g
export async function loadHarfbuzzModule() {
  // @ts-ignore
  const module = await import('harfbuzzjs/hb.wasm');
  module.memory.grow(400);  // each page is 64kb in size
  return {exports: module};
}

// Load the Harfbuzz.js WASM module, wrapped in a JS-interface.
export async function loadHBJS() {
  const result = await loadHarfbuzzModule();
  return wrapInHBJS(result);
}


export function runShaper(hb: HarfbuzzJS, fontBlob: Font, text: string, opts: {size: number}): HarfBuzzInstruction[] {
  const fontAsArray = new Uint8Array(fontBlob.buffer);
  var blob = hb.createBlob(fontAsArray);
  var face = hb.createFace(blob, 0);
  var font = hb.createFont(face);

  // sx: if not given, unitsPerEm as defined in the font is used. Like in the font, this is an arbitrary
  //   number that merely defines the coordinate system within which the font defines its paths. In the same
  //   way, the number you give here will essentially rescale the coordinates in the font vectors to match
  //   your size, and the values returned from harfbuzz will be pixels within that coordinate system/size.
  // sy: This will effect the placement of diacritic marks in arabic for example. Passing the same
  //   value has sx seems to provide good results.
  font.setScale(opts.size, opts.size); // Optional, if not given will be in font upem

  var buffer = hb.createBuffer();
  buffer.addText(text);
  buffer.guessSegmentProperties();
  buffer.setDirection('rtl'); // optional as can be set by guessSegmentProperties also
  buffer.shape(font);        // features are not supported yet
  var result = buffer.json(font);

  buffer.destroy();
  font.destroy();
  face.destroy();
  blob.destroy();
  return result;
}


export function useHarfbuzz(): [HarfbuzzJS, { loading: boolean, error: any }] {
  const [lib, setLib] = useState<HarfbuzzJS|any>(null);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    loadHBJS().then((hbjs) => {
      setLib(hbjs);
      setError(null);
    }).catch(e => {
      setError(e);
    }).finally(() => {
      setLoading(false);
    })
  }, []);

  return [lib, {error, loading}];
}


/**
 * Convert harfbuzz shaping instructions for each character to opentype.js paths that we can then draw.
 *
 * The challenge is that we do not get any height value from harfbuzz. Instead, it places the cursor
 * on the baseline, and then moves the y-coordinates either up or down (with negative values meaning "down").
 */
export function createPathsFromHarfBuzz(
  font: opentype.Font,
  instructions: HarfBuzzInstruction[],
  opts: {
    size: number,
    // So we can place the baseline-values from harfbuzz in a top-left coordinate system
    ascender: number
  }
): opentype.Path[] {
  let size = opts.size;
  let x = 0;
  let y =  opts.ascender;


  // From:
  // https://github.com/nasser/harfbuzz-js/blob/7705c24e732f8d752d75c4cdf9d40bf4194a6d70/api.js#L66
  const paths = instructions.map(function(instruction) {
    //const glyph = font.charToGlyph(String.fromCodePoint(instruction.g))
    const glyph = font.glyphs.get(instruction.g);

    // https://lists.freedesktop.org/archives/harfbuzz/2015-December/005371.html
    const path = glyph.getPath(x + instruction.dx, y - instruction.dy, size);
    x += instruction.ax;
    y -= instruction.ay;

    return path;
  });

  return paths;
}