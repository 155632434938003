/**
 * This helper gives us an audio player that can play the audio version of a hosted video file, as a whole
 * or for a segment within in.
 */
import {getUploadUrl} from "../../Hosting";
import {useCallback, useMemo} from "react";
import {useAsync} from 'react-use';
import {MediaTrack} from "../../../models/MediaTrack";
import {Captions} from "../../../models/Captions";
import {CaptionTrack} from "languagetool-player/src/models";


interface PlayerInterface {
  playSegment(from: number, to: number): void
}


/**
 * This wraps an actual audio element.
 */
class Player implements PlayerInterface {
  private audio: HTMLAudioElement;
  private scheduledEnd: number = 0;

  constructor(audio: HTMLAudioElement) {
    this.audio = audio;
    this.audio.addEventListener('timeupdate', this.handleTimeUpdate);
  }

  handleTimeUpdate = () => {
    if (this.audio.currentTime > this.scheduledEnd) {
      this.audio.pause();
    }
  }

  playSegment(from: number, to: number): void {
    this.audio.currentTime = from;
    this.scheduledEnd = to;
    this.audio.play();
  }
}


/**
 * Written in such a way that it handles transparently a missing media track, one that has no audio etc.
 *
 * As long as the url is empty, this returns undefined.
 *
 * It also knows how to resolve internal hosting urls.
 *
 * TODO: It would be nice if this never returns null and accepts commands already before the player
 * finished loading.
 */
export function useAudioPlayer(track: {
  type: string,
  url: string
}|undefined|null): PlayerInterface|undefined {
  const url = track?.type === 'internal' ? track.url : null;
  const state = useAsync(async () =>
    url
      ? getUploadUrl(url, {endpoint: 'https://market.languagetool.xyz/hosting/api/'})
      : Promise.resolve(null), [url]);

  const audioOnlyUrl = state.value?.audioOnlyUrl ?? undefined;
  return useMemo(() => {
    if (!audioOnlyUrl) {
      return undefined;
    }
    const audio = new Audio(audioOnlyUrl);
    return new Player(audio);
  }, [audioOnlyUrl]);
}


/**
 * Knows how to play a media track line by line.
 */
export function usePlayLine(props: {
  mediaTrack?: MediaTrack,
  track: Captions,
}) {
  const audioPlayer = useAudioPlayer(props.mediaTrack);

  return useCallback((lineIndex: number) => {
    const {time: start, duration} = props.track.lines[lineIndex].data;
    if (start === undefined || duration === undefined) {
      return
    }
    if (!audioPlayer) {
      console.log('Audio player not ready yet, ignoring play()');
      return;
    }
    audioPlayer.playSegment(start, start + duration);
  }, [audioPlayer, props.track.lines]);
}


export function usePlayLineRawTrack(props: {
  mediaTrack?: MediaTrack,
  track: CaptionTrack|undefined,
  currentLineIndex: number
}) {
  const audioPlayer = useAudioPlayer(props.mediaTrack);

  return useCallback(() => {
    if (!props.track) {
      return;
    }
    const {time: start, duration} = props.track.lines[props.currentLineIndex];
    if (start === undefined || duration === undefined) {
      return
    }
    if (!audioPlayer) {
      console.log('Audio player not ready yet, ignoring play()');
      return;
    }
    audioPlayer.playSegment(start, start + duration);
  }, [audioPlayer, props.currentLineIndex, props.track?.lines]);
}