// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-assignScreen-index__root--30wdt{margin:20px}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__wordCell--3wFfb{cursor:pointer;vertical-align:middle}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__wordCell--3wFfb>svg{vertical-align:middle}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__workComplete--3UTG3{background:#4caf5057}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__alternateVocableForm--1klxp{padding:0 5px;font-size:.9em;color:gray}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__errorOverlay--1iKJn{position:absolute;left:0;top:0;right:0;bottom:0;background:rgba(0,0,0,0.85);color:white;padding:2em;display:flex;flex-direction:column;align-items:center;justify-content:center}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__errorOverlay--1iKJn h3{color:#f44336}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__CreateContentIssueButton--rY1n2{display:none !important}._-languagetool-editor-src-js-integrations-Words-assignScreen-index__wordCell--3wFfb:hover ._-languagetool-editor-src-js-integrations-Words-assignScreen-index__CreateContentIssueButton--rY1n2{display:block !important}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__root--30wdt",
	"wordCell": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__wordCell--3wFfb",
	"workComplete": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__workComplete--3UTG3",
	"alternateVocableForm": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__alternateVocableForm--1klxp",
	"errorOverlay": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__errorOverlay--1iKJn",
	"CreateContentIssueButton": "_-languagetool-editor-src-js-integrations-Words-assignScreen-index__CreateContentIssueButton--rY1n2"
};
module.exports = exports;
