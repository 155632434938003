import NothingFoundIcon from "../Icons/NothingFoundIcon";
import * as React from "react";
import ErrorIcon from "../Icons/ErrorIcon";
import {View, Text, StyleSheet} from 'react-native';
import {TextBlock} from "../Base/Text";


const styles = StyleSheet.create({
  message: {
    alignItems: 'center',
    fontSize: 16,
    marginTop: 5,
    marginBottom: 5
  }
})


/**
 * Show a message in the popup instead of the results: An icon left, a text right.
 */
export function Message(props: { Icon: any, children: any }) {
  const {Icon} = props;
  return <View style={styles.message}>
    {/*<Icon*/}
    {/*  width={"10em"}*/}
    {/*  height={null}*/}
    {/*  style={{paddingRight: '0.7em', height: 'initial'}}*/}
    {/*/>*/}
    <View>
      {props.children}
    </View>
  </View>;
}

export function ErrorMessage(props: { refetch?: () => void }) {
  return <Message Icon={ErrorIcon}>
    <TextBlock><Text style={{color: '#F44336'}}>An error occurred</Text> while trying
      to lookup this word. Our engineers have been notified.</TextBlock>

    {/*{props.refetch ? <div style={{marginTop: '0.7em'}}>*/}
    {/*  <a href={"#"} onClick={(e) => {*/}
    {/*    e.preventDefault();*/}
    {/*    props.refetch!();*/}
    {/*  }}>Try again</a>*/}
    {/*</div> : null}*/}
  </Message>
}

export function NoResultsMessage() {
  return <Message Icon={NothingFoundIcon}>
    <TextBlock><Text>No results.</Text> Unfortunately, we
      could not find any further information about this.</TextBlock>
  </Message>;
}