import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import {useMutation} from "multi-apollo";
import {gql as gqlm} from "@apollo/client";
import {SetContentIssueTags} from "../../../../types/SetContentIssueTags";


export function TagSelector(props: {
  selectedRecords: { id: string, tags: string[] }[],

  anchorEl: any,
  onRequestClose: () => void,
}) {
  const setTags = useSetTags();

  const selectTag = (tag: string, shouldSet: boolean) => {
    setTags({
      contentIssueIds: props.selectedRecords.map(r => r.id),
      toAdd: shouldSet ? [tag] : undefined,
      toRemove: !shouldSet ? [tag] : undefined,
    });
  };

  return <Menu
    id="simple-menu"
    anchorEl={props.anchorEl}
    keepMounted
    open={Boolean(props.anchorEl)}
    onClose={props.onRequestClose}
  >
    {[
      "michael-data-fix",      // I should fix the word database for this
      "coding",                // I should implement this
      "postponed",             // We will do this at some point in the future
      "needs-subtitle-edit",
      "second-opinion",        // I want to run this by Armaghan
      "deferred-to-call",      // we want to walk through this in the call to hamed
      "wait-for-deploy",
    ].map((tag) => {
      let allHaveIt = false;
      let allLackIt = false;
      for (const record of props.selectedRecords) {
        if (record.tags.indexOf(tag) > -1) {
          allHaveIt = true;
        }
        if (record.tags.indexOf(tag) === -1) {
          allLackIt = true;
        }
      }
      let shouldSet = allLackIt ? true : allHaveIt ? false : true;

      return (
        <MenuItem key={tag} onClick={() => selectTag(tag, shouldSet)}>
          {!shouldSet ? "✓ " : null} {tag}
        </MenuItem>
      );
    })}
  </Menu>;
}


export function useSetTags() {
  const [setContentIssueTags] = useMutation<SetContentIssueTags>(
    gqlm`
      mutation SetContentIssueTags(
        $props: SetContentIssueTagProps!
      ) {
        setContentIssueTags(
          props: $props
        ) {
          issues { 
            id,
            tags
          }
        }
      }
    `,
    {
      client: 'market'
    }
  );

  return (opts: {
    contentIssueIds: string[];
    toAdd?: string[];
    toRemove?: string[];
  }) => {
    return setContentIssueTags({
      variables: {
        props: {
          contentIssueIds: opts.contentIssueIds,
          addTags: opts.toAdd,
          removeTags: opts.toRemove,
        },
      },
    });
  };
}
