import {InternalNote} from "../types/InternalNote";
import {
  WordLinkWithDetails,
  WordWithDetailsProps
} from "../utils/WordWithDetails";
import {formatDistanceToNow, parseISO} from "date-fns";
import {DateTime} from "../utils/DateTime";
import ReactMarkdown from "react-markdown";
import {Link, Spinner} from "office-ui-fabric-react";
import React, {useState} from "react";
import {gql as gqlw} from "@apollo/client";
import {AddEditNoteForm} from "./WriteNoteForm";
import {useQuery} from "multi-apollo";
import {WordNoteCommentsQuery} from "../types/WordNoteCommentsQuery";
import styles from "./Note.module.css";
import classnames from "classnames";

export const InternalNoteFragment = gqlw`
  fragment InternalNote on InternalNote {
    id,
    text,
    isResolved,
    hasParent,
    isTodo,
    createdAt,
    createdBy {
      id, username
    },
    assignedTo {
      id, username
    },
    responseCount,
    dateOfLastPost
  }
`;

function IssueStatusMark(props: {isResolved: boolean}) {
  return (
    <span
      className={classnames({
        [styles.mark]: true,
        [styles.open]: !props.isResolved,
        [styles.resolved]: props.isResolved
      })}
    >
      {!props.isResolved ? "Open" : "Resolved"}
    </span>
  );
}

export function Note(props: {
  note: InternalNote;
  word?: WordWithDetailsProps & {id: string};
}) {
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const {note} = props;

  return (
    <div className={styles.note}>
      <div className={styles.header}>
        {note.isTodo ? <IssueStatusMark isResolved={note.isResolved} /> : null}
        <strong>{formatDistanceToNow(parseISO(note.createdAt))} ago</strong> on
        the{" "}
        <strong>
          <DateTime date={note.createdAt} />
        </strong>{" "}
        by{" "}
        <strong>
          {note.createdBy?.username ?? note.createdBy?.id ?? "(unknown)"}
        </strong>
        {props.word ? (
          <>
            {" "}
            for <WordLinkWithDetails {...props.word} />
          </>
        ) : null}
        {note.assignedTo ? (
          <>
            {" "}
            assigned to{" "}
            <div
              style={{
                padding: "4px",
                background: "pink",
                border: "1px solid red",
                display: "inline-block"
              }}
            >
              {note.assignedTo?.username ?? note.assignedTo?.id ?? "(unknown)"}
            </div>
          </>
        ) : null}
      </div>

      <div className={styles.content}>
        <ReactMarkdown source={props.note.text} />

        {!note.hasParent || note.responseCount > 0 ? (
          commentsExpanded ? (
            <Link
              onClick={() => {
                setCommentsExpanded(false);
              }}
            >
              Collapse Comments
            </Link>
          ) : (
            <Link
              onClick={() => {
                setCommentsExpanded(true);
              }}
            >
              Show {note.responseCount} comments
            </Link>
          )
        ) : null}
        {commentsExpanded ? (
          <NoteComments
            noteId={props.note.id}
            parentIsTodo={props.note.isTodo}
          />
        ) : null}
      </div>
    </div>
  );
}

function useNoteComments(noteId: string) {
  const {loading, error, data, refetch} = useQuery<WordNoteCommentsQuery>(
    gqlw`
    ${InternalNoteFragment}    
    query WordNoteCommentsQuery($noteId: ID!) {      
      internalNotes(parentId: $noteId) {
        edges {
          node {
            ...InternalNote
          }
        }       
      }
    }
  `,
    {
      variables: {noteId},
      client: "words"
    }
  );

  return {
    loading,
    refetch,
    error,
    notes: data?.internalNotes?.edges.map(edge => edge.node) ?? []
  };
}

/**
 * Show the comments on a note.
 */
export function NoteComments(props: {noteId: string; parentIsTodo: boolean}) {
  const {loading, notes, refetch} = useNoteComments(props.noteId);

  let content;
  if (loading) {
    content = <Spinner />;
  } else {
    content = (
      <>
        {notes.map(note => {
          return <Note key={note.id} note={note} />;
        })}

        <AddEditNoteForm
          disallowTodo={true}
          allowResolve={props.parentIsTodo}
          title={"Write response"}
          onSave={async () => {
            await refetch();
          }}
          parentNoteId={props.noteId}
        />
      </>
    );
  }

  return (
    <div
      style={{
        marginLeft: "10px",
        marginTop: "20px"
      }}
    >
      {content}
    </div>
  );
}
