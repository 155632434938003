import React, {useCallback} from "react";
import {useClient} from "multi-apollo";
import gqlw from "graphql-tag";
import {UserSelectQuery} from "../types/UserSelectQuery";
import {
  FormFieldProps,
  useUIControls
} from "languagetool-editor/src/js/components/FormUISwitch";
import {GetUserSelectItemQuery} from "../types/GetUserSelectItemQuery";

export function UserSelect(props: FormFieldProps) {
  const UI = useUIControls();
  const apolloClient = useClient("words");

  const runSearch = useCallback(
    async (value: string) => {
      const words = await apolloClient.query<UserSelectQuery>({
        query: gqlw`
        query UserSelectQuery {
          users {
            id,
            username            
          }
        }
      `,
        variables: {}
      });

      return words?.data?.users?.map(user => {
        return {
          label: (user?.username ?? user?.id) || "(no name)",
          value: user?.id
        };
      });
    },
    [apolloClient]
  );

  const getItem = useCallback(
    async userId => {
      if (!userId) {
        return {label: ""};
      }

      const {data} = await apolloClient.query<GetUserSelectItemQuery>({
        query: gqlw`
        query GetUserSelectItemQuery($id: ID!) {
          user(id: $id) {
            id,
            username
          }
        }
      `,
        fetchPolicy: "cache-first",
        variables: {
          id: userId
        }
      });
      return {
        label: (data?.user?.username ?? data?.user?.id) || "(no name)"
      };
    },
    [apolloClient]
  );

  return (
    <UI.RemoteSelect {...props} searchFunc={runSearch} getItem={getItem} />
  );
}
