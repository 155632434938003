import {NestableField} from "../../../../components/NestedFormik";
import {validateRequired} from "../index";
import React from "react";
import {PickWordInput} from "../../langpacks/fa/WordForm/fields/PickWordInput";


export function WordAliasReferenceField(props: {}) {
  return <NestableField
    name={"aliasWordRef"}
    component={PickWordInput}

    label="Alias for"
    description={"The word this is an alias for"}
    validate={validateRequired}

    {...props}
  />;
}