import React from 'react'
import {Svg, Path} from 'react-native-svg';

export const GrammarIcon = React.memo((props: any) => (
  <Svg width="16" height="16" viewBox="0 0 48 48" {...props}>
    <Path
      d="M19.824 34.828L14.328 18.54C13.801 16.891 12.828 15 9.996 15c-2.898 0-3.883 2.023-4.363 3.535L.203 34.82c-.363 1.055-.23 2.18.356 3.004C1.089 38.57 1.945 39 2.902 39c1.528 0 2.559-.867 3.075-2.59L6.68 34h6.601l.742 2.445C14.586 38.141 15.605 39 17.055 39c.968 0 1.8-.398 2.343-1.129.598-.805.762-1.93.426-3.043zM8.293 29l1.703-5.426L11.73 29z"
      fill="#ff3d00"
    />
    <Path
      d="M39.773 40C32.953 40 28 34.945 28 27.984 28 21.04 32.953 16 39.773 16c2.5 0 4.633.578 6.442 1.824 1.2.828 1.785 1.778 1.785 2.89 0 1.513-1.121 2.65-2.613 2.65-1.016 0-1.762-.458-2.36-.829-.922-.582-1.836-.918-3.187-.918-3.496 0-5.84 2.559-5.84 6.367 0 3.825 2.348 6.399 5.84 6.399 1.472 0 2.254-.313 3.183-.887.579-.375 1.325-.86 2.332-.86 1.485 0 2.645 1.176 2.645 2.68 0 1.13-.598 2.079-1.832 2.899C44.504 39.379 42.285 40 39.773 40z"
      fill="#689f38"
    />
    <Path
      d="M23.34 34h-5.313C16.273 34 15 32.75 15 31.031V12.938C15 11.233 16.273 10 18.027 10h4.774c7.367 0 8.914 3.848 8.914 7.074a5.946 5.946 0 0 1-1.793 4.309C31.898 22.586 33 24.433 33 26.625 33 31.52 29.75 34 23.34 34zm-2.281-5.504h2.586c2.98 0 3.226-.95 3.226-2.008 0-.578 0-1.937-3.262-1.937h-2.55zM21 19h2.012c2.3 0 2.675-.96 2.675-2 0-1.02-.285-2-3.015-2H21z"
      fill="#ffc107"
    />
  </Svg>
));