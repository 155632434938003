import React from 'react';
import styles from './Sentences.module.css';
import {useQuery} from 'multi-apollo';
import gql from "graphql-tag";
import {Link} from 'react-router-dom';
import {WordWithDetails, WordWithDetailsFragment} from "../../utils/WordWithDetails";


const QUERY = gql`
  ${WordWithDetailsFragment}
  
  query($id: Int!) {
    word(id: $id, allowDeleted: true) {
      id,
      similar {
        id,
        base,
        ...WordWithDetails
      }
    }
  }
`;


export const SimilarWords = React.memo((props: {
  wordId: any
}) => {
  const {data, loading, error} = useQuery(QUERY, {variables: {id: props.wordId}, client: 'words'});

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;

  let content: any;
  if (data.word.similar && data.word.similar.length) {
    content = data.word.similar.map((word: any) => {
      return <li key={word.id}>
        <Link to={`/word/${word.id}`}>
          <WordWithDetails
            base={word.base}
            type={word.type}
            description={word.description}
            isReviewed={word.isReviewed}
            baseTransliteration={word.baseTransliteration}
          />
        </Link>
      </li>
    });
  }

  if (!content) {
    content = <span>Found no possible duplicates.</span>
  }

  return <div className={styles.root}>
    <h3>Possibles Duplicates</h3>
    {content}
  </div>
});