import {PixiComponent} from "@inlet/react-pixi";
import * as PIXI from "pixi.js";


type RectangleProps = {
  fill: number,
  borderColor: number
  x: number,
  y: number,
  width: number,
  height: number
};


export const Rectangle = PixiComponent('Rectangle', {
  create: (props: RectangleProps) => {
    return new PIXI.Graphics();
  },
  applyProps: (g, oldProps, newProps) => {
    const {fill, x, y, width, height, borderColor} = newProps;
    g.clear()
    g.lineStyle(1, borderColor, 1, 1);
    g.beginFill(fill)
    g.drawRect(x, y, width, height)
    g.endFill()
  }
})