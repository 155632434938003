import React, {useState} from 'react';
import {Todos} from "./Todos";
import {Link} from 'office-ui-fabric-react';


export function TodosPage() {
  const [show, setShow] = useState<"all"|"mine">("mine");

  return <div style={{
    margin: "20px"
  }}>
    <h3>Todos and Discussions</h3>
    <div style={{marginBottom: '10px'}}>
      <Link onClick={() => setShow("all")}>All</Link> &bull;
      <Link onClick={() => setShow("mine")}>Mine</Link>
    </div>

    <Todos
      onlyAssignedTo={show == 'all' ? undefined : "current-user"}
    />
  </div>
}