import {NestableField} from "../../../../components/NestedFormik";
import React from "react";
import {useUIControls} from "../../../../components/FormUISwitch";


export function WordDescriptionField(props: any) {
  const UI = useUIControls();

  return <NestableField
    name={"description"}
    component={UI.Text}

    label="Description"
    description={"Short extra text to explain the difference between two words written the same. Only if necessary."}

    {...props}
  />;
}