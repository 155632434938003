import {Editor, Node, Transforms, Element} from "slate";
import {isLine, isLineGroup, LineGroup, Line} from "../../../models/Captions/types";
import {KeyGenEditor} from "../../../components/VocableBasedSlate/withKeyGen";
import {ensureEmptyVocableArrayHasTextElement, removeEmptyVocables} from "../../../components/VocableBasedSlate/schema";


/**
 * We use this schema to enforce the basic `LineGroup -> Line -> Timed` structure. We rely on the schema
 * to initialize a new value with this structure, but otherwise, do not rely on it for editing purposes.
 *
 * Any changes we have to make (such as inserting `timed` inlines), we do manually while the user is typing etc.
 */

export const withSchema = <T extends Editor & KeyGenEditor>(editor: T) => {
  const { normalizeNode } = editor

  editor.normalizeNode = entry => {
    const [node, path] = entry;

    // Root
    if (path.length === 0) {
      if ((node as Element).children.length === 0) {
        Transforms.insertNodes(editor, {type: 'linegroup', children: [], key: "", data: {}} as LineGroup, {at: path.concat(0)})
        return
      }

      // Make sure all are line groups
      for (const [n, p] of Node.children(editor, path)) {
        if (!isLineGroup(n)) {
          if ("text" in n) {
            Transforms.wrapNodes(editor, {type: 'linegroup', children: [], key: "", data: {}} as LineGroup, {at: p})
          }
          else {
            Transforms.setNodes(editor, {type: 'linegroup'}, {at: p})
          }
          return;
        }
      }
    }

    // Ensure at least one line per line group
    if (isLineGroup(node)) {
      if (node.children.length === 0) {
        Transforms.insertNodes(editor, {type: 'line', children: [], key: ""} as Line, {at: path.concat(0)})
        return
      }

      // Make sure all are lines
      for (const [n, p] of Node.children(editor, path)) {
        if (!isLine(n)) {
          if ("text" in n) {
            Transforms.wrapNodes(editor, {type: 'line', children: [], key: ""} as Line, {at: p})
          }
          else {
            Transforms.setNodes(editor, {type: 'line'}, {at: p})
          }
          return;
        }
      }
    }

    // Ensure lines only have vocables and text
    if (removeEmptyVocables(editor, entry)) {
      return;
    }

    if (isLine(node)) {
      if (ensureEmptyVocableArrayHasTextElement(editor, entry)) {
        return;
      }
    }

    // Fallback
    normalizeNode(entry)
  }

  return editor
}
