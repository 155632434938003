import React, {useContext} from "react";
import {isStringWordId, WordId} from "../../../annotations";
import {StructureNode} from "../../../models/Node";
import {getAlternatesText, getVocableText} from "../../../models/getVocableText";
import {WordDataAnnotation} from "../../../annotations/WordData";
import {PopupContext, usePopupConfig, useResolver} from "../PopupContext";
import { getWordType } from "../../../langpacks/fa/formatters";
import {Text, View} from 'react-native';
import { Em } from "./Em";
import { TextBlock } from "../../Base/Text";


/**
 * Render a text just from a wordId. So either the word id is a string,
 * use it is an id with a `canonical` property attached.
 */
export function getCanonicalFromwWordId(wordId: WordId): string {
  if (isStringWordId(wordId)) {
    return wordId.text;
  }
  else {
    if (wordId.canonical) {
      return wordId.canonical;
    }
  }

  return "";
}


type Props = {
  // Render from a WordData annotation
  wordData?: WordDataAnnotation,

  // Or render from a word id canonical
  wordId?: WordId,

  showWordType?: boolean,
  style?: any,
  simulateLinkStyle?: boolean
};



/*
 * Render a word label while respecting the vowel/transliteration setting.
 *
 * Uses either:
 *
 * a) a WordId
 * b) a WordData annotation.
 *
 * If this is enabled in the PopupConfig, and the data is available, we display
 * the voweled version, and in addition, we display a transliteration.
 */
export const WordIdTitle = (props: Props) => {
  const {showTransliteration, preferAlternates, transliterationRender} = usePopupConfig();
  const {defaultLanguage} = useContext(PopupContext);
  const {wordData} = props;

  let name: string = "";

  if (wordData) {
    let isFound = false;
    if (preferAlternates && wordData.alternates) {
      for (const key of preferAlternates) {
        if (wordData.alternates[key]) {
          name = wordData.alternates[key];
          isFound = true;
          break;
        }
      }
    }

    if (!isFound) {
      name = wordData.lemma;
    }
  }

  else if (props.wordId) {
    name = getCanonicalFromwWordId(props.wordId);
  }

  if (!name) {
    name = "???";
  }

  let lang = defaultLanguage;
  if (props.wordId && isStringWordId(props.wordId) && props.wordId.lang) {
    lang = props.wordId.lang;
  }


  let transliteration: any = "";
  if (showTransliteration && wordData) {
    const text = getAlternatesText(wordData.alternates, showTransliteration, {altFormatter: transliterationRender});
    if (text) {
      transliteration = <Transliteration>{text}</Transliteration>;
    }
  }

  return <Text
    // On iOS, we need to give `ltr`. With 'auto', the transliteration will be left, and the farsi text right.
    style={[props.style, {writingDirection: 'ltr'}]}
  >
    <TextBlock kind={"persian"}>{name}</TextBlock> {" "}{transliteration}

    {(wordData && props.showWordType)
      ?  <Em>{" "} {getWordType(wordData.wordType)}</Em>
      : null
    }
  </Text>;
};


/**
 * Render a `WordNode` while respecting the vowel/transliteration setting.
 */
export const TitleForStructureNode = (props: {
  // A word node + a resolver means we can get the display from the vocables.
  // We could also attempt to render the WordNode based on the WordStructure.
  word: StructureNode,

  style?: any,
  className?: string,
  simulateLinkStyle?: boolean
}) => {
  const {showTransliteration, preferAlternates, transliterationRender} = usePopupConfig();
  const resolver = useResolver();
  const {defaultLanguage} = useContext(PopupContext);

  let name = props.word.vocables.map(vocId => {
    let voc = resolver.resolveVocable(vocId);
    return getVocableText(voc, {useAlternates: preferAlternates || undefined});
  }).join(" ");

  let transliterationText = showTransliteration ? props.word.vocables.map(vocId => {
    let voc = resolver.resolveVocable(vocId);
    return getVocableText(voc, {useAlternates: showTransliteration, altFormatter: transliterationRender});
  }).join(" ") : null;
  let transliteration = transliterationText ? <Transliteration>{transliterationText}</Transliteration> : null;

  return <Text
    // On iOS, we need to give `ltr`. With 'auto', the transliteration will be left, and the farsi text right.
    style={[props.style, {writingDirection: 'ltr'}]}
  >
    <Text>{name}</Text> {" "}{transliteration}
  </Text>;
};


function Transliteration(props: any) {
  return <Text style={{
    fontWeight: 'normal',
    //whiteSpace: 'nowrap'
  }}>
    {props.children}
  </Text>;
}