import * as React from "react";
import Bacon from "baconjs";
import {useState} from "react";
import {useEffect} from "react";


export function invertColor(hex: string, bw = true) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  var r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // http://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  let r2 = (255 - r).toString(16);
  let g2 = (255 - g).toString(16);
  let b2 = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r2) + padZero(g2) + padZero(b2);
}

function padZero(str: string, len?: number) {
  len = len || 2;
  let zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

/**
 * Returns a string such as:
 *    0:04   (for 4 seconds)
 *    1:04   (for 64 seconds)
 */
export function formatTime(time: number, dropHundredths: boolean = false) {
  const parts: number[] = [];

  const hundredths = Math.floor((time % 1) * 100);
  const seconds = Math.floor(time);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours > 0) {
    parts.push(hours); // Hours
  }
  parts.push(minutes % 60); // Mins
  parts.push(seconds % 60); // Seconds

  let result: string[] = [];
  for (var i = 0; i < parts.length; i++) {
    result[i] = zeroPad(parts[i]);
  }

  let finalString = parts.join(':');

  if (!dropHundredths) {
    finalString += '.' + zeroPad(hundredths);
  }
  return finalString;
}


function zeroPad(number: number): string {
  return number < 10 ? '0' + number : ""+number;
}

/**
 * Join for an array of react elements.
 */
export function componentJoin(joiner: any, components: any[]): any {
  let result: any[] = [];
  components.forEach((comp, idx) => {
    result.push(
      React.isValidElement(comp)
        ? React.cloneElement(comp, {key: idx})
        : comp
    );
    if (idx < components.length - 1) {
      result.push(joiner);
    }
  });
  return result;
}


/**
 * Allows the observable the be null.
 *
 * Note that you should memo-ize it to avoid resubscribing.
 */
export const useBaconObservable = <T, K>(observable$: Bacon.Observable<any, T>|null, initialValue: K): T|K => {
  const [value, update] = useState<T|K>(initialValue);

  useEffect(() => {
    if (!observable$) {
      return;
    }
    return observable$.onValue(update)
  }, [observable$]);

  return value;
}