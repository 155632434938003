import * as React from "react";
import {WordId} from "../../../annotations";
import {WordNode} from "../../../models/Node";


export type WordLinkContextValue = {
  goToWord: (word: WordId|WordNode) => void;
  goBackToIndex: (idx: number) => void;

};
export const WordLinkContext = React.createContext<WordLinkContextValue|null>(null);


export type WordLinkHandlerProps = {
  onWordClicked: (word: WordId|WordNode) => void;
  onGoBackToIndex: (idx: number) => void;
};


/**
 * Add this to the root if you want to react to link clicks.
 */
export class WordLinkHandler extends React.Component<WordLinkHandlerProps> {
  contextValue: WordLinkContextValue;

  constructor(props: WordLinkHandlerProps) {
    super(props);
    this.contextValue = {
      goToWord: this.handleOnWordLinkClicked,
      goBackToIndex: this.props.onGoBackToIndex
    };
  }
  render() {
    return <WordLinkContext.Provider value={this.contextValue}>
      {this.props.children}
    </WordLinkContext.Provider>
  }

  handleOnWordLinkClicked = (target: WordId|WordNode) => {
    this.props.onWordClicked(target);
  }
}
