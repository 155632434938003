// This is something that can be cached for offline-use, but also makes sense
// for not having to load the data twice when clicking the same word again; in
// particular since often, a single popup display often requires data annotations
// from multiple words.



import {
  Annotation,
  AnnotationType, isQueryByWordId,
  isQueryByString,
  WordIdQuery,
  StringQuery, AnnotationQuery, isQueryByInflection,
} from "../annotations";



interface AnnotationSet {
  annotations: Annotation[],

  // we could store the requested language here, and reload if we do not have all
  // otherwise, reload further.
  requestedTypes?: any,
  // The languages this was requested by. Currently unused.
  requestedLanguages?: string[],
  timestamp?: number
}


/**
 * Objects implementing this interface allow you to query a cache for annotations for a particular word id.
 */
export interface ISimpleCache {
  get(wordId: AnnotationQuery|undefined): Annotation[];
}


/**
 * The main implementation to cache annotations.
 *
 * It simply maintains a map of "word id query" => list of annotations.
 */
export class AnnotationsCache implements ISimpleCache {
  map: Map<string, AnnotationSet>;

  public onChange?: () => void;

  constructor() {
    this.map = new Map();
  }

  /**
   * Allows us to test if the cache knows about annotations for a certain query.
   *
   * May return true, even if no data is available -> because no data is available
   * on the server!
   *
   * For example, if you ask it of knowledge for "pronunciation" annotations for a
   * word, it may say yes, only because it knows that there is no such data on the
   * server.
   */
  hasKnowledge(query: AnnotationQuery, types?: Set<AnnotationType>) {
    const mapKey = getMapKeyForAnnotationQuery(query);
    const set = this.map.get(mapKey);

    return !!set;

    // If a type is desired that has been queried for, return true.
    // if (set.requestedTypes.hasAll(types)) {
    //   return true;
    // }
    // else {
    //   return false;
    // }
  }

  all(): AnnotationSet[] {
    return Array.from(this.map.values());
  }

  get(query: AnnotationQuery): Annotation[] {
    const mapKey = getMapKeyForAnnotationQuery(query);
    const result = this.map.get(mapKey);
    if (!result) {
      return [];
    }
    return result.annotations;
  }

  ensureKeyExists(query: AnnotationQuery) {
    const mapKey = getMapKeyForAnnotationQuery(query);
    if (!this.map.has(mapKey)) {
      this.map.set(mapKey, {
        annotations: []
      })
    }
  }

  /**
   * Add multiple annotations from a query response.
   *
   * Override based on types:
   *    if types is all, but you add less, ignore
   *    if types is less, but you add all, replace
   *    if types overlaps, replace
   *    if types do not overlap, combine
   */
  add(annotations: Annotation[], opts: {override?: boolean}) {
    const addedByUs = new Set();
    annotations.forEach(annotation => {
      const mapKey = getMapKeyForAnnotationQuery(annotation.target);

      const exists = this.map.has(mapKey);

      // So we only add this if the key (based on the annotation "target")
      // was added by us to the map, or it doesn't exist yet.
      //
      // The point is override=false means we do not trust that the annotations
      // we are loading now are the full set and thus should not override an
      // existing full set that might already exist for the key.
      const shouldAdd = !exists || addedByUs.has(mapKey) || opts?.override;
      // Override also causes any existing key to be replaced. If we did not do this it could easily
      // happen that we are adding duplicate annotations to an existing key.
      const shouldForceNew = !addedByUs.has(mapKey) && opts.override;

      if (shouldAdd) {
        if (!exists || shouldForceNew) {
          this.map.set(mapKey, {
            annotations: [annotation]
          })
        }
        else {
          this.map.get(mapKey)!.annotations.push(annotation);
        }

        addedByUs.add(mapKey);
      }
    });
    this.triggerOnChange();
  }

  private triggerOnChange() {
    if (this.onChange) {
      this.onChange();
    }
  }
}


/**
 * A simple implementation of ISimpleCache.
 */
export class SimpleCache implements ISimpleCache {
  map: Map<string, Annotation[]>;

  constructor(initial?: Annotation[]) {
    this.map = new Map();
    if (initial) {
      initial.forEach(item => this.add(item));
    }
  }

  add(annotation: Annotation) {
    const mapKey = getMapKeyForAnnotationQuery(annotation.target);
    let list = this.map.get(mapKey);
    if (!list) {
      list = [];
      this.map.set(mapKey, list);
    }
    list.push(annotation);
    return list;
  }

  get(query: AnnotationQuery|undefined): Annotation[] {
    if (query === undefined) { return []; }
    const mapKey = getMapKeyForAnnotationQuery(query);
    return this.map.get(mapKey) || [];
  }
}


export function cacheFromAnnotationArray(annotations: Annotation[]): SimpleCache {
  const cache = new SimpleCache();
  annotations.forEach(a => cache.add(a));
  return cache;
}

function getMapKeyForAnnotationQuery(query: AnnotationQuery) {
  const data = getMapKeyFieldsForAnnotatinQuery(query);
  let prefix = '';
  if (isQueryByString(query)) {
    prefix = 'textquery';
  }
  else if (isQueryByWordId(query)) {
    prefix = 'wordquery';
  }
  else if (isQueryByInflection(query)) {
    prefix = 'inflection';
  }
  else {
    throw new Error('getMapKeyForWordId() received an unsupported value')
  }

  return prefix + JSON.stringify(data)
}

function getMapKeyFieldsForWordQuery(query: StringQuery|WordIdQuery) {
  if (isQueryByString(query)) {
    const {lang, text} = query;
    return {lang, text};
  }
  if (isQueryByWordId(query)) {
    const {id, meaningId} = query;
    return {id, meaningId};
  }
}


function getMapKeyFieldsForAnnotatinQuery(query: AnnotationQuery) {
  if (isQueryByString(query)) {
    return getMapKeyFieldsForWordQuery(query);
  }
  if (isQueryByWordId(query)) {
    return getMapKeyFieldsForWordQuery(query);
  }
  if (isQueryByInflection(query)) {
    return {
      ...query,
      wordId: getMapKeyFieldsForWordQuery(query.wordId),
      structure: {
        ...query.structure,
        parts: query.structure.parts.map(part => {
          const {value, type} = part;
          return {
            value,
            type
          }
        })
      }
    }
  }
  throw new Error('getMapKeyForWordId() received an unsupported value')
}