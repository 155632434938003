import React from 'react';
import {PlaySpeakerIcon} from "../../Icons/PlaySpeakerIcon";
import { TouchableOpacity } from 'react-native';


type Props = {
  size?: number,
  isPlaying: boolean,
  onClick: any
}


/**
 * Audio UI that is just a simple button.
 *
 * Provide a function that returns the audio object to control.
 */
export default function PlayButton(props: Props) {
  return <TouchableOpacity
    onPress={props.onClick}
  >
    <PlaySpeakerIcon
      width={props.size ?? 32}
      height={props.size ?? 32}
      isFilled={props.isPlaying}
      color={"#607D8B"}
    />
  </TouchableOpacity>
}
