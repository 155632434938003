import * as React from 'react';
import styles from "./WordPopupVisual.cssm";

/**
 * Render the visual popup frame.
 */
export function WordPopupVisual(props: {
  style?: any,
  children?: any
}) {
  return <div className={styles.wordPopup} style={props.style}>
    {props.children}
  </div>
}