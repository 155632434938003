import React from 'react';
import classnames from 'classnames';
import styles from './SeekBar.cssm';
import {Slider, Direction} from 'react-player-controls';
import {PlayerAPI, PlayerState} from 'languagetool-player/src/ui/Video';


type Props = {
  api: PlayerAPI|null,
  state: PlayerState|null,
  style?: any,
  className?: string,
};

type State = {
  time: number,
  load: number,
}


export class SeekBar extends React.Component<Props, State> {
  _onChangeUsed = false

  private unsubscribeTime: any;
  private unsubscribeLoad: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      time: 0,
      load: 0
    };
  }

  componentDidMount(): void {
    this.subscribeToTime();
    this.subscribeToLoad();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.api != prevProps.api) {
      this.subscribeToTime();
      this.subscribeToLoad();
    }
  }

  subscribeToTime() {
    if (this.unsubscribeTime) {
      this.unsubscribeTime();
      this.unsubscribeTime = null;
    }
    if (this.props.api) {
      this.unsubscribeTime = this.props.api.time$.throttle(300).onValue(
        (position: number) => this.setState({time: position}));
    }
  }

  subscribeToLoad() {
    if (this.unsubscribeLoad) {
      this.unsubscribeLoad();
      this.unsubscribeLoad = null;
    }
    if (this.props.api) {
      this.unsubscribeLoad = this.props.api.load$.onValue(
        (position: number) => this.setState({load: position}));
    }
  }

  private handleChange = (value: number) => {
    if (!this.props.api || !this.props.state) {
      return;
    }

    this.props.api.seekTo(value * this.props.state.duration)
    this._onChangeUsed = true
  }

  render() {
    const { style, className, state } = this.props;

    let duration;
    if (state) {
      duration = state.duration;
    }
    else {
      duration = 100;
    }

    const timePercent = this.state.time / duration;
    let loadPercent = this.state.load / duration;
    // Hide the bar when done
    if (loadPercent >= 1) {
      loadPercent = 0;
    }

    return <Slider
      direction={Direction.HORIZONTAL}
      onChange={this.handleChange}
      className={classnames(styles.slider, className)}
    >
      <LoadProgressBar timePercent={loadPercent} />
      <SliderBar timePercent={timePercent} />
      <SliderHandle timePercent={timePercent} />
    </Slider>
  }
}


// A handle to indicate the current value
const SliderHandle: React.FunctionComponent<{
  timePercent: number,
  style?: any
}> = ({ timePercent, style }) => (
  <div
    className={styles.handle}
    style={{
      left: `${timePercent * 100}%`,
      ...style,
    }}
  />
)

// A colored bar that will represent the current value
const SliderBar: React.FunctionComponent<{
  timePercent: number,
  style?: any
}> = ({ timePercent, style }) => (
  <div
    className={styles.seekBar}
    style={{
      width: `${timePercent * 100}%`,
      ...style
    }}
  />
)

const LoadProgressBar: React.FunctionComponent<{
  timePercent: number,
  style?: any
}> = ({ timePercent, style }) => (
  <div
    className={styles.loadProgressBar}
    style={{
      width: `${timePercent * 100}%`,
      ...style
    }}
  />
)