// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__vocable--dHP_p{z-index:auto;position:relative;padding-bottom:15px}._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__timestamp--mG4qh{z-index:auto;position:absolute;left:0;right:0;bottom:4px;height:6px;user-select:none;border-top:0.5px solid gray;padding-top:0.4em;line-height:1em;font-size:8px;color:gray;text-align:center;overflow:hidden;border-left:0.5px solid gray;border-right:0.5px solid gray}/*!** PERFORMANCE: REDUCES THE AMOUNT OF LAYERS IN CHROME **!*//*!** PERFORMANCE: REDUCES THE AMOUNT OF LAYERS IN CHROME **!*/._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__matchesCurrentTime--1B78z ._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__timestamp--mG4qh{border-top-color:red;border-top-width:2px}._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__isFocused--1maZE ._-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__timestamp--mG4qh{border-top-width:2px;border-left-width:2px;border-right-width:2px}\n", ""]);
// Exports
exports.locals = {
	"vocable": "_-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__vocable--dHP_p",
	"timestamp": "_-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__timestamp--mG4qh",
	"matchesCurrentTime": "_-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__matchesCurrentTime--1B78z",
	"isFocused": "_-languagetool-editor-src-js-components-VocableBasedSlate-Vocable__isFocused--1maZE"
};
module.exports = exports;
