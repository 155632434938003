import React from 'react';
import {SvgLettering} from "farsi-script-tool/src/drawtool/svg.web";
import fontFile from '../../../../persian-fonts/assets-raw/NotoSansArabic-Regular.ttf';
import gsap from 'gsap';
import {romanizeSoundsAsArray} from "languagetool-player/src/langpacks/fa/transliterate";
import useReactFontLoader from 'react-font-loader';
import {useQuery} from "multi-apollo";
import gqlw from "graphql-tag";
import { AnimateWordQuery } from '../../types/AnimateWordQuery';
import {ApolloRoot} from "../../ApolloRoot";
import {useAuth0} from "../../utils/Auth0Provider";
import {useURLQuery} from "../../utils/useQuery";
import {SoundsWithMapping} from "languagetool-player/src/langpacks/fa/getSyllables";


const COLORS = [
  '#F44336',
  '#2196F3',
  '#8BC34A',
  '#009688',
  '#FFC107',
  '#E91E63',
  '#9E9E9E',
];


export function useWord(wordId: string) {
  const {data, loading, error, refetch} = useQuery<AnimateWordQuery>(gqlw`
  query AnimateWordQuery($id: Int!) {
    word(id: $id, allowDeleted: true) {
      id, 
      base,      
      baseTransliteration,
      baseTransliterationWithMapping: baseTransliteration(withMapping: true),
      recordings(onlyAccepted: true) {
          id,
          url(key: "main"),          
        }      
    }
  }
`, {
    variables: {id: parseInt(wordId)}
  });
  return {data, loading, error, refetch}
}


export function AnimateWordRoot(props: {
  match: any,
  location: any
}) {
  const {isAuthenticated, getTokenSilently} = useAuth0();
  const query = useURLQuery();

  return <ApolloRoot
    getAuthHeader={async () => {
      if (query.get("token")) {
        return `Bearer ${query.get("token")}`;
      }
      else if (isAuthenticated) {
        const token = await getTokenSilently();
        return `Bearer ${token}`;
      }
      else {
        return "";
      }
    }}
  >
    <AnimatedWord
      wordId={props.match.params.id}
      translationText={query.get('translation') ?? ""}
    />
  </ApolloRoot>
}


export function AnimatedWord(props: { wordId: string, translationText: string }) {
  const {data, loading, error, refetch} = useWord(props.wordId);
  useReactFontLoader({ fonts: [{ name: 'Bitter', weight: [600, 700] }] })

  if (error) {
    return <div>error! {""+error}</div>
  }
  if (loading || !data?.word) { return  <div>loading...</div>}

  return <AnimatedWordScreen
    text={data.word.base}
    translit={JSON.parse(data.word.baseTransliteration)}
    translitMapping={JSON.parse(data.word.baseTransliterationWithMapping)}
    translation={props.translationText}
    recordingUrl={data.word.recordings?.[0].url ?? ""}
  />
}

export function AnimatedWordScreen(props: {
  text: string,
  recordingUrl: string,
  translation: string,
  translit: string[],
  translitMapping: SoundsWithMapping
}) {
  const frameCount = 60;
  const {text, recordingUrl} = props;

  const mapping = parseMapping(props.translitMapping);

  if (text == 'رَأی') {
    mapping.farsiToTranslit = {"0":[0],"1":[1],"2":[2],"3":[3]};
    mapping.translitToFarsi = {"0":[0],"1":[1],"2":[2],"3":[3]};
  }
  if (text == 'رَأی دادَن') {
    mapping.farsiToTranslit = {"0":[0],"1":[1],"2":[2],"3":[3],"4":[3],"5":[5],"6":[6],"7":[7],"8":[8],"9":[9]};
    mapping.translitToFarsi = {"0":[0],"1":[1],"2":[2],"3":[3],"4":[4],"5":[5],"6":[6],"7":[7],"8":[8],"9":[9]};
  }

  // Some annotation chars, for example a connecting -y-, are not rendered here, so we filter them out
  // to create an array that matches in length exactly the persian letter count.
  // We keep the full one around, since `mapping` refers to indices in that one.
  const allAnnotationChars = romanizeSoundsAsArray(props.translit);
  const renderedAnnotationChars = allAnnotationChars.filter((char, idx) => {
    return !!mapping.translitToFarsi[idx];
  });
  renderedAnnotationChars.reverse();

  React.useEffect(() => { gsap.ticker.fps(frameCount); }, [frameCount])

  const [timelineResult, initTimeline] = useDelayedMemo((paths: any) => {
    if (!paths || !renderedAnnotationChars) {
      return {};
    }

    const charShowTime = 0.7;
    const initTime = 0.3;

    const tl = gsap.timeline({repeat: 0, defaults: {ease: "none"}, paused: true});

    // Show each letter separately.
    paths.forEach((path: any, idx: number) => {
      tl.fromTo(`.foo .letter:nth-child(${paths.length-idx})`, {opacity: 0}, {duration: 0.3, opacity: 1}, idx * charShowTime + initTime);
    }, []);

    // Fade in the english text
    tl.fromTo(`.translation`, {opacity: 0}, {duration: 0.3, opacity: 1}, '<');

    // Animate the annotations. The array is in reverse order, matching the DOM node order. So we start at the end.
    // Note that we have to iterate two distinct arrays: The full list of annotationChars (since the mapping farsi <-> finglish
    // is based on that), while the indices into the rendered annotations are different.
    let renderedIndex = renderedAnnotationChars.length;
    for (let i=allAnnotationChars.length-1; i>=0; i--) {
      // The annotations are actually reversed, so i=0 means "the first annotation/letter node"
      // and "the first character in the word string".
      const characterIdx = allAnnotationChars.length - i - 1;

      // There may be no farsi letters effected, in cases where for example a -y- connector is inserted.
      if (!mapping.translitToFarsi[characterIdx]) {
        continue
      }
      renderedIndex--;

      // Output nothing for spaces, skip them.
      if (allAnnotationChars[characterIdx] === " ") {
        continue;
      }

      const colorIdx = renderedIndex % COLORS.length;
      const fadeDur = 0.7;
      tl.fromTo(`.foo .annotation:nth-child(${renderedIndex+1}) > text`, {opacity: 0}, {duration: fadeDur, opacity: 1, fill: COLORS[colorIdx]}, '<1.5');
      tl.fromTo(`.foo .annotation:nth-child(${renderedIndex+1}) > line`, {opacity: 0}, {duration: fadeDur, opacity: 1, stroke: COLORS[colorIdx]}, '<');

      // There may be multiple farsi letters affected.
      for (const mappedCharacterIdx of mapping.translitToFarsi[characterIdx]) {
        // Because letters and annotations index from left to right, we have to map it back!
        const mappedDomNodeIdx = paths.length - mappedCharacterIdx;
        tl.to(`.foo .letter:nth-child(${mappedDomNodeIdx}) > path`, {duration: fadeDur, fill: COLORS[colorIdx]}, `<`);
      }
    }

    // When do we play the sound effects?
    const soundEffects = recordingUrl ? [
      {
        url: recordingUrl,
        time: initTime + paths.length * charShowTime * 1000
      },
      {
        url: recordingUrl,
        time: (tl.duration() + 1) * 1000
      }
    ] : null;

    return {timeline: tl, soundEffects};
  }, [text, renderedAnnotationChars]);

  const {timeline, soundEffects} = timelineResult ?? {};

  useSetupVideoAPI({
    // TODO: Would it not be better for the exporter to *tell* us the desired frame count?
    frameCount: frameCount,
    timeline,
    soundEffects
  });

  const handleOnPaths = React.useCallback((paths: any) => {
    if (!paths) {return;}
    initTimeline(paths);
  }, []);

  const width = 1200;
  const height = 1200;

  return  <div>
    <div id={"scene"} style={{
      width,
      height,
      boxSizing: 'border-box',
      overflow: 'hidden',
      contain: 'strict',

      backgroundColor: '#fcfcfc',
      padding: '10%',
      //border: '1px solid silver',
    }}>
      <img src={"https://farsi.school/assets/logo_1000.png"} style={{
        position: 'absolute',
        width: 0.2 * width,
        right: 0.03 * width,
        top: 0.03 * height
      }} />
      <SvgLettering
        font={fontFile}
        text={text}
        annotationMode={'mix'}
        // //getColor={idx => COLORS[idx % COLORS.length]}
        letterStyle={{fill: "auto"}}
        annotations={renderedAnnotationChars.map(x => ({
          width: 25,
          height: 25,
          label: x
        }))}
        annotationStyle={{
          fontSize: 30,
          fill: 'black',
          fontFamily: 'Bitter, serif',
          fontWeight: 700,
        }}
        onPathsChanged={handleOnPaths}
        style={{
          height: '100%',
          width: '100%',
          // The font tends to align more on the bottom based on line height!
          transform: `translateY(${-0.1 * height}px)`
        }}
        className={"foo"}
      />
      <div className={"translation"} style={{
        position: 'absolute',
        width: 0.8 * width,
        left: 0.1 * width,
        bottom: 0.1 * height,
        fontSize: 0.1 * height,
        fontFamily: 'Bitter, serif',
        fontWeight: 700,
        textAlign: 'center',
      }}>
        {props.translation}
      </div>
    </div>

    {timeline ? <ProgressControl timeline={timeline} /> : null}
  </div>
}

function useDelayedMemo(func: any, deps: any[]): [any, any] {
  // A static value, but determined only after the first render.
  const [v, setValue] = React.useState(undefined);
  const initFunc = React.useCallback((...args: any[]) => {
    setValue(func(...args))
  }, deps);
  return [v, initFunc];
}


function ProgressControl(props: {
  timeline: gsap.core.Timeline
}) {
  return <div>
    <input type={"range"} min={0} max={1} step={0.01} defaultValue={0} onChange={(e) => {
      props.timeline.progress(parseFloat(e.target.value))
    }}/>
    <button onClick={() => props.timeline.play()}>play</button>
  </div>
}

function useSetupVideoAPI(opts: {
  frameCount: number,
  timeline: gsap.core.Timeline|undefined,
  soundEffects: any[]
}) {
  const frameCount = opts.frameCount;
  const timeline = opts.timeline;

  React.useEffect(() => {
    // As soon as we install the `getInfo`, the browser will start frameshotting.
    if (!timeline) { return; }

    (window as any).getInfo = () => {
      let extraSeconds = 3.5; // give the sound at the end time to finish playing
      return {
        fps: frameCount,
        numberOfFrames: (timeline!.duration() + extraSeconds) * frameCount,
        soundEffects: opts.soundEffects
      }
    }

    (window as any).seekToFrame = (frameNum: any) => {
      const duration = timeline!.duration();
      timeline!.pause(duration / (frameCount*duration) * frameNum )

      // This does not give great results, for some reason, maybe the times match the frames less closely?
      // ref.current.progress(
      //   frameNum / (ref.current.duration() * frameCount)
      // )
    }
  }, [timeline, frameCount]);
}

export function parseMapping(mapping: SoundsWithMapping) {
  // Index in translit text maps to index in farsi
  // {0: [1,2], 1: [3]}
  const translitToFarsi: {[key: number]: number[]} = {};
  // Same, but the other way around.
  const farsiToTranslit: {[key: number]: number[]} = {};

  mapping?.forEach((item, idx) => {
    const meta = item[1];
    if (typeof meta === "string") {
      return null;
    }

    translitToFarsi[idx] = meta;

    meta.forEach((value) => {
      if (!farsiToTranslit[value]) {
        farsiToTranslit[value] = [];
        farsiToTranslit[value].push(idx);
      }
    });
  });

  return {
    translitToFarsi,
    farsiToTranslit
  }
}