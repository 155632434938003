import React from "react";
import {formatTime, Scale} from "./coordinates";
import {Container, Text, PixiComponent, useApp} from '@inlet/react-pixi'
import {PlayerAPI} from "languagetool-player/src/ui/Video";
import {string2hex, useCurrentPlayTime} from "./utils";
import {TextStyle} from 'pixi.js';
import * as PIXI from "pixi.js";

type Props = {
  height: number,
  offsetSeconds: number,
  scale: Scale,
  playerAPI: PlayerAPI,
  showText: boolean
};


const style = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12,
  fill: 'black'
});


export const PlayHead = React.memo(function PlayHead(props: Props) {
  const currentTime = useCurrentPlayTime(props.playerAPI);

  const currentPixelPos = props.scale.secondsToPixel(currentTime - props.offsetSeconds);

  return <Container
      x={currentPixelPos}
      y={0}
    >
    {props.showText ? <Text
      x={5}
      y={5}
      text={formatTime(currentTime, true)}
      style={style}
    /> : null}

      <Line
        x1={0.5}
        y1={0}
        x2={0.5}
        y2={props.height}
        color={string2hex("#3f51b5")}
      />
    </Container>;
});


type LineProps = {
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  color: number
};

const Line = PixiComponent('Line', {
  create: (props: LineProps) => {
    return new PIXI.Graphics()
  },
  applyProps: (instance, oldProps: LineProps, newProps: LineProps) => {
    const { x1, y1, x2, y2, color } = newProps
    instance.clear()
    instance.lineStyle(1, color, 1)
    instance.moveTo(x1, y1);
    instance.lineTo(x2, y2);
  }
})