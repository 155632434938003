import React from "react";
import {useAuth0} from "../utils/Auth0Provider";
import {PrimaryButton} from "office-ui-fabric-react";

export function LoginScreen() {
  const {loginWithRedirect} = useAuth0();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <h1>You must login.</h1>
      <PrimaryButton onClick={loginWithRedirect}>Login</PrimaryButton>
    </div>
  );
}
