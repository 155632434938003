import React from 'react';
import gqlw from 'graphql-tag';
import {useQuery} from "multi-apollo";
import { PrimaryButton } from 'office-ui-fabric-react';
import {TodosQuery} from "../../types/TodosQuery";
import { WordWithDetailsFragment } from '../../utils/WordWithDetails';
import {InternalNoteFragment, Note} from "../../components/Note";


const query = gqlw`
  ${InternalNoteFragment}
  ${WordWithDetailsFragment}
  query TodosQuery($cursor: String, $assignedTo: ID) {
    internalNotes(first: 100, after: $cursor, isTodo: true, isResolved: false, assignedTo: $assignedTo, sort: "created-at") {
      pageInfo {
        endCursor
        hasNextPage
      },
      edges {
        node {
          id,
          ...InternalNote,
          word {
            ...WordWithDetails
          }
        }
      }            
    }
  }
`;


function useTodos(opts?: {
  onlyAssignedTo?: string
}) {
  const variables = {
    assignedTo: opts?.onlyAssignedTo
  }
  const {data, error, loading, refetch, fetchMore} = useQuery<TodosQuery>(query, {
    variables
  });

  // const loadMore = () => {
  //   fetchMore({
  //     query,
  //     variables: {
  //       ...variables,
  //       cursor: data!.activityLog!.pageInfo.endCursor,
  //     },
  //     updateQuery: (previousResult, { fetchMoreResult }) => {
  //       const newEdges = fetchMoreResult!.activityLog!.edges;
  //       const pageInfo = fetchMoreResult!.activityLog!.pageInfo;
  //
  //       return newEdges.length
  //         ? {
  //           // Put the new comments at the end of the list and update `pageInfo`
  //           // so we have the new `endCursor` and `hasNextPage` values
  //           activityLog: {
  //             __typename: previousResult.activityLog!.__typename,
  //             edges: [...previousResult.activityLog!.edges, ...newEdges],
  //             pageInfo
  //           }
  //         }
  //         : previousResult;
  //     }
  //   })
  // };

  return {data, error, loading, refetch,
    fetchMore: () => {}
  //  fetchMore: loadMore
  }
}


export function Todos(props: {
  onlyAssignedTo?: string
}) {
  const {data, loading, error, fetchMore} = useTodos({
    onlyAssignedTo: props.onlyAssignedTo
  });

  const handleLoadMore = () => {
    fetchMore();
  }

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;
  if (!data) { return null; }

  return <div>
    {data.internalNotes?.edges?.map(edge => edge.node).map(node => {
      return <Note key={node.id} note={node} word={node.word as any} />
    })}

    <PrimaryButton onClick={handleLoadMore}>Load More</PrimaryButton>
  </div>
}
