import {Button} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import styles from './LineBrowser.cssm';
import {MiniMap} from "./MiniMap";
import {ContentIssue, IndexedContentIssues} from "../../Market/ContentIssues";
import {TrackInterface} from "../assignScreen";



function safeParseInt(s: string, defaultValue: number): number {
  const result = parseInt(s);
  if (isNaN(result)) {
    return defaultValue;
  }
  return result;
}


function ConfirmInput(props: {
  value: any,
  onChange: (newValue: any) => void,
  className?: string
}) {
  const [editValue, setEditValue] = useState(props.value);

  useEffect(() => {
    setEditValue(props.value);
  }, [props.value]);

  const handleBlur = useCallback(() => {
    props.onChange(editValue);
  }, [props.onChange, editValue]);

  const handleKeyPress = useCallback((e: any) => {
    if (e.nativeEvent.keyCode === 13) {   // ENTER
      props.onChange(editValue);
    }
    if (e.nativeEvent.keyCode === 27) {  // ESC
      setEditValue(props.value);
    }
  }, [props.onChange, editValue]);

  const handleChange = useCallback((e: any) => {
    setEditValue(e.target.value);
  }, [setEditValue]);

  return <input
    value={editValue}
    onChange={handleChange}
    onKeyPress={handleKeyPress}
    onBlur={handleBlur}
    className={props.className}
  />
}


export function LineBrowser(props: {
  trackAPI: TrackInterface,
  currentLineIndex: number,
  lineCount: number,
  setCurrentLineIdx: (idx: number) => void,
  contentIssues?: IndexedContentIssues
}) {
  const {currentLineIndex, lineCount, setCurrentLineIdx} = props;

  return <div className={styles.root}>
    <div>
      Line:
      <ConfirmInput
        className={styles.lineNumInput}
        value={"" + (currentLineIndex + 1)}
        onChange={(value) => setCurrentLineIdx(safeParseInt(value, currentLineIndex + 1) -1)}
      />
      / {lineCount}
    </div>
    <div>
      <Button
        color={"primary"}
        variant="contained"
        onClick={() => setCurrentLineIdx(Math.min(currentLineIndex + 1, lineCount - 1))}
        disabled={currentLineIndex >= lineCount - 1}
      >
        Next
      </Button>
      <Button
        onClick={() => setCurrentLineIdx(Math.max(currentLineIndex - 1, 0))}
        disabled={currentLineIndex <= 0}
      >
        Previous
      </Button>
    </div>
    <MiniMap
      style={{marginTop: '10px'}}
      trackAPI={props.trackAPI}
      onSelect={setCurrentLineIdx}
      currentIndex={currentLineIndex}
      contentIssues={props.contentIssues}
    />
  </div>
}