import React, {useState} from 'react';
import {useQuery} from "multi-apollo";
import {gql as gqlw} from '@apollo/client';
import {handleApolloState} from "../../utils/handleApolloState";
import {DefaultButton} from 'office-ui-fabric-react';
import {WordInternalNotesQuery} from "../../types/WordInternalNotesQuery";
import {InternalNoteFragment, Note} from "../../components/Note";
import {AddEditNoteForm} from "../../components/WriteNoteForm";


function useNotes(wordId: string) {
  const {loading, error, data, refetch} = useQuery<WordInternalNotesQuery>(gqlw`
    ${InternalNoteFragment}    
    query WordInternalNotesQuery($id: Int!) {
      word(id: $id, allowDeleted: true) {
        id,
        internalNotes {
          ...InternalNote 
        }
      }
    }
  `, {
    variables: {id: wordId},
    client: 'words'
  });

  return {
    loading,
    refetch,
    error,
    notes: data?.word?.internalNotes ?? []
  }
}



export function NotesTab(props: {word: any}) {
  const {notes, loading, error, refetch} = useNotes(props.word.id);
  const [isNewVisible, setIsNewVisible] = useState(false);

  const stateComponent = handleApolloState({loading, error});
  if (stateComponent) {
    return stateComponent;
  }

  return  <div>

    {isNewVisible
      ?
        <AddEditNoteForm
          onCancel={() => setIsNewVisible(false)}
          onSave={() => {
            setIsNewVisible(false);
            refetch();
          }}
          wordId={props.word.id}
        />
      : <DefaultButton onClick={() => setIsNewVisible(true)}>Add new Note or Todo</DefaultButton>
    }


    <div style={{marginTop: '20px'}}>
      {
        !notes.length ? <div>
          There are no notes for this entry.
        </div> : null
      }
      {
        notes.map((note: any) => {
          return <Note key={note.id} note={note} />
        })
      }
    </div>
  </div>
}
