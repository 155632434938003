/**
 * The popup configuration holds switches such as "should we show a transliterated version?", "should we show a Farsi
 * word with vowels?", "should we show a dictionary entry in the source language, if we can?".
 *
 * The config is made available via context, because many nested components, such as every <WordLink /> need to be
 * able to access it to determine what form of a word to show.
 */


export type PopupConfig = {
  // Use a vocable alternative instead of the main (such as the version with diacritics).
  preferAlternates: string[]|false,

  // Show a transliteration in addition
  showTransliteration: string[]|false,
  transliterationRender?: (x: any) => string,

  // Whether the user prefers to see an explanation in the source language (that is, the foreign
  // language they are learning), or in their own language, usually depending on their skill level.
  preferSourceLanguage: boolean,

  autoPlayAudio: boolean
}


export const defaultConfig: PopupConfig = {
  showTransliteration: false,
  preferAlternates: false,
  preferSourceLanguage: false,
  autoPlayAudio: true
};
