import React, {Fragment} from "react";
import {FarsiGrammarDescription} from "../../../langpacks/fa/GrammarDescription";
import {InflectionAnnotation} from "../../../annotations/Inflection";
import {isQueryByWordId, isWordData} from "../../../annotations";
import {FarsiGrammarChoiceLabel} from "../../../langpacks/fa/GrammarChoiceLabel";
import {ISimpleCache} from "../../../cache";
import {WordNode} from "../../../models/Node";
import {Em} from "./Em";
import {Text} from 'react-native';
import {WordLink} from "./WordLink";
import {PopupCommonStyles} from "../styles";
import { TextBlock } from "../../Base/Text";


/**
 * Render the grammar details of a word, along side a possible meaning.
 *
 * If the meaning of the word is known, either the meaning of the particular grammar shape, or the meaning of
 * the base word, then this can be integrated into the way we display the grammar in some smart way.
 *
 * If no meaning is known, and we only have dictionary entries, then those can not be integrated and would
 * be rendered separately.
 *
 * To put it another way, think of this as acting as either a "explain this particular grammar" view, or a
 * "the easiest and best way to explain this word" view, depending on circumstance.
 *
 * We render, in order of preference:
 *
 *     ketabha -> noun, plural, books
 *     ketabha -> noun, plural of "ketab" (book).
 *     ketabha -> noun, plural of "ketab".
 *     ketabha-yand -> ketabhab + hastand, ketabha, noun, plural of books.
 *
 * TODO: I recently feel that a structure where "ketabha-yand" is returned as a FarsiContraction, with a nested
 * FarsiNoun, would be preferable to the current way we store stuff.
 */


export function GrammarDescription(props : {
  forms: InflectionAnnotation[],

  // This is for information about the base words reference in the grammar.
  relatedAnnotations?: ISimpleCache,
}) {
  const {forms, relatedAnnotations} = props;

  // If there is a single possible form, show that form directly.
  if (forms.length == 1) {
    return <SingleKnownGrammar
      form={forms[0]}
      relatedAnnotations={relatedAnnotations}
    />
  }
  else {
    return <MultiplePossibleGrammars {...props} />
  }
}


function MultiplePossibleGrammars(props: {
  forms: InflectionAnnotation[],
  relatedAnnotations?: ISimpleCache,
}) {
  const {forms, relatedAnnotations} = props;

  throw new Error("Support disabled.")

  // If there are multiple possible forms, then we aren't sure which one is the right one. We
  // show a selection of all of them.
  if (forms.length > 1) {
    //const groupedForms = keyBy(forms, x => x.wordId);

    const items = forms.map((form, idx) => {
      let wordDataNode;
      if (isQueryByWordId(form.wordId)) {
        if (relatedAnnotations) {
          wordDataNode = relatedAnnotations.get(form.wordId).filter(isWordData)[0];
        }
      }

      return {
        form,
        title: form.title || <FarsiGrammarChoiceLabel annotation={form} wordData={wordDataNode} />,
        children: <SingleKnownGrammar form={form} />
      }
    });

    return <>
      <div style={{
        'fontSize': '0.6em',
        'textTransform': 'uppercase',
        'color': 'gray',
        'marginBottom': '1em',
      }}>Possible Grammar Forms</div>

      {items.map((item, idx) => {
        return <WordLink
          key={idx}
          //className={styles.option}
          to={{
            type: 'word',
            id: '',
            vocables: [],
            wordId: item.form.wordId,
            inflection: item.form.inflection,
            structure: item.form.structure
          } as WordNode}
        >
          {item.title}
        </WordLink>
      })}
    </>
  }

  return null;
}


function SingleKnownGrammar(props: {
  form: InflectionAnnotation,
  relatedAnnotations?: ISimpleCache,
}) {
  return <TextBlock style={[PopupCommonStyles.grayPrefix, {fontSize: 16}]}>
    <FarsiGrammarDescription
      annotation={props.form}
      relatedAnnotations={props.relatedAnnotations}
    />
  </TextBlock>
}