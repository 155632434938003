import {NestableField, useNestedValues} from "../../../../components/NestedFormik";
import React from "react";
import {validateRequired} from "../index";
import {SelectOption, useUIControls} from "../../../../components/FormUISwitch";


type Props = {
}

export const AliasTypeField = (props: Props) => {
  const UI = useUIControls();
  const values = useNestedValues();
  const currentValue = values.type;

  return <NestableField
    name={"aliasType"}
    label={"Type of the alias"}
    component={UI.Select}
    style={{
      alignSelf: 'flex-start',
      minWidth: '250px'
    }}
    validate={validateRequired}
    options={BaseOptions}
  />
};


const BaseOptions: SelectOption[] = [
  {
    value: 'colloquial',
    label: "Colloquial",
    subtitle: <>spoken version of a formal entry</>
  },
  {
    value: 'alternate-spelling',
    label: "Alternate Spelling",
    subtitle: <>Another way to write it.</>
  },
];