import styles from "./EditableText.cssm";
import React, {useState, useRef, useEffect} from "react";


export function EditableText(props: {
  text: string,
  children?: any,
  onSave: (text: string) => void,
  allowEdit?: boolean,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState("");
  const input = useRef<any>();

  const handleClick = (e: any) => {
    if (!props.allowEdit) {
      return;
    }
    e.stopPropagation();
    if (!isEditing) {
      setEditText(props.text);
      setIsEditing(true);
    }
  };

  const handleChange = (e: any) => {
    setEditText(e.target.value);
  };

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13) {
      props.onSave(editText);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, [isEditing]);

  let content;
  if (isEditing) {
    content = <input
      ref={input}
      value={editText}
      onChange={handleChange}
      onBlur={() => {
        setIsEditing(false);
      }}
      onKeyUp={handleKeyPress}
    />;
  }
  else {
    content = props.children;
  }

  return <div className={styles.root} onDoubleClick={handleClick}>
    {content}
  </div>;
}

EditableText.defaultProps = {
  allowEdit: true
}