import React, {useMemo} from 'react';
import {useQuery} from "multi-apollo";
import gql from "graphql-tag";
import {BaseLemmaContent} from "languagetool-player/src/ui/WordPopup/PopupContent";
import styles from './ExternalAnnotations.module.css';
import { Spinner } from 'office-ui-fabric-react';


function useAnnotationsQuery(wordId: number) {
  const {data, loading} = useQuery(gql`
    query AnnotationsQuery($wordId: Int!) {
      annotations(wordId: $wordId) {
        type,
        rawData
      }
    }
  `, {
    variables: {
      wordId
    }
  });
  const annotations = useMemo(() => {
    return data ? data.annotations.map((a: any) => JSON.parse(a.rawData)) : null;
  }, [data])
  return {annotations, loading};
}


export function ExternalAnnotations(props: {
  wordId: number
}) {
  const {annotations, loading} = useAnnotationsQuery(props.wordId);
  if (loading) {
    return <div>
      <Spinner />
    </div>
  }

  return <div className={styles.source}>
    <BaseLemmaContent
      annotations={annotations}
      defaultLanguage={"fa"}
      allInitiallyExpanded={true}
    />
  </div>
}