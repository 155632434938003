import React, {useState} from 'react';
import {Checkbox, Dialog, DialogFooter, DefaultButton, DialogType} from 'office-ui-fabric-react';
import gqlw from "graphql-tag";
import {useMutation, useQuery} from 'multi-apollo';
import {ApproveWord} from "../../types/ApproveWord";
import {useMessageBar} from "../../components/MessageBarManager";


const useApprove = () => {
  const [approve, {data: mutationData, loading}] = useMutation<ApproveWord>(gqlw`
    mutation ApproveWord($wordId: Int!, $approve: Boolean!) {
      approveWord(wordId: $wordId, approve: $approve) {
        word {
          id,
          isReviewed
        },
        errors
      }
    }
  `);
  return {approve, loading};
}


export function ReviewStatusCheckbox(props: {
  isReviewed: boolean,
  wordId: number
}) {
  const [errors, setErrors] = useState<string[]|null>(null);
  const approve = useApprove();
  const {showError} = useMessageBar();

  return <>
    <Checkbox
      checked={props.isReviewed}
      disabled={approve.loading}
      onChange={async (e: any) => {
        let result;
        try {
          result = await approve.approve({
            variables: {
              approve: e.currentTarget.checked,
              wordId: props.wordId
            }
          });
        }
        catch(e) {
          console.error(e);
          showError("Connection to server failed, review status not changed.")
          return;
        }

        const errors = result?.data?.approveWord.errors ?? [];
        if (errors && errors.length) {
          setErrors(errors);
        }
      }}
      label={"Reviewed"}
    />
    <ReviewFailedDialog onRequestClose={() => setErrors(null)} errors={errors} />
  </>
}


function ReviewFailedDialog(props: {
  onRequestClose: any,
  errors?: string[]|null
}) {
  return <Dialog
    hidden={!props.errors}
    onDismiss={props.onRequestClose}
    dialogContentProps={{
      type: DialogType.largeHeader,
      title: 'Word not valid',
      subText: 'This word is still invalid and cannot be reviewed as is. Please check the errors.'
    }}
    modalProps={{
      isBlocking: false,
      styles: { main: { maxWidth: 600 } }
    }}
  >
    {props.errors?.map(error => {
      return <li>{error}</li>
    })}
    <DialogFooter>
      <DefaultButton onClick={props.onRequestClose} text="OK" />
    </DialogFooter>
  </Dialog>
}