import {PronunciationPlayer} from "./PronunciationPlayer";
import * as React from "react";
import {View} from 'react-native';
import {LoadingIndicator} from "./LoadingIndicator";
import {PopupCommonStyles} from "../styles";
import {TextBlock} from "../../Base/Text";
import {PronunciationAnnotation} from "../../../annotations/Pronunciation";


/**
 * Shows a title, play button plus optional loading indicator.
 *
 * In a second line, can show content that "previews" that section, such as:
 *
 * - a simple translation, when expanding it would yield more details.
 * - a simple grammar overview, when expanding it would yield a full grammar analysis.
 */
export function SectionTitle(props: {
  title: any,
  moreContent?: any,

  audioAnnotations: PronunciationAnnotation[],

  // Config
  actions?: any,
  isSmall?: boolean,
  isLoading?: boolean,

  style?: any
}) {
  return <View
    style={[props.style]}
  >
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      <PronunciationPlayer
        annotations={props.audioAnnotations}
        size={props.isSmall ? 22 : undefined}
      />

      <TextBlock style={[
        {
          fontWeight: 'bold',
          flex: 1,
        },
        PopupCommonStyles.defaultText
      ]}>{props.title}</TextBlock>

      <View>
        {props.isLoading
          ? <LoadingIndicator />
          : null
        }

        {props.actions}
      </View>
    </View>

    <View>
      {props.moreContent}
    </View>
  </View>
}
