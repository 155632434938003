import React from "react";
import {isEqual} from "lodash";

// From: https://github.com/kentcdodds/use-deep-compare-effect

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export function useDeepCompareEffect(callback: any, dependencies: any[]) {
  React.useEffect(callback, useDeepCompareMemoize(dependencies));
}
