import {CaptionTrack as ExternalCaptionTrack, Line, Vocable} from "languagetool-player/src/models";
import {StructureNode} from "languagetool-player/src/models/Node";
import {StructureNodeStore} from "../StructureNodeStore";
import {Text} from 'slate';
import {LineGroup, Line as LineBlock, WordInline} from "./types";
import {Captions} from "./index";
import {
  parseVocableListToLine,
  serializeLineToVocableList
} from "../../components/VocableBasedSlate/parseVocableListToLine";


/**
 * Takes a CaptionTrack structure as it is stored in our units/database, and converts it to a
 * Slate Value, which is how we handle the data structure internally within the editor.
 *
 * @deprecated Use Captions.fromJSON() instead.
 */
export function slateValueFromCaptions(captionTrack: ExternalCaptionTrack): Captions {
  return Captions.fromJSON(captionTrack);
}


export function loadLinesFromExternalCaptionTrack(captionTrack: ExternalCaptionTrack) {
  const listOfLineGroupBlocks: LineGroup[] = [];

  for (const lineGroup of captionTrack.lines) {
    const listOfLineBlocks: LineBlock[] = [];

    for (const line of lineGroup.elements) {
      listOfLineBlocks.push({
        type: 'line',
        key: "",
        children: parseVocableListToLine(line) as (Text|WordInline)[]
      });
    }

    const block: LineGroup = {
      type: 'linegroup',
      key: "",
      data: {
        time: lineGroup.time,
        duration: lineGroup.duration,
        speakers: lineGroup.speakers
      },
      children: listOfLineBlocks
    };
    listOfLineGroupBlocks.push(block);
  }

  return listOfLineGroupBlocks;
}


export function loadNodesFromExternalCaptionTrack(captionTrack: ExternalCaptionTrack) {
  let structureNodes = new StructureNodeStore();
  for (const node of captionTrack.nodes) {
    structureNodes = structureNodes.addExisting(node);
  }
  return structureNodes;
}


// @deprecated
export function exportLineToVocables(line: LineBlock) {
  return serializeLineToVocableList(line.children)
}


/**
 * Export the slate value structure, convert to our external CaptionTrack format.
 */
export function slateValueToCaptions(captions: Captions): ExternalCaptionTrack {
  let lines: Line[] = [];

  for (const lineGroup of captions.lines) {
    if (lineGroup.type !== 'linegroup') {
      throw new Error(`Expected a linegroup block, but found: ${lineGroup.type}`);
    }
    let linesOfElements: any[] = [];

    for (const line of lineGroup.children) {
      linesOfElements.push(serializeLineToVocableList(line.children));
    }

    // For now, only support a single line
    lines.push({
      elements: linesOfElements,
      // Officially, the player types do not allow the time to be missing, but we do allow saving as such, thus the !
      time: lineGroup.data.time!,
      duration: lineGroup.data.duration!,
      speakers: lineGroup.data.speakers
    })
  }

  const structureNodes = captions.nodes;
  let nodes: StructureNode[] = structureNodes ? Array.from(structureNodes.byId.values()) : [];

  return {
    lines,
    nodes
  }
}