// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-LineByLineView-LineBrowser__root--3n7w8{margin:20px;text-align:center}._-languagetool-editor-src-js-integrations-Words-LineByLineView-LineBrowser__lineNumInput--3wJHi{width:50px;padding:.5em;font-size:16px;outline:none;text-align:center;margin:0 .5em 0.5em}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-LineBrowser__root--3n7w8",
	"lineNumInput": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-LineBrowser__lineNumInput--3wJHi"
};
module.exports = exports;
