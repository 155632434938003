// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../img/oval.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._-languagetool-player-src-ui-WordPopup-parts-LoadingIndicator__spinnerWrapper--1vTDb{display:inline-block}._-languagetool-player-src-ui-WordPopup-parts-LoadingIndicator__spinner--3PADu{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50% 50%;background-size:contain;width:1em;height:1em;margin-left:8px;display:inline-block}\n", ""]);
// Exports
exports.locals = {
	"spinnerWrapper": "_-languagetool-player-src-ui-WordPopup-parts-LoadingIndicator__spinnerWrapper--1vTDb",
	"spinner": "_-languagetool-player-src-ui-WordPopup-parts-LoadingIndicator__spinner--3PADu"
};
module.exports = exports;
