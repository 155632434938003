type ConnectionCharacterDef = {
  isolated: string,
  initial: string,
  medial: string,
  final: string,
};

type UnconnectedCharacterDef = {
  initialOrIsolated: string,
  finalMedial: string,
}

type DiacriticMark = {

}

export type CharDef = ConnectionCharacterDef|UnconnectedCharacterDef;


export function isUnconnectedCharacter(def: CharDef): def is UnconnectedCharacterDef {
  return ('initialOrIsolated' in def);

}

// Merge this with the one in LetterDB.
// noinspection NonAsciiCharacters
const MAPPING: {[key: string]: CharDef} = {
  //'\u0650': {},
  'ب': {
    isolated: '',
    final: 'ـب',
    medial: 'ـبـ',
    initial: 'بـ'
  },
  '\u0631': {
    initialOrIsolated: '\ufead',
    finalMedial: '\ufeae'
  },
  'ن': {
    isolated: 'ن',
    final: 'ـن',
    medial: 'ـنـ',
    initial: 'نـ'
  },
  '\u06cc': {
    isolated: '\ufbfc',
    final: '\ufbfd',
    medial: '\ufbff',
    initial: '\ufbfe'
  },
}

export function getPersianCharDef(char: string): CharDef  {
  return MAPPING[char];
}