import {useEffect, useState} from 'react';
import gqlm from 'graphql-tag';
import { DocumentNode } from 'graphql';
import {QueryUnit_unit} from "../../../types/QueryUnit";

// @ts-ignore
const MARKET_API = `${window.CONFIG ? window.CONFIG.marketApiUrl : ""}`;


let globalAuthKey: string = "";

export function setGlobalAuthKey(key: string) {
  globalAuthKey = key;
  window.dispatchEvent(globalAuthChangedEvent);
}


export function getGlobalAuthKey() {
  return globalAuthKey;
}

const globalAuthChangedEvent = new Event('globalAuthChanged');


function getGqlString(doc: DocumentNode): string {
  return doc.loc!.source.body;
}


export const QUERY_UNIT_QUERY = gqlm`
  query QueryUnit($id: ID!) {
    unit(id: $id) {
      id,
      name,
      captionTrack {
        id,
        language,
        format,
        latestVersion {
          id,
          data,
          translations(langs: ["en"]) {
            data
          }
        }
      }
      mediaTrack {
        id,
        language,
        type,
        url          
      },
      container {
        files {
          name
          url
        }
      }
    }
  }
`

export async function queryUnit(id: string): Promise<QueryUnit_unit|null> {
  return (await graphqlQuery({
    query: getGqlString(QUERY_UNIT_QUERY),
    args: {id: id},
    endpoint: MARKET_API})).unit;
}


export async function createUnit(data: {
  name: string,
  data: {
    youtube?: string,
    aparat?: string
  }
}): Promise<string> {
  const query = `
    mutation ($props: UnitProperties!) {
      createUnit(properties: $props) {
        unit {
          id
        } 
      }
    }
  `;

  const response = await graphqlQuery({
    query: query,
    args: {
      props: {
        name: data.name,
        data: JSON.stringify(data.data)
      },
    },
    endpoint: MARKET_API
  });
  return response.createUnit.unit.id;
}

export async function saveCaptionTrackVersion(captionTrackVersionId: string, data: string, opts?: {
  endpoint: string
}) {
  const query = `
    mutation ($id: String!, $data: String!) {
      saveCaptionTrackVersion(id: $id, data: $data) {
        captionTrackVersion {
          id,
          data
        }
      }
    }
  `;

  const result = (await graphqlQuery({
    query,
    args: {
      id: captionTrackVersionId,
      data
    },
    endpoint: opts?.endpoint || MARKET_API
  }));
  return {id: result.saveCaptionTrackVersion.captionTrackVersion.id};
}


/**
 * Use our custom graphql fetch client as a hook.
 */
export function useQuery<T=any>(query: string): [T|null] {
  const [queryResult, setQueryResult] = useState(null);
  useEffect(() => {
    graphqlQuery({query, endpoint: MARKET_API}).then(result => {
      setQueryResult(result);
    })
  }, [query]);

  return [queryResult];
}


type Opts = {
  query: string,
  args?: any,
  endpoint: string,
  credentials?: RequestCredentials
};


export async function graphqlQuery(opts: Opts) {
  const response = await fetch(opts.endpoint, {
    method: 'POST',
    credentials: opts.credentials,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${getGlobalAuthKey()}`
    },
    body: JSON.stringify({
      query: opts.query,
      variables: opts.args
    })
  });

  const json = await response.json();

  if (json.errors) {
    throw new Error(JSON.stringify(json.errors));
  }
  return json.data;
}
