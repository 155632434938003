// @ts-nocheck

//Types

type AnnotationSize = {
  width: number;
  height: number;
};

type Box = {
  left: number;
  top: number;
  width: number;
  height: number;

  annotation?: AnnotationSize;
};

type Input = {
  boxes: Box[];
};

type Point = {
  x: number;
  y: number;
};

type Arrow = {
  start: Point;
  end: Point;
};

type Annotation = {
  left: number;
  top: number;
  arrow?: Arrow;
};

type Output = {
  annotations: Annotation[];
};

//Algorithms

export function annotate(
  input: Input,
  mode: "mix" | "below" = "below",
  distance: number = 10,
  arrowLength: number = 1
): Output {
  switch (mode) {
    case "below":
      return annotateBelow(input, distance);
    case "mix":
      return annotateMix(input, distance, arrowLength);
  }
}

/**
 * For MIX, the algorithm used is a local best algorithm. We explore a wide array of possible solutions, and for each solution a score is determined.
 * Then we make only slight changes to the best solution, and when a better solution is found we reset the search around the newly found solution.
 * When no better solution is found, the algorithm is continued 2 more times, every time exploring even slighter changes to the best solution.
 *
 * The results can be altered by modifying THOROUGHNESS and DEPTH
 *
 * @param input
 * @param distance
 * @param arrowLength
 */
function annotateMix(
  input: Input,
  distance: number,
  arrowLength: number
): Output {
  type Rectangle = {
    top: number;
    left: number;
    width: number;
    height: number;
  };

  // A trial is an attempt of placing an annotation
  type Trial = {
    boxCenter: Point;
    angle: number;
    center: Point;
    annotation: Rectangle;
    leavePoint: Point;
    arrow: Arrow;
  };

  type Score = {
    spread: number;
    overlaps: number;
  };

  //For a rectangle of given width and height, returns the length of the segment which starts in the center of the rectangle, is directed at a given angle, and ends at the edge of the rectangle
  function interiorSegmentLength(
    width: number,
    height: number,
    angle: number
  ): number {
    return Math.min(
      Math.abs((width * 0.5) / Math.cos(angle)),
      Math.abs((height * 0.5) / Math.sin(angle))
    );
  }

  //Returns the point which is at a given distance(length) form a given point(from), in the direction given by angle
  function getDirectedPoint(from: Point, angle: number, length: number): Point {
    return {
      x: from.x + length * Math.cos(angle),
      y: from.y + length * Math.sin(angle)
    };
  }

  //Returns the area overlapping both rectangles
  function rectanglesOverlap(
    rectangle1: Rectangle,
    rectangle2: Rectangle
  ): number {
    return (
      Math.max(
        0,
        Math.min(
          rectangle1.left + rectangle1.width - rectangle2.left,
          rectangle2.left + rectangle2.width - rectangle1.left,
          rectangle1.width,
          rectangle2.width
        )
      ) *
      Math.max(
        0,
        Math.min(
          rectangle1.top + rectangle1.height - rectangle2.top,
          rectangle2.top + rectangle2.height - rectangle1.top,
          rectangle1.height,
          rectangle2.height
        )
      )
    );
  }

  //Returns true if a given segment (provided by starting point, angle and length) overlaps a horizontal or vertical segment, positioned at x|y = straightPosition, and limited by straightStart and straightEnd (straightStart < straightEnd)
  function segmentOverlapsStraightSegment(
    from: Point,
    angle: number,
    length: number,
    straightDirection: "horizontal" | "vertical",
    straightPosition: number,
    straightStart: number,
    straightEnd: number
  ): boolean {
    let distance: number;
    let reach: number;
    let hitPosition: number;

    if (straightDirection == "horizontal") {
      distance = straightPosition - from.y;
      reach = distance / Math.sin(angle);
      if (reach < 0 || reach >= length) {
        return false;
      }

      hitPosition = from.x + Math.cos(angle) * Math.abs(distance);
    } else {
      distance = straightPosition - from.x;
      reach = distance / Math.cos(angle);
      if (reach < 0 || reach >= length) {
        return false;
      }

      hitPosition = from.y + Math.sin(angle) * Math.abs(distance);
    }

    return hitPosition > straightStart && hitPosition < straightEnd;
  }

  //Returns true if given segment (provided by starting point, angle and length) overlaps given rectangle
  function segmentOverlapsRectangle(
    from: Point,
    angle: number,
    length: number,
    rectangle: Rectangle
  ): boolean {
    return (
      segmentOverlapsStraightSegment(
        from,
        angle,
        length,
        "horizontal",
        rectangle.top,
        rectangle.left,
        rectangle.left + rectangle.width
      ) ||
      segmentOverlapsStraightSegment(
        from,
        angle,
        length,
        "horizontal",
        rectangle.top + rectangle.height,
        rectangle.left,
        rectangle.left + rectangle.width
      ) ||
      segmentOverlapsStraightSegment(
        from,
        angle,
        length,
        "vertical",
        rectangle.left,
        rectangle.top,
        rectangle.top + rectangle.height
      ) ||
      segmentOverlapsStraightSegment(
        from,
        angle,
        length,
        "vertical",
        rectangle.left + rectangle.width,
        rectangle.top,
        rectangle.top + rectangle.height
      )
    );
  }

  function rotatePoint(point: Point, center: Point, angle: number): Point {
    const xO = point.x - center.x;
    const yO = point.y - center.y;
    return {
      x: xO * Math.cos(angle) - yO * Math.sin(angle) + center.x,
      y: xO * Math.sin(angle) + yO * Math.cos(angle) + center.y
    };
  }

  function segmentsOverlap(
    from1: Point,
    angle1: number,
    length1: number,
    from2: Point,
    angle2: number,
    length2: number
  ): boolean {
    return segmentOverlapsStraightSegment(
      rotatePoint(from1, from2, -angle2),
      angle1 - angle2,
      length1,
      "horizontal",
      from2.y,
      from2.x,
      from2.x + length2
    );
  }

  //Returns the distance between 2 points
  function pointDistance(point1: Point, point2: Point): number {
    return Math.sqrt(
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)
    );
  }

  function pointToRectangle(point: Point, rectangle: Rectangle): number {
    const center: Point = {
      x: rectangle.left + rectangle.width / 2,
      y: rectangle.top + rectangle.height / 2
    };

    const angle: number = Math.atan2(point.y - center.y, point.x - center.x);

    return (
      pointDistance(center, point) -
      interiorSegmentLength(rectangle.width, rectangle.height, angle)
    );
  }

  function normalizeAngle(angle: number): number {
    return ((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);
  }

  function angleBetweenDirections(
    from1: Point,
    angle1: number,
    from2: Point,
    angle2: number
  ): number {
    if (from1.x != from2.x || from1.y != from2.y) {
      const angleBetweenCenters: number = Math.atan2(
        from2.y - from1.y,
        from2.x - from1.x
      );

      from2 = rotatePoint(from2, from1, -angleBetweenCenters);
      angle1 -= angleBetweenCenters;
      angle2 -= angleBetweenCenters;
    }

    return Math.min(
      normalizeAngle(angle1) + normalizeAngle(Math.PI - angle2),
      normalizeAngle(2 * Math.PI - angle1) + normalizeAngle(Math.PI + angle2)
    );
  }

  const THOROUGHNESS: number = 8; // How many attempts to make around each angle, for a given depth and box
  const MAX_DEPTH: number = 3; // How many times to make an even deeper, and more localized, global set of attempts
  const ITERATIONS: number = 5; // How many times to run the algorithm, starting at random angles for each annotation. Each single iteration should provide a satisfactory result

  //Only the boxes which have annotations
  let annotatedBoxes: number[] = input.boxes
    .map(function(_item: Box, index: number, _array: Box[]): number {
      return index;
    })
    .filter(function(item: number, _index: number, _array: number[]): boolean {
      return input.boxes[item].annotation != null;
    });

  let globalBestSolution: Trial[];
  let globalBestScore: Score;
  let globalBestBaseConflict: number;

  let iteration: number;

  for (iteration = 0; iteration < ITERATIONS; iteration++) {
    let bestSolution: Trial[];
    let bestScore: Score;
    let solution: number[];
    let totalBaseConflictOfSolution: number;
    let totalBaseConflict: number;
    let deviation: { index: number; direction: boolean };

    let depth: number = 0;
    let trials: Trial[][];
    let distances: { [key: string]: number };
    let overlaps: { [key: string]: number };
    let offshore: { [key: string]: number };

    let basePositions: number[] = [];
    let freedom: number = Math.PI;
    let i: number;
    let j: number;

    //We start at a random angle for each annotation
    for (i = 0; i < annotatedBoxes.length; i++) {
      //const foo = Math.random();
      const foo = 0.5;
      basePositions.push(foo * 2 * Math.PI);
    }

    //We run the algorithm until the depth reached is too high
    while (depth < MAX_DEPTH) {
      //If no trials (possible angles for each annotation) have been set, it means we are just starting this depth. Calculate all trials and distances between trials
      if (!trials) {
        trials = [];
        solution = [];
        deviation = null;

        totalBaseConflict = 0;

        //For each annotated box, compile a set of trials. There are a maximum total of THOROUGHNESS trials for each box and any depth
        //which go around the basePosition (base angle) for that box, and go left and right to a maximum of 'freedom' in both directions
        //Freedom is set at PI/2 when depth is 0, meaning that we try angles all around the box
        //For a given box, only the trials which overlap the least amount of boxes are kept
        for (i = 0; i < annotatedBoxes.length; i++) {
          let box: Box = input.boxes[annotatedBoxes[i]];
          let boxCenter: Point = {
            x: box.left + box.width / 2,
            y: box.top + box.height / 2
          };
          let bestConflict: number;

          for (j = 0; j < THOROUGHNESS; j++) {
            let angle: number =
              basePositions[i] + freedom * ((2 * j + 1) / THOROUGHNESS - 1);

            let boxInteriorLength: number = interiorSegmentLength(
              box.width,
              box.height,
              angle
            );
            let annotationInteriorLength: number = interiorSegmentLength(
              box.annotation.width,
              box.annotation.height,
              angle
            );
            let distanceBetweenCenters: number =
              boxInteriorLength + annotationInteriorLength + distance;

            let annotationCenter: Point = getDirectedPoint(
              boxCenter,
              angle,
              distanceBetweenCenters
            );
            let annotation: Rectangle = {
              left: annotationCenter.x - box.annotation.width / 2,
              top: annotationCenter.y - box.annotation.height / 2,
              width: box.annotation.width,
              height: box.annotation.height
            };

            let arrow: Arrow = {
              start: getDirectedPoint(
                boxCenter,
                angle,
                boxInteriorLength + (distance * (1 + arrowLength)) / 2
              ),
              end: getDirectedPoint(
                boxCenter,
                angle,
                boxInteriorLength + (distance * (1 - arrowLength)) / 2
              )
            };

            let boxLeavePoint: Point = getDirectedPoint(
              boxCenter,
              angle,
              boxInteriorLength
            );

            //Count how many boxes this trial overlaps
            let conflict: number = 0;
            let k: number;

            for (k = 0; k < input.boxes.length; k++) {
              let box2: Box = input.boxes[k];

              conflict +=
                rectanglesOverlap(annotation, box2) /
                (annotation.width * annotation.height);

              if (
                box != box2 &&
                segmentOverlapsRectangle(boxLeavePoint, angle, distance, box2)
              ) {
                conflict++;
              }
            }

            //For a given box, only the trials which overlap the least amount of boxes are kept
            if (!(bestConflict < conflict)) {
              if (!(bestConflict <= conflict)) {
                bestConflict = conflict;
                trials[i] = [];
              }
              trials[i].push({
                angle: angle,
                center: annotationCenter,
                annotation: annotation,
                arrow: arrow,
                boxCenter: boxCenter,
                leavePoint: boxLeavePoint
              });
            }
          }

          solution.push(0);

          totalBaseConflict += bestConflict;
        }

        // If we have a total base conflict (annotations overlapping boxes) larger than the previously found solution, this is enough not to proceed.
        // In this case, move to the next depth
        // totalBaseConflictOfSolution should ideally be zero for any iteration
        if (
          !isNaN(totalBaseConflictOfSolution) &&
          totalBaseConflictOfSolution < totalBaseConflict
        ) {
          trials = null;

          basePositions = bestSolution.map(function(
            item: Trial,
            index: number,
            array: Trial[]
          ): number {
            return item.angle;
          });
          freedom /= THOROUGHNESS;

          depth++;

          continue;
        }

        // Now save the distances between each of a box's trials and each of the other boxes' trials
        // Save overlaps as well, as well as shortest distances to a box
        distances = {};
        overlaps = {};
        offshore = {};

        let i2: number;
        let j2: number;

        let key: string;
        let reverseKey: string;

        for (i = 0; i < annotatedBoxes.length - 1; i++) {
          for (j = 0; j < trials[i].length; j++) {
            for (i2 = i + 1; i2 < annotatedBoxes.length; i2++) {
              for (j2 = 0; j2 < trials[i2].length; j2++) {
                key = [i, j, i2, j2].join("|");
                reverseKey = [i2, j2, i, j].join("|");

                distances[key] = distances[reverseKey] = pointDistance(
                  trials[i][j].center,
                  trials[i2][j2].center
                );

                if (
                  segmentsOverlap(
                    trials[i][j].leavePoint,
                    trials[i][j].angle,
                    distance,
                    trials[i2][j2].leavePoint,
                    trials[i2][j2].angle,
                    distance
                  ) ||
                  segmentOverlapsRectangle(
                    trials[i][j].leavePoint,
                    trials[i][j].angle,
                    distance,
                    trials[i2][j2].annotation
                  ) ||
                  segmentOverlapsRectangle(
                    trials[i2][j2].leavePoint,
                    trials[i2][j2].angle,
                    distance,
                    trials[i][j].annotation
                  ) ||
                  rectanglesOverlap(
                    trials[i][j].annotation,
                    trials[i2][j2].annotation
                  )
                ) {
                  overlaps[key] =
                    1 -
                    angleBetweenDirections(
                      trials[i][j].leavePoint,
                      trials[i][j].angle,
                      trials[i2][j2].leavePoint,
                      trials[i2][j2].angle
                    ) /
                      (2 * Math.PI);
                } else {
                  overlaps[key] = 0;
                }

                overlaps[reverseKey] = overlaps[key];
              }
            }
          }
        }

        //Look for the closest box to each annotation
        for (i = 0; i < annotatedBoxes.length; i++) {
          for (j = 0; j < trials[i].length; j++) {
            let closestBoxDistance: number;

            for (i2 = 0; i2 < input.boxes.length; i2++) {
              const distance = pointToRectangle(
                trials[i][j].center,
                input.boxes[i2]
              );

              if (isNaN(closestBoxDistance) || closestBoxDistance > distance) {
                closestBoxDistance = distance;
              }
            }

            const key: string = [i, j].join("|");
            offshore[key] = closestBoxDistance;
          }
        }
      }

      // At this point we have a possible solution selected. Calculate its score
      let score: Score = { spread: 0, overlaps: 0 };

      for (i = 0; i < solution.length; i++) {
        let distanceToClosest: number;

        for (j = 0; j < solution.length; j++) {
          if (i != j) {
            let key: string = [i, solution[i], j, solution[j]].join("|");
            let distanceTo: number = distances[key];

            if (isNaN(distanceToClosest) || distanceTo < distanceToClosest) {
              distanceToClosest = distanceTo;
            }

            if (j < i) {
              score.overlaps += overlaps[key];
            }
          }
        }

        score.spread +=
          distanceToClosest * 0.6 + offshore[[i, solution[i]].join("|")] * 0.4;
      }

      //If the score is bigger, we save the score and this solution as the best solution, and we reset the deviation so that we continue searching based on this solution
      if (
        !bestSolution ||
        score.overlaps < bestScore.overlaps ||
        (score.overlaps == bestScore.overlaps &&
          score.spread > bestScore.spread)
      ) {
        bestScore = score;
        bestSolution = trials.map(function(
          item: Trial[],
          index: number,
          array: Trial[][]
        ): Trial {
          return item[solution[index]];
        });
        totalBaseConflictOfSolution = totalBaseConflict;
        deviation = null;
      }

      //Change the deviation so as to try the next solution; all solutions (sets of trials) attempted are just slight variations of the best solution previously found

      let newContender: boolean;

      do {
        if (deviation) {
          if (deviation.direction) {
            solution[deviation.index] =
              solution[deviation.index] == 0
                ? trials[deviation.index].length - 1
                : solution[deviation.index] - 1;

            if (deviation.index < solution.length - 1) {
              deviation.index++;
              deviation.direction = false;
            } else {
              deviation = null;
            }
          } else {
            deviation.direction = true;
          }
        } else {
          deviation = { index: 0, direction: false };
        }

        if (
          deviation &&
          trials[deviation.index].length > 1 &&
          (trials[deviation.index].length > 2 || !deviation.direction)
        ) {
          newContender = true;
        }
      } while (deviation && !newContender);

      if (deviation) {
        solution[deviation.index] = deviation.direction
          ? (solution[deviation.index] + 2) % trials[deviation.index].length
          : solution[deviation.index] == 0
            ? trials[deviation.index].length - 1
            : solution[deviation.index] - 1;
      } else {
        //When we finished all deviations for our best solution, it means that we exhaiusted the current depth. Increase depth, reset deviation and reset the base angles
        //so that we can calculate new trials around the new base angles. The trials will be chosen at equal increments inside [BASE - FREEDOM, BASE + FREEDOM].
        //FREEDOM gets divided by THOROUGHNESS on each new depth, so that we explore a smaller and smaller area around our current best solution

        trials = null;

        basePositions = bestSolution.map(function(
          item: Trial,
          index: number,
          array: Trial[]
        ): number {
          return item.angle;
        });
        freedom /= THOROUGHNESS;

        depth++;
      }
    }

    // Now compare this iteration's solution, with the best solution from previous iterations
    let betterLocal: boolean;
    if (iteration == 0) {
      betterLocal = true;
    } else {
      if (totalBaseConflictOfSolution < globalBestBaseConflict) {
        betterLocal = true;
      } else {
        if (totalBaseConflictOfSolution == globalBestBaseConflict) {
          if (bestScore.overlaps < globalBestScore.overlaps) {
            betterLocal = true;
          } else {
            if (bestScore.overlaps == globalBestScore.overlaps) {
              if (bestScore.spread > globalBestScore.spread) {
                betterLocal = true;
              }
            }
          }
        }
      }
    }

    if (betterLocal) {
      globalBestBaseConflict = totalBaseConflictOfSolution;
      globalBestScore = bestScore;
      globalBestSolution = bestSolution;
    }
  }

  let output: Output = { annotations: [] };

  let i: number;
  for (i = 0; i < annotatedBoxes.length; i++) {
    output.annotations[annotatedBoxes[i]] = {
      left: globalBestSolution[i].annotation.left,
      top: globalBestSolution[i].annotation.top,
      arrow: globalBestSolution[i].arrow
    };
  }

  return output;
}

/**
 * For BELOW, we try to place each annotation centered directly below the box, at the given distance from the bottom edge of all boxes.
 * Annotations which overlap on the X direction are placed into 'buckets'. A bucket is a connected graph of annotations which overlap on the X direction.
 * Annotations which don't overlap any other annotation are alone in their respective buckets.
 * We solve each bucket by stacking annotations vertically (keeping their desired x positions) using a backtracking algorithm.
 * The score of each solution is given by the total height of the stacked annotations; the lower the height, the better the score.
 * If the height of 2 solutions is the same, then the best solution is the one which has the least amount of conflicts.
 * A conflict happens when 2 annotations A1 and A2 were originally overlapping, A1 is placed below A2 in the solution, and box B2 rests below B1
 *
 * @param input
 * @param distance
 *
 *
 */
function annotateBelow(input: Input, distance: number): Output {
  type Bucket = number[];

  let i: number;
  let j: number;
  let startY: number = NaN;
  let box: Box;
  let box2: Box;
  let bottom: number;
  let areas: { left: number; right: number }[] = [];
  let buckets: Bucket[] = [];
  let boxBucket: Bucket[] = [];
  let conflicts: number[][] = [];

  //First, boxes are placed into buckets, and the eventual conflicts are calculated between each pair of boxes
  for (i = 0; i < input.boxes.length; i++) {
    box = input.boxes[i];

    bottom = box.top + box.height + distance;
    startY = isNaN(startY) ? bottom : Math.max(startY, bottom);

    conflicts.push([]);

    if (box.annotation) {
      buckets.push([i]);
      boxBucket[i] = buckets[buckets.length - 1];

      areas.push({
        left: box.left + box.width / 2 - box.annotation.width / 2,
        right: box.left + box.width / 2 + box.annotation.width / 2
      });

      //We iterate through all boxes which we have already iterated through, and we check the relation between box i and box j (j < i)
      for (j = 0; j < i; j++) {
        box2 = input.boxes[j];

        //Check if annotations for i and j are overlapping
        if (
          box2.annotation &&
          areas[i].left < areas[j].right &&
          areas[i].right > areas[j].left
        ) {
          //Check if there is a possible conflict between boxes i and j. ANnotations may overlap, but boxes may not be in conflict (e.g. one box next to the other)
          if (
            box2.left < box.left + box.width &&
            box2.left + box2.width > box.left
          ) {
            if (box2.top < box.top) {
              conflicts[i][j] = 1;
              conflicts[j][i] = -1;
            } else {
              conflicts[i][j] = -1;
              conflicts[j][i] = 1;
            }
          } else {
            conflicts[i][j] = 0;
            conflicts[j][i] = 0;
          }

          //Annotations i and j overlap, so we join their respective buckets, if they are different.
          if (boxBucket[i] != boxBucket[j]) {
            boxBucket[j].splice(
              conflicts[i][j] == 1 ? boxBucket[j].length : 0,
              0,
              ...buckets.splice(buckets.indexOf(boxBucket[i]), 1)[0]
            );
            boxBucket[i] = boxBucket[j];
          }
        }
      }
    } else {
      areas.push(null);
    }
  }

  let output: Output = { annotations: [] };

  //We solve each bucket. For each bucket we apply a backtracking algorithm to stack annotations. We want to keep the stack at the lowest possible height, and after that to keep box conflicts at a minimum.
  for (i = 0; i < buckets.length; i++) {
    //There's a minimum gap between stacked annotations
    const GAP: number = 3;

    let bucket: Bucket = buckets[i];
    let solution: {
      index: number;
      boxIndex?: number;
      placementIndex?: number;
      totalHeight?: number;
      conflicts?: number;
      start?: number;
    }[] = [];
    let step: number;
    let chosenIndex: number;
    let box: Box;
    let placements: { boxIndex: number; start: number }[] = [];
    let bestHeight: number;
    let bestConflicts: number;
    let bestSolution: { boxIndex: number; start: number }[];

    let k: number;

    //Let's order the boxes in the bucket by preferred position (top first)
    for (j = 1; j < bucket.length; j++) {
      k = j - 1;
      while (k >= 0 && conflicts[bucket[k]][bucket[i]] == 1) {
        k--;
      }

      if (k + 1 < j) {
        bucket.splice(k + 1, 0, ...bucket.splice(j, 1));
      }
    }

    //We want to calculate the best solution stack height
    //This is equal to the maximum sum of heights of a group of fully conflicted annotations (conflicts[i][j] != undefined, for any i and j)
    let fullGraphs: number[][];
    let fullGraph: number[] = [];
    let l: number;
    for (j = 0; j < bucket.length; j++) {
      fullGraph.push(bucket[j]);
    }
    fullGraphs = [fullGraph];
    for (j = 0; j < bucket.length - 1; j++) {
      for (k = j + 1; k < bucket.length; k++) {
        if (isNaN(conflicts[bucket[j]][bucket[k]])) {
          let currentCount: number = fullGraphs.length;
          for (l = 0; l < currentCount; l++) {
            let jIndex: number = fullGraphs[l].indexOf(bucket[j]);
            let kIndex: number = fullGraphs[l].indexOf(bucket[k]);

            if (jIndex >= 0 && kIndex >= 0) {
              fullGraph = fullGraphs[l].concat();
              fullGraphs[l].splice(jIndex, 1);
              fullGraph.splice(kIndex, 1);
              fullGraphs.push(fullGraph);
            }
          }
        }
      }
    }

    bestHeight = 0;
    for (j = 0; j < fullGraphs.length; j++) {
      let graphHeight: number = (fullGraphs[j].length - 1) * GAP;
      for (k = 0; k < fullGraphs[j].length; k++) {
        graphHeight += input.boxes[fullGraphs[j][k]].annotation.height;
      }

      if (graphHeight > bestHeight) {
        bestHeight = graphHeight;
      }
    }

    //We start the backtracking at step 0
    //Each step will choose an annotation from the bucket and place it on the stack

    step = 0;
    while (step >= 0) {
      //If we had already chosen an annotation for this step, we remove it and choose the next one. Other wise, we choose the first annotation
      if (solution[step]) {
        bucket.splice(solution[step].index, 0, solution[step].boxIndex);
        if (!isNaN(solution[step].placementIndex)) {
          placements.splice(solution[step].placementIndex, 1);
        }

        //Check if another annotation is available to choose
        if (solution[step].index < bucket.length - 1) {
          solution[step].index++;
        } else {
          //When we went through all annotations available for this step, we remove the solution for this step and go back to the previous step

          delete solution[step];
          step--;

          continue;
        }
      } else {
        solution[step] = { index: 0 };
      }

      chosenIndex = bucket.splice(solution[step].index, 1)[0];
      box = input.boxes[chosenIndex];
      solution[step].boxIndex = chosenIndex;

      let tooBig: boolean = false;
      let equallyBig: boolean = false;
      let tooConflicted: boolean = false;
      let equallyConflicted: boolean = false;
      let placementIndex: number;
      let heightUnchanged: boolean = false;

      //We only care of a box if it has an annotation
      //Here we place the chosen annotation as high as possible, with regard to the annotations which were previously placed.
      //The x of an annotation is always fixed: the center of its corresponding box
      if (box.annotation) {
        let placementStart: number = 0;
        let nextPlaced: number = 0;
        let addedConflicts: number = 0;

        placementIndex = 0;

        while (
          nextPlaced < placements.length &&
          placements[nextPlaced].start <
            placementStart + box.annotation.height + GAP
        ) {
          let conflict: number =
            conflicts[chosenIndex][placements[nextPlaced].boxIndex];

          if (!isNaN(conflict)) {
            placementStart = Math.max(
              placementStart,
              placements[nextPlaced].start +
                input.boxes[placements[nextPlaced].boxIndex].annotation.height +
                GAP
            );
            placementIndex = nextPlaced + 1;

            //We count how many conflicts are not solved by this placement, then add to the total found so far
            if (conflict == -1) {
              addedConflicts++;
            }
          }

          nextPlaced++;
        }

        placements.splice(placementIndex, 0, {
          boxIndex: chosenIndex,
          start: placementStart
        });

        solution[step].placementIndex = placementIndex;
        solution[step].start = placementStart;
        solution[step].conflicts =
          addedConflicts + (step == 0 ? 0 : solution[step - 1].conflicts);

        let endHeight: number = placementStart + box.annotation.height;

        if (!step || endHeight > solution[step - 1].totalHeight) {
          solution[step].totalHeight = endHeight;
        } else {
          heightUnchanged = true;
        }
      } else {
        heightUnchanged = true;

        solution[step].conflicts = step ? solution[step - 1].conflicts : 0;
        solution[step].start = 0;
      }

      if (heightUnchanged) {
        solution[step].totalHeight = step ? solution[step - 1].totalHeight : 0;
      }

      //We check if the new stack height is equal to, or larger than, the stack height of the best solution height
      if (solution[step].totalHeight > bestHeight) {
        tooBig = true;
      } else {
        if (solution[step].totalHeight == bestHeight) {
          equallyBig = true;
        }
      }

      //We check if the new stack has more conflicts than, or just as many as, the best solution found so far
      if (!isNaN(bestConflicts)) {
        if (solution[step].conflicts > bestConflicts) {
          tooConflicted = true;
        } else {
          if (solution[step].conflicts == bestConflicts) {
            equallyConflicted = true;
          }
        }
      }

      //We only validate this stack if we haven't already surpassed the best stack height,
      //and if the current stack height is lower than the best stack height OR there are less conflicts so far
      //In all other cases we skip
      if (
        !tooBig &&
        ((!equallyBig && !bestSolution) ||
          (!equallyConflicted && !tooConflicted))
      ) {
        //Stack is so far valid, if we have more annotations to place then we move to the next step
        if (bucket.length) {
          step++;
        } else {
          bestConflicts = solution[step].conflicts;

          bestSolution = [];

          for (j = 0; j < solution.length; j++) {
            bestSolution[j] = {
              boxIndex: solution[j].boxIndex,
              start: solution[j].start
            };
          }
        }
      }
    }

    for (j = 0; j < bestSolution.length; j++) {
      output.annotations[bestSolution[j].boxIndex] = {
        left: areas[bestSolution[j].boxIndex].left,
        top: startY + bestSolution[j].start
      };
    }
  }

  return output;
}
