import React from "react";
import {PickOrCreateWorldField} from "./PickOrCreateWordField";


export function CompoundVerbVectorField(props: {}) {
  return <PickOrCreateWorldField
    name={"vector"}
    label="Verb Component"
    helperText={"For راست گفتن, this is گفتن."}
    config={{
      allowedWordTypes: ['verb'],
      disallowCompoundVerb: true
    }}
  />
}