import {StringQuery, WordId, WordIdQuery} from "../../annotations";
import {StructureNode} from "../../models/Node";

// The popup can browse to:
// - WordIdQuery: A particular word id, which is like a dictionary entry.
// - StructureNode: A particular text, as it appeared in a real text, with a known grammar shape and base word.
// - StringQuery: A particular text, as it appeared in a real text, with an known grammar, or a text that
//   is known to be a lemma, that is, the base form of the word.
export type Page = WordIdQuery | StringQuery | StructureNode;

export function pageIsWordId(page: Page): page is WordId {
  return !("type" in page);
}

export function pageIsNode(page: Page): page is StructureNode {
  return ("type" in page);
}