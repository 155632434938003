import React from 'react'
import {Svg, Path} from 'react-native-svg';

const DictionaryIcon = React.memo((props: any) => {
    return <Svg width="16" height="16" viewBox="0 0 64 64" {...props}>
        <Path
          d="M16 53h35c1.105 0 2-.895 2-2V7c0-1.105-.895-2-2-2H16c-1.105 0-2 .895-2 2v44c0 1.105.895 2 2 2z"
          fill="#faefde"
        />
        <Path d="M19 53H9V8a3 3 0 0 1 3-3h7z" fill="#cda1a7"/>
        <Path
          d="M49 59H11c-1.105 0-2-.895-2-2v-2c0-1.105.895-2 2-2h38c1.105 0 2 .895 2 2v2c0 1.105-.895 2-2 2z"
          fill="#efd8be"
        />
        <Path
          d="M19 8a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0V9a1 1 0 0 0-1-1z"
          fill="#8d6c9f"
        />
        <Path
          d="M51 4H12C9.793 4 8 5.793 8 8v48c0 2.207 1.793 4 4 4h37c1.652 0 3-1.348 3-3v-3.184c1.16-.414 2-1.515 2-2.816V7c0-1.652-1.348-3-3-3zm-1 53c0 .55-.45 1-1 1H12c-1.102 0-2-.898-2-2 0-1.246 1.39-2 2-2h38zm2-6c0 .55-.45 1-1 1H20V17a1 1 0 1 0-2 0v35h-6c-.617 0-1.34.223-2 .633V8c0-1.102.898-2 2-2h39c.55 0 1 .45 1 1z"
          fill="#8d6c9f"
        />
        <Path
          d="M15 8h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 13h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 18h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 23h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 28h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 33h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 38h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 43h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zM15 48h-2a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2z"
          fill="#8d6c9f"
        />
        <Path
          d="M48 36c-1.102 0-2-.898-2-2v-6.91a.99.99 0 1 0-1.871-.465A3.965 3.965 0 0 0 42 26h-1c-2.207 0-4 1.793-4 4v4c0 2.207 1.793 4 4 4h1c1.2 0 2.266-.543 3-1.383.734.84 1.8 1.383 3 1.383.555 0 1-.445 1-1 0-.555-.445-1-1-1zm-4-2c0 1.102-.898 2-2 2h-1c-1.102 0-2-.898-2-2v-4c0-1.102.898-2 2-2h1c1.102 0 2 .898 2 2zM30.336 20.648a1 1 0 0 0-1.895 0l-5.398 16a.998.998 0 0 0 .945 1.32 1 1 0 0 0 .95-.679l1.804-5.348c.051.008.094.028.149.028h5c.05 0 .093-.02.144-.028l1.805 5.348a1 1 0 1 0 1.894-.64zm-2.93 9.32l1.98-5.87 1.985 5.87z"
          fill="#ed7899"
        />
    </Svg>
});

export default DictionaryIcon
