import {CaptionTrackRoot, LineGroup} from "./types";


/**
 * We variously have to refer to lines by index, by their Slate.js key, or we have the line itself.
 *
 * I found it to be helpful to write our API functions that reference lines in a generic way, and let them
 * use these helpers to convert to the desired representation.
 */
export type LineRef = LineGroup|number;



/**
 * Normalize the LineIndex to a node.
 */
export function getLineIndexFromLineRef(captions: CaptionTrackRoot, index: LineRef): number {
  if (typeof index == 'object') {
    return captions.indexOf(index);
  }

  return index;
}
