import {getLineIndexFromLineRef, LineRef} from "../../models/Captions";
import {Node, Editor, NodeEntry, Path} from "slate";
import { ReactEditor } from "slate-react";
import {CaptionTrackRoot} from "../../models/Captions/types";


// slate.js before 0.50 had useful helpers such as getClosestInline etc. which I cannot find anymore. Bring them
// back in part.
export function findClosestParent<T extends Node>(editor: Editor, path: Path, f: (e: T) => boolean): NodeEntry|null {
  for (const entry of Node.levels(editor, path, {reverse: true})) {
    if (f(entry[0] as T)) {
      return entry;
    }
  }
  return null;
}



export function scrollToLine(editor: ReactEditor, line: LineRef, opts: {
  inCenter?: false
} = {}) {
  const lineIdx = getLineIndexFromLineRef(editor.children as CaptionTrackRoot, line);
  const lineNode = (editor.children as CaptionTrackRoot)[lineIdx];

  const domNode = ReactEditor.toDOMNode(editor, lineNode);

  // Scrolling into view is difficult.
  // We might consider replacing this with scrollTo and custom math
  // to do this properly.
  //
  // Ideally, we'd like to only scroll if necessary.
  // But scrollIntoViewIfNeeded()
  //
  // Chrome and Safari support this:
  // https://caniuse.com/#feat=scrollintoviewifneeded
  // But does not support smooth scrolling :(
  if (!opts.inCenter &&
    // @ts-ignore
    domNode.scrollIntoViewIfNeeded) {
    // false = nearest edge, true = center
    // @ts-ignore
    domNode.scrollIntoViewIfNeeded(opts.inCenter);
  }

  else {
    // Might need a polyfill:
    // http://iamdustan.com/smoothscroll/
    // The reason we use "center" here instead of "nearest" is that
    // without a "only if needed" logic it would lead to a line
    // being perfectly in the middle to scroll to a worse place
    // at the edge of the container.
    domNode.scrollIntoView({
      behavior: 'smooth',
      block: opts.inCenter ? 'center' : 'nearest',
      inline: opts.inCenter ? 'center' : 'nearest',
    });
  }
}


// From https://github.com/ianstormtaylor/slate/blob/1923888af1b07b6c21ea20b0a9f054a5cbb27654/packages/slate-react/src/utils/scroll-to-selection.js
const OVERFLOWS = ['auto', 'overlay', 'scroll'];
function findScrollContainer(el: any, window: any) {
  let parent = el.parentNode
  let scroller

  while (!scroller) {
    if (!parent.parentNode) break

    const style = window.getComputedStyle(parent)
    const { overflowY } = style

    if (OVERFLOWS.includes(overflowY)) {
      scroller = parent
      break
    }

    parent = parent.parentNode
  }

  // COMPAT: Because Chrome does not allow doucment.body.scrollTop, we're
  // assuming that window.scrollTo() should be used if the scrollable element
  // turns out to be document.body or document.documentElement. This will work
  // unless body is intentionally set to scrollable by restricting its height
  // (e.g. height: 100vh).
  if (!scroller) {
    return window.document.body
  }

  return scroller
}