import {FieldProps} from "formik";
import {SelectOption, useUIControls} from "../../../../../../components/FormUISwitch";
import {useClient} from "multi-apollo";
import React, {useCallback} from "react";
import {gql} from "@apollo/client";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";


/**
 * Let the user select a word.
 */
export function PickWordInput(props: {
  label: string,
  helperText?: string,

  allowedWordTypes?: string[],
  disallowedWordTypes?: string[],
  queryOptions?: any
} & FieldProps) {
  const UI = useUIControls();

  const apolloClient = useClient('words');

  const runSearch = useCallback(async (value: string) => {
    const words = await apolloClient.query({
      query: gql`
        query PickWordInput($search: String!, $onlyTypes: [WordType], $excludeTypes: [WordType], $options: [String]) {
          words(search: $search, lang: "fa", excludeTypes: $excludeTypes, onlyTypes: $onlyTypes, options: $options) {
            edges {
              node {
                id,
                base,
                lang,
                type,
                description,
                baseTransliteration,
                isReviewed
              }
            }
          }
        }
      `,
      variables: {
        search: value,
        onlyTypes: props.allowedWordTypes,
        excludeTypes: props.disallowedWordTypes,
        options: props.queryOptions
      }
    });

    return words.data.words.edges.map((edge: any) => edge.node).map((word: any) => {
      return {
        label: word.base,
        value: word.id,
        extra: {
          word: word
        }
      }
    });
  }, [props.queryOptions, props.allowedWordTypes, apolloClient]);

  const getItem = useCallback(async (itemId) => {
    // This indicates no item selected.
    if (itemId === true) {
      return {label: ""};
    }

    if (!itemId) {
      return {label: ""};
    }

    const word = await apolloClient.query({
      query: gql`
        query GetWordItem($id: Int!) {
          word(id: $id) {            
            id,
            base
          }
        }
      `,
      fetchPolicy: 'cache-first',
      variables: {
        id: itemId
      }
    });

    return {
      label: word.data.word.base
    }

  }, [apolloClient]);

  const renderSelectOption = useCallback((option: SelectOption<any>) => {
    const word = option?.extra?.word;
    return <div style={{display:  'flex', alignItems: 'center', padding: '.2em'}}>
      <div>
        {word.isReviewed ? <CheckIcon style={{marginRight: "4px"}} /> : null}
        <span>
          <span style={{color: 'gray'}}>{word.type}</span> {word.base} <span style={{color: 'gray'}}>{romanizeSounds(JSON.parse(word.baseTransliteration))}</span>
          {word.description ? <span>
            <small style={{paddingLeft: '5px'}}>({word.description})</small>
          </span> : null}
        </span>
      </div>
    </div>
  }, []);

  return <UI.RemoteSelect
    description={props.helperText}
    {...props}
    searchFunc={runSearch}
    getItem={getItem}
    renderSelectOption={renderSelectOption}
  />;
}


export const  CheckIcon = (props: any) => (
  <svg width={"1em"} height={"1em"} viewBox="0 0 512 512" {...props}>
    <path
      fill="#32BEA6"
      d="M504.1 256C504.1 119 393 7.9 256 7.9S7.9 119 7.9 256 119 504.1 256 504.1 504.1 393 504.1 256z"
    />
    <path
      fill="#FFF"
      d="M392.6 172.9c-5.8-15.1-17.7-12.7-30.6-10.1-7.7 1.6-42 11.6-96.1 68.8-22.5 23.7-37.3 42.6-47.1 57-6-7.3-12.8-15.2-20-22.3-22.1-22.1-46.8-37.3-47.8-37.9-10.3-6.3-23.8-3.1-30.2 7.3-6.3 10.3-3.1 23.8 7.2 30.2.2.1 21.4 13.2 39.6 31.5 18.6 18.6 35.5 43.8 35.7 44.1 4.1 6.2 11 9.8 18.3 9.8 1.2 0 2.5-.1 3.8-.3 8.6-1.5 15.4-7.9 17.5-16.3.1-.2 8.8-24.3 54.7-72.7 37-39.1 61.7-51.5 70.3-54.9h.3s.3-.1.8-.4c1.5-.6 2.3-.8 2.3-.8-.4.1-.6.1-.6.1v-.1c4-1.7 11.4-4.9 11.5-5 11.1-4.8 14.8-16.8 10.4-28z"
    />
  </svg>
)