import * as React from 'react';
import {RecordingItem, RecordingItemGrid} from "./RecordingItem";
import {useAudioContext} from "../../components/Audio/Visualizer";
import {useMemo, useCallback} from 'react';
import {useMediaRecorder} from "../../components/Audio/Recorder";
import {UploadManager} from "./useUploader";
import {useKey, useMeasure} from 'react-use';
import {LoadMoreFunc, useForeverScroll} from "./useForeverScroll";
import {NextWordsInSession_recordingSession_items_existingRecordings} from "../../types/NextWordsInSession";
import {usePlayAllFromItem} from "./usePlayer";
import loglevel from 'loglevel';


const log = loglevel.getLogger("RecordingWorkflow/ListControl");
log.setLevel("DEBUG");


export type RecordingItemDefinition = {
  isComplete: boolean,
  recordings?: Blob[],
  existingRecordings: NextWordsInSession_recordingSession_items_existingRecordings[],
  word: {
    id: number,
    type: string,
    base: string,
    baseTransliteration: string,
    description: string
  },
}


export type RecordingPropsBundle = {
  canRecord: boolean,
  record: () => void,
  stop: () => void,
  getBlob: () => Blob|null
  isRecording: boolean,
  analyzer: AnalyserNode,
}


export function RecordingWorkflowControl(props: {
  loadItems: LoadMoreFunc<RecordingItemDefinition>,
  uploader: UploadManager,
  autoPlay?: boolean,
  style?: any
}) {
  const [ref, {width, height}] = useMeasure();
  const numItems = Math.round(height / 180);
  const uploader = props.uploader;
  const {currentIndex,  firstIndex, setCurrentIndex, items} = useForeverScroll<RecordingItemDefinition>({
    numVisible: numItems,
    loadNext: props.loadItems
  });

  const audioContext = useAudioContext();
  const analyzer = useMemo(() => { return audioContext.createAnalyser(); }, [audioContext]);

  const {isRecording, controller, getCurrentBlob, clearCurrentBlob} = useMediaRecorder({
    disabled: false,
    onSetup: async (stream) => {
      // They are suspended if there was no user-interaction ont he page yet.
      if (audioContext.state === 'suspended') {
        await audioContext.resume();
      }
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyzer);
    }
  });

  const playAllFromItem = usePlayAllFromItem();

  // useKey((event) => event.keyCode === 32, (e) => {
  //   e.preventDefault();
  //   if (!props.allowPlay) { return; }
  //   if (!isPlaying) { player!.play(); }
  //   else if (isPlaying) { player!.pause(); }
  // }, {}, [player, isPlaying, props.allowPlay]);

  const goToIndex = React.useCallback(async (idx: number, opts?: {continueRecording?: boolean}) => {
    // If we are currently recording, we stop the recording, upload the file, and auto-start again.
    let wasRecording = false;
    if (isRecording) {
      wasRecording = true;
      await controller.stop();   // Wait for the stop to finish, so we know we have data.
    }

    // If there was a recording done, start uploading it
    const data = getCurrentBlob();
    if (data) {
      log.info("Size of recorded blob: ", data.size);
      const item = items[currentIndex-firstIndex]
      uploader.uploadFile(item.word.id, data);
      if (!item.recordings) { item.recordings = [] }
      item.recordings.push(data);
      clearCurrentBlob();
    }

    // Move to the new item
    setCurrentIndex(idx);

    // Possible restart recording.
    if (wasRecording && opts?.continueRecording) {
      controller.start();
    }

    // If auto-play is one, play any recordings done for this file.
    else if (props.autoPlay) {
      const item = items[idx-firstIndex];
      playAllFromItem(item);
    }
  }, [setCurrentIndex, currentIndex, getCurrentBlob, uploader.uploadFile, isRecording, controller, firstIndex, props.autoPlay]);

  useKey('ArrowDown', () => {
    goToIndex(currentIndex + 1, {continueRecording: true});
  }, {}, [goToIndex, currentIndex]);

  useKey('ArrowUp', () => {
    goToIndex(currentIndex - 1);
  }, {}, [goToIndex, currentIndex]);

  useKey('Enter', () => {
    if (isRecording) {
      controller.stop()
    }
    else {
      controller.start();
    }
  }, {}, [isRecording, controller]);


  const stateBundleForActiveItem = useMemo<RecordingPropsBundle>(() => {
    return {
      canRecord: true,
      record: () => controller.start(),
      stop: () => controller.stop(),
      getBlob: getCurrentBlob,
      isRecording,
      analyzer
    }
  }, [controller, getCurrentBlob, isRecording, analyzer]);

  const stateBundleForInactiveItem = useMemo<RecordingPropsBundle>(() => {
    return {
      canRecord: false,
      record: () => null,
      stop: () => null,
      getBlob: getCurrentBlob,
      isRecording: false,
      analyzer
    }
  }, [controller, getCurrentBlob, isRecording, analyzer]);

  const handleItemClick = useCallback((idx) => {
    if (idx == currentIndex) {
      return;
    }
    goToIndex(idx);
  }, [goToIndex, currentIndex]);

  // @ts-ignore: https://github.com/streamich/react-use/commit/bedbad723171ed1946bc80f72609432983d4c1ba
  return <div ref={ref} style={props.style}>
    <RecordingItemGrid>
      {items.map((item, idx) => {
        const word = items[idx];
        const realIndex = firstIndex + idx;
        const bundle = (realIndex == currentIndex) ? stateBundleForActiveItem : stateBundleForInactiveItem;

        return <RecordingItem
          key={realIndex}
          item={word}
          uploader={uploader}
          index={realIndex}
          isCurrent={realIndex == currentIndex}
          isComplete={item.isComplete}
          recorder={bundle}
          onClick={handleItemClick}
        />
      })}
    </RecordingItemGrid>
  </div>
}
