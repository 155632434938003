// Will be available as Pixi.utils.string2hex in a future version.
import {PlayerAPI} from "languagetool-player/src/ui/Video";
import {useCallback, useEffect, useRef, useState} from "react";
import {useApp} from "@inlet/react-pixi";


export function string2hex(string: string)
{
  if (typeof string === 'string' && string[0] === '#')
  {
    string = string.substr(1);
  }

  return parseInt(string, 16);
}



export function useCurrentPlayTime(playerAPI: PlayerAPI) {
  const [currentTime, setCurrentTime] = useState(0);

  const callback = useCallback(time => {
    setCurrentTime(time);
  }, [setCurrentTime]);

  useOnCurrentPlayTime(playerAPI, callback);

  return currentTime;
}


/**
 * Invokes `callback` on every frame with the current time. Unless onlyOnChange
 * is set, this will happen irregardless of the time having changed.
 *
 * I noticed that it is generally desirable to use our Bacon.js time$ property
 * instead, because if we only want to react on a change in time, this is really
 * the best source for that information.
 */
export function useOnCurrentPlayTimeRAF(playerAPI: PlayerAPI, callback: any, onlyOnChange?: boolean) {
  const pixiApp = useApp();

  const lastTime = useRef<number|null>(null);

  useEffect(() => {
    function onTick() {
      const time = playerAPI.getCurrentTime();
      if (!onlyOnChange || time != lastTime.current) {
        callback(time);
      }
      lastTime.current = time;
    }
    pixiApp.ticker.add(onTick);
    return () => {
      pixiApp.ticker.remove(onTick);
    }
  }, [pixiApp, callback, playerAPI, lastTime]);
}


export function useOnCurrentPlayTime(playerAPI: PlayerAPI, callback: any) {
  useEffect(() => {
    return playerAPI.time$.onValue(time => {
      callback(time);
    })
  }, [callback, playerAPI]);
}