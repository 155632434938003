// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-player-src-ui-Video-ReactVideo__backgroundImage--1miVM{position:absolute;left:0;right:0;top:0;bottom:0;background-size:cover;background-position:center}\n", ""]);
// Exports
exports.locals = {
	"backgroundImage": "_-languagetool-player-src-ui-Video-ReactVideo__backgroundImage--1miVM"
};
module.exports = exports;
