// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-MediaPlayer-CaptionOverlay__root--1lHVm{position:absolute;bottom:5%;left:50%;transform:translate(-50%, 0%);display:flex;flex-direction:column;align-items:flex-start}._-languagetool-editor-src-js-ui-MediaPlayer-CaptionOverlay__line--3TCO6{background-color:#222222;padding:0.4em;color:white;font-size:1.4em;margin-bottom:5px;border:1px solid white}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-ui-MediaPlayer-CaptionOverlay__root--1lHVm",
	"line": "_-languagetool-editor-src-js-ui-MediaPlayer-CaptionOverlay__line--3TCO6"
};
module.exports = exports;
