/**
 * A set of hooks to let you replace the form UI controls of a form.
 *
 * The form that wants to be customizable has to use the widget interface defined here, and a consumer can use
 * a <Provider> type component to provide a different widget set.
 */

import React, {useContext} from "react";
import {FieldProps as FormikFieldProps} from "formik";


export const FormUISwitchContext = React.createContext<FormUIControls<any>|null>(null);


// The kind of type that could be used for the properties of all UI systems:
// export interface FormFieldProps {
//   value: any,
//   onChange: (value: number) => void,
// }


// What the props are which are passed to your system is entirely up to you.
export interface FormUIControls<D> {
  Text: React.ComponentType<D>,
  Select: React.ComponentType<D>,
  RemoteSelect: React.ComponentType<D>,
  Checkbox: React.ComponentType<D>,
}

export function useUIControls(): FormUIControls<any> {
  const uiControls = useContext(FormUISwitchContext);
  if (!uiControls) {
    throw new Error("You have to setup the form ui switch system with a root element.");
  }
  return uiControls;
}

export function FormUISwitcher<D>(props: {
  uiControls: FormUIControls<D>,
  children?: any
}) {
  return <FormUISwitchContext.Provider
    value={props.uiControls}
  >
    {props.children}
  </FormUISwitchContext.Provider>
}

// For us, we are going to use Formik + some default fields we want all our UI systems to support.
export type SelectOption<E=never> = {
  value: string,
  label: string,
  subtitle?: any,
  extra?: E
};

export interface FormFieldProps extends FormikFieldProps {
  label?: string,
  description?: string,
  disabled?: boolean,

  // For select fields
  options?: SelectOption[],
  renderSelectOption?: (option: SelectOption) => React.ReactElement

  // For remote select
  searchFunc?: (value: string) => SelectOption[],
  getItem?: (itemId: string) => Promise<SelectOption>
}
