import {NestableField, useNestedValues} from "../../../../../../components/NestedFormik";
import React from "react";
import {validateRequired} from "../../../../WordForm";
import {useUIControls} from "../../../../../../components/FormUISwitch";


export function SimpleVerbFields() {
  return <>
    <PresentStemField />
    <PastStemField />
  </>;
}


export function SimpleVerbAdvancedFields() {
  return <>
    <VerbPrefixesField />
    <IrregularPresentStemsField />
    <IrregularPastStemsField />
  </>;
}


export const SimpleVerbAdvancedFieldNames = ['prefixes', 'irregularPresentStems', 'irregularPastStems'];


function PresentStemField(props: {}) {
  const UI = useUIControls();
  const isCompound = useNestedValues().isCompound;

  return <NestableField
    name={`presentStem`}
    label={!isCompound ? "Present Stem" : "Present Stem of verb vector"}
    component={UI.Text}
    margin="normal"
    inputProps={{dir: 'rtl'}}
    helperText={isCompound ? "For راست گفتن, this is گو." : "For گفتن, this is گو."}
    validate={validateRequired}
  />;
}


function PastStemField(props: {}) {
  const UI = useUIControls();
  const isCompound = useNestedValues().isCompound;

  return <NestableField
    name={`pastStem`}
    label={!isCompound ? "Past Stem" : "Past Stem of verb vector"}
    component={UI.Text}
    margin="normal"
    inputProps={{dir: 'rtl'}}
    helperText={isCompound ? "For راست گفتن, this is گفت." : "For گفتن, this is گفت."}
    validate={validateRequired}
  />;
}


export function VerbPrefixesField(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={"prefixes"}
    component={UI.Text}

    label="Verb Prefixes"
    description={"Multiple are comma separated"}
    style={{
      direction: 'rtl',
      textAlign: 'left'
    }}
  />;
}


function IrregularPresentStemsField(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={`irregularPresentStems`}
    component={UI.Text}

    label={"Irregular Present Stems"}
    description={"Multiple are comma separated"}
    inputProps={{dir: 'rtl'}}
  />;
}


function IrregularPastStemsField(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={`irregularPastStems`}
    component={UI.Text}

    label={"Irregular Past Stems"}
    description={"Multiple are comma separated"}
    inputProps={{dir: 'rtl'}}
  />;
}
