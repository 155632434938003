import React, {useCallback, useState} from "react";
import {DefaultButton as Button, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton} from 'office-ui-fabric-react';
import { useMutation } from "multi-apollo";
import gql from "graphql-tag";


export function DeleteButton(props: {wordId: number, onDeleted: () => any}) {
  const [isVisible, setIsVisible] = useState(false);

  const deleteWord = useDeleteMutation(props.wordId);

  const handleDelete = useCallback(() => {
    deleteWord();
    setIsVisible(false)
    props.onDeleted();
  }, []);

  const handleClick = useCallback(() => {
    setIsVisible(true)
  }, [setIsVisible]);

  return <Button
    style={{backgroundColor:'#F44336', color:'white', marginRight: '1em'}}
    onClick={handleClick}
  >
    Delete
    <ConfirmDialog
      onRequestClose={() => setIsVisible(false)}
      onDelete={handleDelete}
      hidden={!isVisible}
    />
  </Button>
}


function useDeleteMutation(wordId: number) {
  const [deleteWord, {data: mutationData}] = useMutation(gql`
    mutation DeleteWord($wordId: Int!) {
      deleteWord(wordId: $wordId) {
        deletedId
      }
    }
  `, {
    variables: {
      wordId
    }
  });

  return deleteWord;
}


function ConfirmDialog(props: {
  onRequestClose: any,
  onDelete: any,
  hidden: boolean
}) {
  return <Dialog
    hidden={props.hidden}
    onDismiss={props.onRequestClose}
    dialogContentProps={{
      type: DialogType.normal,
      title: 'Are you sure?',
      subText: 'Are you sure you want to delete this word?'
    }}
    modalProps={{
      isBlocking: false,
      styles: { main: { maxWidth: 450 } }
    }}
  >
    <DialogFooter>
      <PrimaryButton onClick={props.onDelete} text="Delete" style={{backgroundColor:'#F44336', color:'white'}} />
      <DefaultButton onClick={props.onRequestClose} text="Don't delete" />
    </DialogFooter>
  </Dialog>
}