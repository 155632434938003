import React, {useCallback, useMemo} from 'react';
import styles from './MiniMap.cssm';
import classnames from 'classnames';
import {IndexedContentIssues} from "../../Market/ContentIssues";
import {TrackInterface} from "../assignScreen";


export function MiniMap(props: {
  style?: any,
  trackAPI: TrackInterface,
  onSelect: (lineIdx: number) => void,
  currentIndex?: number,
  contentIssues?: IndexedContentIssues
}) {
  const lines = useMemo(() => {
    const lines: ('complete'|'partial'|'empty')[] = [];

    for (let i=0; i < props.trackAPI.getLineCount(); i++) {
      let wordsEncountered = 0;
      let wordNodesFound = 0;

      for (const vocable of props.trackAPI.getLine(i)) {
        const wordNode = props.trackAPI.getWordNodeForVocables([vocable.id], {allowPartialMatch: true});
        wordsEncountered++;
        if (wordNode) {
          wordNodesFound++;
        }
      }

      if (wordsEncountered === wordNodesFound) {
        lines.push('complete');
      }
      else if (wordNodesFound === 0) {
        lines.push('empty');
      }
      else {
        lines.push('partial');
      }
    }

    return lines;
  }, [props.trackAPI]);

  const handleRowClick = useCallback((e) => {
    const idx = parseInt(e.target.dataset.lineindex);
    props.onSelect(idx);
  }, [props.onSelect]);

  return <div className={styles.root} style={props.style}>
    {lines.map((line, idx) => {
      return <div
        key={idx}
        className={classnames(
          styles.line,
          styles[line],
          {
            [styles.current]: idx === props.currentIndex,
            [styles.hasIssues]: props.contentIssues?.unresolvedByLine[idx]?.length,
          }
        )}
        onClick={handleRowClick}
        data-lineindex={idx}
      >
      </div>
    })}
  </div>
}