import React from "react";


export function Form(props: any) {
  return <div style={{
    display: 'grid',
    gridGap: '0px'
  }}>
    {props.children}
  </div>
}