import * as React from "react";
import {getCanonicalFromwWordId, TitleForStructureNode} from "./WordDisplay";
import {Fragment} from "react";
import {isWordNode} from "../../../models/Node";
import {Page, pageIsNode, pageIsWordId} from "../Page";
import {StyleSheet, View, Text} from 'react-native';
import {WordLink} from "./WordLink";


const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#b5b5b5',
    backgroundColor: 'white',
    padding: 10,
    //fontSize: 0.7
  }
})

/**
 * Breadcrumbs on top of the popup, when following links.
 */
export function Breadcrumbs(props: {
  pages: Page[],
  currentPageIndex: number
}) {
  const {pages} = props;
  if (!pages || pages.length <= 1) {
    return null;
  }

  return <View style={styles.root}>
    {pages.map((page, idx) => {
      const isLast = idx === pages.length - 1;
      const isCurrent = idx === props.currentPageIndex;

      // What is the target word? Get it from the word node, if necessary.
      const wordId = pageIsNode(page) && isWordNode(page) ? page.wordId : pageIsWordId(page) ? page : null;

      // What text do we render? It should represent the "popup page".
      let comp = (pageIsNode(page) && isWordNode(page))
          ? <TitleForStructureNode word={page}/>
          : wordId
            ? <Text>{getCanonicalFromwWordId(wordId)}</Text>
            : <Text>Unknown</Text>;

      if (!isCurrent && wordId) {
        // NB: We do not really use the <WordLink> component here except for the style.
        comp = <WordLink
          key={idx}
          to={wordId}
          onClick={context => {
            context.goBackToIndex(idx);
          }}
        >
          {comp}
        </WordLink>
      }

      if (isLast) {
        return <Fragment key={idx}>{comp}</Fragment>;
      } else {
        return <Fragment key={idx}>
          {comp}
          <Text> →  </Text>
        </Fragment>;
      }
    })}
  </View>
}