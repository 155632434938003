import * as React from 'react';
import {IssueDetails, useIssueDetails} from './IssueDetailsDialog';
import {CircularProgress, Typography, Button, makeStyles} from '@material-ui/core';
import classnames from 'classnames';
import {LinePreview} from "../../Words/LineByLineView";
import {TagSelector} from "./TagSelector";
import { useState, useMemo } from 'react';
import {usePlayLineRawTrack} from "../utils/useAudioPlayer";
import {gql as gqlm} from '@apollo/client';
import { useQuery } from 'multi-apollo';
import {SingleIssueUnitDataQuery, SingleIssueUnitDataQuery_unit} from "../../../../types/SingleIssueUnitDataQuery";


// language=GraphQL
const unitDataQuery = gqlm`
  query SingleIssueUnitDataQuery($id: ID!) {
    unit(id: $id) {
      mediaTrack {
        id,
        type,
        url
      },
      captionTrack {
        currentVersion {
          id,
          data
        }
      }
    }
  }
`;


export function SingleIssue(props: {
  issueId: string
}) {
  const {data, error, loading} = useIssueDetails(props.issueId);
  const {data: unitData} = useQuery<SingleIssueUnitDataQuery>(unitDataQuery, {
    variables: {
      id: data?.contentIssue?.unit.id
    },
    skip: loading || !data?.contentIssue?.unit.id,
    client: 'market'
  });

  if (loading) {
    return <CircularProgress />
  }

  if (error) {
    return <div>Error loading</div>;
  }

  if (!data?.contentIssue) {
    return <div>
      This issue was not found.
    </div>
  }

  const issue = data!.contentIssue!;

  const lineIndex = ("lineIndex" in issue.location) ? issue.location.lineIndex : issue.location.lineIndexOpt;

  return <div>
    <Typography variant={"h5"} style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <IssueStatus isResolved={issue.isResolved} />

      <span style={{flex: 1, marginLeft: '10px'}}>
        #{props.issueId} - {issue.unit.name}
      </span>

      {lineIndex ? <PlayButton
        unitData={unitData?.unit || undefined}
        currentLineIndex={lineIndex}
      /> : null}

      <Button color={"primary"} variant={"contained"} href={`https://editor.languagetool.xyz?load=${issue.unit.id}`}
              target={"_blank"}>
        Open
      </Button>
    </Typography>

    <div style={{marginTop: '20px'}}>
      <HeaderInfoSlice label={"Assigned To"}>
        {issue.assignedTo ? issue.assignedTo.name : "(Unassigned)"}
      </HeaderInfoSlice>

      <HeaderInfoSlice label={"Tags"}>
        {issue.tags?.map(tag => <Tag key={tag} tag={tag} />)}
        {" "} <ChangeTagsLink
          selectedRecord={issue}
        />
      </HeaderInfoSlice>


      {lineIndex ? <HeaderInfoSlice label={"Line"}>
        {lineIndex + 1}
      </HeaderInfoSlice> : null}
    </div>

    {issue.sampleText ? <div style={{marginTop: '20px', border: '1px solid black'}}>
      <LinePreview
        words={JSON.parse(issue.sampleText?.structure)[0]}
      />
    </div> : null}

    <IssueDetails
      issue={issue}
    />
  </div>
}


function PlayButton(props: {
  unitData: SingleIssueUnitDataQuery_unit|undefined,
  currentLineIndex: number
}) {
  const parsedText = useMemo(() => {
    if (!props.unitData?.captionTrack?.currentVersion?.data) {
      return null;
    }
    return JSON.parse(props.unitData.captionTrack.currentVersion.data);
  }, [props.unitData]);

  const playLine = usePlayLineRawTrack({
    // @ts-ignore
    mediaTrack: props.unitData?.mediaTrack || undefined,
    track: parsedText,
    currentLineIndex: props.currentLineIndex

  });
  const handleClick = () => {
    playLine();
  }
  return <Button onClick={handleClick}>Play</Button>;
}


export function HeaderInfoSlice(props: {
  label: string,
  children: any
}) {
  return <span style={{marginRight: '10px'}}>
    <span style={{color: "gray"}}>{props.label}</span>: {" "}
    {props.children}
  </span>
}

export function Tag(props: {
  tag: string
}) {
  return <span style={{
    padding: '0.2em',
    color: 'white',
    backgroundColor: 'green'
  }}>
    {props.tag}
  </span>
}


export function ChangeTagsLink(props: {
  selectedRecord: {id: string, tags: string[]}
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <a href={"#"} onClick={handleClick}>Change</a>
    <TagSelector
      anchorEl={anchorEl}
      onRequestClose={handleClose}
      selectedRecords={[props.selectedRecord]}
    />
  </>
}


const useIssueStatusStyles = makeStyles({
  base: {
    fontSize: '0.8em',
    display: 'inline-block',
    padding: '0.3em 0.5em',
    color: 'white',
    borderRadius: '.2em',
  },
  open: {
    background: '#4CAF50',
  },
  resolved: {
    background: '#9E9E9E',
  }
});

function IssueStatus(props: {
  isResolved: boolean
}) {
  const styles = useIssueStatusStyles();
  return <div className={classnames({
    [styles.base]: true,
    [styles.resolved]: props.isResolved,
    [styles.open]: !props.isResolved
  })}>
    {props.isResolved ? 'Resolved' : 'Open'}
  </div>
}