// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__lineGroup--2EXkg{padding-block-start:10px;padding-block-end:10px;padding-inline-start:15px;padding-inline-end:15px;margin-block-end:20px;display:flex;align-items:stretch}._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__lineGroup--2EXkg._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__rtl--3E6Vj{direction:rtl}._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__matchesCurrentTime--29fWd ._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__content--2C9Jm{border-inline-start-color:#e91e63;border-inline-start-width:5px}._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__content--2C9Jm{border-inline-start:1px solid gray;margin-inline-start:5px;padding-inline-start:5px;flex:1}._-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__isFocused--E1CTk{background-color:#ececec}\n", ""]);
// Exports
exports.locals = {
	"lineGroup": "_-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__lineGroup--2EXkg",
	"rtl": "_-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__rtl--3E6Vj",
	"matchesCurrentTime": "_-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__matchesCurrentTime--29fWd",
	"content": "_-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__content--2C9Jm",
	"isFocused": "_-languagetool-editor-src-js-ui-CaptionEditor-ui-LineGroup__isFocused--E1CTk"
};
module.exports = exports;
