import {ForeignWordNode, NumeralNode} from "languagetool-player/src/models/Node";
import * as React from "react";
import {wordifyNumber} from "../langpacks/fa/wordifyNumber";


export function ForeignWordNodeOption(props: {
  node: ForeignWordNode
}) {
  return <div>
    Foreign Word
  </div>
}
