import styles from "./WordStructureTable.cssm";
import * as React from "react";
import {WordStructure} from "languagetool-player/src/models/Node";
import classnames from "classnames";
import {isLanguageRtl} from "languagetool-player/src/i18n";
import {Fragment} from "react";


/**
 * For debugging purposes, renders all part of a "WordStructure".
 */
export function WordStructureTable(props: {
  structure: WordStructure,
  language: string
}) {
  const {language} = props;
  const {parts} = props.structure;

  return <div
    className={classnames(styles.root, isLanguageRtl(language) ? styles.rtl : null)}
  >
    {
      parts.map((part, idx) => {
        let divider = idx < parts.length-1
          ? <div className={styles.part}>+</div>
          : null;
        return <Fragment key={idx}>
          <div className={styles.part}>{part.orth}</div>
          {divider}
        </Fragment>;
      })
    }

    {
      parts.map((part, idx) => {
        let divider = idx < parts.length-1
          ? <div className={styles.explainer} />
          : null;

        return <Fragment key={idx}>
          <div className={styles.explainer}>{part.type}</div>
          {divider}
        </Fragment>;
      })
    }
  </div>
}