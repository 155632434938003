import React from "react";

export class ErrorBoundary extends React.Component<{}, {hasError: boolean}> {
  constructor(props: {}) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);

  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>The editor crashed. Save and reload.</h1>;
    }

    return this.props.children;
  }
}