import * as React from 'react';
import {useRef, useState} from 'react';
import styles from './RecordingItem.module.css';
import classnames from 'classnames';
import {useKey} from 'react-use';
import {Callout, DefaultButton, IconButton, Spinner, TextField} from 'office-ui-fabric-react';
import {makeFrequencyBars} from "../../components/Audio/Visualizer";
import {useRaf} from "../../components/Audio/utils";
import {RecordingItemDefinition, RecordingPropsBundle} from "./ListControl";
import {UploadManager, useWordUploadStatus, WordUploadStatus} from "./useUploader";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {useMutation} from "multi-apollo";
import gqlw from "graphql-tag";
import {NextWordsInSession_recordingSession_items_existingRecordings} from "../../types/NextWordsInSession";
import {useManagedPlayer, usePlayer} from "./usePlayer";


export function RecordingItemGrid(props: {children: any}) {
  return <div className={styles.grid}>
    {props.children}
  </div>
}


export const RecordingItem = React.memo((props: {
  onClick: (idx: number) => void,
  uploader: UploadManager,
  index: number,
  item: RecordingItemDefinition,
  isComplete: boolean,
  isCurrent: boolean,
  recorder: RecordingPropsBundle
}) => {
  const state = useWordUploadStatus(props.uploader, props.item.word.id);

  // Either the item was already complete on the server, or a recording was made. Note that for now,
  // the server state is unfortunately not synced into the useForeverScroll view, but we could do that.
  const considerComplete = (props.item.isComplete || state.numComplete > 0) && (!state.hasFailed);

  return <div
    className={classnames(
      styles.item,
      (considerComplete && !state.hasFailed) ? styles.complete : null,
      props.isCurrent ? styles.current : null,
      state.hasFailed ? styles.failed : null,
    )}
    onClick={() => {
      props.onClick(props.index);
    }}
  >
    <ToBeRecordedInfo
      uploader={props.uploader}
      item={props.item}
      considerComplete={considerComplete}
      state={state}
      idx={props.index}
    />
    <div className={styles.RightColumn}>
      <RecorderControls
        recorder={props.recorder}
        item={props.item}
      />
      <ExistingRecordings
        item={props.item}
        isCurrent={props.recorder.canRecord}
      />
    </div>
  </div>
});


export function ToBeRecordedInfo(props: {
  uploader: UploadManager,
  item: RecordingItemDefinition,
  considerComplete: boolean,
  state: WordUploadStatus,
  idx: number
}) {
  const word = props.item.word;

  return <div className={styles.ToBeRecordedInfo}>
    <div className={styles.wordId}>
      <span>#{word.id} (item {props.idx+1})</span>

      {props.considerComplete ? <span className={styles.completeMarker}>
        Recording Saved.
      </span>: null}

      {props.state.hasFailed ? <span className={styles.failedMarker}>
        Upload Failed. Not Saved.
      </span>: null}

      {props.state.isUploading ? <span className={styles.spinner} style={{marginLeft: '10px'}}>
        <Spinner /> Uploading...
      </span> : null}
    </div>
    <div>
      <span className={styles.wordType}>{word.type}</span>
      <span className={styles.wordWithVowels}>{word.base}</span>
    </div>
    <div>
      <div className={styles.translit}>
        Possible Roman: <strong>{romanizeSounds(JSON.parse(word.baseTransliteration))}</strong>
      </div>
      {word.description ? <div className={styles.translit}>
        Description: <strong>{word.description}</strong>
      </div> : null}
    </div>
  </div>
}


function useRejectRecording() {
  const [rejectRecording, {data: mutationData}] = useMutation(gqlw`
    mutation RejectRecording($id: ID!) {
      rejectRecording(id: $id) {
        recording {
          id
          isRejected
        }
      }
    }
  `, {
    variables: {}
  });

  return (recordingId: string) => {
    return rejectRecording({
      variables: {
        id: recordingId
      }
    })
  }
}


/**
 * A list of recordings already saved previously.
 */
function ExistingRecordings(props: {
  item: RecordingItemDefinition,
  isCurrent: boolean
}) {
  if (!props.item.existingRecordings?.length) {
    return null;
  }

  return <div style={{marginTop: '20px'}}>
    {props.item.existingRecordings.map(recording => {
      return <ExistingRecording
        recording={recording}
        key={recording.id}
      />
    })}
  </div>
}


/**
 * An existing recording already saved previously.
 */
function ExistingRecording(props: {
  recording: NextWordsInSession_recordingSession_items_existingRecordings,
}) {
  const rejectRecording = useRejectRecording();
  const [calloutVisible, setCalloutVisible] = useState(false);
  const ref = useRef<any>();
  const {play, pause, isPlaying} = useManagedPlayer(props.recording);

  return <span
    className={classnames(styles.existingRecording, {
      [styles.rejectedRecording]: props.recording.isRejected
    })}
    ref={ref}
  >
    {!isPlaying ? <IconButton
      iconProps={{ iconName: 'play' }}
      onClick={() => play() }
    /> : null}
    {isPlaying ? <IconButton
      iconProps={{ iconName: 'pause' }}
      onClick={() => pause() }
    /> : null}

    <div onClick={() => setCalloutVisible(true)}>Recording</div>

    {calloutVisible ? <Callout
      className={styles.callout}
      gapSpace={0}
      target={ref.current}
      onDismiss={() => setCalloutVisible(false)}
      setInitialFocus={true}
    >
      <div style={{padding: '20px'}}>
        <DefaultButton onClick={() => {
          rejectRecording(props.recording.id);
        }}>Reject</DefaultButton>
        <TextField value={props.recording.id} />
      </div>
    </Callout> : null}
  </span>
}


export function RecorderControls(props: {
  recorder: RecordingPropsBundle,
  item: RecordingItemDefinition,
}) {
  const {recorder, item} = props;

  let blobToPlay: Blob|null = null;
  if (recorder.canRecord) {
    blobToPlay = recorder.getBlob();
    if (!blobToPlay && item.recordings?.length) {
      blobToPlay = item.recordings?.[item.recordings?.length - 1];
    }
  }
  const [player, isPlaying] = usePlayer(blobToPlay);

  const canPlay = (!recorder.isRecording && player && !isPlaying);
  const canPause = (player && isPlaying);

  useKey((event) => event.keyCode === 32, (e) => {
    e.preventDefault();
    if (canPlay) { player!.play(); }
    else if (canPause) { player!.pause(); }
  }, {}, [player, canPlay, canPause]);

  return <div className={styles.RecorderControls}>
    <div style={{paddingRight: '30px'}}>
      {(!recorder.isRecording && recorder.canRecord) ? <IconButton
        iconProps={{ iconName: 'record2' }}
        onClick={() => {
          props.recorder.record();
        }}
      /> : null}
      {recorder.isRecording ? <IconButton
        iconProps={{ iconName: 'stop' }}
        onClick={props.recorder.stop}
      /> : null}
      {canPlay ? <IconButton
        iconProps={{ iconName: 'play' }}
        onClick={() => player!.play() }
      /> : null}
      {canPause ? <IconButton
        iconProps={{ iconName: 'pause' }}
        onClick={() => player!.pause() }
      /> : null}
    </div>
    {props.recorder.isRecording ? <Visualizer
      analyzer={props.recorder.analyzer}
    /> : null}
  </div>
}



function Visualizer(props: {
  analyzer: AnalyserNode
}) {
  const [canvas, setCanvas] = useState<HTMLCanvasElement|undefined|null>(null);

  const drawFunc = canvas ? makeFrequencyBars(
    canvas,
    props.analyzer,
    {
      backgroundColor: 'white',
      strokeColor: "red"
    }
  ) : null;

  useRaf(() => {
    if (drawFunc) {
      drawFunc();
    }
  })

  return <canvas width={200} height={80} ref={r => setCanvas(r)} />;
}