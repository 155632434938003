import React from "react";
import {drawWaveform} from "./drawWaveform";
import {Scale, getSampleIndexForTime} from "./coordinates";
import * as PIXI from 'pixi.js'
import {PixiComponent} from '@inlet/react-pixi'
import {Graphics} from 'pixi.js';
import {WaveformData} from "waveform-data";


type Props = {
  x: number,
  y: number,
  width: number,
  height: number,

  // The waveform to render.
  data: WaveformData,
  scale: Scale,

  // Where to start
  offsetSeconds: number,
};


/**
 * A react-pixi shape which renders the given waveform data, starting at `offsetSeconds`, at the given
 * `px/s resolution`.
 */
export const WaveformShape  = PixiComponent('Shape', {
  create: (props: Props) => {
    const g = new PIXI.Graphics()
    g.x = props.x;
    g.y = props.y;
    return g;
  },

  applyProps: (g: Graphics, oldProps: Props, newProps: Props) => {
    // Should not really happen, but currently does. Normalize offset seconds to 0 then.
    let offsetSeconds = Math.max(newProps.offsetSeconds, 0);

    const frameOffset = getSampleIndexForTime(offsetSeconds, newProps.data);

    g.clear()
    drawWaveform(g, {
      width: newProps.width,
      height: newProps.height,
      frameOffset,
      data: newProps.data,
      mono: true,
      scale: newProps.scale
    });
  }
})