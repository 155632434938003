// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-assignScreen-EditableText__root--1vF-2 input{display:inline-block;padding:0.5em}._-languagetool-editor-src-js-integrations-Words-assignScreen-EditableText__root--1vF-2:hover{text-decoration:underline}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-integrations-Words-assignScreen-EditableText__root--1vF-2"
};
module.exports = exports;
