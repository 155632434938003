/**
 * Our "Edit Word Form" can be used with two separate UI systems. This implements the UI controls used
 * on top of the "Microsoft Office Fabric UI".
 */

import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  ReactElement
} from "react";
import {
  TextField as FabricTextField,
  Dropdown as FabricDropdown,
  Checkbox as FabricCheckbox,
  BasePicker,
  IBasePickerProps,
  IPickerItemProps
} from "office-ui-fabric-react";
import styles from "./FabricUIControls.module.css";
import {
  FormFieldProps,
  FormUIControls,
  SelectOption
} from "languagetool-editor/src/js/components/FormUISwitch";

function TextField(props: FormFieldProps) {
  const {field, form, meta, ...rest} = props;

  return (
    <FabricTextField
      {...field}
      errorMessage={meta && meta.touched && meta.error ? meta.error : undefined}
      {...rest}
    />
  );
}

function SelectField(props: FormFieldProps) {
  const {field, form, meta, options, ...rest} = props;
  const handleChange = useCallback(
    (e, item) => {
      form.setFieldValue(field.name, item.key);
    },
    [field.onChange]
  );

  const newOptions =
    props.options?.map(({value, label}) => ({key: value, text: label})) ?? [];

  return (
    <FabricDropdown
      {...field}
      selectedKey={field.value}
      options={newOptions}
      onChange={handleChange}
      {...rest}
    />
  );
}

function Checkbox(props: FormFieldProps) {
  const {field, form, meta, ...rest} = props;

  const handleChange = useCallback(
    (e, isChecked) => {
      form.setFieldValue(field.name, isChecked);
    },
    [field.onChange]
  );

  return (
    <FabricCheckbox
      {...field}
      onChange={handleChange}
      checked={field.value}
      {...rest}
    />
  );
}

/**
 * There are too many options in fabric-ui which are all of questionable quality:
 *
 * Dropdown: Cannot even type, no remote data.
 * ComboBox: Can type, but no remote data.
 * Pickers: Can type + remote data, but:
 *   - Designed for multiple elements.
 *   - Forcably selects the first element even if the user wants to keep "no selection".
 *
 * An alternative would be to combine Downshift with a ComboBox.
 */
class CustomPicker extends BasePicker<
  SelectOption,
  IBasePickerProps<SelectOption>
> {}

function RemoteSelect(props: FormFieldProps) {
  const runSearch = useCallback(
    async (filterText: string) => {
      return props.searchFunc?.(filterText) ?? [];
    },
    [props.searchFunc]
  );

  // For an initial value, we don't have a label. The way we implement it here is that because we do not rely
  // on the Picker component state (which remembers the label), but use it as a controlled component with the
  // formik state as the source (which has no place for label), we instead call the `fetchLabel` helper on
  // every render. and it's up to the caller to have it cached from a previous call to `searchFunc`.
  const [itemLabelProps, setItemLabelProps] = useState<
    Omit<SelectOption, "value">
  >({label: ""});
  useEffect(() => {
    // Indicate we are loading it:
    setItemLabelProps({label: "..."});

    // Query for the label
    let isSubscribed = true;
    (props.getItem?.(props.field.value) ?? Promise.reject(null))
      .catch((e: any) => {
        console.error(e);
        return {label: props.field.value};
      })
      .then(v => {
        if (!isSubscribed) {
          return;
        }
        setItemLabelProps(v);
      });

    return () => {
      isSubscribed = false;
    };
  }, [props.field.value, setItemLabelProps]);

  const selectedItems = useMemo(() => {
    if (!props.field.value || props.field.value === true) {
      return [];
    }
    return [
      {
        ...itemLabelProps,
        value: props.field.value
      } as SelectOption
    ];
  }, [props.field.value, itemLabelProps]);

  const renderSuggestionsItem = useCallback(
    (p: SelectOption) => {
      return (
        <RemoteSelectSuggestionItem
          option={p}
          onRender={props.renderSelectOption}
        />
      );
    },
    [RemoteSelectSuggestionItem, props.renderSelectOption]
  );

  const control = (
    <CustomPicker
      //description={props.description}
      itemLimit={1}
      onRenderSuggestionsItem={renderSuggestionsItem}
      onResolveSuggestions={runSearch}
      onRenderItem={RemoteSelectItem}
      selectedItems={selectedItems}
      getTextFromItem={item => {
        return item.label;
      }}
      onChange={items => {
        if (!items || items.length == 0) {
          props.form.setFieldValue(props.field.name, null);
        } else {
          props.form.setFieldValue(props.field.name, items[0].value);
        }
      }}
      pickerSuggestionsProps={{
        noResultsFoundText: "No Documents Found"
      }}
      disabled={false}
      inputProps={{
        onFocus: () => console.log("onFocus called"),
        onBlur: () => console.log("onBlur called"),
        "aria-label": "Document Picker"
      }}
    />
  );

  return (
    <div>
      <div
        style={{
          color: "rgb(50, 49, 48)",
          fontWeight: "bold"
        }}
      >
        {props.label}
      </div>
      {control}
      <div>
        {props.meta.error ? (
          <div style={{color: "rgb(164, 38, 44)"}}>{props.meta.error}</div>
        ) : null}
      </div>
    </div>
  );
}

function RemoteSelectItem(props: IPickerItemProps<SelectOption>) {
  return (
    <div key={props.key} className={styles.selectItem}>
      <span style={{paddingRight: "20px"}}>{props.item.label}</span>

      <a
        href=""
        onClick={e => {
          e.preventDefault();
          props.onRemoveItem!();
        }}
      >
        Remove
      </a>
    </div>
  );
}

function RemoteSelectSuggestionItem(props: {
  option: SelectOption;
  onRender?: (opt: SelectOption) => ReactElement;
}) {
  let content;
  if (props.onRender) {
    content = props.onRender(props.option);
  } else {
    content = props.option.label;
  }

  return (
    <div key={props.option.value} className={styles.selectItem}>
      {content}
    </div>
  );
}

export const FabricUIFormControls: FormUIControls<FormFieldProps> = {
  Text: TextField,
  Select: SelectField,
  RemoteSelect: RemoteSelect,
  Checkbox: Checkbox
};
