/**

 I want annotations to work like this:

 - A "loader" knows how to fetch all annotations for a word. The annotations
   loader is provided by the unit loader (thus an annotation loader might
   be installed that fetches from a generic server, or from annotations
   that ship with the unit.

- An "annotation source" adds a cache on top of the loader.

  This cache is so important because annotations can depend on each other.
  Example: PoS tagging needs to run on the whole sentence. The information
  can then be used by the dictionary annotation to show the definition for
  just one word in the sentence.

- Each word in a unit has a unique id. Multi-word annotations reference
  those ids (i.e. annotation range that includes a45ef, bb0e45, ff4301).

  This is important because sometimes words build one grammatical unit.
  Examples include named entities or "dust daram" in Farsi. However, these
  simple cases could also be solved by the splitter - "dust daram" could
  be made into a single word.

  Complex cases include German ("wir stellen den Lüfter ab" -> abstellen),
  and even Farsi too ("man daram to ro mizanam" -> daram mizanam).
*/

import {DictionaryAnnotation} from "./Dictionary";
import {BaseAnnotation} from "./base";
import {TranslationAnnotation} from "./Translation";
import {PronunciationAnnotation} from "./Pronunciation";
import {DefinitionAnnotation} from "./Definition";
import {InflectionAnnotation} from "./Inflection";
import {WordDataAnnotation} from "./WordData";
import {StructureNode, WordStructure} from "../models/Node";


export type AnnotationType = 'word-data'|'definition'|'translation'|'pronunciation'|'dictionary'|'inflection';


// TODO: Make more things use it. We used to mix up WordId and AnnotationQuery until now,
// but this really isn't right. A lot of things use AnnotationQuery or WordIdQuery when they should be using WordId.
export type DatabaseWordId = {
  id: number,
  meaningId?: number,
  canonical?: string
};
export type StringWordId = {
  lang: string,
  text: string,
}
export type WordId = StringWordId|DatabaseWordId;


export function isStringWordId(wordId: WordId): wordId is StringWordId {
  return !!(wordId as any).text;
}


// A word id is something that can be used to "lookup" a word from the server, to get the definition and other
// annotations. When attached to a vocabulary, it essentially defines what word that particular string of characters
// represents. If you so desire, you can link the text "went", to an entry for "to go", but in general, it is
// preferable to instead for "went", explain that it is a past tense form, then link to the word id for "to go".
//
// There are two ways a word can be identified or referenced; they match the ways the annotation server allows
// lookup. If you know the id of the word, and it's particular meaning variant (say free as in beer vs freedom),
// you can give that id; otherwise, you can lookup by text. If you are given a word id from the system, we try to
// include a canonical identifier.
export type StringQuery = {
  // This one does not include a meaning, because outside of our database system,
  // we do not have a defined meaning/id system. Each dictionary has different meaning entries.
  lang: string,
  text: string,

  // If this is set, we are telling the server that we do not want any grammar analysis done, but rather,
  // what the server is getting is the word by which he should do the lookup.
  isLemma?: boolean
}

export type WordIdQuery = {
  // this one does not need a language, since the id contains to a specific language
  id: number,
  // Which meaning if kardan do we mean? The slang one? or the real one?
  meaningId?: number,

  // We can't render a numeric id very well, this can include a readable label (optional).
  canonical?: string
};

// Allows querying annotations for a particular form of a word.
export type InflectionQuery = {
  wordId: WordId,
  inflection: unknown,
  structure: WordStructure,
}


export type AnnotationQuery = StringQuery|WordIdQuery|InflectionQuery;


export function isQueryByString(wordId: AnnotationQuery): wordId is StringQuery {
  return !!(wordId as any).text;
}

export function isQueryByWordId(wordId?: AnnotationQuery): wordId is WordIdQuery {
  return !!wordId && !!(wordId as any).id;
}

export function isQueryByInflection(wordId?: AnnotationQuery): wordId is InflectionQuery {
  return !!wordId && !!(wordId as any).structure;
}


export * from './Dictionary';
export * from './Translation';


export function isDefinition(annotation: BaseAnnotation): annotation is DefinitionAnnotation {
  return annotation.type === 'definition';
}

export function isTranslation(annotation: BaseAnnotation): annotation is TranslationAnnotation {
  return annotation.type === 'translation';
}

export function isPronunciation(annotation: BaseAnnotation): annotation is PronunciationAnnotation {
  return annotation.type === 'pronunciation';
}

export function isInflection(annotation: BaseAnnotation): annotation is InflectionAnnotation {
  return annotation.type === 'inflection';
}

export function isDictionary(annotation: BaseAnnotation): annotation is DictionaryAnnotation {
  return annotation.type === 'dictionary';
}

export function isWordData(annotation: BaseAnnotation): annotation is WordDataAnnotation {
  return annotation.type === 'word-data';
}


export type Annotation = DefinitionAnnotation|PronunciationAnnotation|TranslationAnnotation|DictionaryAnnotation|InflectionAnnotation|WordDataAnnotation;


export type Annotations = Annotation[];


export function filterAnnotations(annotations: Annotations, type: AnnotationType) {
  return annotations.filter(a => a.type == type);
}

export function getOneAnnotation(annotations: Annotations|null|undefined, type: AnnotationType) {
  if (!annotations) {
    return null;
  }

  const filtered = filterAnnotations(annotations, type);
  if (filtered.length) {
    return filtered[0];
  }
  return null;
}


export function forceLemmaWordId(wordId: StringQuery|WordIdQuery): WordIdQuery|StringQuery {
  if (isStringWordId(wordId)) {
    return {...wordId, isLemma: true};
  }
  return wordId;
}


// export function isSameWordId(w1: WordId, w2: WordId) {
//   if (isQueryByTextId(w1)) {
//     if (!isQueryByTextId(w2)) {
//       return false;
//     }
//
//     if (w1.text != w2.text) {
//       return false;
//     }
//
//     // noinspection RedundantIfStatementJS
//     if (w1.lang && w2.lang && w1.lang != w2.lang) {
//       return false;
//     }
//
//     return true;
//   }
//
//   else {
//     if (isQueryByTextId(w2)) {
//       return false;
//     }
//
//     // noinspection RedundantIfStatementJS
//     if (w1.meaningId != w2.meaningId || w1.id != w2.id) {
//       return false;
//     }
//
//     return true;
//   }
// }