import {Field, FieldProps, Formik} from "formik";
import {
  Button,
  Checkbox,
  PrimaryButton,
  TextField,
  DefaultButton
} from "office-ui-fabric-react";
import {UserSelect} from "./UserSelect";
import React from "react";
import {useMutation} from "multi-apollo";
import {gql as gqlw} from "@apollo/client";
import {useCounter} from "react-use";

const useAddNote = () => {
  const [approve, {data: mutationData}] = useMutation(gqlw`
    mutation AddNote($wordId: ID, $text: String!, $isTodo: Boolean, $assignedTo: Int, $parentId: ID, $setResolve: Boolean) {
      createInternalNote(wordId: $wordId, text: $text, isTodo: $isTodo, assignedTo: $assignedTo, parentId: $parentId, setResolve: $setResolve) {
        note {
          id,
          parentNote {
            id,
            isResolved
          }
        }
      }
    }
  `);
  return approve;
};

export function AddEditNoteForm(
  props: {
    onCancel?: () => void;
    onSave: () => void;
    disallowTodo?: boolean;
    allowResolve?: boolean;
    title: string;
  } & ({wordId: number} | {parentNoteId?: string})
) {
  const addNote = useAddNote();
  const [state, {inc: incState}] = useCounter(1);

  return (
    <Formik<{
      text: string;
      isTodo: boolean;
      assignedTo: string | null;
      setResolveState?: boolean;
    }>
      key={state}
      initialValues={{
        text: "",
        isTodo: false,
        assignedTo: null
      }}
      validate={values => {
        if (!values.text && !values.setResolveState) {
          return {text: "You must provide this field."};
        }
      }}
      onSubmit={async (values, actions) => {
        try {
          await addNote({
            variables: {
              wordId: "wordId" in props ? props.wordId : null,
              parentId: "parentNoteId" in props ? props.parentNoteId : null,

              text: values.text,
              isTodo: values.isTodo,
              assignedTo: values.assignedTo
                ? parseInt(values.assignedTo)
                : null,

              setResolve: values.setResolveState
            }
          });
          await props.onSave();
          incState();
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {formik => (
        <>
          <Field
            as={TextField}
            name={"text"}
            label={props.title}
            style={{width: "100%"}}
            multiline
            autoAdjustHeight
          />

          {!props.disallowTodo ? (
            <Field
              name={"isTodo"}
              label={"This is a TODO - requires action"}
              as={Checkbox}
            />
          ) : null}

          <Field name={"assignedTo"}>
            {(field: FieldProps) => {
              if (!field.form.values.isTodo) {
                return null;
              }
              return (
                <UserSelect
                  {...field}
                  label={"User responsible for the TODO"}
                />
              );
            }}
          </Field>

          <div style={{marginTop: "20px"}}>
            <PrimaryButton
              onClick={formik.submitForm}
              disabled={formik.isSubmitting}
            >
              Comment
            </PrimaryButton>
            {props.allowResolve ? (
              <DefaultButton
                onClick={() => {
                  formik.setFieldValue("setResolveState", true);
                  formik.submitForm();
                }}
                disabled={formik.isSubmitting}
              >
                {formik.values.text ? "Comment + Resolve" : "Mark As Resolved"}
              </DefaultButton>
            ) : null}
            {props.onCancel ? (
              <DefaultButton onClick={props.onCancel}>Cancel</DefaultButton>
            ) : null}
          </div>
        </>
      )}
    </Formik>
  );
}

AddEditNoteForm.defaultProps = {
  title: "Your note"
};
