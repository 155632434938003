import * as React from 'react';
import ResizeAware from 'react-resize-aware';
import {Overlay} from 'react-overlays';
import {RootCloseWrapper} from "./RootClose";
import {PopupBrowser, PopupBrowserProps} from "./PopupBrowser";
import classnames from 'classnames';
import {defaultConfig, PopupConfig} from "./PopupConfig";
import {NodeResolver, PopupContext} from "./PopupContext";
import {WordPopupVisual} from "./WordPopupVisual";
import {OnPageOpenedEvent} from "./parts/WordBrowserHistory";


interface WordPopupProps extends PopupBrowserProps {
  positionAt?: any,
  onRequestClose?: () => void,
  popupConfig?: PopupConfig,
  resolver?: NodeResolver
  onPageOpened?: OnPageOpenedEvent,
  zIndex?: number
};


/**
 * This component handles the "overlay" feature itself.
 * It defers the actual logic to <PopupBrowser /> and the visual style to <WordPopupVisual />.
 */
export default class WordPopup extends React.PureComponent<WordPopupProps, {}> {

  constructor(props: WordPopupProps) {
    super(props);
  }

  render() {
    return <Overlay
      show={!!this.props.nodes || !!this.props.vocable || !!this.props.initialPage}
      flip={true}
      // The rootClose actually works too, but the interaction between the
      // RootCloseWrapper and our popup event handling logic is very brittle
      // such that I prefer to have my own copy which will be stable.
      rootClose={false}
      onHide={this.props.onRequestClose}
      popperConfig={{
        modifiers: { computeStyle: { x: "top" }, offset: {offset: 50, enabled: true} }
      }}
      placement={"top"}
      container={document.body}
      target={this.getTarget}
    >
      {({ props, arrowProps, placement, scheduleUpdate }) => {
        return <div
            {...(props as any)}
          style={{
            ...props.style,
            // Must be more than the z-index: 10 used by the Seekbar.
            zIndex: this.props.zIndex ?? 20
          }}
            className={classnames("WordPopup-Wrapper")}
          >
            {/* Must be wrapped in a <div>, as <Overlay> does not support a non-DOM child. */}
            <ResizeAware
              onResize={scheduleUpdate}
            >
              <RootCloseWrapper
                onRootClose={this.props.onRequestClose}
              >
                <PopupContext.Provider value={{
                  config: this.props.popupConfig || defaultConfig,
                  resolver: this.props.resolver,
                  defaultLanguage: this.props.defaultLanguage,
                }}>
                  <WordPopupVisual>
                    <PopupBrowser {...this.props} />
                  </WordPopupVisual>
                </PopupContext.Provider>
              </RootCloseWrapper>
            </ResizeAware>
        </div>
      }}
    </Overlay>
  }

  getTarget = () => {
    if (this.props.positionAt) {
      let elem;
      if (typeof this.props.positionAt === 'function') {
        elem = this.props.positionAt();
      }
      else {
        elem = this.props.positionAt;
      }
      if (elem) {
        return elem;
      }
    }

    return document.body;
  }
}
