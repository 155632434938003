import React, { useRef, useEffect } from 'react';
import {AppBar, Button, IconButton, Toolbar} from '@material-ui/core';
import Workspace from './Workspace';
import {Captions, getNextLineIndex} from "../../models/Captions";
import {DocumentState, isUnitState, newDeprecatedUnitState, newUnitState, UnitAPI, UnitState} from "../../models/Unit";
import {PlayerAPI} from 'languagetool-player/src/ui/Video';
import {CenterButtons} from "./CenterButtons";
import {
  queryUnit,
  saveCaptionTrackVersion,
} from "../../integrations/Market/api";
import {Actions, KeyboardShortcuts} from "./KeyboardShortcuts";
import {NewUnitDialog} from "../dialogs/NewUnitDialog";
import {DialogManager} from "../dialogs/DialogManager";
import {ICaptionsEditor, useCaptionsEditor, useSlateEditor} from "../../models/ICaptionsEditor";
import {OpenUnitDialog} from "../dialogs/OpenUnitDialog";
import {AlertDialog} from "../dialogs/AlertDialog";
import {slateValueFromCaptions, slateValueToCaptions} from "../../models/Captions";
import {getSelectedLineGroupIndex} from "../../models/Captions/slateApi";
import {PromptDialog} from "../dialogs/PromptDialog";
import {RightAlignedButtons} from "./RightAlignedButtons";
import {ACTIONS} from "./Actions";
import {Assignment, Textsms} from "@material-ui/icons";
import {useLanguageToolAuth} from "../../integrations/LanguagetoolAuth";
import {MuiFormControls} from "../../components/MuiControls";
import {FormUISwitcher} from "../../components/FormUISwitch";
import {QueryUnit_unit} from "../../../types/QueryUnit";


export type UnitLoadingState = {
  isLoading: boolean
}

export type TimingCursor = {
  lineIdx: number|null,
  isAfter: boolean
};


export type Screen = 'CaptionEditor'|'WordAssign'|'CaptionSource';


export interface UIState {
  isTimingMode: boolean,
  timingCursor: TimingCursor|null,
  screen: Screen
};


export interface UserInterfaceAPI {
  jumpTimingCursor(newTimingCursor?: TimingCursor): void,
}


type State = {
  playerAPI: PlayerAPI|null,
  loadingState: UnitLoadingState,
  ui: UIState,

  // This is what happens: We send the unit + captions track "down" the tree.
  // The Slate.js editor takes the captions, wraps them in an ICaptionsEditor,
  // which is a change API + shadow value state, and sends them back up.
  editorAPI: ICaptionsEditor|null
};


type Props = {
  unit: DocumentState|null,
  unitAPI: UnitAPI
};


export default class MainUI extends React.Component<Props, State> {

  readonly state: State = {
    playerAPI: null,
    editorAPI: null,
    loadingState: {
      isLoading: false,
    },
    ui: {
      screen: 'CaptionEditor',
      isTimingMode: false,
      timingCursor: null
    }
  };

  actions: Actions<typeof ACTIONS>;
  dialogManager: DialogManager|null = null;

  uiAPI: UserInterfaceAPI = {
    jumpTimingCursor: (newTimingCursor?: TimingCursor) => {
      if (!isUnitState(this.props.unit)) {
        return;
      }

      // Ignore if timing mode is not enabled.
      if (!this.state.ui.timingCursor) {
        return;
      }

      // Set it to the given value
      if (newTimingCursor) {
        this.setUIState({timingCursor: newTimingCursor});
        return;
      }

      // Jump to the next one
      let timingCursor = {...this.state.ui.timingCursor};
      if (!timingCursor.isAfter) {
        timingCursor.isAfter = true;
      } else {
        timingCursor.isAfter = false;
        const nextLineIdx = timingCursor.lineIdx !== null ? getNextLineIndex(this.props.unit!.captions!.lines, timingCursor.lineIdx) : null;
        if (nextLineIdx) {
          timingCursor.lineIdx = nextLineIdx;
        }
        else {
          timingCursor.lineIdx = null;
        }
      }
      this.setUIState({timingCursor})
    }
  }

  constructor(props: Props) {
    super(props);

    // @ts-ignore
    this.actions = new Actions(ACTIONS, this.getActionProps);
  }

  getActionProps = () => {
    return {
      uiState: this.state.ui,
      unit: this.props.unit,
      editorAPI: this.state.editorAPI,
      playerAPI: this.state.playerAPI,
      uiAPI: this.uiAPI,
      dialogAPI: this.dialogManager
    }
  }

  render() {
    return (
      <div>
        {(isUnitState(this.props.unit)) ? <CreateCaptionsEditorAPI
          setCaptionsEditor={this.setCaptionsEditor}
          captions={this.props.unit!.captions!}
        /> : null}
        <FormUISwitcher
          uiControls={MuiFormControls}
        >
          <DialogManager
            ref={r => this.dialogManager = r}
          >
            <KeyboardShortcuts
              actions={this.actions}
              disabled={this.isKeyboardShortcutsDisabled}
            />
            <AutoLoad
              unitAPI={this.props.unitAPI}
              loadUnit={this.loadUnit}
            />
            <div id="editor-root">
              <AppBar position={"static"}>
                <Toolbar variant={"dense"}>

                  {this.props.unit
                    ? <Button color={"inherit"} onClick={this.handleCloseUnitClick}>Close</Button>
                    : <>
                        <Button color={"inherit"} onClick={this.handleNewUnitClick}>New Unit</Button>
                        <Button color={"inherit"} onClick={this.handleOpenUnitClick}>Open</Button>
                      </>
                  }
                  {this.props.unit
                    ? <Button
                        color={"inherit"}
                        disabled={!this.props.unit || !this.hasChangesNeedingSave}
                        onClick={this.handleSaveUnitClick}
                      >
                        Save
                      </Button>
                    : null}

                  <div style={{paddingRight: '10px'}}/>

                  <>
                    <IconButton color={"inherit"} onClick={this.handleSwitchToCaptionEditor}>
                      <Textsms htmlColor={this.state.ui.screen === 'CaptionEditor' ? "#ffeb3b" : undefined} />
                    </IconButton>
                    <IconButton color={"inherit"} onClick={this.handleSwitchToWordAssign}>
                      <Assignment htmlColor={this.state.ui.screen === 'WordAssign' ? "#ffeb3b" : undefined} />
                    </IconButton>
                  </>

                  <div style={{flexGrow: 1}}/>

                  {this.props.unit
                    ? <CenterButtons
                        actions={this.actions}
                        editorAPI={this.state.editorAPI}
                        playerAPI={this.state.playerAPI}
                        uiState={this.state.ui}
                        onModeChanged={this.handleModeChanged}
                      />
                    : null
                  }
                  <div style={{flexGrow: 1}}/>
                  <RightAlignedButtons
                    unitAPI={this.props.unitAPI}
                    uiState={this.state.ui}
                    unit={this.props.unit}
                  />
                </Toolbar>
              </AppBar>

              <div className="main-container">
                <Workspace
                  unit={this.props.unit}
                  onPlayerApiReady={this.handlePlayerApiReady}
                  unitAPI={this.props.unitAPI}
                  playerAPI={this.state.playerAPI}
                  uiAPI={this.uiAPI}
                  uiState={this.state.ui}
                  captionsEditor={this.state.editorAPI}
                  isLoading={this.state.loadingState.isLoading}
                />
              </div>
            </div>
          </DialogManager>
        </FormUISwitcher>
      </div>
    );
  }

  handleSwitchToCaptionEditor = () => {
    this.setUIState({screen: "CaptionEditor"});
  }

  handleSwitchToWordAssign = (e: any) => {
    if (e.shiftKey) {
      this.setUIState({screen: "CaptionSource"});
    } else {
      this.setUIState({screen: "WordAssign"});
    }
  }

  isKeyboardShortcutsDisabled = (): boolean => {
    if (!this.dialogManager) {
      return false;
    }
    return this.dialogManager.isDialogVisible();
  }

  setCaptionsEditor = (editor: ICaptionsEditor|null) => {
    this.setState({editorAPI: editor});
  }

  handleModeChanged = (isTiming: boolean, whichLine: 'first'|'selected') => {
    if (!isUnitState(this.props.unit)) {
      return;
    }

    if (!isTiming) {
      this.setUIState({isTimingMode: false, timingCursor: null});
    }
    else {

      let startLine;

      if (whichLine === 'selected') {
        startLine = getSelectedLineGroupIndex(this.state.editorAPI!.slateEditor, 0)!;
      } else {
        startLine = 0;
      };

      this.setUIState({
        isTimingMode: true,
        timingCursor: {
          lineIdx: startLine,
          isAfter: false,
        }
      });

      const {time: lineTime} = this.props.unit!.captions!.lines[startLine].data;
      if (lineTime) {
        this.state.playerAPI!.seekTo(Math.max(0, lineTime-10));
      }
    }
  }

  setUIState(change: Partial<UIState>) {
    this.setState({
      ...this.state,
      ui: {
        ...this.state.ui,
        ...change
      }
    })
  }

  showDialog(DialogClass: any, onSuccessCallbackorProps?: any) {
    if (!this.dialogManager) {
      return;
    }
    this.dialogManager.show(DialogClass, onSuccessCallbackorProps);
  }

  handlePlayerApiReady = (api: PlayerAPI|null) => {
    this.setState({playerAPI: api});
  }

  handleNewUnitClick = async () => {
    this.showDialog(NewUnitDialog, ({language}: any) => {
      const unit = newDeprecatedUnitState({language});
      this.props.unitAPI.loadUnit(unit);
    });
  }

  handleSaveUnitClick = async () => {
    const {unit} = this.props;
    if (!unit) {
      return;
    }

    // These new states never save the unit, always the document. The unit will be created from within
    // the market instead.
    if (!isUnitState(unit)) {
      await this.saveInline();
      return;
    }

    // Unit was never saved, first, create an id for it.
    if (!unit.id) {
      throw new Error("Currently no saving method is supported.")
      // this.showDialog(SaveUnitDialog, async (unitData: UnitProperties) => {
      //   // this.props.unitAPI.setUnitProps({id: unitId});
      //   // await this.saveInline();
      // });
    }
    else {
      await this.saveInline();
    }
  }

  // Save without a dialog.
  // Obv. this is market specific, and should only run if the unit is bound to the market.
  saveInline = async () => {
    const {unit} = this.props;
    if (!unit) {
      return;
    }

    try {
      if (!isUnitState(unit)) {
        const {id: newId} = await saveCaptionTrackVersion(unit.id!, JSON.stringify(unit.data));
        this.props.unitAPI.setUnitProps({id: newId});
      }

      else {
        const unitId = unit.id!;

        // Are there captions?
        const captions = unit.captions;
        if (captions) {
          const data = slateValueToCaptions(captions!);

          // Save any changes
          const {id: newId} = await saveCaptionTrackVersion(captions.versionId, JSON.stringify(data));
          captions.versionId = newId;
        }
      }
    }
    catch (e) {
      alert("Saving failed!")
      throw e;
    }


    this.props.unitAPI.markUnitSaved();
    this.forceUpdate();
  }

  // Has to match what "handleSaveUnitClick" actually does.
  get hasChangesNeedingSave() {
    const {unit} = this.props;

    if (!unit) {
      return false;
    }

    if (!isUnitState(unit)) {
      if (unit.hasChanged) {
        return true;
      }

      return false;
    }

    // Has an unsaved media track.
    if (unit.media && !unit.media.id) {
      return true;
    }

    // Has an unsaved caption track.
    if (unit.captions && !unit.captions.id) {
      return true;
    }

    if (unit.hasCaptionsChanged) {
      return true;
    }
  }

  handleOpenUnitClick = async () => {
    this.showDialog(OpenUnitDialog, ({unitId}: any) => {
      return this.loadUnit(unitId!);
    });
  }

  loadUnit = async (unitId: string) => {
    this.setState({loadingState: {isLoading: true}});
    try {
      const unit = await queryUnit(unitId!);
      if (!unit) {
        this.showDialog(AlertDialog, {title: 'Error', message: 'The desired unit could not be loaded.'});
        return;
      }

      // short cut to deal with the new-type unit state.
      if (unit.captionTrack?.format == 'simple-picture-book') {
        if (!unit.captionTrack.latestVersion?.id) {
          throw new Error("Cannot open if no version exists...")
        }
        this.props.unitAPI.loadUnit(newUnitState({
          id: unit.captionTrack.latestVersion?.id,
          unitId: unit.id,
          format: unit.captionTrack.format,
          files: unit.container?.files,
          language: unit.captionTrack.language || "en",
          data: JSON.parse(unit.captionTrack.latestVersion?.data!)
        }));
        return;
      }

      let language;

      let captions: Captions | undefined;
      if (unit.captionTrack) {
        captions = getCaptionsFromLoadedUnit(unit);
        language = unit.captionTrack.language;
      }

      // If there is a media track, use it.
      let mediaTrack;
      const remoteMediaTrack = unit.mediaTrack;
      if (remoteMediaTrack) {
        mediaTrack = {
          id: remoteMediaTrack.id!,
          url: remoteMediaTrack.url!,
          type: remoteMediaTrack.type!,
        };
        language = remoteMediaTrack.language;
      }

      if (!language) {
        throw new Error('Unable to determine the unit language.')
      }

      this.props.unitAPI.loadUnit(newDeprecatedUnitState({
        id: unit.id,
        language,
        captions,
        media: mediaTrack,
        translation: JSON.parse(unit.captionTrack?.latestVersion?.translations?.[0]?.data ?? "null")
      }));
    }
    finally {
      this.setState({loadingState: {isLoading: false}});
    }
  }

  handleCloseUnitClick = () => {
    if (!this.hasChangesNeedingSave) {
      this.props.unitAPI.closeUnit();
      return;
    }

    this.showDialog(PromptDialog, {
      title: 'Unsaved changes',
      message: 'There are unsaved changes. Do you really want to close without saving? These changes will be lost.',
      buttons: [
        {label: "Yes, close", id: 'close', destructive: true, primary: true},
        {label: "No, do not close", id: 'abort'},
      ],
      onButtonClick: ({id}: any) => {
        if (id === 'close') {
          this.props.unitAPI.closeUnit();
        }
      }
    });
  }
};


// A workaround to let us use the hook-based API to create the editor.
function CreateCaptionsEditorAPI(props: {
  setCaptionsEditor: (editor: ICaptionsEditor|null) => void,
  captions: Captions,
}) {
  const slateEditor = useSlateEditor({idGen: props.captions.idGen}, [props.captions.idGen]);
  const editor = useCaptionsEditor(props.captions, slateEditor);

  useEffect(() => {
    props.setCaptionsEditor(editor);
    return () => {
      props.setCaptionsEditor(null);
    }
  }, [props.setCaptionsEditor, editor]);

  return null;
}


// Essentially a way to let us use hooks here, despite the <MainScreen /> component being a class component.
function AutoLoad(props: {
  loadUnit: any,
  unitAPI: UnitAPI
}) {
  const {loadUnit, unitAPI} = props;
  const hasRun = useRef(false);
  const {isAuthenticated, loading} = useLanguageToolAuth();

  // The first time after we finished loading the auth
  useEffect(() => {
    if (hasRun.current) {
      return;
    }

    if (loading) {
      return;
    }

    hasRun.current = true;

    const urlParams = new URLSearchParams(window.location.search);
    const unitIdToLoad = urlParams.get('load');
    if (unitIdToLoad) {
      loadUnit(unitIdToLoad);
    }
    else if (urlParams.has('new')) {
      const url = urlParams.get('new');
      const media = url ? {type: 'url', id: "", url} : undefined;
      const unit = newDeprecatedUnitState({language: 'fa', media});
      unitAPI.loadUnit(unit);
    }
  }, [isAuthenticated, unitAPI, loadUnit, loading])

  return null;
}


export function getCaptionsFromLoadedUnit(unit: QueryUnit_unit): Captions {
  const captionTrack = unit.captionTrack;
  if (!captionTrack || !captionTrack.latestVersion) {
    throw new Error("no caption track in this unit")
  }
  const data = captionTrack.latestVersion.data ? JSON.parse(captionTrack.latestVersion.data) : null;
  const captions = data ? Captions.fromJSON(data) : new Captions();
  captions.id = captionTrack.id!;
  captions.versionId = captionTrack.latestVersion!.id;
  return captions;
}