import {default as React, useEffect, useMemo, useState, createContext, useContext} from "react";
import {RecordingItemDefinition} from "./ListControl";
import {NextWordsInSession_recordingSession_items_existingRecordings} from "../../types/NextWordsInSession";
import {useNewPromise} from "../../utils/useNewPromise";
import loglevel from 'loglevel';


const log = loglevel.getLogger("RecordingWorkflow/usePlayer");
log.setLevel("DEBUG");


function useAudioElement(deps?: any): [HTMLAudioElement, boolean] {
  const [state, setState] = useState<boolean>(false);

  const audio = React.useMemo(() => {
    return new window.Audio();
  }, []);

  useEffect(() => {
    audio.onplay = () => setState(true);
    audio.onpause = () => setState(false);
  }, [audio]);

  return [audio, state];
}


export function usePlayer(blob: Blob | string | null): [HTMLAudioElement | null, boolean] {
  const [audioElement, state] = useAudioElement();

  useEffect(() => {
    if (!blob) {
      audioElement.src = "";
      return;
    }

    const url = typeof blob === 'string' ? blob : window.URL.createObjectURL(blob);
    audioElement.src = url;

    if (typeof blob !== 'string') {
      log.debug("Setting up player with blob of size: ", blob.size);
    }

    return () => {
      if (typeof blob === 'string') {
        return
      }
      ;
      window.URL.revokeObjectURL(url);
    }
  }, [blob, audioElement]);

  return [blob ? audioElement : null, state];
}


export function useDelayedPlayer(): [any, boolean] {
  const [audioElement, state] = useAudioElement();
  const playPromise = useNewPromise();

  useEffect(() => {
    if (!state) {
      playPromise.resolve();
    }
  }, [state, playPromise]);

  const intf = useMemo(() => {
    return {
      play: async (url: string) => {
        audioElement.src = url!;
        audioElement.load();
        await audioElement.play();
        return playPromise.makeNew({reject: true});
      },
      pause: () => {
        audioElement.pause();
      }
    }
  }, [audioElement, playPromise])

  return [intf, state];
}


export type PlayerInterface = {
  play: (id: string, url: string) => Promise<void>,
  playRecording: (recording: NextWordsInSession_recordingSession_items_existingRecordings) => Promise<void>,
  pause: () => void,
  isPlaying: boolean,
  currentId: string,
  preferredType: 'original'|'processed'
}

const PlayerManagerContext = createContext<PlayerInterface|null>(null);


/**
 * Allow multiple children to control a single player state.
 */

export const PlayerManager = (props: {
  children: any,
  preferredType: 'original'|'processed'
}) => {
  const [player, isPlaying] = useDelayedPlayer();
  const [fileId, setFileId] = useState<any>(null);

  const playRaw = async (id: string, url: string) => {
    setFileId(id);
    return player.play(url);
  };

  const playRecording = async (item: NextWordsInSession_recordingSession_items_existingRecordings) => {
    const url = props.preferredType == 'processed' ? item.urlProcessed : item.url;
    return playRaw(item.id, url);
  }

  const value: PlayerInterface = useMemo(() => {
    return {
      currentId: fileId,
      play: playRaw,
      playRecording: playRecording,
      pause: () => {
        player.pause()
      },
      isPlaying,
      preferredType: props.preferredType
    }
  }, [isPlaying, player, setFileId]);

  return <PlayerManagerContext.Provider value={value}>
    {props.children}
  </PlayerManagerContext.Provider>
}


export function usePlayerManagerContext() {
  return useContext(PlayerManagerContext);
}


export function useManagedPlayer(
  recording: {id: string, url: string, urlProcessed: string}
) {
  const intf = useContext(PlayerManagerContext);

  return {
    isPlaying: intf?.currentId == recording.id && intf?.isPlaying,
    pause: () => {
      intf?.pause();
    },
    play: () => {
      const url = intf?.preferredType == 'processed' ? recording.urlProcessed : recording.url;
      intf?.play(recording.id, url);
    }
  }
}


/**
 * Return a function that plays all recordings of an item.
 */
export function usePlayAllFromItem() {
  const player = usePlayerManagerContext();

  return async (item: RecordingItemDefinition) => {
    for (const r of item.existingRecordings) {
      if (r.isRejected) {
        continue;
      }
      try {
        await player?.playRecording(r);
      }
      catch (e) {
        break;
      }
    }
  }
}
