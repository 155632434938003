// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-MainScreen-Workspace__workspace--2vP0F{flex:1;display:flex;flex-direction:column;position:relative}._-languagetool-editor-src-js-ui-MainScreen-Workspace__workspaceEmpty--3rb-5{justify-content:center;align-items:center;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"workspace": "_-languagetool-editor-src-js-ui-MainScreen-Workspace__workspace--2vP0F",
	"workspaceEmpty": "_-languagetool-editor-src-js-ui-MainScreen-Workspace__workspaceEmpty--3rb-5 _-languagetool-editor-src-js-ui-MainScreen-Workspace__workspace--2vP0F"
};
module.exports = exports;
