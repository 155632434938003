import Cldr from 'cldrjs';

Cldr.load(
  require("cldr-data/supplemental/likelySubtags.json"),
  require("cldr-data/main/en/languages")
)


var cldr = new Cldr('en');

// Fetch data
var localeDisplayNames = cldr.main('localeDisplayNames').languages;

export function getLanguageDisplayName(locale: string) {
  // TODO: Cache for speed, or copy the code from cldrjs, which just reads
  // the language from the locale string, using likelySubtags.
  var langCode = new Cldr(locale).attributes.language;
  return localeDisplayNames[langCode];
}

export * from './i18n.shared';