import React, { Fragment } from "react";
import {Link} from "@material-ui/core";
import {NestableField, useNestedFieldToken} from "../../../../../../components/NestedFormik";
import {NestedForm} from "../NestedForm";
import {FarsiWordForm, FarsiWordFormConfig} from "../WordFormAndLogic";
import {isWordRefEnabled} from "../FormValues";
import {PickWordInput} from "./PickWordInput";


export function PickOrCreateWorldField(props: {
  name: string,
  label: string,
  helperText: string,

  // This is the config for a nested word form.
  config: FarsiWordFormConfig,

  // Allow extra action links
  actionLinks?: any[]
}) {
  // Where the wordref is stored
  const idField = `${props.name}.id`;

  const [value, setValue] = useNestedFieldToken(idField);
  const isNewWord = !isWordRefEnabled(value);

  if (isNewWord) {
    return <NestedForm
      title={<>
        {props.label}
        &nbsp;&nbsp;
        <RenderActionLinks
          links={[
            <><Link href={""} onClick={(e: any) => { e.preventDefault(); setValue(true) }}>Search existing word</Link></>,
            ...(props.actionLinks || [])
          ]}
        />
      </>}
      prefix={props.name}
    >
      <FarsiWordForm
        config={props.config}
      />
    </NestedForm>
  }

  // Because mui-downshift does not allow us to set the label.
  return <div className={"MuiFormControl-marginNormal"}>
    <NestableField
      name={idField}
      label={props.label}
      helperText={props.helperText}

      component={PickWordInput}
      queryOptions={props.config.disallowCompoundVerb ? ["exclude-compound-verbs"] : undefined}
      allowedWordTypes={props.config.allowedWordTypes}
      disallowedWordTypes={props.config.disallowedWordTypes}
    />

    <div style={{marginTop: '0.5em'}}>
      <RenderActionLinks
        links={[
          <>Not Found? <Link href={""} onClick={(e: any) => { e.preventDefault(); setValue(false) }}>Create New Word</Link></>,
          ...(props.actionLinks || [])
        ]}
      />
    </div>
  </div>
}


function RenderActionLinks(props: {
  links: any[]
}) {
  let links = props.links.filter(l => !!l);
  return <span>{React.Children.map(links, (link, idx)=> {
    return <Fragment key={idx}>
      {link}
      {idx < links.length-1 ? <span>&nbsp;&nbsp;&nbsp;</span> : null}
    </Fragment>
  })}</span>;
}