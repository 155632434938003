// is-hotkey is great. It would be nice to have an extension that handles
// the stateful part of the high-level libraries.
//
// Best react lib seems to be: https://github.com/greena13/react-hotkeys

import * as React from "react";
import {PlayerAPI} from 'languagetool-player/src/ui/Video';
import {UnitState} from "../../models/Unit";
import {UIState, UserInterfaceAPI} from "./index";
import {ICaptionsEditor} from "../../models/ICaptionsEditor";
import isHotkey from 'is-hotkey'
import {ContextAPI} from "../dialogs/DialogManager";


export type Action<T> = {
  name?: string,
  trigger: string[],
  func: (props: T, extra: any) => (typeof UNHANDLED|void),
  count?: boolean
};


export class Actions<P> {
  public getProps: () => P;
  private actionMap: {[key: string]: Action<P>};
  private actions: Action<P>[];

  constructor(
    actions: Action<P>[],
    getProps: () => P
  ) {
    this.getProps = getProps;
    this.actions = actions;
    this.actionMap = {};
    actions.forEach(action => {
      if (!action.name) {
        return;
      }
      this.actionMap[action.name] = action;
    })
  }

  getActions(): Action<P>[] {
    return this.actions;
  }

  execute(name: string) {
    const shortcut = this.actionMap[name];
    if (!shortcut) {
      return;
    }
    shortcut.func(this.getProps(), {count: 1});
  }
}


type Props<A> = {
  actions: Actions<A>,
  disabled?: boolean|(() => boolean)
};


export const UNHANDLED = Symbol('unhandled');


export class KeyboardShortcuts<A> extends React.Component<Props<A>> {

  componentDidMount(): void {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  render() {
    return null;
  }

  get isDisabled() {
    if (typeof this.props.disabled === 'function') {
      return this.props.disabled();
    }
    return this.props.disabled;
  }

  keyCounter = new KeyCounter();

  handleKeyDown = (e: KeyboardEvent) => {
    if (this.isDisabled) {
      return;
    }

    for (const shortcut of this.props.actions.getActions()) {
      let matchedKeySeq: string|null = null;
      for (const candidateSeq of shortcut.trigger) {
        if (isHotkey(candidateSeq)(e)) {
          matchedKeySeq = candidateSeq;
          break;
        }
      }

      if (matchedKeySeq) {
        const commandProps = this.props.actions.getProps();
        // Such a sequence is not executed directly, but sent through the debouncer.
        let continueProcessing = false;
        if (shortcut.count) {
          this.keyCounter.action(
            matchedKeySeq,
            250,
            (count) => shortcut.func(commandProps, {count}))
          continueProcessing = false;
        }
        else {
          continueProcessing = shortcut.func(commandProps, null) === UNHANDLED;
        }

        if (!continueProcessing) {
          e.preventDefault();
          e.stopPropagation();
        }

        break;
      }
    }
  }
}


type KeyCounterItem = {
  count: number,
  lastTime: number,
  debounceMs: number,
  timeoutEvent?: any
};

type KeyCounterCallback = (count: number) => void;


/**
 * A helper which can "debounce" keyboard sequences, it allows you
 * to handle a shortcut which can support a key to be pressed multiple
 * times in short order.
 */
class KeyCounter {

  private actions: Map<string, KeyCounterItem>

  constructor() {
    this.actions = new Map();
  }

  action(key: string, debounceMs: number, callback: KeyCounterCallback) {
    const item = this.actions.get(key);
    const now = new Date().getTime();

    let useExisting = false;
    if (item) {
      // Use the existing item if the timeout has not triggered yet,
      // and the ms debounce threshold has not been exceeded.
      useExisting = (now - item.lastTime < item.debounceMs) && item.timeoutEvent;
    }

    if (!useExisting || !item) {
      const item = {
        lastTime: new Date().getTime(),
        count: 1,
        debounceMs: debounceMs
      };
      this.actions.set(key, item);
      this.scheduleTimeout(item, callback);
      return;
    }

    item.lastTime = now;
    item.count = item.count + 1;
    this.scheduleTimeout(item, callback);
  }

  private scheduleTimeout(item: KeyCounterItem, callback: KeyCounterCallback) {
    if (item.timeoutEvent) {
      window.clearTimeout(item.timeoutEvent);
    }
    item.timeoutEvent = window.setTimeout(() => {
      delete item.timeoutEvent;
      callback(item.count)
    }, item.debounceMs);
  }
}
