import {NestableField, useNestedFieldToken} from "../../../../../../components/NestedFormik";
import React from "react";
import {useUIControls} from "../../../../../../components/FormUISwitch";
import {handleCommaSeparated} from "../WordFormAndLogic";


enum Sound {
  long_a = "long_a",
  long_u = "long_u",
  long_i = "long_i",
  short_a = "short_a",
  short_e = "short_e",
  short_o = "short_o",
  diph_ow = "diph_ow",
  diph_ey = "diph_ey",
  diph_ay = "diph_ay",

  // Consonants
  be = "be",
  pe = "pe",
  te = "te",
  se = "se",
  jim = "jim",
  ce = "ce",
  he_jimi = "he_jimi",
  xe = "xe",
  dal = "dal",
  zal = "zal",
  re = "re",
  ze = "ze",
  ge = "ge",
  sin = "sin",
  shin = "shin",
  sad = "sad",
  zad = "zad",
  ta = "ta",
  za = "za",
  qein = "qein",
  ayn = "ayn",
  fe = "fe",
  qaf = "qaf",
  kaf = "kaf",
  gaf = "gaf",
  lam = "lam",
  mim = "mim",
  nun = "nun",
  vav = "vav",
  he_do_cesm = "he_do_cesm",
  ye = "ye",
  vav_silent = "vav_silent",
  tanvine_nasb = "tanvine_nasb",
  short_alef = "short_alef",
  final_e = "final_e",
  hamze = "hamze",
}


enum NonSoundElement {
  word_separator = "word_separator",
  dash = "dash",
  breakpoint = "breakpoint",
}


const AllElements = {
  ...NonSoundElement,
  ...Sound
}

function validateSounds(value: string) {
  const list = handleCommaSeparated(value);
  if (!list) { return; }

  for (const item of list) {
    if (!AllElements[item as Sound]) {
      return `${item} is not a valid value.`
    }
  }
}

export function SoundsField(props: {
  defaultSounds?: string[]
}) {
  const UI = useUIControls();
  const [value, setValue] = useNestedFieldToken("sounds");
  const {defaultSounds, ...otherProps} = props;

  return <NestableField
    name={"sounds"}
    validate={validateSounds}
    component={UI.Text}

    label="Sounds"
    description={<span>
      Manually define the sounds.
      {" "}
      {props.defaultSounds ? <a href={"#"} onClick={e => {
          e.preventDefault();
          setValue(props.defaultSounds!.join(', '))
        }}>Set to autogenerated.</a> : null}
    </span>}

    {...otherProps}
  />;
}