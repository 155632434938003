import {Vocable} from "languagetool-player/src/models";
import {isWordInline, WordInline} from "../../models/Captions/types";
import {Node, Text} from 'slate';
import {forceNative} from "../../utils";


/**
 * A list of vocables is used by multiple formats. Share the code that parses them into a structure we can use
 * with the Slate.js text editor.
 */
export function parseVocableListToLine(elements: Vocable[]) {
  const listOfLineContents: (WordInline|Text)[] = [];

  for (const vocable of elements) {

    const inline: WordInline = {
      key: vocable.id,
      type: 'timed',
      data: {
        time: vocable.time,
        duration: vocable.duration,
        alternates: vocable.alternates
      },
      children: [
        {
          text: vocable.text || ""
        }
      ]
    };

    let preText;
    let postText;

    if (vocable.pre) {
      preText = {text: vocable.pre || ""};
    };

    // Always attach a post text, because Slate requires them between inline blocks, even if empty,
    // and the normalization will take *ages* if they are missing for a big document. At least that used
    // to be the case pre 0.50, not sure about now.
    postText = {text: vocable.post || ""};

    if (preText) {
      listOfLineContents.push(preText);
    }
    listOfLineContents.push(inline);
    listOfLineContents.push(postText)
  }

  return listOfLineContents.length ? listOfLineContents : [{text: ""}];
}


export function serializeLineToVocableList(line: Node[]) {
  const vocables: Vocable[] = [];
  let lastVocable: any = null;
  let collectedPre: string = '';

  for (const inline of line) {
    const type = inline.type;

    if ("text" in inline) {
      // Exclude any empty text nodes
      if (inline.text === '') {
        continue;
      }

      if (lastVocable) {
        if (!lastVocable.post) {
          lastVocable.post = '';
        }
        lastVocable.post = lastVocable.post + inline.text;
      } else {
        // add to pre
        collectedPre = collectedPre + inline.text;
      }
    }
    else if (isWordInline(inline)) {
      const newVocable: Vocable = {
        id: inline.key,
        text: Node.string(inline),
        time: inline.data.time,
        duration: inline.data.duration,
        alternates: forceNative(inline.data.alternates)
      };
      if (collectedPre.length) {
        newVocable.pre = collectedPre;
        collectedPre = '';
      }
      lastVocable = newVocable;
      vocables.push(newVocable);
    } else {
      throw new Error(`Unexpected node inside line: type=${type}`);
    }
  }

  return vocables;
}