/**
 * From: https://github.com/hackingbeauty/react-mic/blob/master/src/libs/Visualizer.js
 */

import {useMemo} from "react";


export function useAudioContext() {
  return useMemo(() => new (window.AudioContext || (window as any).webkitAudioContext)(), []);
}


export function makeVisualizeSineWave(canvas: HTMLCanvasElement, analyser: any, opts: {backgroundColor: string, strokeColor: string}) {
  const bufferLength = analyser.fftSize;
  const dataArray = new Uint8Array(bufferLength);
  const canvasCtx = canvas.getContext("2d")!;
  const width = canvas.width;
  const height = canvas.height;

  function draw() {
    analyser.getByteTimeDomainData(dataArray);

    canvasCtx.fillStyle = opts.backgroundColor;
    canvasCtx.fillRect(0, 0, width, height);

    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = opts.strokeColor;

    canvasCtx.beginPath();

    const sliceWidth = width * 1.0 / bufferLength;
    let x = 0;

    for (let i = 0; i < bufferLength; i++) {
      const v = dataArray[i] / 128.0;
      const y = v * height / 2;

      if (i === 0) {
        canvasCtx.moveTo(x, y);
      } else {
        canvasCtx.lineTo(x, y);
      }

      x += sliceWidth;
    }

    canvasCtx.lineTo(width, height / 2);
    canvasCtx.stroke();
  };

  return draw;
}


export function makeFrequencyBars(canvas: HTMLCanvasElement, analyser: any, opts: {backgroundColor: string, strokeColor: string}) {
  ///analyser.fftSize = 256;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);
  const canvasCtx = canvas.getContext("2d")!;
  const width = canvas.width;
  const height = canvas.height;

  function draw() {
    analyser.getByteFrequencyData(dataArray);

    canvasCtx.fillStyle = opts.backgroundColor;
    canvasCtx.clearRect(0, 0, width, height);

    const barWidth = (width / bufferLength) * 2.5;
    let barHeight;
    let x = 0;

    for(let i = 0; i < bufferLength; i++) {
      barHeight = dataArray[i];

      const rgb = hexToRgb(opts.strokeColor);

      // canvasCtx.fillStyle = `rgb(${barHeight+100},${rgb.g},${rgb.b})`;
      canvasCtx.fillStyle = opts.strokeColor;
      canvasCtx.fillRect(x,height-barHeight/2,barWidth,barHeight/2);

      x += barWidth + 1;
    }
  };

  return draw;
}


export function makeFrequencyCircles(canvas: HTMLCanvasElement, analyser: any, opts: {backgroundColor: string, strokeColor: string}) {
  //analyser.fftSize = 32;
  const bufferLength = analyser.frequencyBinCount;
  const dataArray = new Uint8Array(bufferLength);

  const canvasCtx = canvas.getContext("2d")!;
  const width = canvas.width;
  const height = canvas.height;

  function draw() {
    analyser.getByteFrequencyData(dataArray);
    const reductionAmount = 3;
    const reducedDataArray = new Uint8Array(bufferLength / reductionAmount);

    for (let i = 0; i < bufferLength; i += reductionAmount) {
      let sum = 0;
      for (let j = 0; j < reductionAmount; j++) {
        sum += dataArray[i + j];
      }
      reducedDataArray[i/reductionAmount] = sum / reductionAmount;
    }

    //canvasCtx.clearRect(0, 0, width, height);
    canvasCtx.beginPath();
    canvasCtx.arc(width / 2, height / 2, Math.min(height, width) / 2, 0, 2 * Math.PI);
    canvasCtx.fillStyle = opts.backgroundColor;
    canvasCtx.fill();
    const stepSize = (Math.min(height, width) / 2.0) / (reducedDataArray.length);
    canvasCtx.strokeStyle = opts.strokeColor;

    for (let i = 0; i < reducedDataArray.length; i++) {
      canvasCtx.beginPath();
      const normalized = reducedDataArray[i] / 128;
      const r = (stepSize * i) + (stepSize * normalized);
      canvasCtx.arc(width / 2, height / 2, r, 0, 2 * Math.PI);
      canvasCtx.stroke();
    }
  };

  return draw;
}


function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}