import styles from "./index.cssm";
import {useKey} from 'react-use'
import classnames from "classnames";
import {CheckCircle} from "@material-ui/icons";
import {NodeOptionPicker} from "./NodeOptionPicker";
import React, {useCallback, useState} from "react";
import {Button} from "@material-ui/core";
import {StructureNode} from "languagetool-player/src/models/Node";
import {Manager, Reference, Popper} from 'react-popper';
import {Cell, HeaderRow, Row} from "../LineByLineView";
import {isEqual} from 'lodash';
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {AnalyzeResult} from "../wordsApi";
import {getAlternatesText, getVocableText} from "languagetool-player/src/models/getVocableText";
import {EditableText} from "./EditableText";
import {CreateIssueButton} from "./CreateIssueButton";
import {IndexedContentIssues} from "../../Market/ContentIssues";
import {WordNode} from "languagetool-player/src/models/Node";
import {VocableId} from "languagetool-player/src/models/formats/CaptionTrack";
import {TrackInterface} from "./index";


type Props = {
  language: string,
  trackAPI: TrackInterface,
  trackVersionId?: string,
  lineIndex: number,
  words: { id: any; text: any; alternates?: any }[],
  nodeSuggestions: {[key: string]: AnalyzeResult[]},
  forcedVocableMerges: Set<string>[],
  isDataLoaded: boolean,
  contentIssues?: IndexedContentIssues

  onCreateWordClick: (word: string, vocableIds: VocableId[]) => void,
  onEditWordClick: (wordId: number, vocableText: string) => void,
  onOptionSelected: (vocableIds: VocableId[], option: AnalyzeResult|null) => void,
  onMergeVocables: (vocableIds: Set<string>) => void,
  onSplitVocables: (vocableIds: Set<string>) => void,
  onVocableTextChange: (vocableId: string, text: string) => void,
  onAssignCustomNode: (vocableIds: VocableId[], node: WordNode) => void
}


export function LineTable(props: Props) {
  const {nodeSuggestions, language, isDataLoaded, words, trackAPI} = props;

  const [showJSON, setShowJSON] = useState(false);
  useKey('F5', () => {
    setShowJSON(state => !state);
  }, undefined, [setShowJSON]);

  const [selectedForMerge, setSelectedForMerge] = useState<Set<string>>(new Set());
  const firstToBeMergedVocable = Array.from(selectedForMerge)[0];

  const handleNameCellClick = useCallback((e: any, id: string) => {
    if (e.shiftKey) {
      if (selectedForMerge.has(id)) {
        const newSelected = new Set(selectedForMerge);
        newSelected.delete(id);
        setSelectedForMerge(newSelected);
      }
      else {
        setSelectedForMerge(new Set([id, ...selectedForMerge]));
      }
    }
    else {
      setSelectedForMerge(new Set([id]));
    }
  }, [setSelectedForMerge, selectedForMerge]);

  const handleMergeWords = useCallback(() => {
    props.onMergeVocables(selectedForMerge);
    setSelectedForMerge(new Set());
  }, [selectedForMerge, props.onMergeVocables, setSelectedForMerge]);

  return <Manager>
    <div style={{display: 'table', width: '100%'}}>
      <HeaderRow>
        <div style={{display: 'table-cell'}}>
          Word in Text
        </div>
        <div style={{display: 'table-cell'}}>
          Possible Grammar
        </div>
      </HeaderRow>

      {iterateProspectiveWordNodes({
        vocables: words,
        trackAPI,
        forcedVocableMerges: props.forcedVocableMerges
      }).map((potentialWordNode, idx) => {

        let nodes: AnalyzeResult[] = [];
        let currentWordNode: StructureNode|null = null;

        if (potentialWordNode.type === 'refer') {
          throw new Error('foo');
        }

        const vocable = potentialWordNode.vocable;

        let showSplitButton = false;
        let vocables: Vocable[] = [];

        // For a regular vocable, show word node suggestions + the current word node.
        if (potentialWordNode.type === 'vocable') {
          nodes = nodeSuggestions[potentialWordNode.vocable.id];
          currentWordNode = trackAPI.getWordNodeForVocables([potentialWordNode.vocable.id]);
          vocables = [potentialWordNode.vocable];
        }

        // TODO: Needs a special refer-target mode? Show the non-merged text, but the merged nodes and the split button?
        else if (potentialWordNode.type === 'merged') {
          const vocableIds = potentialWordNode.vocables.map(w => w.id);
          currentWordNode = trackAPI.getWordNodeForVocables(vocableIds);
          showSplitButton = true;
          nodes = nodeSuggestions[potentialWordNode.vocable.id];
          nodes = nodes ? nodes.filter(result => {
            // The suggestion has to be valid for all the merged vocables, or will not be shown
            return isEqual(new Set(result.node.vocables), new Set(vocableIds))
          }) : [];
          vocables = potentialWordNode.vocables;
        }

        // The text we display
        let textToDisplay = vocables.map(v => v.text).join(" ");

        return <Row
          className={styles.tableRow}
          key={idx}
          style={{backgroundColor: selectedForMerge.has(vocable.id) ? '#e8eaf6' : ''}}
        >
          <VocableCell
            vocable={vocable}
            lineIndex={props.lineIndex}
            trackVersionId={props.trackVersionId}
            currentWordNode={currentWordNode}
            vocables={vocables}
            onClick={handleNameCellClick}
            onChangeText={props.onVocableTextChange}
            onSplitVocables={() => {
              if (!potentialWordNode || potentialWordNode.type !== 'merged') {
                return;
              }
              props.onSplitVocables(new Set(potentialWordNode.vocables.map(v => v.id)));
            }}
            showSplitButton={showSplitButton}
            firstToBeMergedVocable={firstToBeMergedVocable}
            contentIssues={props.contentIssues}
          />

          <Cell className={styles.choicesCell}>
            {isDataLoaded
              ? <NodeOptionPicker
                  suggestedNodes={nodes}
                  currentNode={currentWordNode}
                  vocables={vocables}
                  vocableText={textToDisplay}
                  language={language}
                  onCreateWordClick={props.onCreateWordClick}
                  onEditWordClick={props.onEditWordClick}
                  onOptionSelected={props.onOptionSelected}
                  onAssignCustomNode={props.onAssignCustomNode}
                  showJSON={showJSON}
                />
              : <div style={{backgroundColor: 'silver', width: '150px', height: '1em'}} />
            }
          </Cell>
        </Row>
      })}
    </div>

    {selectedForMerge.size > 1
      ? <Popper placement="right">

        {({ ref, style, placement, arrowProps }) => {
          return <div ref={ref} style={style} data-placement={placement} data-foo={"test"}>
            <Button
              color={"primary"}
              variant="contained"
              onClick={handleMergeWords}
            >
              Merge {selectedForMerge.size} Words
            </Button>
          </div>
        }}
        </Popper>
      : null}
  </Manager>;
}


/**
 * Renders the vocable from the text which we want to assign. The left column.
 */
function VocableCell(props: {
  vocable: Vocable,
  vocables: Vocable[],
  currentWordNode: StructureNode|null,
  firstToBeMergedVocable?: string,
  showSplitButton?: boolean,
  lineIndex: number,
  trackVersionId?: string,
  onClick: (e: any, vocableId: string) => void,
  onChangeText: (vocableId: string, text: string) => void,
  onSplitVocables: () => void,
  contentIssues?: IndexedContentIssues
}) {
  const {vocable, vocables, currentWordNode, firstToBeMergedVocable, showSplitButton} = props;

  // The text we display
  let textToDisplay = vocables.map(v => v.text).join(" ");

  // Generate a diacritic-auto and roman version
  let diacriticVersion = vocables.map(
    vocable => getVocableText(vocable, {useAlternates: ['diacritics', 'diacritics-auto'], fallbackToMain: true})).join(" ");
  if (diacriticVersion === textToDisplay) {
    diacriticVersion = "";
  }

  let romanVersion = "";
  if (vocables.filter(v => !!v.alternates && (v.alternates['sounds'] || v.alternates['roman'])).length) {
    romanVersion = vocables.map(v =>
      getAlternatesText(
        v.alternates, ['sounds', 'roman'],
        {altFormatter: (x) => typeof x === 'string' ? x : romanizeSounds(x as string[])}
      ) || "_____").join(" ");
  }

  const isWorkComplete = !!currentWordNode;

  return <Cell
    className={classnames(styles.wordCell, isWorkComplete ? styles.workComplete : null)}
    onClick={(e: any) => props.onClick(e, vocable.id)}
  >
    <div>
      {firstToBeMergedVocable == vocable.id ?
        <Reference>
          {({ ref }) => (
            <span ref={ref} />
          )}
        </Reference> : null}

      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {isWorkComplete ? <CheckCircle htmlColor={"green"} style={{paddingRight: '5px'}} /> : null}

        {props.trackVersionId ? <CreateIssueButton
          buttonClassName={styles.CreateContentIssueButton}
          vocableId={props.vocable.id}
          lineIndex={props.lineIndex}
          trackVersionId={props.trackVersionId}
          contentIssues={props.contentIssues}
        /> : null}
      </div>

      <EditableText
        text={textToDisplay}
        onSave={(text) => props.onChangeText(props.vocable.id, text)}
        allowEdit={props.vocables.length == 1}
      >
        {textToDisplay}

        <span style={{display: 'inline-block'}}>
          {diacriticVersion
            ? <span className={styles.alternateVocableForm}>{diacriticVersion}</span>
            : null}
          {romanVersion
            ? <span className={styles.alternateVocableForm}>{romanVersion}</span>
            : null}
        </span>
      </EditableText>
    </div>
    {
      showSplitButton
        ? <Button
          style={{marginTop: '20px'}}
          variant={"contained"}
          onClick={(e) => {
            e.stopPropagation();
            props.onSplitVocables()
          }}
        >
          Split
        </Button>
        : null
    }
  </Cell>
}


/**
 * Iterates over all word nodes in the given line of vocables.
 *
 * If a vocable already has a word node, return that word node.
 * If a vocable does not yet have a word node, assume that it would be a single word node for this vocable.
 * A vocable may also be in the process of being merged.
 *
 * The main point is that for two vocables next to each other we ony get a single result, and for those apart,
 * we can hide the controls for the other parts and control the word via the first one. It's a display only thing.
 */

type Vocable = {id: any; text: any; alternates?: any};
type ProspectiveNode =
  {type: 'vocable', vocable: Vocable, node?: StructureNode}|
  {type: 'merged', vocable: Vocable, vocables: Vocable[], node?: StructureNode}|
  {type: 'refer'}
;

export function iterateProspectiveWordNodes(opts: {
  vocables: Vocable[],
  trackAPI: TrackInterface,
  forcedVocableMerges?: Set<string>[]
}): ProspectiveNode[] {
  let result: ProspectiveNode[] = [];

  const getForceMerge = (vocableId: string) => {
    if (!opts.forcedVocableMerges) {
      return null;
    }
    for (const n of opts.forcedVocableMerges) {
      if (n.has(vocableId)) {
        return n;
      }
    }
    return null;
  }

  let lastForceMergeOrWordNode = null;

  for (const vocable of opts.vocables) {
    // Does the user want to merge those two vocables, in which case they are in the "force merge" list?
    const forceMerge = getForceMerge(vocable.id);

    // Is this vocable already part of a merged one, in that a multi-node word node is assigned?
    const wordNode = opts.trackAPI.getWordNodeForVocables([vocable.id], {allowPartialMatch: true});

    if (wordNode && wordNode.vocables.length > 1) {
      if (wordNode === lastForceMergeOrWordNode) {
        continue;
      }

      // This will ensure that later vocables belonging to this node are skipped or specially marked
      lastForceMergeOrWordNode = wordNode;

      result.push({
        type: 'merged',
        vocable,
        vocables: opts.vocables.filter(v => wordNode.vocables.indexOf(v.id) > -1),
        node: wordNode,
      });
    }

    else if (forceMerge) {
      // This is the same word
      if (forceMerge === lastForceMergeOrWordNode) {
        continue;
      }

      // This will ensure that later vocables belonging to this node are skipped or specially marked
      lastForceMergeOrWordNode = forceMerge;
      result.push({
        type: 'merged',
        vocable,
        vocables: opts.vocables.filter(v => forceMerge.has(v.id))
      });
    }

    else {
      lastForceMergeOrWordNode = null;

      result.push({
        type: 'vocable',
        vocable,
        node: wordNode || undefined
      });
    }
  }

  return result;
}