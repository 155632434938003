import React from 'react';
import {MenuItem, Button, makeStyles, Typography} from "@material-ui/core";
import {BaseDialog, DialogProps} from "../../../ui/dialogs/DialogManager";
import { Info } from '@material-ui/icons';
import {useUIControls} from "../../../components/FormUISwitch";
import {PickWordInput} from "../langpacks/fa/WordForm/fields/PickWordInput";
import {Field, Formik, FieldProps} from 'formik';


const useStyles = makeStyles(theme => ({
  typography: {
  },
}));


/**
 * For those rare cases when we need a custom node, such as "rize rize rize" linking to the entry for "rize rize",
 * or hehehehe linking to "he he".
 */
export type CustomWordDialogResult = {wordId: number, diacritization: string};
export function CustomWordNodeDialog(props: {
  onOk: (data: CustomWordDialogResult) => void,
  subtitleText: string
} & DialogProps) {
  return (
    <Formik
      initialValues={{
        wordId: 0,
        customDiacritization: props.subtitleText
      }}
      validate={(values) => {
        let errors: any = {};
        if (!values.customDiacritization) {
          errors.customDiacritization = "This field is required";
        }
        if (!values.wordId) {
          errors.wordId = "This field is required";
        }
        return errors;
      }}
      onSubmit={(values) => {
        props.onOk({
          wordId: values.wordId,
          diacritization: values.customDiacritization
        });
        props.onRequestClose();
      }}
    >
      {(formik) => {
        return <BaseDialog
          isOpen={props.isOpen}
          onRequestClose={props.onRequestClose}
          title={"Manual Node"}
          actions={[
            <Button key="ok" onClick={() => formik.handleSubmit()} color="primary" variant={"contained"}>
              Set
            </Button>,
            <Button key="cancel" onClick={props.onRequestClose}>
              Cancel
            </Button>
          ]}
        >
          <Content
          />
        </BaseDialog>
      }}
    </Formik>
  );
}


function Content() {
  const classes = useStyles();

  return <Typography className={classes.typography} component={"div"}>
    <div style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginBottom: '15px'}}>
      <Info htmlColor={"#ff9800"} style={{ fontSize: 40 }} />
      <span style={{marginLeft: '10px'}}>
        <strong>Do not use this, unless you are SURE you are doing the right thing.</strong> This
        is only to be used in very rare cases. An example is the sentence "ریزه ریزه ریزه ریزه", which we
        want to link to the database entry "ریزه ریزه".
      </span>
    </div>

    <Form />
  </Typography>
}


function Form(props: any) {
  const UI = useUIControls();

  return <div>
    <Field name={"wordId"}>
      {
        ({field, form, meta}: FieldProps) => {
          return <PickWordInput
            label={"Database word to link to this text"}
            helperText={"If your word does not exist yet, create a content issue for this row."}

            meta={meta}
            field={field}
            form={form}
          />
        }
      }
    </Field>

    <Field name={"customDiacritization"}>
      {
        (props: FieldProps) => {
          return <UI.Text
            {...props}
            label={"Write the full text with diacritics"}
            description={"It is important this be correct, so we can generate the transliteration."}
            fullWidth
          />
        }
      }
    </Field>
  </div>
}