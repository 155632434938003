import React, {useState} from 'react';
import {Button} from '@material-ui/core';
import {BaseDialog} from "./DialogManager";
import {MyTab, TabContainer, VerticalTabs} from "../../components/VerticalTabs";
import {OpenDialogAll} from "../../integrations/Market/OpenDialogAll";
import {OpenDialogTasks} from "../../integrations/Market/OpenDialogTasks";
import {getOpenSources, TabDefinition} from "../../integrations/Market";


type Props = {
  isOpen: boolean,
  onRequestClose: () => void,
  onOK: (data: {unitId: string}) => void,
}


export function OpenUnitDialog(props: Props) {
  const sources = getOpenSources();
  const defaultSource = sources[0].id;

  const [currentTab, setCurrentTab] = useState(defaultSource);

  return (
    <BaseDialog
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      title={"Open Unit"}
      fullWidth
      maxWidth={"md"}
      actions={[
        <Button key="cancel" onClick={props.onRequestClose}>
          Cancel
        </Button>
      ]}
    >
      <OpenTabs
        tabs={sources}
        currentTab={currentTab}
        onChange={setCurrentTab}
        onOK={props.onOK}
      />
    </BaseDialog>
  );
};


// https://gist.github.com/hafbau/1a332cf84455aafc2dcd2716ee14fcc1
class OpenTabs extends React.PureComponent<{
  tabs: TabDefinition[],
  currentTab: string,
  onChange: (tab: string) => void,
  onOK: (data: {unitId: string}) => void,
}> {

  handleChange = (_: any, activeIndex: number) => this.props.onChange(this.props.tabs[activeIndex]?.id);

  render() {
    const { currentTab } = this.props;

    let CurrentContentClass = this.props.tabs.find(s => s.id == currentTab)?.component;
    if (!CurrentContentClass) {
      CurrentContentClass = NotFound;
    }

    return (
      <div
        style={{
          display: 'flex',
        }}
      >
        <VerticalTabs
          value={this.props.tabs.findIndex(s => s.id == currentTab)}
          onChange={this.handleChange}
        >
          {
            this.props.tabs.map(tab => {
              return <MyTab key={tab.id} label={tab.label} />
            })
          }
        </VerticalTabs>


        <TabContainer>
          <CurrentContentClass
            onOK={this.props.onOK}
          />
        </TabContainer>
      </div>
    )
  }
}


function NotFound() {
  return <div>Not Found.</div>
}