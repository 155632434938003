import React, {useCallback, useState, useEffect} from 'react';
import {FilterOptions, WordList} from "./WordList";
import {Filters} from "./Filters";
import {useLocalStorage} from 'react-use';
import {useLocation} from 'react-router-dom';


function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}


export default function MainScreen() {
  const query = useQuery();
  const [sortBy, setSortBy] = useLocalStorage<string>("wordui-wordlist-sortby", "base");
  const [filters, setFilters] = useLocalStorage<FilterOptions>("wordui-wordlist-filters", {});
  const handleChangeSort = useCallback((sort: string) => {
    setSortBy(sort);
  }, [setSortBy])

  useEffect(() => {
    if (!query.has("search")) {
      return;
    }

    setFilters({
      search: query.get("search")!
    });
  }, [query.get("search"), setFilters])

  return <div style={{padding: '20px'}}>
    <div>
      <Filters
        value={filters ?? {}}
        onChange={setFilters}
      />
    </div>

    <div style={{marginTop: '30px'}}>
      <WordList
        sortBy={sortBy}
        onChangeSort={handleChangeSort}
        filters={filters}
      />
    </div>
  </div>
}