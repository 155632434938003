import {NestableField} from "../../../../components/NestedFormik";
import * as React from "react";
import {useUIControls} from "../../../../components/FormUISwitch";


export function PluralEnabledField(props: any) {
  const UI = useUIControls();

  return <NestableField
    name={"pluralEnabled"}
    component={UI.Checkbox}

    label="Plural Enabled"
    description={"If this pronoun allows plurals"}

    {...props}
  />;
}