import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styles from './Sentences.module.css';
import gqlm from "graphql-tag";
import {DefaultButton, IconButton, PrimaryButton} from 'office-ui-fabric-react';
import {useQuery, useClient} from "multi-apollo";
import {useTransliterator} from "../../utils/transliterate";
import {useAudioPlayer} from "languagetool-editor/src/js/integrations/Market/utils/useAudioPlayer";
import {
  WordDetailsSentencesQuery,
  WordDetailsSentencesQuery_wordInfo_uses_edges_node_occurrences,
  WordDetailsSentencesQuery_wordInfo_uses_edges_node_unit,
  WordDetailsSentencesQuery_wordInfo_uses_edges_node_unit_mediaTrack
} from "../../types/WordDetailsSentencesQuery";
import WordPopup from "languagetool-player/src/ui/WordPopup/WordPopup";
import { AnnotationLoader } from 'languagetool-player/src/annotations/loader';
import {AnnotationsCache} from "languagetool-player/src/cache";
import {StructureNode} from "languagetool-player/src/models/Node";
import {
  WordAssignScreen,
  WordAssignScreenCaptionsTrack
} from "languagetool-editor/src/js/integrations/Words/assignScreen";
import {QUERY_UNIT_QUERY, saveCaptionTrackVersion} from "languagetool-editor/src/js/integrations/Market/api";
import {QueryUnit} from "languagetool-editor/src/types/QueryUnit";
import {useAsync} from 'react-use';
import {getCaptionsFromLoadedUnit} from "languagetool-editor/src/js/ui/MainScreen";
import {Captions, slateValueToCaptions} from 'languagetool-editor/src/js/models/Captions';
import {Vocable, VocableId} from "languagetool-player/src/models/formats/CaptionTrack";
import {NodeResolver} from "languagetool-player/src/ui/WordPopup/PopupContext";


const QUERY = gqlm`
  query WordDetailsSentencesQuery($ids: [Int!]!) {
    wordInfo(ids: $ids) {
      wordId,
      uses(max: 25) {
        edges {
          node {
            unit {
              id,
              name,
              mediaTrack {
                type,
                url
              }
            },
            occurrences(includeTranslations: ["en"], max: 20) {
              lineIndex,
              lineStructure,
              lineProps,
              node,
              translations {
                text
              }
            }
          }
        }        
      }
    }
  }
`;


export const Sentences = React.memo((props: {
  wordId: any
}) => {
  const {loading, error, data} = useQuery<WordDetailsSentencesQuery>(QUERY, {
    variables: {ids: [props.wordId]},
    client: 'market'
  });

  const [overlay, setOverlay] = useState<{unitId: string, lineIdx: number}|null>(null);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{`Error! ${error.message}`}</div>;

  let content: any;
  if (data?.wordInfo?.[0]?.uses) {
    const nodes = data!.wordInfo?.[0].uses.edges.map((edge: any) => edge.node)

    const comps = nodes.map(({unit, occurrences}: any) => {
      return <Occurrence
        unit={unit}
        occurences={occurrences}
        key={unit.id}
        openOverlay={setOverlay}
      />
    });

    if (comps.length) {
      content = comps;
    }
  }

  if (!content) {
    content = <span>This word is not used anywhere so far.</span>
  }

  return <div className={styles.root}>
    <h3>Used in these units and sentences</h3>
    {content}

    {overlay ? <div>
      <WordAssignOverlay
        unitId={overlay.unitId}
        lineIdx={overlay.lineIdx}
        onRequestClose={() => setOverlay(null)}
      />
    </div>: null}
  </div>
});


function Occurrence(props: {
  unit: WordDetailsSentencesQuery_wordInfo_uses_edges_node_unit,
  occurences: WordDetailsSentencesQuery_wordInfo_uses_edges_node_occurrences[],
  openOverlay: (state: {unitId: string, lineIdx: number}|null) => void
}) {
  const transliterate = useTransliterator();
  const annotationsLoader = useAnnotationLoader();
  const [popupState, setPopupState] = useState<null|{
    node: StructureNode,
    vocable: any,
    el: any
  }>(null);

  const resolver = useMemo<NodeResolver>(() => ({
    resolveVocable: (vocableId: VocableId): Vocable => {
      const result = popupState?.vocable;
      if (!result) {
        throw new Error("Must not happen");
      }
      return result;
    },
    getNodesForVocable(vocableId: VocableId): StructureNode[] {
      return [];
    },
    resolveNode(nodeId: string): StructureNode {
      throw new Error("not implemented");
    }
  }), [popupState]);

  const handleEditorClick = () => {
    window.open(`https://editor.languagetool.xyz?load=${props.unit.id}`, '_blank');
  }

  return <div className={styles.occurrenceGroup}>
    <div className={styles.groupTitle}>
      <strong>{props.unit.name}</strong>

      <div className={styles.actions}>
        <DefaultButton onClick={handleEditorClick}>Editor</DefaultButton>
      </div>
    </div>

    {props.occurences.map((occurrence, idx) => {
      const node: StructureNode = JSON.parse(occurrence.node);
      const lineStructure = JSON.parse(occurrence.lineStructure);

      return <div key={idx} className={styles.occurrence}>
        <div style={{
          fontSize: "1.2em",
          paddingRight: '1em',
          textAlign: 'center',
          width: '30px'
        }}>
          <a onClick={(e) => { e.preventDefault(); props.openOverlay({unitId: props.unit.id, lineIdx: occurrence.lineIndex})}}>
            {occurrence.lineIndex + 1}
          </a>
          {occurrence.lineProps ? <PlayButton
            track={props.unit.mediaTrack!}
            props={occurrence.lineProps}
          /> : null}
        </div>
        <div>
          <div>
            {lineStructure.map((vocable: any) => {
              const isMatch = node.vocables.indexOf(vocable.id) > -1;
              const clickableMain = <span onClick={(e) => {
                setPopupState({
                  node: node,
                  vocable,
                  el: e.target
                })
              }}>{vocable.text}</span>;
              return <span key={vocable.id} style={{
                color: isMatch ? '#0278d4' : undefined,
                fontWeight: isMatch ? 'bold' : undefined,
              }}>{vocable.pre}{clickableMain}{vocable.post}</span>
            })}
          </div>
          <div>
            {lineStructure.map((vocable: any) => {
              const isMatch = node.vocables.indexOf(vocable.id) > -1;
              const clickableMain = <span onClick={(e) => {
                setPopupState({
                  node: node,
                  vocable,
                  el: e.target
                })
              }}>{(vocable.alternates && vocable.alternates['sounds']) ? transliterate(vocable.alternates['sounds']) : '____'}</span>;
              return <span key={vocable.id} style={{
                color: isMatch ? '#0278d4' : undefined,
                fontWeight: isMatch ? 'bold' : undefined,
              }}>{vocable.pre}{clickableMain}{vocable.post}</span>
            })}
          </div>
          {(occurrence.translations && occurrence.translations[0]) ? <div style={{color: 'gray'}}>
            {occurrence.translations[0].text}
          </div> : null}
        </div>
      </div>
    })}

    {popupState ? <WordPopup
      nodes={[popupState.node]}
      vocable={popupState.vocable}
      learnerLangs={["en"]}
      resolver={resolver}
      loader={annotationsLoader}
      positionAt={popupState.el}
      defaultLanguage={"fa"}
      onRequestClose={() => setPopupState(null)}
    /> : null}
  </div>
}

export function useAnnotationLoader() {
  const annotationCache = React.useMemo(() => new AnnotationsCache(), []);
  return React.useMemo(() => new AnnotationLoader(
    {
      wordsApiUrl: process.env.REACT_APP_WORDS_API_URL as any,
      learnerLangs: ["en"]
    },
    annotationCache
  ), [annotationCache]);
}


function PlayButton(props: {
  track: WordDetailsSentencesQuery_wordInfo_uses_edges_node_unit_mediaTrack,
  props: string
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  // Audio player loads once isLoaded is set to True.
  const audioPlayer = useAudioPlayer(isLoaded ? (props.track as any) : undefined);

  const lineData = JSON.parse(props.props);

  // Play the line at current time.
  const play = useCallback(() => {
    if (!audioPlayer) {
      return;
    }
    audioPlayer.playSegment(lineData.time, lineData.time+lineData.duration);
  },  [audioPlayer, lineData.time, lineData.duration]);

  // Automatically play when the file is loaded initially (after the first click).
  // Before the first click, audioPlayer should be undefined.
  useEffect(() => {
    if (!audioPlayer) {
      return;
    }
    play();
  }, [audioPlayer])

  const handleClick = useCallback(() => {
    if (isLoaded) {
      play();
    }
    else {
      setIsLoaded(true)
    }
  }, [audioPlayer, isLoaded, setIsLoaded]);

  return <IconButton iconProps={{ iconName: 'BoxPlaySolid' }} onClick={handleClick} />
}


function WordAssignOverlay(props: {
  unitId: string,
  lineIdx: number,
  onRequestClose: () => void
}) {
  const [isSaving, setIsSaving] = useState(false);
  const client = useClient("market");
  const [captions, setCaptions] = useState<Captions|null>(null);
  useAsync(async () => {
    const data = await client.query<QueryUnit>({
      query: QUERY_UNIT_QUERY,
      variables: {
        id: props.unitId
      },
      fetchPolicy: 'network-only'
    });
    const captions = getCaptionsFromLoadedUnit(data.data.unit!);
    setCaptions(captions);
  }, [props.unitId]);

  const handleSave = useCallback(async () => {
    const data = slateValueToCaptions(captions!);
    setIsSaving(true);
    try {
      await saveCaptionTrackVersion(captions!.versionId, JSON.stringify(data), {endpoint: process.env.REACT_APP_MARKET_API_URL!});
    }
    finally {
      setIsSaving(false);
    }
    props.onRequestClose();
  }, [captions, setIsSaving, props.onRequestClose]);

  if (!captions) {
    return null;
  }

  return <div style={{
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    background: 'white',
    zIndex: 150
  }}>
    <WordAssignScreenCaptionsTrack
      captions={captions!}
      language={"fa"}
      apiUrl={process.env.REACT_APP_WORDS_API_URL!}
      initialLineIndex={props.lineIdx}
      extraButtons={<div>
        <PrimaryButton onClick={handleSave} disabled={isSaving}>Save</PrimaryButton>
        <DefaultButton
          onClick={props.onRequestClose}
        >
          Close
        </DefaultButton>
      </div>}
    />
  </div>
}