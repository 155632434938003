import React, {useMemo} from "react";
import {formatTime, Scale} from "./coordinates";
import {TextStyle} from 'pixi.js';
import {Graphics} from 'pixi.js'
import {Container, PixiComponent, Text} from '@inlet/react-pixi'
import {string2hex} from "./utils";
import * as PIXI from "pixi.js";


type Props = {
  x: number,
  y: number,
  width: number,
  height: number,

  scale: Scale,
  offsetSeconds: number,
};


function useTickerPositions(scale: Scale, offsetSeconds: number, width: number) {
  let offsetPixels = scale.secondsToPixel(offsetSeconds);
  let currentSeconds = Math.ceil(offsetSeconds);

  // This sometimes happens, then causes an invalid loop. why?
  if (Number.isNaN(offsetSeconds)) {
    throw  new Error('invalid')
  }

  return useMemo(() => {
    const result = [];
    while (true) {
      let currentPixels = scale.secondsToPixel(currentSeconds) - offsetPixels;
      if (currentPixels > width) {
        break;
      }

      result.push([currentPixels, currentSeconds]);
      currentSeconds = currentSeconds + 1;
    };

    return result;
  }, [scale, offsetSeconds, width]);
}


const style = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 9,
  fill: '#607d8b'
});


/**
 * The tickers itself are a customs graphic shape, but we do not know how to render the text labels
 * there, so we do that using <Text> components.
 */
export const TimeScale = React.memo(function TimeScale(props: Props) {
  // At which points do we need to render tickers? This could possibly do more and calculate
  // some background ticks as well.
  const tickerPositions = useTickerPositions(props.scale, props.offsetSeconds, props.width);

  // Ideally, this would a case where react-pixi helps, because it makes it very easy
  // to keep the same text labels around while scrolling, while at the same time not
  // having to pre-generate all of them (and there was at least one Github ticket
  // reporting trouble with a lot of texts in memory). Using the proper React keys,
  // we can trust that they right text textures remain in memory.
  //
  // TODO: Cache for performance.
  const labels = useMemo(() => {
    const labels = [];
    for (const [currentPixels, currentSeconds] of tickerPositions) {
      labels.push(<Text
        x={currentPixels+2}
        y={0}
        key={currentSeconds}
        text={formatTime(currentSeconds, true)}
        style={style}
      />)
    }
    return labels;
  }, [tickerPositions]);

  return <Container
    x={0}
    y={0}
  >
    <TickerShape
      x={0}
      y={0}
      height={15}
      width={props.width}
      tickerPositions={tickerPositions}
    />
    {labels}
  </Container>
});



type TickerShapeProps = {
  x: number,
  y: number,
  width: number,
  height: number,
  tickerPositions: any
};

const TickerShape  = PixiComponent('TickerShape', {
  create: (props: TickerShapeProps) => {
    return new PIXI.Graphics();
  },

  applyProps: (g: Graphics, oldProps: TickerShapeProps, newProps: TickerShapeProps) => {
    g.clear()

    const color = string2hex('#607d8b');
    g.lineStyle(1, color, 1)

    for (const [currentPixels, currentSeconds] of newProps.tickerPositions) {
      g.moveTo(currentPixels, 0);
      g.lineTo(currentPixels, newProps.height);
    }
  }
})