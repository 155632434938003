// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__root--1cSSF{display:flex;flex-direction:column;flex:1}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__editor--2LlVQ{padding:20px}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__editorEmpty--23Hfr{text-align:center;align-self:center;margin:0 auto}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__filename--3QslP{margin-bottom:1em;font-size:1em;color:silver}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoPlayerItself--3zqkE{width:100%;height:100%}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoAbsolutePositioner--88mFy{position:absolute;bottom:var(--height-controls);left:0;right:0;top:0}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__controlsAbsolutePositioner--kR4nA{position:absolute;bottom:0;left:0;right:0;height:var(--height-controls)}._-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoAndControls--X6AKI{position:relative;flex:1;display:flex;--height-controls: 36px;border-bottom:1px solid gray}\n", ""]);
// Exports
exports.locals = {
	"root": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__root--1cSSF",
	"editor": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__editor--2LlVQ",
	"editorEmpty": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__editorEmpty--23Hfr _-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__editor--2LlVQ",
	"filename": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__filename--3QslP",
	"videoPlayerItself": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoPlayerItself--3zqkE",
	"videoAbsolutePositioner": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoAbsolutePositioner--88mFy",
	"controlsAbsolutePositioner": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__controlsAbsolutePositioner--kR4nA",
	"videoAndControls": "_-languagetool-editor-src-js-ui-MediaPlayer-MediaEditor__videoAndControls--X6AKI"
};
module.exports = exports;
