import React, {useEffect, useState} from "react";

const isBrowser = typeof window !== `undefined`;

/**
 * Returns an audio element + the state of isPlaying.
 */
export function useAudioElement(deps?: any): [HTMLAudioElement, boolean] {
  const [state, setState] = useState<boolean>(false);

  const audio = React.useMemo(() => {
    // Such as SSR
    if (!isBrowser) {
      return (null as any) as HTMLAudioElement;
    }
    return new window.Audio();
  }, [deps]);

  useEffect(() => {
    audio.onplay = () => setState(true);
    audio.onpause = () => setState(false);
  }, [audio]);

  useEffect(() => {
    return () => {
      audio.src = "";
    }
  }, [audio]);

  return [audio, state];
}
