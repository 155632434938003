import React, {Fragment} from 'react';
import {CheckBoxOutlineBlank, CheckCircleOutline, Work} from "@material-ui/icons";


const TaskStatusToLabel: any = {
  pending: 'Pending',
  started: 'Started',
  review: 'Review',
  rejected: 'Rejected',
  complete: 'Complete',
}


export function TaskStatus(props: {status: string}) {
  return TaskStatusToLabel[props.status];
}


const TaskTypeToLabel: any = {
  captioning: 'Create Subtitles',
  grammar: 'Grammar',
  translation: 'Translation',
  content: 'Content'
}


export function TaskKind(props: {kind: string}) {
  return TaskTypeToLabel[props.kind] || props.kind;
}


const TaskStatusToIcon: any = {
  pending: CheckBoxOutlineBlank,
  started: Work,
  review: CheckCircleOutline,
};


export function TaskStatusIcon(props: {status: string}) {
  const IconKlass = TaskStatusToIcon[props.status];
  if (!IconKlass) {
    return null;
  }
  return <IconKlass />;
}
