import React, {Fragment, useState} from 'react';
import { useQuery, useMutation } from 'multi-apollo';
import gql from "graphql-tag";
import { Link } from 'react-router-dom';
import {WordWithDetails, WordWithDetailsFragment} from "../../utils/WordWithDetails";
import styles from './InfoBox.module.css';
import { Dialog, DialogType, DialogFooter, DefaultButton } from 'office-ui-fabric-react';


export function InfoBox(props: {
  word: {
    id: string,
    type: string,
    base: string,
    data: any
  }
}) {
  const {word} = props;
  const data = word.data || {};
  const rows: {label: string, value: any}[] = [];

  if (word.type === 'noun') {
    if (word.data && word.data.plurals) {
      rows.push({label: "Custom Plurals", value: word.data.plurals.map((p: any) => p)});
    }
  }
  if (word.type === 'pronoun') {
    if (word.data) {
      rows.push({label: "Plural Enabled", value: word.data.plural_enabled ? 'Yes' : 'No'});
    }
  }
  if (word.type === 'preposition') {
    if (word.data?.ezafe) {
      rows.push({label: "Ezafe", value: word.data.ezafe});
    }
  }
  if (word.type === 'verb' && data.is_compound) {
    rows.push({label: "Compound Verb", value: 'Yes'});
    rows.push({label: "Primary Components", value: <ResolveWords ids={data.primary} />});
    rows.push({label: "Vector", value: <ResolveWords ids={[data.vector]} />});
  }
  if (word.type === 'verb' && !data.is_compound) {
    let prefixStr = "";
    if (data.prefixes && data.prefixes.length) {
      prefixStr = data.prefixes.join(" + ")
      prefixStr = prefixStr + ' + ';
    }
    rows.push({label: "Present Stem", value: `${prefixStr}${data.present_stem}`});
    rows.push({label: "Past Stem", value: `${prefixStr}${data.past_stem}`});
    if (data.irregular_present_stems && data.irregular_present_stems.length) {
      rows.push({label: "Irregular Present Stems", value: data.irregular_present_stems.join(', ')});
    }
    if (data.irregular_past_stems && data.irregular_past_stems.length) {
      rows.push({label: "Irregular Past Stems", value: data.irregular_past_stems.join(', ')});
    }
  }
  if (word.type === 'alias' && word.data) {
    rows.push({label: "Type", value: word.data.alias?.type});
    rows.push({label: "Target Word", value: <ResolveWords ids={[word.data.alias?.word]} />});
  }

  if (data.stems) {
    rows.push({
      label: "Stems",
      value: <CommaSepStrings sounds={data.stems} />})
  }
  if (data.sounds) {
    rows.push({
      label: "Manual Sounds",
      value: <CommaSepStrings sounds={data.sounds} />})
  }
  if (data) {
    rows.push({
      label: "Internal Data Blob",
      value: <ShowInternalLink data={data} />});
  }

  if (!rows.length) {
    return null;
  }

  return <table className={styles.table}>
    <tbody>
      {rows.map((row, idx) => {
        return <tr key={idx}>
          <td>{row.label}</td>
          <td>{row.value}</td>
        </tr>
      })}
    </tbody>
  </table>
}


function CommaSepStrings(props: {sounds: string[]}) {
  return <span>{props.sounds.join(', ')}</span>
}


function ResolveWords(props: {ids: number[]}) {
  const {data, error, loading, refetch} = useQuery(gql`
    ${WordWithDetailsFragment}
    query WordPartsQuery($wordIds: [Int]) {
      words(ids: $wordIds) {
        edges {
          node {
            id,
            base,
            ...WordWithDetails
          }
        }            
      }
    }
  `, {
    skip: !props.ids || !props.ids.filter(x => !!x).length,
    variables: {
      wordIds: props.ids
    }
  });

  if (loading || error || !data) {
    return null;
  }

  return <div>
    {data.words.edges.map(({node: word}: any, idx: number) => {
      return <Fragment key={idx}>
        <Link to={`/word/${word.id}`}>
          <WordWithDetails
            {...word}
          />
        </Link>
        {idx !== (data.words.edges.length-1) ? ", " : null}
      </Fragment>
    })}
  </div>
}


function ShowInternalLink(props: {
  data: any
}) {
  const [visible, setVisible] = useState(false);

  return <>
    <a href={""} onClick={(e) => {
      e.preventDefault();
      setVisible(true)
    }}>Show</a>

    <Dialog
      hidden={!visible}
      onDismiss={(() => setVisible(false))}
      dialogContentProps={{
        type: DialogType.normal,
        title: 'Internal Data Blob',
      }}
      modalProps={{
        isBlocking: false,
        styles: { main: { maxWidth: 450 } },
      }}
    >
      <pre>
        {JSON.stringify(props.data, null, 4)}
      </pre>
      <DialogFooter>
        <DefaultButton onClick={() => setVisible(false)} text="Close" />
      </DialogFooter>
    </Dialog>
  </>;
}