import {Graphics} from 'pixi.js';
import {string2hex} from "./utils";
import {WaveformData} from "waveform-data";
import {getSamplesPerSecond, Scale} from "./coordinates";


type DrawWaveFormOpts = {
  width: number,
  height: number,
  data: WaveformData,
  frameOffset: number,
  mono?: boolean,
  scale: Scale
};


export function drawWaveform(g: Graphics, opts: DrawWaveFormOpts) {
  const { width, height, data, frameOffset, mono, scale } = opts;

  _drawWaveform(
    g,
    data,
    frameOffset,
    width,
    height,
    mono || false,
    scale
  );
}


/**
 * waveform-data gives us a datastructure. I am not a fan of it, but let's
 * work with it. It is basically just a list of high-low, or min/max pairs.
 *
 * We do not want to do any resampling at this point, so we just render the
 * values we have, with one sample being one pixel in width.
 */
function _drawWaveform(
  // Drawing context to write to
  g: Graphics,

  // The data to render
  waveformData: WaveformData,

  // The first sample to render, at position 0
  firstSampleIdx: number,

  // How many pixels to fill
  width: number,

  // The height of the canvas. WaveForm will
  // be aligned in the vertical center.
  height: number,

  // Only draw one of the sides
  mono: boolean,

  // How far are we zoomed in?
  scale: Scale
) {

  const samplesPerSecond = getSamplesPerSecond(waveformData);
  const pixelsPerSecond = scale.pixelsPerSecond;
  const pixelsPerSample = pixelsPerSecond / samplesPerSecond;
  const samplesPerPixel = samplesPerSecond / pixelsPerSecond;

  const color = string2hex('#c0c0c0');
  g.beginFill(color);
  g.lineStyle(1, color, 1)

  if (mono) {
    const scaleY = makeScaleY(128, 0);

    // Move to the bottom of the canvas on the right
    g.moveTo(0, scaleY(0, height));

    // For 128, move up, to 0
    // For 0, stay down
    let lastSampleIdx = -1;
    for (let x = 0; x < width; x++) {
      // Which sample to render at pixel position x?
      let sampleIdx = x * samplesPerPixel;

      // If zoomed in too much that we repeat samples, do not paint them multiple times.
      // With this, the wave form is just different than without this logic, but both
      // possible approaches.
      if (sampleIdx === lastSampleIdx) {
        continue;
      }
      lastSampleIdx = sampleIdx;

      // So this part is important. If the zoom is such that there are more than one sample
      // available per pixel, then obviously we can only draw one of those (TODO: It might
      // be best to merge them? min_sample should do something like this already? Are we
      // not supposed to "rescale" the waveform file to match our resolution, precompute it
      // to save CPU cycles? The waveform-data library does not make that easy!)
      // But depending on the start sample, which will shift when scrolling, we will pick
      // a different one of those X samples that represent a pixel. Then, then result is that
      // while scrolling, a different sample will be drawn for what really is the same value,
      // causing flickering. Here, we round it to one of the "samplesPerPixel" step values.
      // If there are 11 samples per pixel, then only 11, 22, 33 etc. are valid, and we round
      // to the closest one always.
      let totalSampleIdx = firstSampleIdx + sampleIdx;
      if (samplesPerPixel > 1) {
        totalSampleIdx = Math.round(totalSampleIdx / samplesPerPixel) * samplesPerPixel;
      }
      //totalSampleIdx = Math.round(totalSampleIdx);
      if (totalSampleIdx >= waveformData.adapter.length - 1) {
        break;
      }

      let val_min = waveformData.min_sample(totalSampleIdx);
      let val_max = waveformData.max_sample(totalSampleIdx);
      let val_merged = (Math.abs(val_min) + Math.abs(val_max)) / 2;

      g.lineTo(x, scaleY(val_merged, height));
    }

    // Move down to the bottom
    g.lineTo(width - 1, scaleY(0, height));
    // Move back to the beginning to complete the area (then later fill it).
    g.lineTo(0, scaleY(0, height));

    g.endFill();
  }

  else {
    const scaleY = makeScaleY(256, 128);

    // Move to the y-middle of the canvas.
    g.moveTo(0, scaleY(0, height))

    // Draw bottom from left to right
    for (let x = 0; x < width; x++) {
      let val = waveformData.min_sample(firstSampleIdx + x);

      g.lineTo(x + 0.5, scaleY(val, height) + 0.5);
    }

    // Draw top from right to left
    for (let x = width - 1; x >= 0; x--) {
      let val = waveformData.max_sample(firstSampleIdx + x);

      g.lineTo(x + 0.5, scaleY(val, height) + 0.5);
    }

    g.endFill();
  }
}


/**
 * For a given amplitude, where is the Y position?
 *
 * With range 256, and offset 128, this would do:
 *
 * At 128, returns 0 (the top side of the waveform)
 * At -128, returns the full height (the bottom side waveform)
 * At 0, returns half the height.
 */
function makeScaleY(range: number, offset: number) {
  function scaleY(amplitude: number, height: number) {
    return height - ((amplitude + offset) * height) / range;
  }

  return scaleY;
}