import React from 'react'
import {Svg, Path} from 'react-native-svg';

export const PlaySpeakerIcon = (props: {
  color?: string,
  isFilled?: boolean,

  width?: number,
  height?: number,
}) => {
  const color = props.color || "red";
  const foreColor = props.isFilled ? 'white' : color;

  return <Svg viewBox="0 0 512 512" fill={foreColor} width={props.width || 64} height={props.height || 64}>
    <Path
      fill={props.isFilled ? color : "transparent"}
      stroke={color}
      strokeWidth="20"
      d="M506 256C506 119 394.9 7.9 257.9 7.9S9.8 119 9.8 256s111.1 248.1 248.1 248.1S506 393 506 256z"
    />
    <Path
      d="M257.6 133.2c-3.4-1.9-7.6-1.7-10.8.4l-95.1 61.8h-35.2c-5.8 0-10.6 4.7-10.6 10.6v92.7c0 5.8 4.7 10.6 10.6 10.6h35.2l95.1 61.8c1.7 1.1 3.8 1.7 5.8 1.7 1.7 0 3.5-.4 5-1.3 3.4-1.9 5.5-5.4 5.5-9.3V142.5c0-3.9-2.1-7.5-5.5-9.3zM292.5 327.2c.9 0 1.8-.2 2.7-.5 24.2-9.4 40.5-38.5 40.5-72.4 0-30-13.2-57-33.6-69-3.5-2.1-8.1-.9-10.2 2.7-2.1 3.5-.9 8.1 2.7 10.2 16 9.4 26.3 31.4 26.3 56.2 0 27.4-12.8 51.4-31.1 58.5-3.8 1.5-5.7 5.8-4.2 9.6 1.2 2.9 4 4.7 6.9 4.7z"
    />
    <Path
      d="M311.9 358.1c.9 0 1.8-.2 2.7-.5 34-13.2 57.8-56 57.8-104 0-43.2-18.9-82.2-48.1-99.3-3.5-2.1-8.1-.9-10.2 2.7-2.1 3.5-.9 8.1 2.7 10.2 24.8 14.5 40.8 48.5 40.8 86.5 0 42-19.9 79.1-48.4 90.2-3.8 1.5-5.7 5.8-4.2 9.6 1.1 2.8 3.9 4.6 6.9 4.6z"
    />
    <Path
      d="M331.9 387.6c-3 0-5.8-1.8-7-4.8-1.5-3.9.4-8.2 4.3-9.7C368 358 395 308.7 395 253.1c0-50.1-21.6-95.2-55.1-114.8-3.6-2.1-4.8-6.7-2.7-10.3 2.1-3.6 6.7-4.8 10.3-2.7C385.4 147.5 410 197.7 410 253c0 61.7-31 116.8-75.4 134-.9.5-1.8.6-2.7.6z"
    />
  </Svg>
}