import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {
  Captions,
} from "../../../models/Captions";
import styles from './index.cssm';
import {useLocalStorage, useKeyPress} from 'react-use';
import {useDialogs} from "../../../ui/dialogs/DialogManager";
import {getInitialValuesFromResult} from "./AddEditWordDialog";
import {isQueryByWordId} from "languagetool-player/src/annotations";
import {analyzeListOfWords, AnalyzeResult, getAPI, isWordNodeResult, WordNodeResult} from "../wordsApi";
import {LineTable} from "./LineTable";
import {isEqual, keyBy} from 'lodash';
import {LinePreview, useLineByLineBrowse} from "../LineByLineView";
import {LineBrowser} from "../LineByLineView/LineBrowser";
import {pickWordDialogDefaults, useShowWordDialog} from "./useShowWordDialog";
import {usePlayLine} from "../../Market/utils/useAudioPlayer";
import {MediaTrack} from "../../../models/MediaTrack";
import {Button, ButtonGroup} from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import {NewUnitState, Translation, UnitDataSetter} from "../../../models/Unit";
import {useIndexedContentIssues} from "../../Market/ContentIssues";
import {IssueManagerButton} from "./IssueManager";
import {WordNode} from "languagetool-player/src/models/Node";
import {Vocable, VocableId} from "languagetool-player/src/models/formats/CaptionTrack";
import {WordLikeNode} from "../../../models/Captions/wordNodes";
import {useGetTrackAPIForCaptions, useGetTrackInterfaceForSimplePictureBook} from "../../Market/useGetTrackInterface";
import {SimplePictureBook} from "languagetool-player/src/models/formats/SimplePictureBook";
import {generatePlaySegmentsForPage, useBookPlayer} from "languagetool-player/src/ui/SimplePictureBook/useBookPlayer";
import {URLResolver} from "languagetool-player/src/ui/SimplePictureBook/SimplePictureBookContext";
import {useAudioPlayer} from "languagetool-player/src/ui/Audio/useAudioPlayer";


export type NodeSuggestions = {[key: string]: AnalyzeResult[]};


export type WordDef = {id: number, meaningId?: number};


/**
 * Index node suggestions by vocable id.
 */
function indexNodeSuggestions(nodeSuggestions: AnalyzeResult[]|null): NodeSuggestions {
  const db: NodeSuggestions = {};
  if (nodeSuggestions) {
    nodeSuggestions.forEach(suggestion => {
      suggestion.node.vocables.forEach(vocableId => {
        if (!db[vocableId]) {
          db[vocableId] = [];
        }
        db[vocableId].push(suggestion);
      })
    })
  };

  return db;
}


export type TrackInterface = {
  setVocableText: (vocableId: string, text: string) => void
  setVocableAlternates: (vId: string, alternates: { [key: string]: string|undefined }) => void,
  getWordNodeForVocables: (vid: string[], opts?: {allowPartialMatch?: boolean}) => WordLikeNode|null,
  setWordNode: (node: WordLikeNode) => void,
  removeWordNode: (vocableId: string) => void,
  getLineCount: () => number
  getLine: (idx: number) => Vocable[]
}

export type PlayInterface = {
  playLine(idx: number): void;
}


type Props = {
  // This object must update itself on every change.
  trackIntf: TrackInterface,
  playIntf?: PlayInterface,

  language: string,
  trackVersionId?: string,

  apiUrl: string,

  // Some of those are specific to the caption still
  unitId?: string|null,
  mediaTrack?: MediaTrack,
  translation?: Translation,
  initialLineIndex?: number,
  captions?: Captions,
  extraButtons?: any
};


export function WordAssignScreenCaptionsTrack(props: Omit<Props, 'trackIntf'> & {captions: Captions}) {
  const trackIntf = useGetTrackAPIForCaptions(props.captions);

  const playLine = usePlayLine({
    mediaTrack: props.mediaTrack,
    track: props.captions,
  });
  const playIntf = useMemo(() => {
    return {playLine};
  }, [playLine]);

  return <WordAssignScreen
    {...props}
    captions={props.captions}
    trackIntf={trackIntf}
    playIntf={playIntf}
  />
}


export function WordAssignScreenSimplePictureBook(props: Omit<Props, 'trackIntf'> & {
  book: SimplePictureBook,
  setData: UnitDataSetter<SimplePictureBook>,
  unitState: NewUnitState<SimplePictureBook>
}) {
  const trackIntf = useGetTrackInterfaceForSimplePictureBook(props.book, props.setData, props.unitState.idGen);

  const player = useAudioPlayer();
  const fileResolver = makeResolver(props.unitState.files);
  const playIntf = useMemo(() => {
    return {
      playLine: (lineIdx: number) => {
        const segments = generatePlaySegmentsForPage({
          // TODO: Only works because page = one paragraph = one blob for now
          page: props.book.pages[lineIdx],
          media: props.book.media
        });
        let url = segments[0].url;
        const result = fileResolver(url);
        player.play(result.uri);
      }
    };
  }, [player, fileResolver]);

  return <WordAssignScreen
    {...props}
    captions={props.captions}
    trackIntf={trackIntf}
    playIntf={playIntf}
  />
}

function makeResolver(files: {name: string; url: string}[]|undefined): URLResolver {
  const filesMap = keyBy(files, "name");
  return (url, opts) => {
    if (url.startsWith("container://")) {
      const filename = url.slice(12);
      const resolvedUrl = filesMap[filename]?.url;
      return {uri: resolvedUrl};
    }

    return {uri: url};
  };
}


export function WordAssignScreen(props: Props) {
  const {trackIntf, trackVersionId} = props;
  const [contentIssues, _] = useIndexedContentIssues(props.unitId, props.captions);

  const [, setIsLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [hasData, setHasData] = useState(false);
  const [nodeSuggestions, setNodeSuggestions] = useState<NodeSuggestions>({});
  const [forcedVocableMerges, setForcedVocableMerges] = useState<Set<string>[]>([]);

  const {words, currentLineIndex, setCurrentLineIdx} = useLineByLineBrowse({
    trackAPI: props.trackIntf,
    initialLine: props.initialLineIndex
  });

  // Make sure this callback is not replaced every time we assign a structure node, only
  // if the line changes. IMPORTANT! If this is not properly memoized we will loop.
  const reloadSuggestions = useCallback(() => {
    setIsLoading(true);

    // Send all existing multi-vocable nodes to the server so we get up-to-date
    // suggestions for all of them.
    const compoundVocables: Set<string>[] = Array.from(forcedVocableMerges);

    words.map((vocable, idx) => {
      const wordNode = trackIntf.getWordNodeForVocables([vocable.id], {allowPartialMatch: true});
      if (!wordNode) {
        return;
      }
      if (wordNode.vocables.length <=1 ) {
        return;
      }
      compoundVocables.push(new Set(wordNode.vocables));
    })

    return analyzeListOfWords(words, props.language, compoundVocables, {apiUrl: props.apiUrl})
      .then(r => {
        const indexed = indexNodeSuggestions(r);
        setNodeSuggestions(indexed);
        setHasData(true);
        setError(null);
        return indexed;
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e);
      });
  }, [currentLineIndex, trackIntf, words, setIsLoading, setHasData, setNodeSuggestions, forcedVocableMerges]);

  // Re-analyze:
  // - When the list of words changes, including their text.
  // - When the forced alternates change.
  // But do not reanalyze:
  // - If only the attached nodes or transliteration alternates change.
  const texts = words.map(v => v.text);
  useEffect(() => {
    setNodeSuggestions({});
    setHasData(false);
    reloadSuggestions();
  }, [setNodeSuggestions, setHasData, JSON.stringify(texts), forcedVocableMerges]);

  const dialogs = useDialogs();

  const showWordDialog = useShowWordDialog({
    language: props.language,
    onSave: async (word?: WordDef) => {
      await reloadSuggestions();
      // If there is just one result with this new word id now, auto-select it?
      // Note that we cannot just add the node suggestion which the user clicked,
      // and patch in the new word id. For example, the word the user entered
      // might not actually resolve to the grammar form.
    },
  });

  // "Create Word" button
  const handleCreateWordClick = useCallback((text: string, vocableIds: string[]) => {
    showWordDialog(
      {
        initialValues: {text},
        ...pickWordDialogDefaults({
          vocableIds,
          nodeSuggestions,
          trackAPI: props.trackIntf
        }),
        matchText: text,
      }
    );
  }, [showWordDialog, nodeSuggestions, trackIntf]);

  // "Edit Word" button
  const handleEditWordClick = useCallback(async (wordId: number, vocableText: string) => {
    const wordData = await getAPI().getWord(wordId);

    showWordDialog({
      wordId: wordId,
      initialValues: {
        text: wordData.base,
        type: wordData.type,
        wordData: wordData.data,
      },
      matchText: vocableText,
    });
  }, [dialogs, props.language, reloadSuggestions]);

  const handleMergeVocables = useCallback((vocableIds: Set<string>) => {
    setForcedVocableMerges([
      ...forcedVocableMerges,
      vocableIds
    ])
  }, [forcedVocableMerges, setForcedVocableMerges]);

  const handleSplitVocables = useCallback((vocableIds: Set<string>) => {
    // If the vocables are in a forced merge, remove it
    const newForcedMerges = forcedVocableMerges.filter(merge => !isEqual(new Set(merge), new Set(vocableIds)));
    setForcedVocableMerges(newForcedMerges);

    for (const vocableId of vocableIds) {
      // If there is a word node, remove it.
      trackIntf.removeWordNode(vocableId);
      trackIntf.setVocableAlternates(vocableId, {
        'sounds': undefined,
        'diacritics-auto': undefined
      });
    }
  }, [trackIntf, forcedVocableMerges, setForcedVocableMerges]);

  const handleCustomNodeEdited = useCallback((vocableIds: VocableId[], result: WordNode) => {
    trackIntf.setWordNode(result);
  }, [trackIntf]);

  const handleWordNodeSelected = useCallback((vocableIds: VocableId[], result: AnalyzeResult|null) => {
    // A missing result indicates the user unselected all nodes, so remove whatever node is assigned.
    if (!result) {
      trackIntf.removeWordNode(vocableIds[0]);
      for (const vocableId of vocableIds) {
        trackIntf.setVocableAlternates(vocableId, {
          'sounds': undefined,
          'diacritics-auto': undefined
        });
      }
      return;
    };

    const node = result.node;

    // The word node selected refers to a base word by string, which means it was detected on the server
    // side either with rules only, or possibly with a simple word list, but not based on word which is
    // in our database. We want to migrate all words to our database, step for step, so at this point,
    // we force the user to add it now.
    if (isWordNodeResult(result) && !isQueryByWordId(result.node.wordId)) {
      showWordDialog({
        initialValues: getInitialValuesFromResult(result as WordNodeResult<any>),
      });
    }

    // The word node selected already refers to a word in our word database, so we can assign this
    // word node directly to the vocable.
    else {
      trackIntf.setWordNode(node);

      if ('alternates' in result && result.alternates) {
        for (const vId of node.vocables) {
          const alternatesForThisVocable = result.alternates[vId];
          if (alternatesForThisVocable) {
            trackIntf.setVocableAlternates(vId, alternatesForThisVocable);
          }
        }
      }
    }
  }, [trackIntf, showWordDialog]);

  const handleVocableTextChange = useCallback((vocableId: string, text: string) => {
    trackIntf.setVocableText(vocableId, text);
    reloadSuggestions();
  }, [trackIntf, reloadSuggestions]);

  const [shiftKeyPressed,] = useKeyPress('F10');

  const lineCount = trackIntf.getLineCount();

  return <div className={styles.root}>

    <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }}>
      <div>
        {props.extraButtons}
      </div>

      {/*{(shiftKeyPressed && props.captions) ? <Button onClick={() => {*/}
      {/*  const data = slateValueToCaptions(new Captions(editor.children as CaptionTrackRoot));*/}
      {/*  //alert("Function Temporarily not available.")*/}


      {/*  // function removeVowelsFromVocables(captions: CaptionTrack): void {*/}
      {/*  for (const lineGroup of data.lines) {*/}
      {/*    for (const line of lineGroup.elements) {*/}
      {/*      for (const vocable of line) {*/}
      {/*        vocable.text = vocable.text.normalize("NFD").replace(/[\u064b-\u065b]/g, "")*/}
      {/*      }*/}
      {/*    }*/}
      {/*  }*/}

      {/*  setLines(loadLinesFromExternalCaptionTrack(data));*/}
      {/*}} >DO!</Button> : null}*/}

      {contentIssues ? <IssueManagerButton
        issues={contentIssues}
        setCurrentLineIdx={setCurrentLineIdx}
      /> : null}

      {props.playIntf ? <AudioControls
        playIntf={props.playIntf}
        currentLineIndex={currentLineIndex}
      /> : null}
    </div>

    <LineBrowser
      currentLineIndex={currentLineIndex}
      lineCount={lineCount}
      setCurrentLineIdx={setCurrentLineIdx}
      trackAPI={trackIntf}
      contentIssues={contentIssues}
    />

    <LinePreview
      words={words}
      translationLine={props.translation?.[currentLineIndex]}
    />

    <div style={{position: 'relative'}}>
      <LineTable
        trackAPI={props.trackIntf}
        trackVersionId={trackVersionId}
        contentIssues={contentIssues}
        words={words}
        lineIndex={currentLineIndex}
        nodeSuggestions={nodeSuggestions}
        isDataLoaded={hasData}
        forcedVocableMerges={forcedVocableMerges}
        language={props.language}
        onCreateWordClick={handleCreateWordClick}
        onEditWordClick={handleEditWordClick}
        onOptionSelected={handleWordNodeSelected}
        onMergeVocables={handleMergeVocables}
        onSplitVocables={handleSplitVocables}
        onVocableTextChange={handleVocableTextChange}
        onAssignCustomNode={handleCustomNodeEdited}
      />

      {error ? <div className={styles.errorOverlay}>
        <h3>Error: Suggestions could not be loaded.</h3>
        <p>
          Try again by clicking first next, then previous.
        </p>
      </div> : null}
    </div>

  </div>
}


function AudioControls(props: {
  playIntf: PlayInterface,
  currentLineIndex: number
}) {
  const [autoPlay, setAutoPlay] = useLocalStorage<boolean>('languagetoolEditor.AudioControls.autoPlay', true);

  useEffect(() => {
    if (autoPlay) {
      props.playIntf.playLine(props.currentLineIndex);
    }
  }, [props.currentLineIndex]);

  return <div>
    <ButtonGroup color="primary" size={"small"}>
      <Button onClick={() => {
        props.playIntf.playLine(props.currentLineIndex);
      }}>Play!
      </Button>
      <ToggleButton
        selected={autoPlay}
        onChange={() => {
          setAutoPlay(!autoPlay);
        }}
      >
        Auto Play
      </ToggleButton>
    </ButtonGroup>
  </div>
}