import * as React from "react";
import {DefinitionAnnotation} from "../../../annotations/Definition";
import {PopupCommonStyles} from "../styles";
import {Text} from 'react-native';
import {superScriptNumber} from "./Dictionary";
import { TextBlock } from "../../Base/Text";


/**
 * Render a `DefinitionAnnotation`.
 */
export class Definition extends React.Component<{
  definitions: DefinitionAnnotation[],
}> {

  render() {
    const {definitions} = this.props;

    let content;
    if (definitions.length == 1) {
      const def = definitions[0];
      content = def.text || def.words?.join(', ')
    } else {
      content = definitions.map((def, idx) => {
          return <TextBlock key={idx}>
            <Text style={{color: 'gray', fontStyle: 'italic'}}>{superScriptNumber(idx+1)}</Text>
            {" "}
            <Text>{def.text || def.words?.join(', ')}</Text>
            {" "} {" "}
          </TextBlock>
        })
    }

    return <Text style={PopupCommonStyles.defaultText}>{content}</Text>;
  }
}

