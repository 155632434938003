/**
 * We need a font as both an opentype.js object (to render it) as well as an array buffer (to pass to harfbuzz).
 *
 * NB: It might be possible to configure harfbuzz such that it will read the data it needs from helper functions,
 * and we can provide those helper functions and read from the opentype.js object, so we do not have to keep the
 * data in memory twice.
 */

import * as opentype from 'opentype.js';
import {useLoadFile} from "./utils";
import {useMemo} from 'react';


/**
 * A custom font object which holds both the buffer and the parsed variant.
 */
export class Font {
  public buffer: ArrayBuffer;
  public font: opentype.Font;

  constructor(opts: {
    buffer?: ArrayBuffer,
    font?: opentype.Font
  }) {
    if (opts.buffer) {
      this.buffer = opts.buffer;
    }
    if (opts.font) {
      this.font = opts.font;
    }
    // @ts-ignore
    if (!this.buffer && !this.font) {
      throw new Error("Requires either font or buffer");
    }
    // @ts-ignore
    if (!this.buffer) {
      // @ts-ignore
      this.buffer = this.font.toArrayBuffer();
    }
    // @ts-ignore
    if (!this.font) {
      this.font = opentype.parse(this.buffer);
    }
  }
};


/**
 * Can be a url, already loaded array buffer etc.
 */
export type LoadableFontSpecs = string|ArrayBuffer|opentype.Font;
export function useFont(font: LoadableFontSpecs): [Font|null, {loading: boolean, error: any}] {
  const [loadedBuffer, loadingState] = useLoadFile(typeof font === 'string' ? font : null);

  const result = useMemo(() => {
    if (font instanceof opentype.Font) {
      return new Font({font});
    }

    if (font instanceof ArrayBuffer) {
      return new Font({buffer: font});
    }

    if (loadedBuffer) {
      return new Font({buffer: loadedBuffer});
    }

    return null;
  }, [font, loadedBuffer]);

  return [result, loadingState];
}