/**
 * Two things:
 *
 * - Splits vocables into two when typing whitespace.
 *
 * - Attempts to solve the inline cursor problem by controlling the text insertion; although the cursor might be
 * in the wrong place, we catch `insertText` and insert the text where we want to.
 *
 * Also see the issues here:
 *   https://github.com/ianstormtaylor/slate/issues/3148 (the current state)
 *   https://github.com/ianstormtaylor/slate/issues/3151
 *   https://github.com/ianstormtaylor/slate/issues/1610 (old discussion)
 *
 * See the docs for an overview of the problem.
 */

import {Editor, Text, Range, Node, Element, Path, Transforms} from "slate";
import {allAreWordCharacters, isInVocable, splitCurrentVocableWithWhitespace, startNewVocable} from "./withVocableControl.helpers";
import { isWordInline } from "../../models/Captions/types";


/**
 * Slate.js plugin which does the following:
 *
 * 1. Splits an existing "word inline" when inserting whitespace inside.
 * 2. Starts a new "word inline" when the user types an alpha numeric character outside of an inline.
 * 3. Marks node as "inline" if they are type=timed.
 */
export function withVocableControl<T extends Editor>(editor: T): T {
  const {insertText, isInline} = editor;

  editor.isInline = (node: any) => {
    if ("type" in node && node.type == 'timed') {
      return true;
    }
    return isInline(node);
  }

  editor.insertText = (text: string) => {
    const range = editor.selection;

    // Sometimes the cursor is (at the beginning of a line only):
    //   <line><timed>|foo</timed></line>
    // By the time `insertText` invoked though, Slate seems to normalize this to
    //   <line>|<timed>foo</timed></line>

    if (text.length === 1 && range) {
      const isWordCharacter = allAreWordCharacters(text);

      // If it's a whitespace/comma, and we are in a "word inline", split it here.
      if (!isWordCharacter && isInVocable(editor, range)) {
        splitCurrentVocableWithWhitespace(editor, text, range);
        return;
      }

      // If it is a word character, and the cursor is right after a "word inline",
      // even if it is **outside** of the inline, still insert as part of the inline.
      // Same thing if it is right before.
      if (isWordCharacter && possiblyForceInsertInsideOfVocable(editor, text, range)) {
        return;
      }

      // If it is a word character, and we did not insert it as part of the previous sibling,
      // and we are not in a vocable, start a new vocable.
      console.log(isWordCharacter, isInVocable(editor, range))
      if (isWordCharacter && !isInVocable(editor, range)) {
        startNewVocable(editor, text, range);
        return;
      }

      // Fall back to the default, which means: text is either inserted inside or outside
      // vocable, depending on where the cursor is. Because slate positions the cursor always
      // *outside* the inline at the end of an inline, typing a whitespace will automatically
      // insert that whitespace outside of the inline, thus essentially paving the way to create
      // a new one, skipping the `possiblyForceInsertInsideOfVocable` logic. If the cursor *were*
      // inside at the end of the inline, then `splitCurrentVocableWithWhitespace` above would
      // trigger and do the job.
    }

    insertText(text);
  }

  return editor;

}


/**
 * If the cursor is at the boundary of a wordinline, always make sure we insert the new text INSIDE.
 */
function possiblyForceInsertInsideOfVocable(editor: Editor, text: string, range: Range) {
  /**
   * We'd always expect the cursor to be in a text node, either inside a WordInline, or in a text node that
   * is in between two WordInline elements. If there is no text node, something must be wrong I think.
   */
  const textNode: Text = Node.get(editor, range.focus.path) as Text;
  if (!textNode) {
    console.log('Slate.js cursor position outside of a text node, this is unexpected.')
    return false;
  }

  // Now, we only do something if we are at the beginning, or at the end, of this text node.
  const isAtBeginningOfText = !range.focus.offset &&
    // Should not be the first node in a line though!
    range.focus.path[range.focus.path.length-1] > 0;
  const isAtEndOfText = range.focus.offset === textNode.text.length;

  // If the caret is at the beginning of a text node, then it could be either the first position right at
  // the start of a vocable, or the first position right after the end of a vocable.
  const inVocable = isInVocable(editor, range);

  // IN ALL CASES we enforce that the text is attached to the vocable itself.

  // We are still inside the vocable, at the end. Sometimes (in Chrome for example), despite this cursor
  // position, the new character will actually be inserted outside of the inline. We force it.
  if (inVocable && isAtEndOfText) {
    console.log('add at end of current', range.focus)

    const insertPoint = {
      path: range.focus.path,
      offset: textNode.text.length
    }

    Transforms.insertText(editor, text, {at: insertPoint});
    return true;
  }

  // We are at the beginning of a vocable, still inside the inline.
  if (inVocable && isAtBeginningOfText) {
    console.log('add at beginning of current', range.focus)

    const insertPoint = {
      path: range.focus.path,
      offset: 0
    }

    Transforms.insertText(editor, text, {at: insertPoint});
    Transforms.move(editor,{distance: 1, unit: 'character'});
    return true;
  }

  // Force cursor to insert at the end of the word, inside of it.
  if (isAtBeginningOfText) {
    console.log('add at end of last', range.focus)
    let prevPath: Path|undefined = undefined;
    try {
      prevPath = Path.previous(range.focus.path);
    } catch (e) {
    }

    if (prevPath) {
      const prevNode = Node.get(editor, prevPath);

      if (Node.has(editor, prevPath) && isWordInline(prevNode)) {
        const insertPoint = {
          path: [...prevPath, prevNode.children.length - 1],
          offset: prevNode.children[prevNode.children.length - 1].text.length
        }

        Transforms.insertText(editor, text, {at: insertPoint});
        return true;
      }
    }
  }

  else if (isAtEndOfText) {
    console.log('add at beginning of next', range.focus)
    let nextPath: Path|undefined = undefined;
    try {
      nextPath = Path.next(range.focus.path);
    } catch (e) {
    }


    if (nextPath && Node.has(editor, nextPath)) {
      const nextNode = Node.get(editor, nextPath);

      if (isWordInline(nextNode)) {
        const insertPoint = {
          path: [...nextPath, 0],
          offset: 0
        }

        Transforms.insertText(editor, text, {at: insertPoint});
        Transforms.move(editor,{distance: 1, unit: 'character'});
        return true;
      }
    }
  }
}