import langenscheidt from './transliteration-tables/langenscheidt.yaml';
import wiktionary from './transliteration-tables/wiktionary.yaml';
import englishCasual from './transliteration-tables/english-casual.yaml';
import libraryOfCongress from './transliteration-tables/library-of-congress.yaml';
import ipa from './transliteration-tables/ipa.yaml';
import ijmes from './transliteration-tables/ijmes.yaml';
import alefbaye2om from './transliteration-tables/alefbaye2om.yaml';
import bgnPcgn from './transliteration-tables/bgn-pcgn.yaml';
import efarsi from './transliteration-tables/efarsi.yaml';
import iranica from './transliteration-tables/iranica.yaml';
import { pickBy } from 'lodash';


export type TranslitDef = {
  name: string,
  unlisted?: boolean,
  sounds: {[key: string]: string}
}


const styles: {[key: string]: TranslitDef} = {
  "english-casual": englishCasual,
  "library-of-congress": libraryOfCongress,
  langenscheidt,
  wiktionary,
  ijmes,
  iranica,
  efarsi,
  'bgn-pcgn': bgnPcgn,
  alefbaye2om,
  ipa
};

const listedStyles = pickBy(styles, x => !x.unlisted);


const defaultStyle = 'wiktionary';


export function getTransliterationTable(style: string) {
  return styles[style];
}


export function romanizeSoundsAsArray(sounds: string[], opts?: {style: string}): string[] {
  const style = (opts && opts.style) ? opts.style : defaultStyle;
  const dataTable = getTransliterationTable(style);

  if (!dataTable) {
    throw new Error(`Invalid style: ${style}`)
  }

  return sounds.map(s => {
    let char = dataTable.sounds[s];
    if (!char) {
      char = nonSoundTable[s];
    }
    return char;
  });
}


export function romanizeSounds(sounds: string[], opts?: {style: string}): string {
  return romanizeSoundsAsArray(sounds, opts).join("");
}


const nonSoundTable: {[key: string]: string} = {
  word_separator: ' ',
  breakpoint: '',
  dash: '-',
}


export function getTransliterationStyles(): {[key: string]: TranslitDef} {
  return listedStyles;
}