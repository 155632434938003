import React from 'react';
import {Text} from '@inlet/react-pixi'
import {TextStyle} from 'pixi.js';


type Props = {
  isLoading: boolean,
  hasData: boolean,
  stageHeight: number
};


const style = new TextStyle({
  fontFamily: 'Arial',
  fontSize: 12,
  fontStyle: 'italic',
  fill: 'silver'
});



export const LoadingStatus = React.memo(function LoadingStatus(props: Props) {
  let textPos = {
    x: 10,
    y: props.stageHeight - 20
  }
  if (props.isLoading) {
    return <Text
      {...textPos}
      text={"Loading..."}
      style={style}
    />
  }
  if (!props.hasData) {
    return <Text
      {...textPos}
      text={"Wave form not available."}
      style={style}
    />
  }

  return null;
});