// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-components-MuiControls__menuItemSubtitle--3dgrC{color:gray;font-size:.8em;margin-left:1em}\n", ""]);
// Exports
exports.locals = {
	"menuItemSubtitle": "_-languagetool-editor-src-js-components-MuiControls__menuItemSubtitle--3dgrC"
};
module.exports = exports;
