import React from "react";
import {Text} from 'react-native';
import { TextBlock } from "../../Base/Text";

export function Em(props: { children: any, padRight?: boolean }) {
  return <TextBlock style={{
    fontSize: 10,
    color: 'gray',
    textTransform: 'lowercase',
    paddingRight: props.padRight ? 4 : undefined
  }}>
    {props.children}
  </TextBlock>
}