import styles from "./index.cssm";
import * as React from "react";
import {Fragment, useState, useCallback} from "react";
import classnames from "classnames";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {Vocable} from "languagetool-player/src/models";
import {TrackInterface} from "../assignScreen";


export function LinePreview(props: {
  words: Vocable[],
  translationLine?: string[]
}) {
  return <div className={styles.linePreview} style={{direction: 'rtl'}}>
    {
      props.words.map((word: any) => {
        return <Fragment key={word.id}>
          <span className={styles.wordNormal}>
            {word.text}
          </span>
          <span className={styles.wordVowels}>
            {word.alternates?.['diacritics-auto'] ?? word.text}
          </span>
          <span className={styles.wordRoman}>
            {word.alternates?.sounds ? romanizeSounds(word.alternates['sounds']) : word.alternates?.roman}
          </span>
        </Fragment>;
      })
    }
    {props.translationLine ? <div className={styles.translation}>
      {props.translationLine.join(" ")}
    </div> : null}
  </div>;
}

/**
 * Help us browse the caption track line by line.
 *
 * Holds the state of the current line index, allows us to change it, and returns a list of
 * words for the current line.
 */
export function useLineByLineBrowse(opts: {
  trackAPI: TrackInterface
  initialLine?: number
}) {
  const maxIndex = opts.trackAPI.getLineCount() - 1;

  const [currentLineIndex, setCurrentLineIdx] = useState(Math.min(opts.initialLine || 0, maxIndex));

  const setSafeLineIndex = useCallback((index: number) => {
    setCurrentLineIdx(Math.min(index, maxIndex));
  }, [setCurrentLineIdx, maxIndex]);

  const words = React.useMemo(() => opts.trackAPI.getLine(currentLineIndex), [currentLineIndex, opts.trackAPI]);

  return {
    currentLineIndex,
    setCurrentLineIdx: setSafeLineIndex,
    words
  }
}

export function Cell(props: any) {
  const {className, ...others} = props;
  return <div
    className={classnames(styles.cell, className)}
    {...others}
  >
    {props.children}
  </div>
}


export function Row(props: any) {
  const {className, ...others} = props;
  return <div
    className={classnames(styles.tableRow, className)}
    {...others}
  >
    {props.children}
  </div>
}

export function HeaderRow(props: any) {
  const {className, ...others} = props;
  return <div
    className={classnames(styles.tableHeader, className)}
    {...others}
  >
    {props.children}
  </div>
}