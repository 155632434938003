import * as React from "react";
import {WordStructureTable} from "./WordStructureTable";
import {getCanonicalFromwWordId} from "languagetool-player/src/ui/WordPopup/parts/WordDisplay";
import {WordTypeLabels} from "../WordForm/fields/WordTypeField";
import {CheckIcon, WordDataQueryResult} from "./NodeOptionPicker";
import {shouldRenderGrammar} from "languagetool-player/src/langpacks/fa/GrammarDescription";
import {getVerbFormName, getVerbPersonName} from "languagetool-player/src/langpacks/fa/formatters";
import {romanizeSounds} from "languagetool-player/src/langpacks/fa/transliterate";
import {FarsiWordNode} from "languagetool-player/src/langpacks/fa";
import {Fragment} from "react";


/**
 * Show a single word node in the <LineTable /> for the user to select.
 *
 * Display a basic overview of the structure detected and the base form.
 */
export function WordNodeOption(props: {
  wordNode: FarsiWordNode,
  wordData?: WordDataQueryResult,
  language: string,
  showReviewedMark?: boolean
}) {
  const {wordData} = props;
  const {structure, wordId, inflection} = props.wordNode;

  const roman = wordData?.sounds ? <small key={"roman"}>{" "}{romanizeSounds(wordData?.sounds)}</small> : null;

  let summary = null;
  if (inflection?.type === 'verb') {
    summary = <span style={{
      paddingLeft: '0.5em',
      fontSize: '0.9em'
    }}>
        ({getVerbFormName(inflection.form)}, {getVerbPersonName(inflection.person)}){inflection.passive ? ", passive" : null}
      </span>;
  }

  let structureDisplay = null;
  if (structure && shouldRenderGrammar(props.wordNode)) {
    structureDisplay = <>
      <WordStructureTable
        structure={structure}
        language={props.language}
      />
    </>;
  }


  let label: (string|React.ReactElement|null)[] = [];

  if (inflection) {
    label.push(<Fragment key={"inflection"}>
      <span style={{
        textTransform: 'uppercase',
        fontSize: '0.8em'
      }}>
        {getWordTypeName(inflection.type)}
      </span>: {(wordData?.isReviewed && props.showReviewedMark) ? <CheckIcon style={{paddingRight: '5px'}} /> : null}
    </Fragment>);
  }
  if (props.wordNode.isManual) {
    label.push(<span key={"manual-flag"}>Manually Selected: </span>);
  }
  label.push(wordData?.base || getCanonicalFromwWordId(wordId));
  label.push(roman);


  return <div  style={{
    display: 'flex',
    alignItems: 'center',
  }}>
    <span style={{marginRight: '8px'}}>{label}</span>
    {structureDisplay}
    {summary}
  </div>
}


export function getWordTypeName(wordType: string) {
  return (WordTypeLabels as any)[wordType] || wordType;
}