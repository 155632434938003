import React, { useEffect } from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {EditWord} from "./screens/EditWord";
import {initializeIcons} from '@uifabric/icons';
import {Fabric, SearchBox} from 'office-ui-fabric-react';
import {GlobalSettings} from "./GlobalSettings";
import {useAuth0} from "./utils/Auth0Provider";
import {LoginScreen} from "./screens/LoginScreen";
import {setGlobalAuthKey} from "languagetool-editor/src/js/integrations/Market/api";
import {ApolloRoot} from "./ApolloRoot";
import {FabricUIFormControls} from "./components/FabricUIFormControls";
import {FormUISwitcher} from "languagetool-editor/src/js/components/FormUISwitch";
import Link from "./components/Link";
import MainScreen from "./screens/WordList";
import {IconButton} from 'office-ui-fabric-react';
import styles from "./Navigation.module.css";
import {TodosPage} from "./screens/Todos";
import {ActivityLogPage} from "./screens/ActivityLog";
import {MessageBarProvider, MessageBarRender} from "./components/MessageBarManager";
import { useHistory } from "react-router-dom";
import {RecordingWorkflowScreen} from "./screens/RecordingWorkflow/Screen";
import {AnimatedWord, AnimateWordRoot} from "./screens/Render/AnimatedWord";


export default function App() {
  useEffect(() => {
    initializeIcons();
  }, []);

  const {isAuthenticated, getTokenSilently} = useAuth0();

  // We need to init the global api key for the old-style calls in languagetool-editor
  useEffect(() => {
    if (isAuthenticated) {
      getTokenSilently().then((token: any) => {
        setGlobalAuthKey(`Bearer ${token}`);
      })
    }
  }, [isAuthenticated])

  return (
    <Router>
      <Switch>
        <Route path="/render/animate-word/:id" render={
          (props) => {
            return  <AnimateWordRoot {...props} />
          }
        }
        />
        <Route>
          <ApolloRoot
            getAuthHeader={async () => {
              if (isAuthenticated) {
                const token = await getTokenSilently();
                return `Bearer ${token}`;
              }
              else {
                return "";
              }
            }}
          >
            <GlobalSettings>
              <Fabric>
                <FormUISwitcher
                  uiControls={FabricUIFormControls}
                >
                  <div className="App">
                    <LoginLock>
                      <AppRouter />
                    </LoginLock>
                  </div>
                </FormUISwitcher>
              </Fabric>
            </GlobalSettings>
          </ApolloRoot>
        </Route>
      </Switch>
    </Router>
  );
}



function LoginLock(props: {
  children: any
}) {
  const {isAuthenticated, loading: authLoading} = useAuth0();

  let contents;
  if (isAuthenticated) {
    contents = props.children;
  } else if (authLoading) {
    contents = null;
  } else {
    contents = <LoginScreen />
  }

  return contents;
}


function AppRouter(props: {}) {
  return <MessageBarProvider>
    <Switch>
      <Route path="/recording-session/:id" render={
        (props) => {
          return  <RecordingWorkflowScreen
            recordingSessionId={props.match.params.id}
            style={{height: '100vh'}}
          />
        }
      }
      />

      <Route>
        <Nav />
        <MessageBarRender />

        <Switch>
          <Route path="/log" component={ActivityLogPage} />
          <Route path="/todos" component={TodosPage} />
          <Route path="/word/:id" component={EditWord}/>
          <Route component={MainScreen} />
        </Switch>
      </Route>
    </Switch>
  </MessageBarProvider>
}


function Nav(props: {}) {
  const history = useHistory();
  const {logout} = useAuth0();

  return <div className={styles.nav}>
    <Link href={"/log"}>Log</Link>
    &nbsp;&bull;&nbsp;
    <Link href={"/todos"}>Todos</Link>
    &nbsp;&bull;&nbsp;
    <Link href={"/"}>Words</Link>

    <div style={{flex: 1}}></div>

    <SearchBox
      styles={{ root: { width: 200 } }}
      placeholder="Search Words"
      onSearch={newValue => {
        history.push('/?search=' + newValue);
      }}
    />

    <div style={{width: '50px'}}></div>

    <IconButton
      style={{float: 'right'}}
      iconProps={{iconName: 'leave'}} title="Logout" ariaLabel="Logout"
      onClick={logout}
    />
  </div>
}