import * as React from "react";
import {useUIControls} from "../../../../../../components/FormUISwitch";
import {NestableField} from "../../../../../../components/NestedFormik";


export function StemsField(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={"stems"}
    component={UI.Text}

    label="Custom Stems"
    description={"Multiple are comma separated."}
    style={{
      direction: 'rtl',
      textAlign: 'left'
    }}
  />;
}