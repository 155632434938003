import {Field} from "formik";
import {TextField} from "formik-material-ui";
import React from "react";

export function MeaningDescriptorField() {
  return <Field
    name={"meaningDescriptor"}
    label="Describe the meaning"
    component={TextField}
    margin="normal"
    helperText={"Ideally, use a single word. This is important in relationship to other possible meanings of the word."}
  />;
}