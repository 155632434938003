import React, { Fragment } from "react";
import {gql as gqlw} from "@apollo/client";
import {formatDistanceToNow, parseISO, differenceInMinutes} from "date-fns";
import {DateTime} from "../utils/DateTime";
import {diff, Diff} from "deep-diff";
import {Link} from "react-router-dom";
import {ActivityLogEntryFragment} from "../types/ActivityLogEntryFragment";
import styles from "./AnnotationLogEntry.module.css";
import {
  WordLinkWithDetails,
  WordWithDetailsFragment
} from "../utils/WordWithDetails";
import ReactMarkdown from "react-markdown";

export const activityLogEntryFragment = gqlw`
  ${WordWithDetailsFragment}
  fragment ActivityLogEntryFragment on LogEntry {
    id,
    timestamp,
    eventType,
    actingUser { id, username },
    data,
    
    word {
      id,
      base
      ...WordWithDetails
    }
  }
`;

type TitleOpts = {
  hideWord?: boolean;
};

/**
 * This renders a single entry.
 */
export function ActivityLogEntry(props: {
  logEntry: ActivityLogEntryFragment;
  onUserClick?: (userId: number) => void;
}) {
  const {logEntry: entry} = props;

  let renderFunc: (data: any) => any =
    RenderFuncs[entry.eventType!] || renderUnknownEntry;
  let renderTitleFunc: (data: any) => any =
    RenderTitleFuncs[entry.eventType!] || renderUnknownTitle;

  return (
    <div style={{margin: "20px 0"}}>
      <div>
        <strong>{formatDistanceToNow(parseISO(entry.timestamp))} ago</strong> on
        the{" "}
        <strong>
          <DateTime date={entry.timestamp} />
        </strong>{" "}
        by{" "}
        <strong
          onClick={() => {
            if (!props.onUserClick) {
              return;
            }
            props.onUserClick(parseInt(entry?.actingUser?.id ?? ""));
          }}
        >
          {entry.actingUser
            ? entry.actingUser.username || entry.actingUser.id
            : "unknown"}
        </strong>
        : {renderTitleFunc(entry)}
      </div>

      {renderFunc(entry.data)}
    </div>
  );
}

type RenderTitleFunc = (entry: any, opts?: TitleOpts) => any;
const RenderTitleFuncs: {[name: string]: RenderTitleFunc} = {
  word_created: renderWordTitle,
  word_changed: renderWordTitle,
  word_approved: renderWordTitle,
  word_unapproved: renderWordTitle,
  word_deleted: renderWordTitle,
  note_created: renderWordTitle
};

function renderUnknownTitle(entry: any) {
  return <strong>{entry.eventType}</strong>;
}

function renderWordTitle(entry: ActivityLogEntryFragment, opts?: TitleOpts) {
  const word = entry.word;

  let parts = [];

  parts.push(<strong key={"type"}>{entry.eventType}</strong>);

  if (!opts?.hideWord) {
    let link;
    if (word) {
      link = <WordLinkWithDetails {...word} hideReviewedMark={true} />;
    } else {
      const data = JSON.parse(entry.data);
      link = (
        <Link to={`/word/${data.word_id}`}>
          <span>now deleted word</span>
        </Link>
      );
    }
    parts.push(<Fragment key={"post"}> for {link}</Fragment>);
  }

  return <span>{parts}</span>;
}

type RenderFunc = (data: any) => any;
const RenderFuncs: {[name: string]: RenderFunc} = {
  word_created: renderWithoutData,
  word_changed: renderWordChanged,
  word_approved: renderWithoutData,
  word_unapproved: renderWithoutData,
  word_deleted: renderWithoutData,
  note_created: renderNoteCreated
};

//<pre>{JSON.stringify(JSON.parse(data), null, 4)}</pre>;

function renderWithoutData(data: any) {
  return null;
}

function renderNoteCreated(data: string) {
  const parsed = JSON.parse(data);
  const note = JSON.parse(parsed.note);
  return <ReactMarkdown source={note.text} className={styles.note} />;
}

function renderWordChanged(data: any) {
  const parsed = JSON.parse(data);
  const changes = diff(maybeJson(parsed.old), maybeJson(parsed.new), {
    normalize: (path, key, lhs, rhs) => {
      const fullpath = [...path, key].join("/");
      if (
        ["base_translit", "stems", "data/primary", "data/sounds"].indexOf(
          fullpath
        ) > -1
      ) {
        return [lhs?.join(", ") ?? "", rhs?.join(", ") ?? ""];
      }

      return [lhs, rhs];
    }
  });
  if (!changes) {
    return null;
  }
  return (
    <div>
      {/*<pre>{JSON.stringify(maybeJson(parsed.old), null, 4)}</pre>*/}
      {/*<pre>{JSON.stringify(maybeJson(parsed.new), null, 4)}</pre>*/}
      {changes.map((change, idx) => {
        return <li key={idx}>{renderDiffChange(change)}</li>;
      })}
    </div>
  );
}

function renderDiffChange(change: Diff<any, any>) {
  const path = change?.path?.join("/");

  // property/element was edited
  if (change.kind === "E") {
    return (
      <span>
        Changed <strong>{path}</strong> from{" "}
        <strong>{JSON.stringify(change.lhs)}</strong> to{" "}
        <strong>{JSON.stringify(change.rhs)}</strong>.
      </span>
    );
  }

  // newly added property/element
  if (change.kind === "N") {
    return (
      <span>
        Added <strong>{JSON.stringify(change.rhs)}</strong> to{" "}
        <strong>{path}</strong>.
      </span>
    );
  }

  // deleted property/element
  if (change.kind === "D") {
    return (
      <span>
        Removed <strong>{JSON.stringify(change.lhs)}</strong> from{" "}
        <strong>{path}</strong>.
      </span>
    );
  }

  return <pre>{JSON.stringify(change, null, 4)}</pre>;
}

function renderUnknownEntry(data: any) {
  return <div style={{fontStyle: "italic"}}>This log event is unknown.</div>;
}

function maybeJson(data: any): any {
  if (typeof data == "string") {
    return JSON.parse(data);
  }
  return data;
}

type GroupedEntry = {
  user: string | undefined;
  time: Date;
  groupedEntries: ActivityLogEntryFragment[];
};

/**
 * Collapses log entries by the same acting user around the same time.
 */
export type LogEntry = {
  actingUser: {id: string};
  timestamp: string;
};
export function groupLogEntriesByUserAndTime(
  entries: ActivityLogEntryFragment[]
) {
  const result: GroupedEntry[] = [];

  for (const entry of entries) {
    let currentGroup = result[result.length - 1];

    const timeOfEntry = parseISO(entry.timestamp);

    // Add a new group?
    let addNewGroup = false;
    if (!currentGroup) {
      addNewGroup = true;
    } else {
      addNewGroup =
        currentGroup.user != entry.actingUser?.id ||
        differenceInMinutes(currentGroup.time, timeOfEntry) > 15;
    }

    if (addNewGroup) {
      currentGroup = {
        user: entry.actingUser?.id,
        time: timeOfEntry,
        groupedEntries: []
      };
      result.push(currentGroup);
    }

    currentGroup.groupedEntries.push(entry);
  }

  return result;
}

/**
 * This renders a grouped entry.
 */
export function ActivityLogGroupedEntry(props: {
  entry: GroupedEntry;
  onUserClick?: (userId: number) => void;
  hideTitle?: boolean;
}) {
  const {entry} = props;

  const actingUser = entry.groupedEntries[0].actingUser;

  return (
    <div style={{margin: "20px 0"}} className={"group"}>
      <div className={styles.groupTitle}>
        <strong>{formatDistanceToNow(entry.time)} ago</strong> on the{" "}
        <strong>
          <DateTime date={entry.time} />
        </strong>{" "}
        by{" "}
        <strong
          onClick={() => {
            if (!props.onUserClick) {
              return;
            }
            props.onUserClick(parseInt(actingUser?.id ?? ""));
          }}
        >
          {actingUser ? actingUser.username || actingUser.id : "unknown"}
        </strong>
        :{" "}
      </div>

      {entry.groupedEntries.map(e => {
        let renderFunc: (data: any) => any =
          RenderFuncs[e.eventType!] || renderUnknownEntry;
        let renderTitleFunc: RenderTitleFunc =
          RenderTitleFuncs[e.eventType!] || renderUnknownTitle;

        return (
          <div key={e.id!} className={styles.groupEntry}>
            {renderTitleFunc(e, {hideWord: props.hideTitle})}
            {renderFunc(e.data)}
          </div>
        );
      })}
    </div>
  );
}
