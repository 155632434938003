import {StyleSheet} from 'react-native';


export const PopupCommonStyles = StyleSheet.create({
  defaultText: {
    fontSize: 20
  },

  grayPrefix: {
    fontSize: 16,
    color: 'gray'
  }
});