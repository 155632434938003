import React from "react";

export function handleApolloState(state: {loading: boolean; error: any}) {
  if (state.loading) {
    return <div>Loading...</div>;
  }
  if (state.error) {
    return <div>ERROR!</div>;
  }
}
