import {ICaptionsEditor, SlateEditor} from "../ICaptionsEditor";
import {Path, Transforms, createEditor} from "slate";
import {CaptionTrackRoot} from "./types";


/**
 * This is a generic interface our helper functions use to transform the tree.
 *
 * The reason we need this is because we have different trees to transform:
 *
 * - A value wrapped into a Slate.js <Editor> controller, because we want it to apply the
 *   correct plugins, the history operations for undo, and so on.
 *
 * - A simple Slate.js `Value` structure, were we make changes directly, without
 *   involving a Slate.js controller - thus we have no undo, and we use this for the "shadow"
 *   value, to preview a change before it is committed to history.
 */
export interface ICaptionOps {
  // NB! A slate.js setNode() call cannot set children!
  setNode(path: Path, properties: any): void,

  value: CaptionTrackRoot,
}


/**
 * Work with a Slate.js `Value`, which is an immutable.js record.
 *
 * Note that the undo/redo logic is not implemented here, we make the changes directly to the data structure.
 */
export function newValueOps(value: CaptionTrackRoot): ICaptionOps {
  const editor = createEditor();
  editor.children = value;
  return {
    setNode(path, p) {
      Transforms.setNodes(editor, p, {at: path});
    },
    get value() {
      return editor.children as CaptionTrackRoot;
    }
  }
}

/**
 * Work with an `ICaptionsEditor` interface, another layer of indirection we have on top of the Slate.js
 * controller. By applying changes to the Slate.js controller (`SlateEditor`), we change the "committed value",
 * and add the changes to the undo stack.
 *
 * Since this, deep down, uses the Slate.js Editor control, and the interface it provides,
 * this will add the changes to the undo stack.
 */
export function newEditorOps(editor: ICaptionsEditor): ICaptionOps {
  // editor.setNodeByPath always works based on the committed value.
  let value = editor.committedValue;
  return {
    setNode(path, p) {
      // Important: After we call setNodeByPath, ICaptionEditor.value is not guaranteed
      // to have been updated, but the caller expects ICaptionOps to provide the updated
      // value, we be must capture the return value from setNodeByPath.
      //
      // Slate.js's version of editor.setNodeByPath actually does update `editor.value`
      // straight away, but our `ICaptionsEditor` wrapper cannot provide the same guarantee,
      // for technical reasons.
      value = editor.setNodeByPath(path, p);
    },
    get value() {
      return value;
    }
  }
}

/**
 * Work directly with a Slate.js editor.
 */
export function newSlateEditorOps(editor: SlateEditor): ICaptionOps {
  return {
    setNode(path, props: any) {
      Transforms.setNodes(editor, props, {at: path});
    },
    get value() {
      return editor.children as CaptionTrackRoot;
    }
  }
}


/**
 * Whenever the value changes, run the callback. This behaves like `newValueOps` in the sense that
 * that `.value` always returns the freshest value, which allows you to use the ops multiple times,
 * each time seeing the latest value.
 */
export function newCallbackOps(value: CaptionTrackRoot, onChange: (v: CaptionTrackRoot) => void): ICaptionOps {
  const editor = createEditor();
  editor.children = value;
  return {
    setNode(path, p) {
      Transforms.setNodes(editor, p, {at: path});
      onChange(this.value);
    },
    get value() {
      return editor.children as CaptionTrackRoot;
    }
  }
}