// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__linePreview--3JkUg{margin:30px 0;display:grid;line-height:0.8em;grid-auto-columns:min-content;justify-content:center}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__linePreview--3JkUg ._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordRoman--36otF{padding:10px;grid-row:3;white-space:nowrap}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__linePreview--3JkUg ._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordVowels--2ebkB{padding:10px;grid-row:2;white-space:nowrap}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__linePreview--3JkUg ._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordNormal--_C8U7{padding:10px;grid-row:1;white-space:nowrap}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__tableHeader--2FA6h{display:table-row}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__tableHeader--2FA6h>div{padding:10px;text-transform:uppercase;border-bottom:1px solid #353535;font-size:.9em}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__tableRow--YKVpT{display:table-row;margin-bottom:2px}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__cell---Pehq{display:table-cell;padding:8px;border-bottom:1px solid gray}._-languagetool-editor-src-js-integrations-Words-LineByLineView-index__translation--3aiQS{direction:ltr;grid-row:4;grid-column:99 span;padding:10px;text-align:center}\n", ""]);
// Exports
exports.locals = {
	"linePreview": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__linePreview--3JkUg",
	"wordRoman": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordRoman--36otF",
	"wordVowels": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordVowels--2ebkB",
	"wordNormal": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__wordNormal--_C8U7",
	"tableHeader": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__tableHeader--2FA6h",
	"tableRow": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__tableRow--YKVpT",
	"cell": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__cell---Pehq",
	"translation": "_-languagetool-editor-src-js-integrations-Words-LineByLineView-index__translation--3aiQS"
};
module.exports = exports;
