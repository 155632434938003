import * as React from "react";
import {TranslationAnnotation, WordId} from "../../../annotations";
import {getLanguageDisplayName} from "../../../i18n";
import {WordLink} from "./WordLink";
import {View, Text} from 'react-native';
import {PopupCommonStyles} from "../styles";
import { TextBlock } from "../../Base/Text";

export class Translation extends React.Component<{
  translations?: TranslationAnnotation[]
}> {

  render() {
    const {translations} = this.props;

    if (translations && translations.length == 1) {
      return this.renderTranslation(translations[0], 0);
    }

    if (translations && translations.length > 1) {
      return <div>
        {translations.map((def, idx) => {
          return this.renderTranslation(def, idx);
        })}
      </div>
    }

    return null;
  }

  // noinspection JSMethodCanBeStatic
  renderTranslation(translation: TranslationAnnotation, idx: number) {
    const listOfWords = Array.isArray(translation.text) ? translation.text : [translation.text];
    return <View key={idx} style={{flexDirection: 'row'}}>
      <TextBlock style={PopupCommonStyles.defaultText}>
        <Text style={PopupCommonStyles.grayPrefix}>{getLanguageDisplayName(translation.lang)}&nbsp;{" "}</Text>
        <WordList words={listOfWords} source={translation.source ? translation.source.name : undefined}/>
      </TextBlock>
    </View>;
  }
}


const WordList: React.FunctionComponent<{
  words: (string | WordId)[],
  source?: string | undefined
}> = function (props) {
  const title = props.source;

  const inside = props.words.map((word, idx) => {
    const isLast = idx == props.words.length - 1;
    const isBeforeLast = idx == props.words.length - 2;
    let final;
    if (props.words.length <= 1) {
      final = ""
    } else if (isLast) {
      final = '.';
    } else if (isBeforeLast) {
      final = ' or ';
    } else {
      final = ', ';
    }

    let wordComp;
    if (typeof word != 'string') {
      // @ts-ignore TODO check this
      wordComp = <WordLink to={word} title={title}>{word.text}</WordLink>
    } else {
      // @ts-ignore rn-web types are missing the title attribute
      wordComp = <Text style={{}} title={title}>{word}</Text>;
    }

    return <TextBlock key={idx}>{wordComp}{final}</TextBlock>;
  });
  return <TextBlock>{inside}</TextBlock>;
}