import {useDialogs} from "../../../ui/dialogs/DialogManager";
import {useCallback} from "react";
import {
  AddEditWordDialog, AddEditWordDialogProps,
  BaseAddEditWordDialogProps,
} from "./AddEditWordDialog";
import {isWordNode, WordNode} from "languagetool-player/src/models/Node";
import {WordLikeNode} from "../../../models/Captions/wordNodes";
import {flatten, isEqual} from "lodash";
import {isQueryByString} from "languagetool-player/src/annotations";
import {NodeSuggestions, TrackInterface, WordDef} from "./index";
import {isWordNodeResult, WordNodeResult} from "../wordsApi";
import {FarsiWordNode} from "languagetool-player/src/langpacks/fa";


// Essentially the default values for how the showWordDialog() function show behave.
type ShowWordDialogProps = {
  language: string,
  onSave: (word?: WordDef) => void,
};


export type ShowWordDialogFunction = (
  opts: Partial<AddEditWordDialogProps>
) => void;


/**
 * Returns a function which shows a "create word" dialog.
 *
 * Will be initialized smartly based on the current vocables.
 *
 * TODO: This is now quite a bit generic to account for different ways to call into the function, and the
 * interaction between the arguments is a bit unusual. Maybe we should just let the caller pass in the
 * desired arguments for the dialog directly.
 */
export function useShowWordDialog(opts: ShowWordDialogProps): ShowWordDialogFunction {
  const dialogs = useDialogs();

  return useCallback((args) => {
    dialogs.show(AddEditWordDialog, {
      language: opts.language,
      onSave: opts.onSave,
      ...args
    })
  }, [dialogs, opts.language, opts.onSave]);
}


export function pickWordDialogDefaults(args: {
  // The vocables involved; used with track data, if available, to be smart about a default form setup.
  vocableIds?: string[],
  vocableText?: string,

  nodeSuggestions: NodeSuggestions,
  trackAPI: TrackInterface,
}) {
  const {vocableIds} = args;

  let dialogDefaults: Partial<BaseAddEditWordDialogProps>|null = null;
  let templateResults: WordNodeResult[]|null = null;

  // See if we can figure out if a multi-vocable word can be a compound.
  if (vocableIds && vocableIds.length === 2) {
    dialogDefaults = pickPossibleCompoundVerb({
      vocableIds,
      trackAPI: args.trackAPI,
      nodeSuggestions: args.nodeSuggestions
    })
  }

  // Should we suggest any of the server wordlist words?
  if (vocableIds && vocableIds.length === 1) {
    if (args.nodeSuggestions[vocableIds[0]]) {
      templateResults = args.nodeSuggestions[vocableIds[0]].filter(isWordNodeResult).filter(suggestion => {
        // Only word list suggestions (not database words).
        if (!isQueryByString(suggestion.node.wordId)) {
          return false;
        }
        return suggestion;
      });
    }
  }

  return {
    ...dialogDefaults,
    templateResults
  }
}


// Attempt to setup a correctly initialized compound verb screen (for now).
function pickPossibleCompoundVerb(opts: {
  nodeSuggestions: NodeSuggestions,
  trackAPI: TrackInterface,
  vocableIds: string[]
}) {
  // Returns True if the test returns true for least one of the node suggestions or current node for the vocable.
  function atLeastOneNodeOf(test: (node: FarsiWordNode) => boolean, idsToIgnore?: string[]): { isMatch: boolean, onlyNode: WordNode | null } {
    let matchingNodes: WordLikeNode[][];

    // For every current node of every vocable affected, test if they match
    matchingNodes = opts.vocableIds
      .filter(vId => {
        if (!idsToIgnore) { return true; }
        return idsToIgnore.indexOf(vId) == -1;
      })
      .map((vId) => {
      const currentNode = opts.trackAPI.getWordNodeForVocables([vId]) as FarsiWordNode;

      // If there is a current node, then only this node has to pass the test, we do not look at the suggestions.
      // This is because if there is more than one suggestion match, the auto-select will fail, and by giving
      // preference to the node the user has selected, we alllow the user to "help".
      if (currentNode && isWordNode(currentNode) && currentNode.inflection) {
        if (test(currentNode)) {
          return [currentNode];
        }
      }

      // Otherwise, any of the suggestions is allowed to pass the test.
      if (!(vId in opts.nodeSuggestions)) {
        return [];
      }
      return opts.nodeSuggestions[vId].map(result => result.node as FarsiWordNode).filter(node => {
        // But only consider suggestions that target the individual vocables.
        if (!isEqual(node.vocables, [vId])) {
          return false;
        }

        if (!isWordNode(node)) {
          return false;
        }

        return node.inflection && test(node);
      });
    });

    // For every vocable, we have the suggestions that match the test.
    const nodes = flatten(matchingNodes).filter(val => !!val).filter(isWordNode);
    const isMatch = nodes.length > 0;
    const onlyNode = nodes.length == 1 ? nodes[0] : null;
    return {isMatch, onlyNode};
  }

  // 1. Can at least one vocable be a verb?
  const oneCanBeVerb = atLeastOneNodeOf(node => node.inflection?.type === 'verb');
  // 2. Is at least one vocable definitely not a verb?
  const oneCanNotBeVerb = atLeastOneNodeOf(node => node.inflection?.type !== 'verb',
    oneCanBeVerb.onlyNode ? oneCanBeVerb.onlyNode.vocables : undefined);

  let dialogDefaults: Partial<BaseAddEditWordDialogProps> = {};

  if (oneCanBeVerb.isMatch && oneCanNotBeVerb.isMatch) {
    dialogDefaults = {
      initialValues: {
        type: 'verb',
        wordData: {
          is_compound: true
        }
      }
    };

    if (oneCanBeVerb.onlyNode) {
      dialogDefaults.initialValues!.wordData!.vector = oneCanBeVerb.onlyNode.wordId.id;
    }
    if (oneCanNotBeVerb.onlyNode) {
      dialogDefaults.initialValues!.wordData!.primary = [oneCanNotBeVerb.onlyNode.wordId.id];
    }

    return dialogDefaults;
  }

  return null;
}