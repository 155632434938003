/**
 * High-level functions to change the caption line times.
 */


import {ICaptionOps} from "./opsInterface";
import {
  getLineGroup,
  getNextLinesWithTime,
  getPreviousLinesWithTime,
  iterateLineWords,
  setLineData
} from "./slateApi";
import {isEmpty} from "../../utils";
import {LineData, LineGroup, WordInline} from "./types";
import { Path } from "slate";


/**
 * Change the beginning of a line, while keeping the end the same, thus changing the duration as well.
 *
 * The first word will change it's time and duration accordingly:
 * - If the time is now earlier, the duration of the first word will increase.
 * - If the time is now later, the duration of the word word will decrease.
 * - If the duration of the first word would become negative, the timing information is removed,
 *   and the second word becomes the first one with a timestamp.
 */
export function setLineBeginning(ops: ICaptionOps, lineIndex: number, newTime: number) {
  const {time: currentTime, duration: currentDuration} = ops.value[lineIndex].data;

  let newDuration;
  if (isEmpty(currentDuration)) {
    newDuration = 1;
  }
  else if (!isEmpty(currentTime)) {
    const timeShift = newTime - currentTime;
    newDuration = currentDuration - timeShift;
    if (newDuration <= 0) {
      // Setup a default duration.
      newDuration = 1;
    }
  }

  const newData = {
    time: newTime,
    duration: newDuration
  };

  setLineDataAndNormalize(ops, lineIndex, newData)
  if (!isEmpty(currentTime)) {
    normalizeWordsFromLineBeginning(ops, lineIndex, newTime - currentTime);
  }
}


type setTimeFunc = (opts: { time: number }) => number | null;


/**
 * Change the end of the line, while keeping the beginning the same - in effect, only the duration
 * value has to be changed.
 *
 * The last word will change it's duration accordingly, equivalent to the rules for `setLineBeginning()`.
 */
export function setLineEnd(ops: ICaptionOps, lineIndex: number, time: number | setTimeFunc) {
  const node = ops.value[lineIndex] as LineGroup;

  const lineTime = node.data.time;
  if (isEmpty(lineTime)) {
    // If the line has no time, can't do it.
    return;
  }

  let desiredDuration: number | null;
  if (time instanceof Function) {
    desiredDuration = time({time: lineTime});
    // If the function returns null, we skip the action.
    if (isEmpty(desiredDuration)) {
      return;
    }
  } else {
    desiredDuration = time - lineTime
  }

  if (desiredDuration <= 0) {
    // What can we do here?
    // - Enforce a minimum.
    // - Do a move instead of a resize now (since a resize makes no sense).
    // - Clear the full line time - this does not work well, because the drag&drop within the waveform
    //     would then be interrupted, given that the object itself disappears. (TODO: It is anyway better
    //     to refactor the waveform to have the drag&drop logic handling on the root, not in the component).
    desiredDuration = 0.5;
    return;
  }

  setLineDataAndNormalize(ops, lineIndex, {duration: desiredDuration!});
}


/**
 * Move the line, while keeping the duration the same. Words within the lines will all move with it
 * accordingly.
 */
export function moveLine(ops: ICaptionOps, lineIndex: number, time: number) {
  setLineDataAndNormalize(ops, lineIndex, {time});
  // NB: We do not need to normalize the words, as word timestamps are stored relative to the line start,
  // and we are only  moving the line.
}


/**
 * Helps you not to forget `normalizeFromLine()`.
 */
function setLineDataAndNormalize(editor: ICaptionOps, lineIndex: number, data: null | Partial<LineData>) {
  setLineData(editor, lineIndex, data);
  normalizeFromLine(editor, lineIndex);
}


// Assume that lineIndex having been set, fix any issues before or after.
function normalizeFromLine(editor: ICaptionOps, lineIndex: number) {
  const line = editor.value[lineIndex];
  const {time: startTime, duration} = line.data;
  const endTime = startTime! + duration!;

  // Ensure next lines match
  for (const nextLineIndex of getNextLinesWithTime(editor.value, lineIndex)) {
    const nextLine = editor.value[nextLineIndex];
    const {time: nextLineStartTime, duration: nextLineDuration} = nextLine.data;
    const nextLineEndTime = nextLineStartTime! + nextLineDuration!;

    if (endTime > nextLineEndTime) {
      setLineData(editor, nextLineIndex, null);
      // NB: We should clear all word data here, but we do not have to, because in fact, if there is no line
      // time, then all the (relative) word times become invalid, as well. We should just be sure not to write it
      // to the file! (TODO)
    } else if (endTime > nextLineStartTime!) {
      setLineData(editor, nextLineIndex, {
        time: endTime,
        duration: nextLineDuration! - (endTime - nextLineStartTime!)
      });
      normalizeWordsFromLineBeginning(editor, nextLineIndex, (endTime - nextLineStartTime!))
    }
  }

  // Ensure previous lines match
  for (const prevLineIndex of getPreviousLinesWithTime(editor.value, lineIndex)) {
    const prevLine = editor.value[prevLineIndex];
    const {time: prevLineStartTime, duration: prevLineDuration} = prevLine.data;
    const prevLineEndTime = prevLineStartTime! + prevLineDuration!;

    if (startTime! <= prevLineStartTime!) {
      setLineData(editor, prevLineIndex, null);
      // TODO: Same not as above!
    } else if (startTime! < prevLineEndTime!) {
      const newDuration = startTime! - prevLineStartTime!;
      setLineData(editor, prevLineIndex, {
        duration: newDuration
      });
      normalizeWordsFromLineEnd(editor, prevLineIndex, newDuration)
    }
  }
}


// NB: The fact that we moved the internal format to use line-based word-timestamps, i.e. the first word having
// the time 0, did not buy as anything in terms of code complexity, because instead of changing all the word timestamps.

/**
 * Low-level function to modify the start or end time of a particular word.
 */
export function setWordData(editor: ICaptionOps, word: WordInline, path: Path, data: null | Partial<LineData>) {
  if (data && data.time !== undefined && data.time < 0) {
    data.time = 0;
  }

  let newData = {...word.data};
  if (!data) {
    delete newData.duration;
    delete newData.time;
  } else {
    newData = {...newData, ...data}
  }

  editor.setNode(path, {
    data: newData
  });
}

// as part of moveLine(), we now have to change all of them as part of a line resize operation.
function normalizeWordsFromLineBeginning(editor: ICaptionOps, lineIndex: number, shift: number) {
  const line = editor.value[lineIndex];

  const lineWords = iterateLineWords(line);
  for (const [word, path] of lineWords) {
    const {time} = word.data;
    if (isEmpty(time)) {
      continue;
    }

    // TODO: Keep a 0-timed word in sync with the line beginning

    // If the shift is negative (we enlarge the line), this will increase the new time, to effectively remain at the
    // same absolute position in the file.
    // If the shift is positive (we make the line smaller), this will decrease the time.
    const newTime = time - shift;

    // If the line becomes so small that it cannot fit this word, we clear it.
    if (newTime < 0) {
      setWordData(editor, word, path,null);
    }
    else {
      setWordBeginning(editor, word, path, newTime);
    }
  }
}


function normalizeWordsFromLineEnd(editor: ICaptionOps, lineIndex: number, newDuration: number) {
  const line = getLineGroup(editor.value, lineIndex);

  const lineWords = iterateLineWords(line, {reverse: true});
  for (const [word, path] of lineWords) {
    const {time, duration} = word.data;
    if (isEmpty(time)) {
      continue;
    }

    // TODO: If the duration was all the way to the end, extend it as well

    if (time >= newDuration) {
      setWordData(editor, word, path,null);
    }


    else if (duration && time + duration > newDuration) {
      setWordEnd(editor, word, path,time + duration);
    }

    else {
      // The others then should be fine also.
      break;
    }
  }
}


export function setWordBeginning(ops: ICaptionOps, word: WordInline, path: Path, newTime: number) {
  const {time: currentTime, duration: currentDuration} = word.data;

  let newDuration;
  if (isEmpty(currentDuration)) {
    newDuration = 0.5;
  }
  else if (!isEmpty(currentTime)) {
    const timeShift = newTime - currentTime;
    newDuration = currentDuration - timeShift;
    if (newDuration <= 0) {
      // Setup a default duration.
      newDuration = 0.5;
    }
  }

  const newData = {
    time: newTime,
    duration: newDuration
  };

  setWordData(ops, word, path, newData)
}


export function setWordEnd(ops: ICaptionOps, word: WordInline, path: Path, time: number) {
  const {time: wordTime} = word.data;
  if (isEmpty(wordTime)) {
    return;
  }

  let desiredDuration: number | null;
  desiredDuration = time - wordTime;

  if (desiredDuration <= 0) {
    setWordData(ops, word, path, null);
    return;
  }

  const newData = {
    duration: desiredDuration
  };

  setWordData(ops, word, path, newData)
}

