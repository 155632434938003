// Wraps our own fork of react-player, which has a different interface, with our abstracted player interface.
// We have an imperative API, but ReactPlayer is functional.
//
// As an alternative to react-player, consider that https://github.com/souporserious/react-media-player has a
// very similar internal setup, the code seems cleaner, but supports fewer sources.

import React from 'react';
import Bacon from 'baconjs';
import classnames from 'classnames';
import {PlayerState, VideoProps} from "./Api";
import styles from './ReactVideo.cssm';
import ReactPlayer from '../../contrib/react-player';
import {makeVideoUrl} from "./resolveMediaSoure";


export class ReactPlayerVideo extends React.Component<VideoProps> {

  state = {
    playing: false,
    ready: false,
    playbackRate: 1,
    videoUrl: ""
  }

  private player: ReactPlayer|null = null;
  private position$: Bacon.Bus<any, number> = new Bacon.Bus();
  private loadProgress$: Bacon.Bus<any, number> = new Bacon.Bus();

  componentDidMount(): void {
    makeVideoUrl(this.props.track).then(url => {
      this.setState({videoUrl: url});
    })
  }

  render() {
    const player = <ReactPlayer
      className={classnames(this.props.className)}
      style={this.props.style}
      width={""}
      height={""}
      controls={this.props.nativeControls}

      progressInterval={1000}

      url={this.state.videoUrl}
      ref={this.handleRef}
      onProgress={this.handleProgress}
      onReady={this.handleReady}
      playbackRate={this.state.playbackRate}

      playing={this.state.playing}
      onPlay={this.handlePlay}
      onPause={this.handlePause}
      onStart={this.handleStart}

      config={{
        youtube: {playerVars: getYoutubePlayerVars() },
        file: {
          forceAudio: this.props.forceAudio,
          attributes: {
            poster: this.props.posterUrl,
            playsInline: true,
          },
          onClick: this.props.onClick
        }
      }}
    />

    if (this.props.track.background) {
      return <div>
        <div className={styles.backgroundImage}>
        </div>
        {player}
      </div>
    }
    else {
      return player;
    }
  }

  handleReady = () => {
    this.setState({ ready: true }, this.triggerStateChange)
  }

  handleRef = (r: ReactPlayer|null) => {
    this.player = r;
    this.startStopTimeUpdates();

    if (r === null) {
      this.props.controlRef(null);
      return;
    }

    this.props.controlRef({
      pause: () => {
        this.setState({playing: false}, this.triggerStateChange);
      },
      play: () => {
        this.setState({playing: true}, this.triggerStateChange);
      },
      seekTo: async (time: number): Promise<void> => {
        this.player!.seekTo(time);

        // We do not want to wait until the event comes back from the
        // player; update the new seek to right now.
        this.setNewTime(time);
      },
      getCurrentTime: (): number => {
        return this.player!.getCurrentTime();
      },
      getState: (): PlayerState => {
        return this.buildState();
      },
      setPlaybackRate: (rate) => {
        this.setState({playbackRate: rate}, this.triggerStateChange);
      },
      time$: this.position$.toProperty(0).skipDuplicates(),
      load$: this.loadProgress$.toProperty(0).skipDuplicates(),
    });
  }

  handleStart = () => {
    this.setState({ playing: true }, this.triggerStateChange)
  }

  handleProgress = (state: {playedSeconds: number, loadedSeconds: number}) => {
    this.loadProgress$.push(state.loadedSeconds);
  }

  handlePause = () => {
    this.setState({ playing: false }, this.triggerStateChange)
  }

  handlePlay = () => {
    this.setState({ playing: true }, this.triggerStateChange)
  }

  triggerStateChange = () => {
    if (this.props.onStateChange) {
      this.props.onStateChange(this.buildState());
    };

    this.startStopTimeUpdates();
  }

  buildState(): PlayerState {
    return {
      isPlaying: this.state.playing,
      isReady: this.state.ready,
      playbackRate: this.state.playbackRate,
      duration: this.player ? this.player.getDuration() : 0
    };
  }

  private timeUpdateId: any;

  startStopTimeUpdates = () => {
    const shouldBeRunning = this.state.playing && this.player !== null;

    if (shouldBeRunning) {
      if (!this.timeUpdateId) {
        this.timeUpdateId = requestAnimationFrame(this.handleTimeUpdate)
      }
    }

    else {
      if (this.timeUpdateId) {
        cancelAnimationFrame(this.timeUpdateId)
        this.timeUpdateId = null
      }
    }
  }

  private handleTimeUpdate = () => {
    if (!this.player) {
      return null;
    }
    this.setNewTime(this.player.getCurrentTime())

    if (this.timeUpdateId) {
      this.timeUpdateId = requestAnimationFrame(this.handleTimeUpdate)
    }
  }

  private setNewTime = (time:  number) => {
    this.position$.push(time);
    if (this.props.onTime) {
      this.props.onTime(time);
    }
  }
}



export function getYoutubePlayerVars() {
  return {
    controls: 0,
    autoplay: 0,
    rel: 0,
    modestbranding: 1,
    fs: 0,
    showinfo: 0,
    // https://stackoverflow.com/questions/23070024/how-to-disable-youtube-captions-in-a-youtube-embed-link
    cc_load_policy: 3 as any
  }
}