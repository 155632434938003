import React, {useState} from 'react';
import {ActivityLog} from "./ActivityLog";
import {UserSelect} from "../../components/UserSelect";
import {Field, Formik, FieldProps} from 'formik';


export function ActivityLogPage() {
  return <div style={{
    margin: "20px"
  }}>
    <h3>Activity Log</h3>

    <Formik<{userId: string}> initialValues={{
      userId: ""
    }} onSubmit={() => {}}>
      {bag => {
        return <>
          <Field name={"userId"}>
            {
              (props: FieldProps) => { return <UserSelect {...props} /> }
            }
          </Field>

          <ActivityLog
            forUser={bag.values.userId ? parseInt(bag.values.userId) : undefined}
            onChangeUser={uid => bag.setFieldValue("userId", uid)}
          />
        </>
      }}
    </Formik>
  </div>
}