// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-CaptionEditor-index__editor--36VIR{width:100%;border-top:1px solid #9e9e9e;overflow-y:scroll;overflow-x:auto}\n", ""]);
// Exports
exports.locals = {
	"editor": "_-languagetool-editor-src-js-ui-CaptionEditor-index__editor--36VIR"
};
module.exports = exports;
