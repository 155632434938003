// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__slider--2Zpt2{background:#eee;transition:width 0.1s;cursor:pointer;flex:1}._-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__handle--iAF-b{position:absolute;width:10px;margin-left:-5px;top:0;bottom:0;border-radius:2px;background:#3498db;transform:scale(1);transition:transform 0.2s}._-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__seekBar--28oYV{position:absolute;background:#aad2f4;top:0;bottom:0;left:0}._-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__loadProgressBar--HgG_5{position:absolute;background:#dcdbdb;top:0;left:0;height:7px}\n", ""]);
// Exports
exports.locals = {
	"slider": "_-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__slider--2Zpt2",
	"handle": "_-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__handle--iAF-b",
	"seekBar": "_-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__seekBar--28oYV",
	"loadProgressBar": "_-languagetool-editor-src-js-ui-MediaPlayer-SeekBar__loadProgressBar--HgG_5"
};
module.exports = exports;
