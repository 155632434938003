import {CreateWord, getAPI, WordsAdminAPI} from "../../../wordsApi";
import React, {useContext} from "react";
import {Field, FormikProps, useFormikContext} from "formik";
import {Warning} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import {useNestedPrefix} from "../../../../../components/NestedFormik";
import {useUIControls} from "../../../../../components/FormUISwitch";
import {NewWordEntryLogicContext} from "../../../WordForm/WordFormAndLogic";


// FATHATAN, DAMMATAN, KASRATAN, FATHA, DAMMA, KASRA, SHADDA, SUKUN
const diacriticsPattern = /[\u064B\u064C\u064D\u064E\u064F\u0650\u0651\u0652]/;


export async function validateWordWarnings(word: CreateWord, addWarning: any, opts: {
  textToMatch: string | null,
  api: WordsAdminAPI,
  wordId?: number
}): Promise<{transliterated?: string[]}> {
  const {hasDictionaryResults, existingWords, couldParse, data} = await opts.api.validateWord(word, 'fa', opts.textToMatch || "");
  if (!hasDictionaryResults) {
    addWarning('not-in-dict')
  }
  if (existingWords.length && !opts.wordId) {
    addWarning('duplicate');
  }

  if (!couldParse && opts.textToMatch) {
    addWarning('could-not-parse')
  }

  if (word.base && !word.base.match(diacriticsPattern)) {
    addWarning('no-vowels')
  }

  return data;
}


const Warnings: { [key: string]: any } = {
  duplicate: {
    title: 'Word already exists.',
    description: <>
      A word with the same base text already exists in the database. An example of two words with the same lemma
      is <em>تو</em>, which can be <em>tu</em> or <em>to</em>.
      Are you sure the word you are adding is different?</>
  },

  "not-in-dict": {
    title: 'Not in a dictionary.',
    description: <>
      The word was not found in any dictionary. This might mean that you made a typing error. Please <strong>double-check
      the word</strong> you are about to add.
    </>
  },

  "could-not-parse": {
    level: 'error',
    title: 'Does not match text.',
    description: <>
      The system cannot understand how the word in the unit is a grammar form of the word you want to add. If you are
      sure you typed the word correctly, it is possible
      that the system needs to be changed to support more Persian grammar. Please let us know.
    </>
  },

  "no-vowels": {
    title: 'Check vowels',
    description: <>
      Please <strong>double check</strong> that you included all the vowels correctly. For example, you have to save the
      word as <em>صُبح</em>, not as <em>صبح</em>.
    </>
  },
}


export function WarningsField() {
  const UI = useUIControls();
  const formik = useFormikContext();
  const nestedFormPrefix = useNestedPrefix();
  const {warnings: warningsDict} = useContext(NewWordEntryLogicContext)!;

  // Get all the warnings for the current nested form.
  // Also, filter out all warnings of fields non yet touched, we do not want to show those yet.
  let warnings: string[] = [];
  Object.entries(warningsDict).forEach(([formKey, items]) => {
    // Two form is the nest level, basically
    const formPath = formKey.substr(0, formKey.lastIndexOf("."))
    const fieldName = formKey.substr(formKey.lastIndexOf("."))

    // We might make the warnings depending on certain fields being touched. For now, we use the submitCount
    // to not show them too early.
    if (formik.submitCount <= 0) {
      return;
    }

    if (formPath == nestedFormPrefix) {
      warnings.push(...items);
    }
  });

  return <Field
    name={"ignoreWarnings"}
    children={(props: { field: any, form: FormikProps<any>, [_: string]: any }) => {
      const form = props.form;

      // Show it if there are warnings, or if it was enabled, or if it was ever visible.
      if ((!warnings || !warnings.length) && !form.touched.warnings && !form.values.warnings) {
        return null;
      }

      // This particular warning is really an error, we do not allow submit.
      const allowWarningsIgnore = (warnings.indexOf('could-not-parse') == -1);

      // @ts-ignore
      let label = allowWarningsIgnore ? <UI.Checkbox label={'Ignore warnings, submit anyway'} {...props} /> : null;

      return <div style={{
        alignSelf: 'flex-start',
        minWidth: '250px',
        marginTop: '16px',
        marginBottom: '8px'
      }}>
        {warnings ? warnings.map((messageId, idx) => {
          let description, title, isError;
          if (Warnings[messageId]) {
            title = Warnings[messageId].title;
            description = Warnings[messageId].description;
            isError = Warnings[messageId].level == 'error';
          } else {
            title = "";
            description = messageId;
            isError = false;
          }

          return <div key={idx} style={{marginTop: '0.3em'}}>
            <Warning htmlColor={isError ? '#f44336' : '#ff9800'} style={{
              width: '1.4em',
              verticalAlign: 'sub',
              float: 'left'
            }}/>

            <Typography variant={'subtitle1'} style={{fontWeight: 'bold', color: isError ? '#f44336' : ""}}>
              {title}
            </Typography>
            <Typography variant={'subtitle2'} style={{color: "gray"}}>
              {description}
            </Typography>
          </div>
        }) : null}

        {label}
      </div>
    }}
  />
}