// Taken from react-overlays and adapted.

import React from 'react'
import ReactDOM from 'react-dom'


function ownerDocument(componentOrElement: any) {
  const node = ReactDOM.findDOMNode(componentOrElement);
  return (node && node.ownerDocument) || document;
}

function contains(context: any, node: any) {
  // HTML DOM and SVG DOM may have different support levels,
  // so we need to check on context instead of a document root element.
  if (context.contains) {
    return context.contains(node);
  } if (context.compareDocumentPosition){
    return context === node || !!(context.compareDocumentPosition(node) & 16);
  }
  return fallback(context, node);
};


function fallback(context: any, node: any) {
  if (node) do {
    if (node === context) return true;
  } while ((node = node.parentNode));

  return false;
}

const escapeKeyCode = 27
const noop = () => {}


function isLeftClickEvent(event: any) {
  return event.button === 0
}

function isModifiedEvent(event: any) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)
}


type Props = {
  disabled?: boolean,
  event: string,
  onRootClose?: (e: any) => void
}

/**
 * The `<RootCloseWrapper/>` component registers your callback on the document
 * when rendered. Powers the `<Overlay/>` component. This is used achieve modal
 * style behavior where your callback is triggered when the user tries to
 * interact with the rest of the document or hits the `esc` key.
 */
export class RootCloseWrapper extends React.Component<Props> {
  static defaultProps = {
    event: 'click',
  };

  preventMouseRootClose: boolean;
  removeMouseListener: any;
  removeKeyupListener: any;
  removeMouseCaptureListener: any;

  constructor(props: Props) {
    super(props)

    this.preventMouseRootClose = false
  }

  componentDidMount() {
    if (!this.props.disabled) {
      this.addEventListeners()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!this.props.disabled && prevProps.disabled) {
      this.addEventListeners()
    } else if (this.props.disabled && !prevProps.disabled) {
      this.removeEventListeners()
    }
  }

  componentWillUnmount() {
    if (!this.props.disabled) {
      this.removeEventListeners()
    }
  }

  addEventListeners = () => {
    const { event } = this.props;
    const doc = ownerDocument(this)

    // Use capture for this listener so it fires before React's listener, to
    // avoid false positives in the contains() check below if the target DOM
    // element is removed in the React mouse callback.
    doc.addEventListener(
      event,
      this.handleMouseCapture,
      true
    );
    this.removeMouseCaptureListener = () => doc.removeEventListener(event, this.handleMouseCapture, true);

    doc.addEventListener(event, this.handleMouse)
    this.removeMouseListener = () => doc.removeEventListener(event, this.handleMouse);

    doc.addEventListener('keyup', this.handleKeyUp)
    this.removeKeyupListener = () => doc.removeEventListener('keyup', this.handleKeyUp);
  }

  removeEventListeners = () => {
    if (this.removeMouseCaptureListener) this.removeMouseCaptureListener()
    if (this.removeMouseListener) this.removeMouseListener()
    if (this.removeKeyupListener) this.removeKeyupListener()
  }

  handleMouseCapture = (e: any) => {
    // Set a flag to NOT close if the popup itself is clicked.
    this.preventMouseRootClose =
      isModifiedEvent(e) ||
      !isLeftClickEvent(e) ||
      contains(ReactDOM.findDOMNode(this), e.target)
  }

  handleMouse = (e: any) => {
    if (!this.preventMouseRootClose && this.props.onRootClose) {
      this.props.onRootClose(e)
    }
  }

  handleKeyUp = (e: any) => {
    if (e.keyCode === escapeKeyCode && this.props.onRootClose) {
      this.props.onRootClose(e)
    }
  }

  render() {
    return this.props.children;
  }
}

