import * as React from "react";
import {useUIControls} from "../../../../../../components/FormUISwitch";
import {NestableField} from "../../../../../../components/NestedFormik";


export function PluralsField(props: {}) {
  const UI = useUIControls();

  return <NestableField
    name={"plurals"}
    component={UI.Text}

    label="Custom Plural Forms"
    description={"Multiple are comma separated. DO NOT PUT the regular form, such as -ha."}
    style={{
      direction: 'rtl',
      textAlign: 'left'
    }}
  />;
}