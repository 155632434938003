import React from 'react'
import {Svg, Path, G} from 'react-native-svg';

export const LangenscheidtIcon = React.memo((props: any) => (
  <Svg viewBox="0 0 114 114" width="16" height="16" {...props}>
    <G>
      <G>
        <Path fill="#fdcc0e" d="M0 0h114v114H0z" />
        <Path
          d="M89.68 89.68H24.32V85.6h13.89V28.41H24.32v-4.09h46.57v4.09H57V85.6h12.25l20.43-20.43v24.51"
          fill="#35a4ea"
        />
      </G>
    </G>
  </Svg>
));
