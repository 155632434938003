import React, { useMemo } from 'react';
import {WordForm, WordFormLogic} from "languagetool-editor/src/js/integrations/Words/WordForm/WordFormAndLogic";
import {PrimaryButton} from 'office-ui-fabric-react';
import {WordsAdminAPI} from "languagetool-editor/src/js/integrations/Words/wordsApi";
import {useClient} from "multi-apollo";
import {gql} from '@apollo/client';
import {WordDef} from "languagetool-editor/src/js/integrations/Words/assignScreen";


export function EditWordForm(props: {
  language: string,
  word: any,
  wordData?: any,
  reload: () => void
}) {
  const wordsClient = useClient("words");
  const api = useMemo(() => {
    return new WordsAdminAPI(
      async (query, vars) => {
        const response = await wordsClient.query({
          query: gql([query]),
          variables: vars
        });
        if (response.errors) {
          throw new Error("" + response.errors);
        }
        return response.data;
      },
      async (query, vars) => {
        const response = await wordsClient.mutate({
          mutation: gql([query]),
          variables: vars
        });
        if (response.errors) {
          alert("Operation Failed!");
          throw new Error("" + response.errors);
        }
        return response.data;
      },
    );
  }, [wordsClient]);

  return <WordFormLogic
    api={api}
    onWordChanged={() => {
      props.reload();
    }}
    wordId={props.word.id}
    wordText={props.word.base}
    wordType={props.word.type}
    wordDescription={props.word.description}
    wordData={props.wordData}
    lang={props.language}
    ignoredWarningIds={['not-in-dict', 'no-vowels', 'could-not-parse']}
  >
    {formik => {
      return <>
        <WordForm
          lang={props.language}
          allowInvariantSubtypes={true}
          showDescriptionField={true}
          {...{
            config: {
              autoSounds: props.word.baseTransliteration ? JSON.parse(props.word.baseTransliteration) : null
            }
          }}
        />

        <div style={{marginTop: '20px'}}>
          <PrimaryButton
            onClick={formik.submitForm}
            disabled={formik.isSubmitting}
          >Save Changes</PrimaryButton>
        </div>
      </>
    }}
  </WordFormLogic>
}