import React from 'react';
import {BaseDialog} from "../../../ui/dialogs/DialogManager";
import {Button, CircularProgress, TextField, FormControlLabel} from '@material-ui/core';
import {useMutation, useQuery} from "multi-apollo";
import {gql as gqlm} from '@apollo/client';
import {
  IssueDetailsDialogQuery,
  IssueDetailsDialogQuery_contentIssue,
} from "../../../../types/IssueDetailsDialogQuery";
import {Formik, Field, FieldProps, Form} from 'formik';
import {AddContentIssueComment} from "../../../../types/AddContentIssueComment";
import {useNumber} from 'react-use';
import {formatDistanceToNow, parseISO} from "date-fns";
import {DateTime} from "../../../components/DateTime";
import Markdown from "react-markdown";
import { Checkbox } from 'formik-material-ui';
import {ChangeTagsLink, HeaderInfoSlice, Tag} from "./SingleIssue";


// language=GraphQL
const issueDetailsDialogQuery = gqlm`
  query IssueDetailsDialogQuery($id: ID!) {
    contentIssue(id: $id) {
      id,
      description,
      tags,
      createdAt,
      assignedTo { id, name }
      createdBy {
        ...on Editor {
          id,
          name
        },
        ...on User {
          id,
          name
        }
      }
      isResolved,
      sampleText {
        structure
      }
      location {
        ... on ContentIssueLocationLine {
          lineIndex
        }
        ... on ContentIssueLocationNode {
          nodeId,
          lineIndexOpt: lineIndex
        }
        ... on ContentIssueLocationVocable {
          vocableId,
          lineIndexOpt: lineIndex
        }
      }
      unit {
        id,
        name,
      }
      comments {
        id,
        text,
        createdBy { id, name },
        createdAt
      }
    }      
  }
`;


export function useIssueDetails(id: string) {
  const {data, error, loading} = useQuery<IssueDetailsDialogQuery>(issueDetailsDialogQuery, {
    client: "market",
    variables: {
      id
    }
  });

  return {data, error, loading};
}


export function useAddContentIssueComment() {
  const [addContentIssueComment, _] = useMutation<AddContentIssueComment>(gqlm`    
     mutation AddContentIssueComment($props: AddContentIssueCommentProps!) {
       addContentIssueComment(props: $props) {
         comment {
           id,
           text,
           createdBy { id, name },
           createdAt
         },
         issue {
           id
         }
       }
     }
  `, {
    client: 'market',
  });

  return (props: {
    contentIssueId: string,
    text: string,
    disableNotifications?: boolean
  }) => addContentIssueComment({
    variables: {
      props: {
        text: props.text,
        issueId: props.contentIssueId,
        disableNotifications: props.disableNotifications
      }
    },
    update(cache, result) {
      const issueId = result?.data?.addContentIssueComment.issue.id;
      const existingRecord = cache.readQuery<IssueDetailsDialogQuery>({ query: issueDetailsDialogQuery, variables: {id: issueId} });
      if (!existingRecord) { return; }
      existingRecord.contentIssue?.comments.push(result?.data?.addContentIssueComment.comment!);
      cache.writeQuery({
        query: issueDetailsDialogQuery,
        data: existingRecord
      });
    }
  });
}



export function IssueDetailsDialog(props: {
  contentIssueId: string,
  isOpen: boolean,
  onRequestClose: () => void
}) {
  const {data, error, loading} = useIssueDetails(props.contentIssueId);

  return <BaseDialog
    isOpen={props.isOpen}
    onRequestClose={props.onRequestClose}
    title={"Issue Details"}
    actions={[
      <Button key="cancel" onClick={props.onRequestClose}>
        Close
      </Button>
    ]}
  >
    {loading ? <CircularProgress /> : null}
    {error ? <div>Loading failed</div> : null}
    {data && data.contentIssue ?
      <DialogContent
        issue={data.contentIssue}
      /> : null}
  </BaseDialog>
}


export function DialogContent(props: {
  issue: IssueDetailsDialogQuery_contentIssue
}) {
  return <div>
    <div>
      <HeaderInfoSlice label={"Tags"}>
        {props.issue.tags?.map(tag => <Tag key={tag} tag={tag} />)}
        {" "} <ChangeTagsLink
        selectedRecord={props.issue}
      />
      </HeaderInfoSlice>

      <a href={`https://editor.languagetool.xyz/#/issues/${props.issue.id}`} target={"_blank"}>Link</a>
    </div>

    <IssueDetails
      issue={props.issue}
    />
  </div>
}


export function IssueDetails(props: {
  issue: IssueDetailsDialogQuery_contentIssue
}) {
  const {issue} = props;

  const [formKey, {inc: incFormKey}] = useNumber();

  return <div>
    <Comment
      comment={{
        createdBy: {
          id: "",
          name: issue.createdBy?.name || "Issue Reporter"
        },
        createdAt: issue.createdAt,
        text: issue.description
      }}
    />

    {issue.comments.map(comment => {
      return <Comment key={comment.id} comment={comment} />
    })}

    <WriteCommentForm
      key={formKey}
      issueId={props.issue.id}
      onRequestClose={incFormKey}
    />
  </div>
}


function Comment(props: {
  comment: {
    createdBy: {name: string, id: string},
    createdAt: string,
    text: string
  }
}) {
  const {comment} = props;

  return <div style={{
    margin: '1em 0 1em'
  }}>
    <div style={{
      color: 'gray',
      fontSize: '14px'
    }}>
      {comment.createdBy.name || comment.createdBy.id} wrote <strong>
      {formatDistanceToNow(parseISO(comment.createdAt))} ago
    </strong> on the {" "} <strong><DateTime date={comment.createdAt} /></strong>:
    </div>
    <div style={{"marginTop": '.5em'}}>
      <Markdown source={comment.text} />
    </div>
  </div>
}


function WriteCommentForm(props: {
  initialText?: string,
  issueId: string,
  onRequestClose: () => void
}) {
  const addContentIssueComment = useAddContentIssueComment();

  return (
    <Formik<{text: string, disableNotifications: boolean}>
      initialValues={{
        text: props.initialText || "",
        disableNotifications: false
      }}
      validate={values => {
        if (!values.text) {
          return {text: "You need to write a comment."}
        }
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await addContentIssueComment({
            text: values.text,
            contentIssueId: props.issueId,
            disableNotifications: values.disableNotifications
          });
          props.onRequestClose();
        }
        finally {
          setSubmitting(false);
        }
      }}
    >
      {formik => {
        return <Form style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
          <Field name={"text"}>
            {
              (props: FieldProps) => {
                return <TextField fullWidth multiline placeholder={""} {...props.field} />
              }
            }
          </Field>
          <div style={{marginTop: '10px'}}>
            <Button
              variant="contained"
              color={"primary"}
              disabled={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              Write Comment
            </Button>

            <span style={{marginLeft: '30px'}}>
              <Field name={"disableNotifications"}>
                {
                  (props: FieldProps) => {
                    return <FormControlLabel
                      label={"Do not send email notifications"}
                      control={<Checkbox
                        {...props}
                      />}
                    />
                  }
                }
              </Field>
            </span>
          </div>
        </Form>
      }}
    </Formik>)
}